<template>
  <section>
    <form data-vv-scope="work-item-part" @submit.prevent="validateBeforeSubmit" class="view-container" @change="clearErrors">
      <div class="columns is-mobile is-multiline is-marginless">

        <div class="column is-12">
          <button-list-selector
            validateAs="order status"
            label="Order Status"
            :required="true"
            scope="work-item-part"
            v-model="selectedVehiclePartOrderStatus"
            :disabled="!allowedVehiclePartOrderStatusValues"
            :activeValues="allowedVehiclePartOrderStatusValues"
            :availableValues="this.definedTypes.vehiclePartOrderStatusType.options"
            :enabled="specialModifyRights"></button-list-selector>
        </div>

        <div class="column is-12">
          <vehicle-part-selector
            label="Vehicle Part"
            :required="true"
            v-model="selectedVehiclePart"
            scope="work-item-part"
            :selectedDealer="currentDealer"
            :allowNew="true"
            :allowEdit="true"
            validateAs="vehicle part">
          </vehicle-part-selector>
        </div>

        <div class="column is-6" v-if="selectedVehiclePart">
          <label class="label">Part Code<b-icon pack="fas" icon="star" class="fa-ss" v-if="partCodeRequired"></b-icon></label>
          <div class="control">
            <input name="partCode" data-vv-as="part code" v-validate="partCodeRequired ? 'required': ''" :class="{'input': true, 'is-danger': errors.has('work-item-part.partCode') }" type="text" placeholder="Enter part code" v-model="currentWorkItemPart.partCode" readonly>
            <span v-show="errors.has('work-item-part.partCode')" class="help is-danger">
              <i v-show="errors.has('work-item-part.partCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('work-item-part.partCode') }}
            </span>
          </div>
        </div>

        <div class="column is-12 " v-if="selectedVehiclePart">
          <label class="label">Part Description<b-icon pack="fas" icon="star" class="fa-ss" v-if="partDescriptionRequired"></b-icon></label>
          <div class="control">
            <textarea
              placeholder="Enter part description"
              name="partDescription"
              v-validate="partDescriptionRequired ? 'required': ''"
              data-vv-as="part description"
              :class="{'textarea': true, 'is-danger': errors.has('work-item-part.partDescription') }"
              rows="4"
              v-model="currentWorkItemPart.partDescription"
              :disabled="!specialModifyRights">
            </textarea>
            <span v-show="errors.has('work-item-part.partDescription')" class="help is-danger">
              <i v-show="errors.has('work-item-part.partDescription')" class="fas fa-exclamation-triangle"></i> {{ errors.first('work-item-part.partDescription') }}
            </span>
          </div>
        </div>

        <div class="column is-6" v-if="selectedVehiclePart">
          <label class="label">Required Quantity<b-icon pack="fas" icon="star" class="fa-ss" v-if="quantityRequired"></b-icon></label>
          <div class="control">
            <input type="number" name="quantity" data-vv-as="quantity" v-validate="quantityRequired ? 'required': ''" :class="{'input': true, 'is-danger': errors.has('work-item-part.quantity') }" placeholder="0.00" v-model="currentWorkItemPart.quantity">
            <span v-show="errors.has('work-item-part.quantity')" class="help is-danger">
              <i v-show="errors.has('work-item-part.quantity')" class="fas fa-exclamation-triangle"></i> {{ errors.first('work-item-part.quantity') }}
            </span>
          </div>
        </div>

        <div class="column is-6" v-if="selectedVehiclePart">
          <label class="label">Unit Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="unitCostRequired"></b-icon></label>
          <div class="control has-icons-left">
            <money name="unitCost" data-vv-as="unit cost" v-validate="{'required': unitCostRequired, 'decimal':2}" :class="{'input': true, 'is-danger': errors.has('work-item-part.unitCost') }" type="text" placeholder="0.00" v-model="currentWorkItemPart.unitCost" v-bind="$globalMoneyFormat"></money>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
            <span v-show="errors.has('work-item-part.unitCost')" class="help is-danger">
              <i v-show="errors.has('work-item-part.unitCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('work-item-part.unitCost') }}
            </span>
          </div>
        </div>

        <div class="column is-6" v-if="selectedVehiclePart">
          <label class="label">Calculated Total Cost</label>
          <div class="control has-icons-left">
            <money name="totalCost" :class="{'input': true}" type="text" placeholder="0.00" :value="calculateTotalCost" v-bind="$globalMoneyFormat" disabled></money>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
      </div>

      <portal :to="useFooterPortal">
        <div class="field is-grouped">
          <p class="control" v-if="hasFeatureAccess(editAccessPermission)">
            <button key="saveWorkItemPart" class="button is-primary" type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a key="cancelWorkItemPart" class="button is-danger" @click.prevent="onCancel()">Cancel</a>
          </p>
          <p class="control" v-if="hasFeatureAccess(deleteAccessPermission)">
            <button key="deleteWorkItemPart" class="button is-warning" type="button" @click.prevent="onDelete">Delete</button>
          </p>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Delete Action</p>
        </header>
        <section class="modal-card-body">
          Are you sure you wish to delete this line item part?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirmDelete()">Delete</button>
          <button class="button" @click="isDeleteConfirmationActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import vehiclePartService from '@/services/vehiclePartService'
import VehiclePartSelector from '@/components/generic/VehiclePartSelector'

const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'RoServiceWorkItemPart',
  mixins: [utilitiesMixin],
  props: {
    useFooterPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    forRoServiceWorkItem: {
      type: Object,
      default: function () {
        return {
          id: 0,
          lines: []
        }
      }
    },
    currentWorkItemPartId: {
      default: function () {
        return '0'
      }
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'vehicle-part-selector': VehiclePartSelector
  },
  data () {
    return {
      currentWorkItemPart: {
        id: this.$uuid.v4(),
        status: 'Active',
        orderStatusId: 0,
        vehiclePartId: 0,
        partCode: '',
        partDescription: '',
        quantity: 1,
        unitCost: 0.00,
        totalCost: 0.00,
        new: true
      },
      mode: 0,
      selectedVehiclePartOrderStatus: null,
      selectedVehiclePart: null,
      isDeleteConfirmationActive: false,
      createAccessPermission: 'ro.service.create',
      viewAccessPermission: 'ro.service.view',
      editAccessPermission: 'ro.service.modify',
      deleteAccessPermission: 'ro.service.delete',
      partCodeRequired: true,
      partDescriptionRequired: true,
      quantityRequired: true,
      unitCostRequired: true
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapConfigGetters(['definedTypes']),
    allowedVehiclePartOrderStatusValues: function () {
      return this.definedTypes.vehiclePartOrderStatusType.options
    },
    specialModifyRights: function () {
      return true
      // return this.hasFeatureAccess('ro.service.special.edit', false)
    },
    calculateTotalCost: function () {
      return (this.currentWorkItemPart.quantity || 0) * (this.currentWorkItemPart.unitCost || 0)
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll('work-item-part').then((result) => {
        if (result) {
          this.onSave()
        } else {
          this.$goToValidationError(this.$validator.errors.items[0].id)
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
    },
    onConfirmDelete: function () {
      this.isDeleteConfirmationActive = false
      this.currentWorkItemPart.status = 'Deleted'
      this.$emit('save-edit-work-item-part', this.currentWorkItemPart, 'Deleted')
    },
    onDelete: function () {
      this.isDeleteConfirmationActive = true
    },
    onSave: function () {
      this.currentWorkItemPart.totalCost = this.calculateTotalCost
      if (this.currentWorkItemPart.new === true) {
        this.currentWorkItemPart.new = false
        this.$emit('save-new-work-item-part', this.currentWorkItemPart, 'Active')
      } else {
        this.$emit('save-edit-work-item-part', this.currentWorkItemPart, 'Active')
      }
    },
    onCancel: function () {
      this.$emit('cancel-work-item-part', {})
    },
    loadVehiclePart: function (vehiclePartId) {
      this.isLoading = true
      this.serverErrors = []
      this.serverErrorMessage = ''
      vehiclePartService.single(vehiclePartId).then(response => {
        this.selectedVehiclePart = response.vehiclePart
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    }
  },
  watch: {
    selectedVehiclePart: function (newValue, oldValue) {
      if (newValue) {
        let oldVehiclePartId = this.currentWorkItemPart.vehiclePartId
        let isVehiclePartChanged = oldVehiclePartId !== newValue.id
        if (this.currentWorkItemPart.new === false && !isVehiclePartChanged) return

        this.currentWorkItemPart.vehiclePartId = newValue.id
        this.currentWorkItemPart.partCode = newValue.partNumber
        this.currentWorkItemPart.partDescription = newValue.description
        this.currentWorkItemPart.quantity = 1
        this.currentWorkItemPart.unitCost = newValue.unitCost
        this.currentWorkItemPart.totalCost = this.calculateTotalCost
      }
    },
    selectedVehiclePartOrderStatus: function (newValue, oldValue) {
      if (newValue) {
        this.currentWorkItemPart.orderStatusId = newValue.id
      }
    }
  },
  mounted: function () {
    if (this.currentWorkItemPartId !== '0') {
      var that = this
      let found = this.forRoServiceWorkItem.parts.find(function (x) {
        return x.id === that.currentWorkItemPartId
      })

      if (found) {
        this.currentWorkItemPart = Object.assign({}, found)
        this.selectedVehiclePartOrderStatus = this.definedTypes.vehiclePartOrderStatusType.options.find((x) => x.id === this.currentWorkItemPart.orderStatusId)
        this.loadVehiclePart(this.currentWorkItemPart.vehiclePartId)
      }
    } else {
      this.selectedVehiclePartOrderStatus = { id: 0, name: 'New', altName: 'New' }
    }
  }
}
</script>

<style scoped>

</style>
