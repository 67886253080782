export default {
  all: [
    {
      id: 1,
      status: 'Active',
      subscriberId: 1,
      code: 'OPC0000001',
      listName: 'OPC0000001 - Replace air conditioning filter',
      description: 'Replace air conditioning filter',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      unitCost: 13.5
    },
    {
      id: 2,
      status: 'Active',
      subscriberId: 1,
      code: 'OPC0000002',
      listName: 'OPC0000002 - Replace tyres',
      description: 'Replace tyres',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      unitCost: 3
    },
    {
      id: 3,
      status: 'Active',
      subscriberId: 1,
      code: 'OPC0000003',
      listName: 'OPC0000003 - Replace spark plugs',
      description: 'Replace spark plugs',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      unitCost: 1
    },
    {
      id: 4,
      status: 'Active',
      subscriberId: 1,
      code: 'OPC0000004',
      listName: 'OPC0000004 - Replace engine',
      description: 'Replace engine',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      unitCost: 48
    },
    {
      id: 5,
      status: 'Active',
      subscriberId: 1,
      code: 'OPC0000005',
      listName: 'OPC0000005 - Replace worn rubbers',
      description: 'Replace worn rubbers',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      unitCost: 3
    },
    {
      id: 6,
      status: 'Active',
      subscriberId: 1,
      code: 'OPC0000006',
      listName: 'OPC0000006 - Replace windscreen wipers',
      description: 'Replace windscreen wipers',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      unitCost: 6
    }
  ]
}
