<template>
    <div class="is-inline-block" v-if="hasBuyersAgreementAccess">
      <div class="is-inline-block" v-if="hasBuyersAgreementAccess">
        <div class="quick-action" v-if="isValidForBuyersAgreementSigning">
          <button type="button" class="button" @click="updateBuyersAgreementModalState(true)" v-tooltip="getToolTipForBuyersAgreement">
            <i data-type="B" class="icon fal fa-envelope-open-text fa-lg" :class="getColorClassByAgreementStatus(currentDeal.buyersOrderStatusId)" v-if="!currentDeal.isBuyersAgreementUploaded"/>
            <i data-type="U" class="icon fal fa-upload fa-lg has-text-success" v-else/>
          </button>
        </div>
        <div class="quick-action" v-else>
          <button type="button" class="button" disabled :title="invalidForBuyersAgreementReasons">
            <b-icon pack="fal" icon="envelope-open-text" custom-size="fa-lg"></b-icon>
          </button>
        </div>
      </div>
      <div class="control is-inline-block quick-action" v-if="lastSignedBuyersAgreement !== '' && hasBuyersAgreementAccess">
        <button class="button is-success" @click.prevent="onDownloadLastBuyersAgreement" title="Click to download the last signed buyers order.">
          <b-icon pack="fal" icon="cloud-download" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <pdf-modal
          :pdfFile="buyersAgreementSelectedPdf"
          :usePortal="true"
          :portalOrder="2"
          v-on:on-pdf-downloaded="onPreviewed"
          v-on:on-pdf-closed="onPreviewClosed"
          v-if="buyersAgreementSelectedPdf && buyersAgreementSelectedPdf.src">
      </pdf-modal>
      <portal to="global-modal-portal" v-if="isNewBuyersAgreement" :order="1">
        <b-modal :active.sync="isNewBuyersAgreement" :full-screen="isMobile" class="buyers-order-modal" :has-modal-card="true" :canCancel="false">
          <div class="modal-card">
            <header class="modal-card-head is-block">
              <div class="subtitle is-7 has-text-centered has-text-info is-marginless">Send Buyers Order to finalize sale of vehicle</div>
              <el-steps :active="active" :space="300" finish-status="wait" simple style="background-color: whitesmoke !important;" v-show="currentDeal.saleType === 'Retail'">
                <el-step class="el-step" title="Pricing" icon="fal fa-dollar-sign fa-lg" @click.native="active = 0"></el-step>
                <el-step class="el-step" title="Definition" icon="fal fa-users-class fa-lg" @click.native="next"></el-step>
              </el-steps>
            </header>
            <section class="modal-card-body view-container" style="padding: 0 0;">
              <div name="Pricing" v-show="currentDeal.saleType === 'Retail' && active === 0">
                <section style="padding: 12px">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-4">
                      <label class="label">Sales Price<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="salePrice" data-vv-scope="page-1" data-vv-as="sales price" v-validate="{'decimal': 2, 'required': true,  'min_value': 1.00}" class="is-small" :class="{'input': true}" type="text" v-model="salePrice" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.salePrice')" class="help is-danger">
                          <i v-show="errors.has('page-1.salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.salePrice') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-4">
                      <button-list-selector
                        scope="page-1"
                        validateAs="contract sale type"
                        label="Contract Sale Type"
                        :required="true"
                        v-model="selectedVehicleType"
                        :useValidator="thisValidator"
                        :availableValues="definedTypes.dealSaleVehicleType.options">
                      </button-list-selector>
                    </div>
                    <div class="column is-3">
                        <label class="label">Contract Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <el-date-picker
                          name="contractSoldAt"
                          v-model="contractSoldAt"
                          type="date"
                          placeholder="Click to select..."
                          :clearable="true"
                          :editable="true"
                          :class="{'is-danger': errors.has('page-1.contractSoldAt') }"
                          format="MM-dd-yyyy"
                          data-vv-scope="page-1"
                          popper-class="datepicker-container"
                          v-validate="'required'"
                          data-vv-as="contract date">
                        </el-date-picker>
                        <span v-show="errors.has('page-1.contractSoldAt')" class="help is-danger">
                          <i v-show="errors.has('page-1.contractSoldAt')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.contractSoldAt') }}
                        </span>
                        <pop-fill-control title="Click to replace contract date with this value" :fillableValue="dealSoldAt" :onFillAction="onSetContractDate"></pop-fill-control>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss" v-if="true"></b-icon></label>
                        <div class="control is-vertical-center">
                          <input name="usage" data-vv-scope="page-1" data-vv-as="miles" class="mileage-input" v-validate="{'numeric': true, 'required': true, 'min_value': currentInventory.mileageEstimate ? 0 : minimumUsageAllowed}"
                            :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('page-1.usage') }" type="number" placeholder="#####" v-model="updatedUsage">
                          <usage-history-component
                            :inventoryId="currentInventory.id"
                            :vinNo="currentInventory.vinNo"
                            :vinUsageHistory="currentInventory.vinUsageHistory"
                            :usage="Number(currentInventory.usage)"
                            v-on:on-last-mileage-click="onLastMileageClick"
                            :usageRequired="true"
                            :minimumUsageAllowed="currentInventory.mileageEstimate ? 0 : minimumUsageAllowed"
                            :usePortal="false"
                            :showEditIcon="false">
                          </usage-history-component>
                        </div>
                        <span v-show="errors.has('page-1.usage')" class="help is-danger"><i v-show="errors.has('page-1.usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.usage') }}</span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Pre-Delivery Service Charge<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="preDeliveryServiceCharge" data-vv-scope="page-1" data-vv-as="pre-delivery service charge" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="preDeliveryServiceCharge" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.preDeliveryServiceCharge')" class="help is-danger">
                          <i v-show="errors.has('page-1.preDeliveryServiceCharge')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.preDeliveryServiceCharge') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Electronic Registration Filing Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="electronicRegistrationFilingFee" data-vv-scope="page-1" data-vv-as="electronic registration filing fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="electronicRegistrationFilingFee" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.electronicRegistrationFilingFee')" class="help is-danger">
                          <i v-show="errors.has('page-1.electronicRegistrationFilingFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.electronicRegistrationFilingFee') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Battery/Tire Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="batteryTireFee" data-vv-scope="page-1" data-vv-as="battery or tire fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="batteryTireFee" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.batteryTireFee')" class="help is-danger">
                          <i v-show="errors.has('page-1.batteryTireFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.batteryTireFee') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Documentation Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="documentationFee" data-vv-scope="page-1" data-vv-as="documentation fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="documentationFee" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.documentationFee')" class="help is-danger">
                          <i v-show="errors.has('page-1.documentationFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.documentationFee') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Sales Tax<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="salesTax" data-vv-scope="page-1" data-vv-as="sales tax" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="salesTax" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-percentage"></i>
                        </span>
                        <span v-show="errors.has('page-1.salesTax')" class="help is-danger">
                          <i v-show="errors.has('page-1.salesTax')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.salesTax') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">New Vehicle Lemon Law Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="lemonLawFee" data-vv-scope="page-1" data-vv-as="lemon law fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="lemonLawFee" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.lemonLawFee')" class="help is-danger">
                          <i v-show="errors.has('page-1.lemonLawFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.lemonLawFee') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Registration & Titling Fees<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="registrationTitlingFee" data-vv-scope="page-1" data-vv-as="registration and titiling fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="registrationTitlingFee" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.registrationTitlingFee')" class="help is-danger">
                          <i v-show="errors.has('page-1.registrationTitlingFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.registrationTitlingFee') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Doc Stamps Fees<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="docStampsFee" data-vv-scope="page-1" data-vv-as="doc stamps fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="docStampsFee" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.docStampsFee')" class="help is-danger">
                          <i v-show="errors.has('page-1.docStampsFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.docStampsFee') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Rebates<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="rebates" data-vv-scope="page-1" data-vv-as="rebates" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="rebates" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.rebates')" class="help is-danger">
                          <i v-show="errors.has('page-1.rebates')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.rebates') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Non-Cash Credits<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="nonCashCredits" data-vv-scope="page-1" data-vv-as="non-cash credits" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="nonCashCredits" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.nonCashCredits')" class="help is-danger">
                          <i v-show="errors.has('page-1.nonCashCredits')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.nonCashCredits') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Cash Due at Delivery<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="cashDue" data-vv-scope="page-1" data-vv-as="cash due" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="cashDue" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.cashDue')" class="help is-danger">
                          <i v-show="errors.has('page-1.cashDue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.cashDue') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Deposit<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="deposit" data-vv-scope="page-1" data-vv-as="deposit" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="deposit" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.deposit')" class="help is-danger">
                          <i v-show="errors.has('page-1.deposit')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.deposit') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">County Charge<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="countyCharge" data-vv-scope="page-1" data-vv-as="county charge" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="countyCharge" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.countyCharge')" class="help is-danger">
                          <i v-show="errors.has('page-1.countyCharge')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.countyCharge') }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Additional Notes</label>
                        <div class="control">
                          <textarea rows="3" name="retailAgreementNotes" v-validate="{'required': false}" :class="{'textarea': true}" data-vv-scope="page-1" data-vv-as="notes" v-model="retailAgreementNotes"></textarea>
                          <span v-show="errors.has('page-1.retailAgreementNotes')" class="help is-danger"><i v-show="errors.has('page-1.retailAgreementNotes')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.retailAgreementNotes') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div name="Definition" v-show="(currentDeal.saleType === 'Retail' && active === 1) || currentDeal.saleType === 'Wholesale'">
                <b-tabs size="is-small" :animated="false" type="is-toggle" @change="loadData()" v-model="tabIndex">
                  <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                    <section style="padding: 12px">
                      <div id="simple-overview" class="box">
                        <section-header :title="'Sale Details'"></section-header>
                        <div class="columns is-multiline is-mobile">
                          <div class="column is-4">
                            <label class="label">Deal #</label>
                            <div class="field">
                              {{ currentDeal.dealCode || '-' }}
                            </div>
                          </div>
                          <div class="column is-4">
                            <div class="field">
                              <label class="label">Sold To</label>
                              <div class="field">
                                {{currentDeal.supplierName}}
                              </div>
                            </div>
                          </div>
                          <div class="column is-4">
                            <div class="field">
                              <label class="label">Sales Person</label>
                              <div class="field">
                                {{currentDeal.salesPersonName || '-' }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div v-for="dealItem in this.soldItems" :key="dealItem.id">
                          <div class="columns is-multiline is-mobile">
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Vehicle</label>
                                <div class="field">
                                  {{ dealItem.forInventory.name}}
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Vin</label>
                                <div class="field">
                                  {{ dealItem.forInventory.vinNo}}
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Stock No</label>
                                <div class="field">
                                  <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)">
                                    <span class="has-text-weight-semibold" :title="dealItem.forInventory.stockNo">{{ dealItem.forInventory.stockNo}}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Mileage</label>
                                <div class="field">
                                  {{ dealItem.forInventory.usage }}
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Sale Price</label>
                                <div class="field">
                                  {{ dealItem.salePrice | currency }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="!isUpload">
                        <form data-vv-scope="page-2">
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <label class="label">Sale Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <el-date-picker
                                data-vv-scope="page-2"
                                @change="onFormChange()"
                                v-model="saleDate"
                                type="date"
                                format="MM-dd-yyyy"
                                name="saleDate"
                                id="saleDate"
                                data-vv-as="sale date"
                                :class="{'is-danger': errors.has('saleDate') }"
                                v-validate="'required'"
                                popper-class="datepicker-container"
                                placeholder="Click to select...">
                              </el-date-picker>
                              <span v-show="errors.has('page-2.saleDate')" class="help is-danger">
                                <i v-show="errors.has('page-2.saleDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.saleDate') }}
                              </span>
                            </div>
                            <div class="column is-12">
                              <div class="field">
                                <label class="label">Recipients Email: <b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                                <multiselect
                                  :multiple="true"
                                  :taggable="true"
                                  data-vv-scope="page-2"
                                  @tag="onRecipientAdded"
                                  v-validate="'required'"
                                  name="recipients"
                                  label="fullName"
                                  trackBy="email"
                                  tagPlaceholder="Enter to add a new destination email address"
                                  v-model="selectedRecipients"
                                  :options="selectedRecipients"
                                  data-vv-validate-on="input|close"
                                  data-vv-as="recipients email"
                                  :class="{'is-danger': errors.has('page-2.recipients') }">
                                </multiselect>
                                <span v-show="errors.has('page-2.recipients')" class="help is-danger"><i v-show="errors.has('page-2.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.recipients') }}</span>
                                <span v-show="mustShowEmailError" class="help is-danger"><i v-show="mustShowEmailError" class="fas fa-exclamation-triangle"></i> {{ emailMatchErrorMessage }}</span>
                              </div>
                            </div>
                            <div class="column is-12">
                              <div class="field">
                                <label class="label">Recipients Mobile</label>
                                <multiselect
                                  :multiple="true"
                                  :taggable="true"
                                  data-vv-as="recipients mobile"
                                  data-vv-validate-on="input|close"
                                  @tag="onRecipientMobileAdded"
                                  name="recipients-mobile"
                                  label="fullName"
                                  trackBy="mobile"
                                  tagPlaceholder="Enter to add mobile number"
                                  v-model="selectedRecipientsMobile"
                                  :options="selectedRecipientsMobile">
                                </multiselect>
                                <span v-show="mobileInvalidFormat !== ''" class="help is-danger"><i v-show="mobileInvalidFormat !== ''" class="fas fa-exclamation-triangle"></i> {{ mobileInvalidFormat }}</span>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="columns is-multiline">
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Overriding Subject</label>
                              <div class="control">
                                <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                              </div>
                            </div>
                          </div>
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Optional Message</label>
                              <div class="control">
                                <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6 no-v-padding">
                            <switch-selector type="is-info" label="Would you like to attach title documents?" v-model="attachDocuments"></switch-selector>
                          </div>
                          <div class="column is-6 no-v-padding">
                            <switch-selector type="is-info" label="Watermark Title?" v-model="watermarkTitle"></switch-selector>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="columns" style="margin-left:-0.5em">
                          <div class="column is-12">
                            <file-selector v-model="buyersAgreementDoc" label="Signed Agreement" :multiple="false" :required="true" :isBoxed="false" :usePortalViewer="false"></file-selector>
                          </div>
                        </div>
                      </div>
                    </section>
                  </b-tab-item>
                  <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                    <section>
                      <b-table
                        class="lists"
                        :data="documentSigningRequestData">
                        <template slot-scope="props">
                          <b-table-column field="status" label="Status" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            <i data-type="B" class="icon fal fa-envelope-open-text fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.signingStatus)" :title="'Purchasers Agreement Status: ' + getDescriptionByAgreementStatus(props.row.signingStatus)"/>
                            <a target="live-doc-download" :href="buildBuyersAgreementUrl(props.row.dealFileLocation)" title="Click to download the last signed purchasers agreement." v-if="props.row.dealFileLocation && (props.row.signingStatus == 10 || props.row.signingStatus === 3)"><i class="icon fal fa-cloud-download fa-lg list-icon has-text-success"/></a>
                          </b-table-column>
                          <b-table-column field="email" label="Recipients" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ props.row.destinationAddresses }}
                          </b-table-column>
                          <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ formateSignatureType(props.row.type) }}
                          </b-table-column>
                          <b-table-column field="date" label="Date" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ props.row.sentAt | formatDateOnly }}
                          </b-table-column>
                          <b-table-column field="time" label="Time" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ convertTimeString(props.row.sentAt, props.row.signingStatus) }}
                          </b-table-column>
                          <b-table-column field="id" label="Actions">
                            <a v-if="props.row.cancellationSent === false" @click.stop.prevent="onCancelBuyersAgreement(props.row)"><i class="icon fa-fw fal fa-times-circle fa-lg" :class="{'has-text-danger': !isCancellationDisabled(props.row), 'has-text-grey': isCancellationDisabled(props.row)}" :title="isCancellationDisabled(props.row) ? 'Request cannot be cancelled at this point' : 'Cancel Request'"/></a>
                            <a v-else><i class="icon fa-fw fal fa-times-circle fa-lg has-text-success" title='Cancellation request has been made'/></a>
                            <a v-if="props.row.reminderSent === false" @click.stop.prevent="onBuyersAgreementReminder(props.row)"><i class="icon fa-fw fal fa-redo fa-lg" :class="{'has-text-link': !isReminderDisabled(props.row), 'has-text-grey': isReminderDisabled(props.row)}" :title="isReminderDisabled(props.row) ? 'Reminder cannot be sent at this point' : 'Send Reminder'"/></a>
                            <a v-else><i class="icon fa-fw fal fa-envelope fa-lg has-text-success" title='Reminder has been sent'/></a>
                          </b-table-column>
                        </template>

                        <template slot="empty">
                          <section class="section">
                            <div class="has-text-centered">
                              <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                              <p>No deal documents has been sent for this item.</p>
                            </div>
                          </section>
                        </template>

                      </b-table>
                    </section>
                  </b-tab-item>
                </b-tabs>
              </div>

            </section>
            <footer class="modal-card-foot">
              <div class="columns" style="width: 100%;">
                <div :class="isMobile ? 'column is-2 is-pulled-left' : 'column is-8'">
                  <button type="button" class="button is-link" @click="previous" v-if="currentDeal.saleType === 'Retail' && active > 0 && !isMobile">Previous</button>
                  <button type="button" class="button is-link" @click="previous" v-else-if="currentDeal.saleType === 'Retail' && active > 0 && isMobile"><b-icon pack="fal" icon="arrow-alt-left" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-link" :class="{ 'is-loading' : isCostSaving }" @click="next" v-if="currentDeal.saleType === 'Retail' && active < 1 && !isMobile">Next</button>
                  <button type="button" class="button is-link" @click="next" v-else-if="currentDeal.saleType === 'Retail' && active < 1 && isMobile"><b-icon pack="fal" icon="arrow-alt-right" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onPreview(currentDeal)" v-if="showPreviewButton && !isMobile">Preview</button>
                  <button type="button" class="button is-primary" @click="onPreview(currentDeal)" v-else-if="showPreviewButton && isMobile"><b-icon pack="fal" icon="eye" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onVerifyBuyersAgreement(currentDeal)" v-if="showVerifyButton && !isMobile">Verify</button>
                  <button type="button" class="button is-primary" @click="onVerifyBuyersAgreement(currentDeal)" v-else-if="showVerifyButton && isMobile"><b-icon pack="fal" icon="badge-check" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onEmailBuyersAgreement(currentDeal)" v-if="showSendButton && !isMobile" :disabled="buyersAgreementFinalSendDisallowed">Send</button>
                  <button type="button" class="button is-primary" @click="onEmailBuyersAgreement(currentDeal)" v-else-if="showSendButton && isMobile" :disabled="buyersAgreementFinalSendDisallowed"><b-icon pack="fal" icon="envelope" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onUploadBuyersAgreement(currentDeal)" v-if="showSaveButton && !isMobile">Save</button>
                  <button type="button" class="button is-primary" @click="onUploadBuyersAgreement(currentDeal)" v-else-if="showSaveButton && isMobile"><b-icon pack="fal" icon="save" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-danger" @click="updateBuyersAgreementModalState(false)" v-if="!isMobile">Cancel</button>
                  <button type="button" class="button is-danger" @click="updateBuyersAgreementModalState(false)" v-else-if="isMobile"><b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon></button>
                </div>
                <div :class="isMobile ? 'column is-2 is-pulled-right' : 'column is-4'">
                  <button type="button" class="button is-primary is-pulled-right" @click="updateUploadState()" v-if="!isMobile">{{isUpload ? 'Cancel Upload & Send For Signing' : 'Upload Signed Agreement'}}</button>
                  <button type="button" class="button is-primary is-pulled-right" @click="updateUploadState()" :title="isUpload ? 'Cancel Upload & Send For Signing' : 'Upload Signed Agreement'" v-else-if="isMobile"><b-icon pack="fal" :icon="isUpload ? 'window-close' : 'file-upload'" custom-size="fa-lg"></b-icon></button>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
      </portal>
      <send-reminder-control
        v-on:on-hide-reminder-control="onHideReminderModal"
        :showConfirmationModal="showConfirmReminder"
        :signatureRequest="signatureRequest"
        v-on:send-reminder="sendReminder">
      </send-reminder-control>
      <cancel-signature-request-control
        v-on:on-hide-cancel-request-control="onHideCancellationRequestModal"
        :showRequestCancellationModal="showCancelRequest"
        :signatureRequest="cancelSignatureRequest"
        v-on:send-cancellation-request="sendCancellationRequest">
      </cancel-signature-request-control>
    </div>
</template>

<script>

import eventBus from '@/eventBus'
import dealService from '@/services/dealService'
import inventoryService from '@/services/inventoryService'
import documentSigningRequestService from '@/services/documentSigningRequestService'
import formattingMixin from '@/mixins/inventory/formatting'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import supplierService from '@/services/supplierService'
import customerService from '@/services/customerService'
import SectionHeader from '@/components/generic/SectionHeader'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import FileSelector from '@/components/generic/FileSelector'
import PopFillControl from '@/components/generic/PopFillControl'
import SwitchSelector from '@/components/generic/SwitchSelector'
import UsageHistoryComponent from '@/components/inventory/UsageHistoryComponent'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import Vue from 'vue'
import SendReminderControl from '@/components/generic/SendReminderControl'
import CancelSignatureRequestControl from '@/components/generic/CancelSignatureRequestControl'
import { Steps, Step } from 'element-ui'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DealBuyersAgreementAction',
  mixins: [validateBeforeSubmitMixin, formattingMixin, dealInventoryStyleClasses, utilitiesMixin],
  components: {
    'section-header': SectionHeader,
    'file-selector': FileSelector,
    'switch-selector': SwitchSelector,
    'pop-fill-control': PopFillControl,
    'usage-history-component': UsageHistoryComponent,
    'button-list-selector': ButtonListSelector,
    'send-reminder-control': SendReminderControl,
    'cancel-signature-request-control': CancelSignatureRequestControl,
    'el-steps': Steps,
    'el-step': Step,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      documentSigningRequestData: [],
      buyersAgreementVerificationActive: false,
      buyersAgreementRequiresVerification: false,
      buyersAgreementPreviewed: false,
      buyersAgreementAcceptConditions: false,
      buyersAgreementSelectedPdf: null,
      isNewBuyersAgreement: false,
      instanceId: this.$uuid.v4(),
      selectedRecipients: [],
      isUpload: false,
      attachDocuments: false,
      watermarkTitle: false,
      buyersAgreementDoc: [],
      tabIndex: 0,
      selectedRecipientsMobile: [],
      selectedVehicleType: null,
      mobileInvalidFormat: '',
      emailMatchErrorMessage: '',
      signatureRequest: null,
      showConfirmReminder: false,
      sendingReminder: false,
      cancelSignatureRequest: null,
      showCancelRequest: false,
      sendingCancellationRequest: false,
      saleDate: null,
      active: 0,
      preDeliveryServiceCharge: null,
      electronicRegistrationFilingFee: null,
      batteryTireFee: null,
      documentationFee: null,
      salesTax: null,
      lemonLawFee: null,
      registrationTitlingFee: null,
      docStampsFee: null,
      rebates: null,
      nonCashCredits: null,
      cashDue: null,
      deposit: null,
      countyCharge: null,
      salePrice: null,
      contractSoldAt: null,
      contractSaleVehicleTypeId: null,
      retailAgreementNotes: '',
      updatedUsage: null,
      isMobile: window.innerWidth <= 700,
      isCostSaving: false
    }
  },
  methods: {
    onLastMileageClick: function (lastMileage) {
      this.updatedUsage = lastMileage
    },
    setBatteryTireFee: function (recalculate) {
      let useDealerDefaults = !this.currentDeal.retailAgreementCostsSet
      if (useDealerDefaults || recalculate) {
        this.batteryTireFee = this.isNewSale ? this.currentDealer.batteryTireFee : this.currentDealer.batteryTireFeeUsed
        return
      }
      this.batteryTireFee = this.currentDeal.batteryTireFee
    },
    onSetContractDate: function (date) {
      this.contractSoldAt = date
    },
    next () {
      var scope = 'page-' + (this.active + 1) + '.*'
      switch (this.active) {
        case 0: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.updateCostData()
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
        case 1: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.onVerifyBuyersAgreement(this.currentDeal)
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
      }
    },
    previous () {
      if (this.active-- < 1) this.active = 0
    },
    isCancellationDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    isReminderDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    loadData: function () {
      if (this.tabIndex === 0) return
      let dealId = this.currentDeal.id
      const searchCriteria = {
        dealId: dealId,
        dealerId: this.currentDealer.id,
        signingRequestType: 0,
        query: '',
        pageSize: this.perPage,
        sortBy: 'sentAt',
        page: this.page
      }
      this.isLoading = true
      documentSigningRequestService.searchByType(searchCriteria).then(response => {
        this.documentSigningRequestData = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          item.reminderSent = false
          item.cancellationSent = false
          this.documentSigningRequestData.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.documentSigningRequestData = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    updateCostData: function () {
      let that = this
      this.isCostSaving = true
      var costModel = {
        dealId: this.currentDeal.id,
        preDeliveryServiceCharge: this.preDeliveryServiceCharge,
        electronicRegistrationFilingFee: this.electronicRegistrationFilingFee,
        batteryTireFee: this.batteryTireFee,
        documentationFee: this.documentationFee,
        salesTax: this.salesTax,
        lemonLawFee: this.lemonLawFee,
        docStampsFee: this.docStampsFee,
        registrationTitlingFee: this.registrationTitlingFee,
        rebates: this.rebates,
        nonCashCredits: this.nonCashCredits,
        cashDue: this.cashDue,
        countyCharge: this.countyCharge,
        salePrice: this.salePrice,
        contractSoldAt: this.contractSoldAt,
        deposit: this.deposit,
        contractSaleVehicleTypeId: this.selectedVehicleType.id,
        retailAgreementNotes: this.retailAgreementNotes,
        updatedUsage: this.updatedUsage
      }

      dealService.updateRetailAgreementCosts(costModel).then(response => {
        if (response.errors) {
          that.isCostSaving = false
          that.serverErrors = response.errors
        } else {
          that.isCostSaving = false
          that.active++
          eventBus.$emit('deal-changed', [response.deal])
          eventBus.$emit('reload-deal', [response.deal])
        }
      }).catch((error) => {
        that.isCostSaving = false
        throw error
      })
    },
    convertTimeString: function (date, status) {
      if (status !== 0) {
        var d = new Date(date)
        var hour = '' + d.getHours()
        var minute = '' + d.getMinutes()

        if (hour.length < 2) hour = '0' + hour
        if (minute.length < 2) minute = '0' + minute

        return [hour, minute].join(':')
      } else {
        return ''
      }
    },
    onCancelBuyersAgreement: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.cancelSignatureRequest = signatureRequest
        this.onShowCancellationRequestModal()
      } else {
        this.failedToast('Signature request cannot be cancelled at this point.')
      }
    },
    onBuyersAgreementReminder: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.signatureRequest = signatureRequest
        this.onShowReminderModal()
      } else {
        this.failedToast('Signature request reminder cannot be sent at this point.')
      }
    },
    formateSignatureType: function (type) {
      return type === 'LeaseReturned' ? 'Lease Returned' : type
    },
    onBuyersAgreementRequired: function () {
      if (this.hasBuyersAgreementAccess && this.isValidForBuyersAgreementSigning) {
        this.updateBuyersAgreementModalState(true)
      } else {
        eventBus.$emit('buyers-agreement-cancelled', {})
      }
    },
    onVerifyBuyersAgreement: function (deal) {
      var scope = 'page-2.*'
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.buyersAgreementVerificationActive = true
        }
      })
    },
    onDownloadLastBuyersAgreement: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedBuyersAgreement)
    },
    onDownloadListDealAgreement: function (signedAgreementUrl) {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    buildBuyersAgreementUrl: function (signedAgreementUrl) {
      return process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
      this.checkEmails(this.selectedRecipients)
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      this.isUpload = false
      this.buyersAgreementDoc = []
      this.attachDocuments = false
      this.watermarkTitle = false
      this.mobileInvalidFormat = ''
      this.emailMatchErrorMessage = ''
    },
    updateBuyersAgreementModalState: function (state) {
      this.isNewBuyersAgreement = state
      let that = this

      if (state === true && this.soldItems.length > 0) {
        let firstDealItem = this.soldItems[0]
        let firstSoldVehicle = firstDealItem.forInventory
        this.buyersAgreementPreviewed = false
        this.buyersAgreementVerificationActive = false
        this.buyersAgreementAcceptConditions = false
        this.overridingSubject = 'Bill of Sale - '
        this.overridingSubject += firstSoldVehicle ? firstSoldVehicle.name + ' - ' + firstSoldVehicle.vinNo : ''
        this.optionalMessage = 'Please review and sign the attached Bill of Sale to purchase stock #'
        this.optionalMessage += firstSoldVehicle ? firstSoldVehicle.stockNo : ''
        this.optionalMessage += ' for sale price ' + this.$formatCurrency(firstDealItem.salePrice)
        if (this.currentDeal.saleType === 'Wholesale' && this.currentDeal.purchaserId) {
          supplierService.single(this.currentDeal.purchaserId).then(response => {
            let purchasingSupplier = response.supplier
            let validContacts = purchasingSupplier.contacts.filter((x) => x.status === 'Active' && x.receivesBuyersOrder === true && x.email !== '')
            // email
            that.availableAddresses = validContacts.map(function (y) {
              return {
                email: y.email,
                fullName: y.fullName ? y.fullName + '(' + y.email + ')' : y.fullName
              }
            })
            if (that.availableAddresses.length > 0) {
              that.selectedRecipients = that.availableAddresses
            } else {
              that.selectedRecipients = []
            }

            that.checkEmails(that.selectedRecipients)

            // mobile
            that.availableMobileNumbers = validContacts.filter(x => x.mobile !== '' && x.mobile.length === 16).map(function (y) {
              return {
                mobile: y.mobile,
                fullName: y.fullName ? y.fullName + '(' + y.mobile + ')' : y.fullName
              }
            })
            if (that.availableMobileNumbers.length > 0) {
              that.selectedRecipientsMobile = that.availableMobileNumbers
            } else {
              that.selectedRecipientsMobile = []
            }
          }).catch((error) => {
            that.serverErrorMessage = error.message
          })
        } else if (this.currentDeal.saleType === 'Retail' && this.currentDeal.purchaserId) {
          customerService.single(this.currentDeal.purchaserId).then(response => {
            that.active = 0
            let purchasingCustomer = response.customer

            if (purchasingCustomer.email !== '') {
              let availableAddresses = [{
                email: purchasingCustomer.email,
                fullName: purchasingCustomer.fullName ? purchasingCustomer.fullName + '(' + purchasingCustomer.email + ')' : purchasingCustomer.fullName
              }]
              if (availableAddresses.length > 0) {
                that.selectedRecipients = availableAddresses
              } else {
                that.selectedRecipients = []
              }
            }

            let useDealerDefaults = !that.currentDeal.retailAgreementCostsSet
            that.preDeliveryServiceCharge = useDealerDefaults ? that.currentDealer.preDeliveryServiceCharge : that.currentDeal.preDeliveryServiceCharge
            that.electronicRegistrationFilingFee = useDealerDefaults ? that.currentDealer.electronicRegistrationFilingFee : that.currentDeal.electronicRegistrationFilingFee
            that.documentationFee = useDealerDefaults ? that.currentDealer.documentationFee : that.currentDeal.documentationFee
            that.salesTax = useDealerDefaults ? that.currentDealer.salesTax : that.currentDeal.salesTax
            that.lemonLawFee = useDealerDefaults ? that.currentDealer.lemonLawFee : that.currentDeal.lemonLawFee
            that.docStampsFee = useDealerDefaults ? that.currentDealer.docStampsFee : that.currentDeal.docStampsFee
            that.registrationTitlingFee = useDealerDefaults ? that.currentDealer.registrationTitlingFee : that.currentDeal.registrationTitlingFee
            that.rebates = that.currentDeal.rebates
            that.nonCashCredits = that.currentDeal.nonCashCredits
            that.cashDue = that.currentDeal.cashDue
            that.countyCharge = useDealerDefaults ? that.currentDealer.countyCharge : that.currentDeal.countyCharge
            let firstDealItem = that.soldItems[0]
            that.salePrice = firstDealItem.salePrice
            that.deposit = that.currentDeal.paidDeposits
            that.retailAgreementNotes = that.currentDeal.retailAgreementNotes
            that.contractSoldAt = that.currentDeal.contractSoldAt
            if (that.currentDeal.contractSaleVehicleTypeId != null) {
              that.selectedVehicleType = that.definedTypes.dealSaleVehicleType.options.find(x => x.id === that.currentDeal.contractSaleVehicleTypeId)
            }
            that.setBatteryTireFee(false)
            that.checkEmails(that.selectedRecipients)

            if (purchasingCustomer.mobile !== '' && purchasingCustomer.mobile.length === 16) {
              let availableMobileNumbers = [{
                mobile: purchasingCustomer.mobile,
                fullName: purchasingCustomer.fullName ? purchasingCustomer.fullName + '(' + purchasingCustomer.mobile + ')' : purchasingCustomer.fullName
              }]
              if (availableMobileNumbers.length > 0) {
                that.selectedRecipientsMobile = availableMobileNumbers
              } else {
                that.selectedRecipientsMobile = []
              }
            }
          }).catch((error) => {
            that.serverErrorMessage = error.message
          })
        } else {
          // email
          if (this.currentDeal.purchaserContactEmail && this.currentDeal.purchaserContactEmail !== '') {
            let purchaser = {
              email: this.currentDeal.purchaserContactEmail,
              fullName: this.currentDeal.purchaserName + ' (' + this.currentDeal.purchaserContactEmail + ')'
            }
            this.selectedRecipients = purchaser
          } else {
            this.selectedRecipients = []
          }

          this.checkEmails(this.selectedRecipients)

          // mobile
          if (this.currentDeal.purchaserContactMobile && this.currentDeal.purchaserContactMobile !== '' && this.currentDeal.purchaserContactMobile.length === 16) {
            this.availableMobileNumbers = [{
              mobile: this.currentDeal.purchaserContactMobile,
              fullName: this.currentDeal.purchaserName + ' (' + this.currentDeal.purchaserContactName + ')'
            }]
            this.selectedRecipientsMobile = this.availableMobileNumbers
          } else {
            this.selectedRecipientsMobile = []
          }
        }
      }

      if (state === false) {
        eventBus.$emit('buyers-agreement-cancelled', {})
        this.clearAllRecipients()
        this.preDeliveryServiceCharge = null
        this.electronicRegistrationFilingFee = null
        this.batteryTireFee = null
        this.documentationFee = null
        this.salesTax = null
        this.lemonLawFee = null
        this.docStampsFee = null
        this.registrationTitlingFee = null
        this.rebates = null
        this.nonCashCredits = null
        this.cashDue = null
        this.countyCharge = null
        this.salePrice = null
        this.deposit = null
        this.contractSoldAt = null
        this.selectedVehicleType = null
        this.updatedUsage = null
      }
    },
    checkEmails: function (recipients) {
      if (recipients.length > 0) {
        let emails = recipients.map(function (x) {
          return x.email
        })

        let blacklistEmailCheckModel = {
          subscriberId: this.getCurrentSubscriber.id,
          emailAddresses: emails,
          errorMessage: ''
        }

        inventoryService.getVerifiedEmailList(blacklistEmailCheckModel).then(response => {
          blacklistEmailCheckModel = response.blacklistEmailCheck

          let approvedEmails = blacklistEmailCheckModel.emailAddresses.map(function (x) {
            return {
              email: x,
              fullName: x
            }
          })
          this.selectedRecipients = approvedEmails
          if (this.selectedRecipients.length === 0) {
            this.selectedRecipients = []
          }

          if (blacklistEmailCheckModel.errorMessage !== '' || blacklistEmailCheckModel.errorMessage !== null) {
            this.emailMatchErrorMessage = blacklistEmailCheckModel.errorMessage
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      }
    },
    onPreview: function (deal) {
      let applicableDate = new Date()
      if (this.saleDate) {
        applicableDate = this.saleDate
      }
      let docUrl = deal.saleType === 'Wholesale' ? process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/' + deal.id + '/' + this.currentUser.id + '/' + applicableDate.toDateString() : process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/retail/' + deal.id + '/' + this.currentUser.id + '/' + applicableDate.toDateString()
      docUrl = encodeURI(docUrl)
      this.buyersAgreementSelectedPdf = {
        name: 'Bill of Sale',
        src: docUrl,
        fileName: 'buyers_agreement_' + deal.id + '.pdf',
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
      this.buyersAgreementPreviewed = true
    },
    onPreviewClosed: function () {
      this.buyersAgreementSelectedPdf = null
    },
    onEmailBuyersAgreement: function (deal) {
      let that = this
      this.$validator.validateAll('recipient-form').then((result) => {
        if (result) {
          var emailAddresses = this.selectedRecipients.map((x) => x.email).join(',')
          var mobileNumbers = this.selectedRecipientsMobile.map(e => e.mobile).join(',')
          var commandModel = {
            subscriberId: deal.subscriberId,
            dealerId: deal.dealerId,
            dealId: deal.id,
            type: deal.saleType,
            destinationAddresses: emailAddresses,
            destinationMobileNumbers: mobileNumbers,
            overridingSubject: this.overridingSubject,
            optionalMessage: this.optionalMessage,
            attachDocuments: this.attachDocuments,
            watermarkTitle: this.watermarkTitle,
            saleDate: this.saleDate
          }

          dealService.queueMail(commandModel).then(response => {
            if (response.errors) {
              that.serverErrors = response.errors
            } else {
              that.clearAllRecipients()
              that.isNewBuyersAgreement = false
              that.buyersAgreementVerificationActive = false
              that.buyersAgreementPreviewed = false
              that.billOfSaleCarFaxCheckComplete = false
              that.billOfSaleCarFaxIsGood = false
              that.billOfSaleCarFaxFailed = false
              that.buyersAgreementAcceptConditions = false
              that.billOfSaleAppraisalCheckComplete = false
              that.billOfSaleAppraisalIsGood = false
              that.billOfSaleAppraisalFailed = false
              that.active = 0
              eventBus.$emit('deal-changed', [response.deal])
              eventBus.$emit('buyers-agreement-complete', response.deal)
            }
          }).catch((error) => {
            throw error
          })
        }
      })
    },
    updateUploadState: function () {
      this.isUpload = this.isUpload === false
      if (this.active !== 1) this.active = 1
    },
    onUploadBuyersAgreement: function (deal) {
      if (this.buyersAgreementDoc.length > 0) {
        var signedDocumentModel = this.buyersAgreementDoc.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            docName: x.uniqueId,
            FileUri: ''
          }
        })
        var dealId = deal.id
        var signedAgreementUploadModel = {
          dealId: dealId,
          fileName: signedDocumentModel[0].docName
        }

        dealService.uploadSignedBuyersAgreement(signedAgreementUploadModel).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isNewBuyersAgreement = false
            this.buyersAgreementVerificationActive = false
            this.buyersAgreementPreviewed = false
            this.buyersAgreementAcceptConditions = false
            let allSignedBuyersAgreements = response.deal.attachments.filter((x) => x.classType === 'BuyersAgreement')
            if (allSignedBuyersAgreements.length > 0) {
              let newAttachment = allSignedBuyersAgreements[allSignedBuyersAgreements.length - 1]
              Vue.set(this.currentDeal, 'attachments', [...this.currentDeal.attachments, newAttachment])
              this.currentDeal.buyersOrderStatus = 'Complete'
              this.currentDeal.buyersOrderStatusId = 10
            }
            eventBus.$emit('deal-changed', [response.deal])
            eventBus.$emit('buyers-agreement-complete', response.deal)
          }
        }).catch((error) => {
          throw error
        })
      }
    },
    onRecipientMobileAdded: function (data) {
      this.mobileInvalidFormat = ''
      if (data && data.length === 16) {
        var recipient = {
          fullName: data,
          mobile: data
        }
        this.selectedRecipientsMobile.push(recipient)
      } else {
        this.mobileInvalidFormat = 'Invalid format for mobile number:  ' + data + '. Mobile format should be +1(xxx)-xxx-xxxx'
      }
    },
    onShowReminderModal: function () {
      this.isNewBuyersAgreement = false
      this.showConfirmReminder = true
    },
    onHideReminderModal: function () {
      this.showConfirmReminder = false
      this.isNewBuyersAgreement = true
      this.signatureRequest = null
    },
    sendReminder: function () {
      if (!this.sendingReminder) {
        this.sendingReminder = true
        documentSigningRequestService.sendReminder(this.signatureRequest).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.signatureRequest.reminderSent = true
            this.successToast('Signature request reminder has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideReminderModal()
          this.sendingReminder = false
        })
      }
    },
    onShowCancellationRequestModal: function () {
      this.isNewBuyersAgreement = false
      this.showCancelRequest = true
    },
    onHideCancellationRequestModal: function () {
      this.showCancelRequest = false
      this.isNewBuyersAgreement = true
      this.cancelSignatureRequest = null
    },
    sendCancellationRequest: function () {
      if (!this.sendingCancellationRequest) {
        this.sendingCancellationRequest = true
        documentSigningRequestService.cancelSignatureRequest(this.cancelSignatureRequest.signatureRequestId).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.cancelSignatureRequest.cancellationSent = true
            this.successToast('Signature cancellation request has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideCancellationRequestModal()
          this.sendingCancellationRequest = false
        })
      }
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    ...mapConfigGetters(['definedTypes']),
    isNewSale: function () {
      return this.selectedVehicleType ? this.selectedVehicleType.id === this.definedTypes.dealSaleVehicleType.New : false
    },
    currentInventory: function () {
      return this.currentDeal ? this.soldItems[0].forInventory : null
    },
    minimumUsageAllowed: function () {
      if (this.currentInventory && this.currentInventory.vinUsageHistory && this.currentInventory.vinUsageHistory.length > 0) {
        return this.currentInventory.vinUsageHistory[this.currentInventory.vinUsageHistory.length - 1].usage
      }
      return 0
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    dealSoldAt: function () {
      if (!this.currentDeal.soldAt) return ''
      let soldAt = new Date(this.currentDeal.soldAt)
      return ('0' + (soldAt.getMonth() + 1)).slice(-2) + '-' + ('0' + soldAt.getDate()).slice(-2) + '-' + soldAt.getFullYear()
    },
    isDealBooked: function () {
      return this.currentDeal && this.currentDeal.type === 'Booked'
    },
    soldItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    buyersAgreementFinalSendDisallowed: function () {
      return false
    },
    hasBuyersAgreementAccess: function () {
      let allowedPermissions = ['deal.issue.buyers-order']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    lastSignedBuyersAgreement: function () {
      if (this.currentDeal && (this.currentDeal.buyersOrderStatus === 'Complete' || this.currentDeal.buyersOrderStatus === 'Signed')) {
        var allSignedBuyersAgreements = this.currentDeal.attachments.filter((x) => x.classType === 'BuyersAgreement' && x.status === 'Active')
        if (allSignedBuyersAgreements.length > 0) {
          return allSignedBuyersAgreements[allSignedBuyersAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    isOrderedDeal: function () {
      return this.currentDeal && this.currentDeal.type === 'Ordered'
    },
    isValidForBuyersAgreementSigning: function () {
      if (this.soldItems && this.soldItems.length > 0) {
        let dealItem = this.soldItems[0]
        if (this.currentDeal.purchaserName === '') return false

        if (this.isOrderedDeal) return true

        if (!dealItem) return false

        if (!dealItem.salePrice || dealItem.salePrice <= 0) return false

        return (dealItem.forInventory.trueMileageUnknown === true ||
        (dealItem.forInventory.mileageEstimate === false &&
        dealItem.forInventory.usage &&
        dealItem.forInventory.usage > 0))
      } else {
        return false
      }
    },
    invalidForBuyersAgreementReasons: function () {
      let dealItem = this.soldItems.length > 0 ? this.soldItems[0] : null
      var result = 'Action disabled. Please ensure the following details are captured.\n'
      if (this.currentDeal && dealItem) {
        if (this.currentDeal.purchaserName === '') {
          result += 'Buyer details not set.\n'
        }

        if (this.isOrderedDeal) return result

        if (!dealItem.salePrice || dealItem.salePrice <= 0) {
          result += 'Sale price not set.\n'
        }
        if (dealItem.forInventory.mileageEstimate === true) {
          result += 'Actual vehicle mileage is required. Mileage is currently estimated.\n'
        }
        if (!dealItem.forInventory.usage || dealItem.forInventory.usage <= 0) {
          result += 'Vehicle mileage not set.\n'
        }
      } else {
        result += 'Deal selected is invalid.\n'
      }
      return result
    },
    mustShowEmailError: function () {
      return this.emailMatchErrorMessage !== '' && this.emailMatchErrorMessage !== ' ' && this.emailMatchErrorMessage !== null && this.emailMatchErrorMessage !== undefined
    },
    showPreviewButton: function () {
      if (this.currentDeal) {
        if (this.isUpload) return false
        if (this.currentDeal.saleType === 'Wholesale') return true
        return this.currentDeal.retailAgreementCostsSet
      }
      return false
    },
    showVerifyButton: function () {
      if (this.currentDeal && this.currentDeal.saleType === 'Wholesale') {
        return (!this.buyersAgreementVerificationActive && this.buyersAgreementRequiresVerification) && this.buyersAgreementPreviewed && !this.isUpload
      } else {
        if (this.active === 1) {
          return (!this.buyersAgreementVerificationActive && this.buyersAgreementRequiresVerification) && this.buyersAgreementPreviewed && !this.isUpload
        } else {
          return false
        }
      }
    },
    showSendButton: function () {
      if (this.currentDeal && this.currentDeal.saleType === 'Wholesale') {
        return (this.buyersAgreementVerificationActive || !this.buyersAgreementRequiresVerification) && this.buyersAgreementPreviewed && !this.isUpload
      } else {
        if (this.active === 1) {
          return (this.buyersAgreementVerificationActive || !this.buyersAgreementRequiresVerification) && this.buyersAgreementPreviewed && !this.isUpload
        } else {
          return false
        }
      }
    },
    showSaveButton: function () {
      if (this.currentDeal && this.currentDeal.saleType === 'Wholesale') {
        return this.isUpload
      } else {
        if (this.active === 1) {
          return this.isUpload
        } else {
          return false
        }
      }
    },
    getToolTipForBuyersAgreement: function () {
      let baseText = 'Buyers Agreement Status: '
      if (this.currentDeal && !this.currentDeal.isBuyersAgreementUploaded) {
        baseText += this.getDescriptionByAgreementStatus(this.currentDeal.buyersOrderStatusId, 'Click to send a buyers order for this deal for signing.')
      } else {
        if ((this.currentDeal.buyersAgreementUploadedBy !== '' && this.currentDeal.buyersAgreementUploadedBy !== undefined) && (this.currentDeal.buyersAgreementUploadedAt !== null && this.currentDeal.buyersAgreementUploadedAt !== undefined)) {
          baseText += this.getUploadDescriptionByAgreementStatus(this.currentDeal.buyersAgreementUploadedBy, this.currentDeal.buyersAgreementUploadedAt)
        } else {
          baseText += 'No user details or date of upload has been captured for uploaded file.'
        }
      }
      return baseText
    }
  },
  beforeDestroy: function () {
    eventBus.$off('buyers-agreement-required', this.onBuyersAgreementRequired)
  },
  mounted: function () {
    eventBus.$on('buyers-agreement-required', this.onBuyersAgreementRequired)
    this.saleDate = new Date()
  },
  watch: {
    selectedVehicleType: function (newVal, oldVal) {
      if (newVal) {
        this.setBatteryTireFee(true)
      }
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  #simple-overview  {
    margin-bottom:0.25em !important;
  }

  #simple-overview .columns .column {
    padding-top: 0.25em !important;
    padding-bottom: 0 !important;
    margin-top:0.25em !important;
    margin-bottom:0 !important;
  }

  .modal-card-body {
    position:relative;
    max-height: calc(70vh - 10px) !important;
    height: calc(70vh - 10px) !important;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow-y: auto !important;
    width: 960px !important;
  }

  @media screen and (max-height: 600px), print {
    .modal-card-body {
      max-height: calc(70vh - 60px) !important;
      height: calc(70vh - 60px) !important;
    }
  }

  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }

  .datepicker-container {
    position: fixed !important;
    z-index: 10000 !important;
  }

</style>
