<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :taggable="false"
      :disabled="isDisabled"
      :searchable="true"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedRoServiceLaborType"
      :options="allRoServiceLaborTypes"
      label="listName"
      :placeholder="placeholder"
      trackBy="id"
      :loading="isRoServiceLaborTypesLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :data-vv-scope="scope"
      :hideSelected="true">
        <template slot="option" slot-scope="props">
          <label>{{ props.option.code }} - {{props.option.description}}</label>
        </template>
      </multiselect>
      <a class="button is-primary new-labor-type-btn" @click="isNewRoServiceLaborTypeActive = true" v-if="allowNew && !isDisabled">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
      </a>
      <a class="button is-primary edit-labor-type-btn" @click="editRoServiceLaborTypeActive = true" v-if="allowEdit && showEditAction && selectedRoServiceLaborTypeId !== 0 && !isDisabled">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewRoServiceLaborTypeActive" scroll="keep" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <new-ro-service-labortype
            mode="modal"
            actionPortal="modal-footer"
            overviewPortal=""
            headerPortal="modal-header"
            v-on:on-save="onRoServiceLaborTypeSaved"
            v-on:on-cancel="onRoServiceLaborTypeCancelled">
          </new-ro-service-labortype>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="editRoServiceLaborTypeActive" scroll="keep" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <edit-ro-service-labortype
            :laborTypeId="selectedRoServiceLaborTypeId"
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onExistingRoServiceLaborTypeSaved"
            v-on:on-cancel="onCancelEditRoServiceLaborType"
            :fulfillForDepositCaptureMode="false">
          </edit-ro-service-labortype>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
      </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import eventBus from '@/eventBus'
import roServiceService from '@/services/roServiceService'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'RoServiceLaborTypeSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Labor Type'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded service labor type'
    },
    validateAs: {
      type: String,
      default: 'Labor Type'
    },
    allowNew: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    showEditAction: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  components: {
    'new-ro-service-labortype': () => import('@/components/roservice/NewRoServiceLaborType'),
    'edit-ro-service-labortype': () => import('@/components/roservice/EditRoServiceLaborType')
  },
  data () {
    return {
      isRoServiceLaborTypesLoading: false,
      selectedRoServiceLaborType: this.value,
      isNewRoServiceLaborTypeActive: false,
      data: [],
      instanceName: 'ro-service-labor-type-selector-' + this.$uuid.v4(),
      editRoServiceLaborTypeActive: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    allRoServiceLaborTypes () {
      return this.data
    },
    selectedRoServiceLaborTypeId: function () {
      return this.selectedRoServiceLaborType ? this.selectedRoServiceLaborType.id : 0
    },
    hasEditRoServiceLaborTypeAccess: function () {
      let allowedPermissions = ['ro.service.labortype.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    selectedRoServiceLaborType: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentRoServiceLaborType: function (newValue, oldValue) {
      this.selectedRoServiceLaborType = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedRoServiceLaborType = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    }
  },
  methods: {
    onRoServiceLaborTypeSaved: function (newRoServiceLaborType) {
      this.data.push(newRoServiceLaborType)
      this.selectedRoServiceLaborType = newRoServiceLaborType
      this.isNewRoServiceLaborTypeActive = false
    },
    onRoServiceLaborTypeCancelled: function () {
      this.isNewRoServiceLaborTypeActive = false
    },
    clearAllRoServiceLaborTypes () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.currentDealer.id,
        sortBy: 'code.asc'
      }
      this.isRoServiceLaborTypesLoading = true
      this.clearAllRoServiceLaborTypes()
      roServiceService.allLaborTypes(searchCommand).then(response => {
        this.data = response.results
        this.isRoServiceLaborTypesLoading = false
      })
    },
    doRoServiceLaborTypeSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (query) {
      this.doRoServiceLaborTypeSearch(query)
    },
    onExistingRoServiceLaborTypeSaved: function (roLaborType) {
      this.selectedRoServiceLaborType = roLaborType
      this.editRoServiceLaborTypeActive = false
    },
    onCancelEditRoServiceLaborType: function () {
      this.editRoServiceLaborTypeActive = false
    }
  },
  created () {
    eventBus.$on('preview-ro-service-labortype', this.onPreviewRoServiceLaborType)
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  },
  destroyed () {
    eventBus.$off('preview-ro-service-labortype', this.onPreviewRoServiceLaborType)
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .new-labor-type-btn {
    margin-left:5px;
    height:40px;
  }

  .edit-labor-type-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
  }

</style>
