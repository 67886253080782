<template>
  <div class="payment-main-container columns is-multiline">
    <div v-if="!hasDevices" class="column is-12">
      <div class="notification is-warning is-flex">
        <div>
          <i class="fas fa-exclamation-triangle is-warning fa-2x"></i>
        </div>
        <div class="ml-10">
          No Paired Devices Found. There needs to be at least one PAIRED Square Terminal Device to be able to checkout purchaser.
        </div>
      </div>
    </div>
    <div v-if="hasDevices && !internalValue.signOnly" class="column is-12">
      <div class="notification is-info is-flex">
        <div>
          <i class="fas fa-info-circle is-info fa-2x"></i>
        </div>
        <div class="ml-10">
          Once saved LIVE will send Square a request to check out the buyer and accept a signature and payment. Square will send the checkout details to the selected Square Terminal, which will show the payment details to the buyer.
        </div>
      </div>
    </div>
    <div v-else-if="hasDevices" class="column is-12">
      <div class="notification is-info is-flex">
        <div>
          <i class="fas fa-info-circle is-info fa-2x"></i>
        </div>
        <div class="ml-10">
          Once saved LIVE will send Square a signature request. Square will send the signature request to the selected Square Terminal.
        </div>
      </div>
    </div>
    <div class="column is-6">
      <square-device-code-selector
        v-model="selectedDeviceCode"
        :useValidator="this.$validator"
        scope="recipient-form"
        :required=true
        v-on:codes-loaded="codesLoaded">
      </square-device-code-selector>
    </div>
    <div class="column is-6" v-if="!internalValue.signOnly">
      <label class="label">Collect Receipt Signature</label>
      <b-checkbox class="title-checkbox" size="is-small" type="is-info" v-model="internalValue.collectSignature"></b-checkbox>
    </div>
    <div class="column is-6" v-if="!internalValue.signOnly">
      <div class="field">
        <label class="label">Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
        <div class="control has-icons-left">
          <money name="amount" data-vv-as="amount" v-validate="{'required': true, decimal:2, 'min_value': 0}" :class="{'input': true, 'is-danger': errors.has('recipient-form.amount') }" type="text" placeholder="0000.00" v-model="internalValue.amount" v-bind="$globalMoneyFormat"></money>
          <span v-show="errors.has('recipient-form.amount')" class="help is-danger">
            <i v-show="errors.has('recipient-form.amount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.amount') }}
          </span>
          <span class="icon is-small is-left">
            <i class="fas fa-dollar-sign"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="column is-6" v-if="!internalValue.signOnly">
      <div class="field">
        <label class="label">Square Payment Note<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
        <div class="control">
          <input name="note" data-vv-as="note" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('recipient-form.note') }" type="text" v-model="internalValue.note">
          <span v-show="errors.has('recipient-form.note')" class="help is-danger">
            <i v-show="errors.has('recipient-form.note')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.note') }}
          </span>
        </div>
      </div>
    </div>

    <div class="column is-12" v-if="canShowSquareItemSelector">
      <div class="field">
        <label class="label">Item</label>
        <multiselect
          :multiple="false"
          v-validate="{'required': false}"
          data-vv-scope="recipient-form"
          data-vv-as="item"
          data-vv-validate-on="input|close"
          :class="{'is-danger': errors.has('recipient-form.selectedInvoiceItem') }"
          name="selectedInvoiceItem"
          label="name"
          trackBy="id"
          v-model="selectedInvoiceItem"
          :options="squareItems"
          :allowEmpty="true"
          :searchable="true"
          placeholder="select invoice item"
          :loading="isLoading"
          :internal-search="true"
          :options-limit="300"
          :hideSelected="false">
        </multiselect>
        <span v-show="errors.has('recipient-form.selectedInvoiceItem')" class="help is-danger"><i v-show="errors.has('recipient-form.selectedInvoiceItem')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.selectedInvoiceItem') }}</span>
      </div>
    </div>

    <div class="column is-12" v-if="canShowTaxMultiSelect">
      <div class="field">
        <label class="label">Taxes</label>
        <multiselect
          :multiple="true"
          data-vv-scope="recipient-form"
          name="selectedInvoiceTaxes"
          label="name"
          trackBy="id"
          v-model="selectedInvoiceTaxes"
          :options="squareTaxes"
          :allowEmpty="true"
          :searchable="true"
          placeholder="select tax item"
          :loading="isLoading"
          :internal-search="true"
          :options-limit="300">
        </multiselect>
        <span v-show="errors.has('recipient-form.selectedInvoiceTaxes')" class="help is-danger"><i v-show="errors.has('recipient-form.selectedInvoiceTaxes')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.selectedInvoiceTaxes') }}</span>
      </div>
    </div>

    <div class="column is-12">
      <div class="field">
        <label class="label">Signature Terms & Conditions<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
        <div class="control">
          <textarea rows="7" name="signatureBody" v-validate="'required'" :class="{'textarea': true}" data-vv-as="signature terms & conditions" v-model="internalValue.signatureBody"></textarea>
          <span v-show="errors.has('recipient-form.signatureBody')" class="help is-danger">
            <i v-show="errors.has('recipient-form.signatureBody')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.signatureBody') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import squareDeviceCodeSelector from '@/components/generic/SquareDeviceCodeSelector.vue'
import squareService from '@/services/squareService'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('user')

export default {
  name: 'SquareTerminalCheckoutControl',
  components: {
    'square-device-code-selector': squareDeviceCodeSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      internalValue: {
        amount: '',
        deviceId: '',
        note: '',
        collectSignature: true,
        signatureBody: '',
        deviceCodeName: '',
        signOnly: false,
        itemId: '',
        taxIds: [],
        locationId: ''
      },
      selectedDeviceCode: null,
      devicesCount: 0,
      selectedInvoiceItem: null,
      squareConnectionStatus: null,
      isLoading: false,
      squareCatalogDownload: [],
      selectedInvoiceTaxes: []
    }
  },
  computed: {
    ...mapGetters(['hasFeatureAccess']),
    hasDevices: function () {
      return this.devicesCount > 0
    },
    squareItems: function () {
      if (this.squareCatalogDownload && this.selectedDeviceCode) {
        let filteredItems = this.squareCatalogDownload.filter(function (x) {
          let presentAtCurrentLocation = true

          if (x.present_at_all_locations === false && x.present_at_location_ids && x.present_at_location_ids.length > 0) {
            let index = x.present_at_location_ids.findIndex(y => y === this.selectedDeviceCode.locationId)
            presentAtCurrentLocation = index !== -1
          }

          if (x.present_at_all_locations === true && x.absent_at_location_ids && x.absent_at_location_ids.length > 0) {
            let index = x.absent_at_location_ids.findIndex(y => y === this.selectedDeviceCode.locationId)
            presentAtCurrentLocation = index === -1
          }

          return x.type === 'ITEM' && presentAtCurrentLocation === true
        }, this)

        return filteredItems.map(function (y) {
          return { id: y.id, name: y.item_data.name, taxIds: y.item_data.tax_ids, variationItemId: y.item_data.variations[0].id }
        })
      }

      return []
    },
    squareTaxes: function () {
      if (this.squareCatalogDownload && this.selectedDeviceCode) {
        let filteredTaxes = this.squareCatalogDownload.filter(function (x) {
          if (this.selectedInvoiceItem.taxIds) {
            let index = this.selectedInvoiceItem.taxIds.findIndex(y => y === x.id)
            return x.type === 'TAX' && index !== -1
          }
        }, this)

        return filteredTaxes.map(function (y) {
          return { id: y.id, name: y.tax_data.name + ' ' + y.tax_data.percentage + '%' }
        })
      }

      return []
    },
    canShowTaxMultiSelect: function () {
      return this.selectedInvoiceItem !== null && this.canShowSquareItemSelector
    },
    squareCatalogDownloadCompleted: function () {
      return this.squareCatalogDownload && this.squareCatalogDownload.length > 0
    },
    canShowSquareItemSelector: function () {
      return this.hasSquareTerminalTaxesAccess && !this.internalValue.signOnly
    },
    hasSquareTerminalTaxesAccess: function () {
      return this.hasFeatureAccess('deposit.square.terminal.taxes', false)
    }
  },
  methods: {
    codesLoaded: function (count) {
      this.devicesCount = count
    },
    setSquareDefaults: function () {
      this.selectedInvoiceItem = null
      this.selectedInvoiceTaxes = []

      let foundItem = this.squareItems.find(x => x.name.toLowerCase() === 'repair order payment')
      foundItem = foundItem || this.squareItems.find(x => x.name.toLowerCase().includes('partial payment'))
      if (foundItem) {
        this.selectedInvoiceItem = foundItem
      }
    },
    loadSquareCatalogs: function () {
      this.isLoading = true
      squareService.getCatalogs().then(response => {
        this.isLoading = false
        if (response.errors) {
          this.squareConnectionStatus.serverErrorMessage = response.errors[0].errorMessage
        } else {
          this.squareCatalogDownload = response
          this.setSquareDefaults()
        }
      }).catch((error) => {
        console.log('error', error)
        this.isLoading = false
        this.squareConnectionStatus.serverErrorMessage = error
      })
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.internalValue = newValue
    },
    selectedDeviceCode: function (newValue, oldValue) {
      this.internalValue.deviceId = newValue ? newValue.deviceId : null
      this.internalValue.deviceCodeName = newValue ? newValue.name : null
      this.internalValue.locationId = newValue ? newValue.locationId : null

      if (!this.squareCatalogDownloadCompleted && this.hasSquareTerminalTaxesAccess) {
        this.loadSquareCatalogs()
      } else if (this.hasSquareTerminalTaxesAccess) {
        this.setSquareDefaults()
      }

      this.$emit('input', this.internalValue)
    },
    selectedInvoiceItem: function (newValue, oldValue) {
      this.selectedInvoiceTaxes = []
      if (newValue && newValue.taxIds && newValue.taxIds.length > 0) {
        newValue.taxIds.forEach((taxId) => {
          let tax = this.squareTaxes.find(x => x.id === taxId)
          if (tax) {
            this.selectedInvoiceTaxes.push({id: tax.id, name: tax.name})
          }
        })
      }

      this.internalValue.itemId = newValue ? newValue.variationItemId : null
      this.$emit('input', this.internalValue)
    },
    selectedInvoiceTaxes: function (newValue, oldValue) {
      this.internalValue.taxIds = []
      newValue.forEach((tax) => {
        this.internalValue.taxIds.push(tax.id)
      })

      this.$emit('input', this.internalValue)
    },
    'internalValue.amount': function (newValue, oldValue) {
      if (newValue) {
        this.$emit('input', this.internalValue)
      }
    },
    'internalValue.note': function (newValue, oldValue) {
      if (newValue) {
        this.$emit('input', this.internalValue)
      }
    },
    'internalValue.signatureBody': function (newValue, oldValue) {
      if (newValue) {
        this.$emit('input', this.internalValue)
      }
    },
    'internalValue.itemId': function (newValue, oldValue) {
      if (newValue) {
        this.$emit('input', this.internalValue)
      }
    },
    'internalValue.taxIds': function (newValue, oldValue) {
      if (newValue) {
        this.$emit('input', this.internalValue)
      }
    }
  },
  mounted: function () {
  },
  created: function () {
    this.internalValue = this.value
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.notification {
  line-height: 1.5 !important;
}

</style>
