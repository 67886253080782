<template>
  <div>
    <div class="notification is-info is-flex" v-if="fulfillForDepositCaptureMode">
      <span>
        <i class="fas fa-info-circle is-info"></i>
      </span>
      <span class="ml-10">
        {{getfulfillForDepositCaptureModeMessage}}
      </span>
    </div>
    <div class="box" :class="hasContactInActiveClass">
      <div class="columns is-multiline" v-if="showNameField">
        <div class="column is-5">
          <div class="field">
            <label class="label">First Name<b-icon pack="fas" :icon="isIcon" class="fa-ss" v-show="isRequired"></b-icon></label>
            <div class="control">
              <input :disabled="isInActiveContact" :name="instanceName + '.firstName'" :data-vv-scope="scope" data-vv-as="first name" v-validate="{'required': isRequired}" :class="{'input': true, 'is-danger': errors.has(validationName + 'firstName') }" type="text" placeholder="e.g John" v-model="contact.firstName" maxlength="150">
              <span v-show="errors.has(validationName + 'firstName')" class="help is-danger">
                <i v-show="errors.has(validationName + 'firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'firstName') }}
              </span>
            </div>
          </div>
        </div>
        <div class="column is-5">
          <div class="field">
            <label class="label">Last Name<b-icon pack="fas" :icon="isIcon" class="fa-ss" v-show="isRequired"></b-icon></label>
            <div class="control">
              <input :disabled="isInActiveContact" :name="instanceName + '.lastName'" :data-vv-scope="scope" data-vv-as="last name" v-validate="{'required': isRequired}" :class="{'input': true, 'is-danger': errors.has(validationName + 'lastName') }" type="text" placeholder="e.g Doe" v-model="contact.lastName" maxlength="150">
            </div>
            <span v-show="errors.has(validationName + 'lastName')" class="help is-danger">
              <i v-show="errors.has(validationName + 'lastName')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'lastName') }}
            </span>
          </div>
        </div>
        <div class="column is-2" v-if="!fulfillForDepositCaptureMode">
          <a class="is-pulled-right" v-if="index && index > 0" @click="onRemoveContactConfirm">
            <i class="has-text-danger fas fa-trash fa-sm"></i>
          </a>
        </div>
      </div>
      <div class="columns">
        <div class="column is-5" v-if="showNotificationField">
          <div class="field">
            <label class="label">Email Address<b-icon pack="fas" :icon="isIcon" class="fa-ss" v-show="isRequired"></b-icon></label>
            <div class="control has-icons-left has-icons-right">
              <input :disabled="isInActiveContact" :name="instanceName + '.email'" :data-vv-scope="scope" data-vv-as="email address" v-validate="{'required': isRequired, 'email': true}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has(validationName + 'email') }" type="text" placeholder="Email" v-model="contact.email" data-lpignore="true" autocomplete="off">
              <span v-show="errors.has(validationName + 'email')" class="help is-danger">
                <i v-show="errors.has(validationName + 'email')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'email') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right" v-show="!errors.has(validationName + 'email')">
                <i class="fas fa-check"></i>
              </span>
              <span class="icon is-small is-right" v-show="errors.has(validationName + 'email')">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-5" v-if="showNotificationField">
          <div class="field">
            <label class="label">Mobile</label>
            <div class="control">
              <input :disabled="isInActiveContact" v-validate="'min:16|max:16'" name="mobile" data-vv-as="mobile" :class="{'input': true }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="contact.mobile" data-lpignore="true" autocomplete="off">
              <span v-show="errors.has('mobile')" class="help is-danger">
                <i v-show="errors.has('mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('mobile') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!fulfillForDepositCaptureMode" class="columns is-multiline">
        <div class="column is-3">
          <button-list-selector v-on:on-change="onActiveChanged" validateAs="active" :scope="scope" label="Active" :required="isRequired" v-model="selectedActiveStatus" :availableValues="definedTypes.fillTypes.options"></button-list-selector>
        </div>
        <div class="column is-3">
          <button-list-selector :enabled="!isInActiveContact" validateAs="primary contact" :scope="scope" label="Primary Contact" :required="isRequired" v-model="selectedIsPrimary" :availableValues="definedTypes.fillTypes.options"></button-list-selector>
        </div>
        <div class="column is-6">
          <button-list-selector :enabled="!isInActiveContact" v-on:on-change="onBuyersChanged" :scope="scope" validateAs="receives paperwork to buy a car from us" label="Receives paperwork to buy a car from us" :required="isRequired" v-model="selectedReceivesBuyersOrder" :availableValues="definedTypes.fillTypes.options"></button-list-selector>
        </div>
        <div class="column is-5">
          <button-list-selector :enabled="!isInActiveContact" v-on:on-change="onSellersChanged" :scope="scope" validateAs="receives paperwork to sell a car to us" label="Receives paperwork to sell a car to us" :required="isRequired" v-model="selectedReceivesSellersOrder" :availableValues="definedTypes.fillTypes.options"></button-list-selector>
        </div>
        <div class="column is-4">
          <button-list-selector :enabled="!isInActiveContact" validateAs="receives payment &amp; title" :scope="scope" label="Receives Payment &amp; Title" :required="isRequired" v-model="selectedReceivesPaymentAndTitle" :availableValues="definedTypes.fillTypes.options"></button-list-selector>
        </div>
        <div class="column is-3">
        </div>
      </div>
    </div>

    <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>

    <confirm-delete-modal v-if="isDeleteConfirmationActive" title="Confirm Delete Contact" @on-confirm-delete="onRemoveContact" @on-cancel-delete="isDeleteConfirmationActive = false">
      Are you sure you wish to delete the selected contact?
    </confirm-delete-modal>
  </div>
</template>
<script>
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ConfirmDeleteModalComponent from '@/components/generic/ConfirmDeleteModalComponent'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'ContactCapture',
  props: {
    value: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    scope: {
      type: String,
      default: null
    },
    getValidationErrors: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    isIcon: {
      type: String,
      defualt: 'none'
    },
    useValidator: {
      type: Object,
      default: null
    },
    fulfillForDepositCaptureMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'confirm-delete-modal': ConfirmDeleteModalComponent
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      showLoader: false,
      contact: this.value,
      isDeleteConfirmationActive: false,
      selectedActiveStatus: this.value.selectedActiveStatus,
      selectedIsPrimary: this.value.selectedIsPrimary,
      selectedReceivesBuyersOrder: this.value.selectedReceivesBuyersOrder,
      selectedReceivesSellersOrder: this.value.selectedReceivesSellersOrder,
      selectedReceivesPaymentAndTitle: this.value.selectedReceivesPaymentAndTitle
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    validationName: function () {
      return (this.scope) ? this.scope + '.' + this.instanceName + '.' : this.instanceName + '.'
    },
    isInActiveContact: function () {
      return this.selectedActiveStatus.name === 'No'
    },
    hasContactInActiveClass: function () {
      return this.isInActiveContact ? 'inactive-contact' : ''
    },
    showNameField: function () {
      return !this.fulfillForDepositCaptureMode || (this.fulfillForDepositCaptureMode && this.contact.completeFirstContact !== undefined && this.contact.completeFirstContact === true)
    },
    showNotificationField: function () {
      return !this.fulfillForDepositCaptureMode || (this.fulfillForDepositCaptureMode)
    },
    getfulfillForDepositCaptureModeMessage: function () {
      let text = 'You need to complete the primary contact info below to be able to capture a deposit request.'
      return text
    }
  },
  methods: {
    onRemoveContactConfirm: function () {
      this.isDeleteConfirmationActive = true
    },
    onRemoveContact: function () {
      this.$emit('on-contact-remove', this.contact)
    },
    onBuyersChanged: function (value) {
      if (value !== null && value.name === 'Yes') {
        this.$emit('contactBuyerChanged', this.contact)
      }
    },
    onSellersChanged: function (value) {
      if (value !== null && value.name === 'Yes') {
        this.$emit('contactSellerChanged', this.contact)
      }
    },
    onActiveChanged: function (value) {
      if (value !== null && value.name === 'No') {
        let no = this.definedTypes.fillTypes.options.find(x => x.id === this.definedTypes.fillTypes.No)
        this.selectedIsPrimary = no
        this.selectedReceivesBuyersOrder = no
        this.selectedReceivesSellersOrder = no
        this.selectedReceivesPaymentAndTitle = no
      }
    }
  },
  watch: {
    'value.key': function (newValue, oldValue) {
      this.selectedActiveStatus = this.contact.selectedActiveStatus
      this.selectedIsPrimary = this.contact.selectedIsPrimary
      this.selectedReceivesBuyersOrder = this.contact.selectedReceivesBuyersOrder
      this.selectedReceivesSellersOrder = this.contact.selectedReceivesSellersOrder
      this.selectedReceivesPaymentAndTitle = this.contact.selectedReceivesPaymentAndTitle
      this.$forceUpdate()
    },
    getValidationErrors: function (newValue, oldValue) {
      if (newValue === true) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.contact.hasValidationErrors = false
            this.contact.canSave = true
          } else {
            this.contact.hasValidationErrors = true
            this.contact.canSave = false
          }
        })
      }
    },
    selectedActiveStatus: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.contact.selectedActiveStatus = newValue
      }
    },
    selectedIsPrimary: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.contact.selectedIsPrimary = newValue

        if (oldValue.name === 'Yes') {
          this.$emit('autoSelectPrimaryContact', this.contact)
        }
      }
    },
    selectedReceivesBuyersOrder: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.contact.selectedReceivesBuyersOrder = newValue
      }
    },
    selectedReceivesSellersOrder: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.contact.selectedReceivesSellersOrder = newValue
      }
    },
    selectedReceivesPaymentAndTitle: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.contact.selectedReceivesPaymentAndTitle = newValue
      }
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<style scoped>
  .box {
    background-color: #f6f8fa;
  }

  .inactive-contact {
    opacity: 0.5;
  }

</style>
