<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :taggable="false"
      :disabled="isDisabled"
      :searchable="true"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedVehiclePart"
      :options="allVehicleParts"
      label="listName"
      :placeholder="placeholder"
      trackBy="id"
      :loading="isVehiclePartsLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :data-vv-scope="scope"
      :hideSelected="true">
        <template slot="option" slot-scope="props">
          <label>{{ props.option.partNumber }} - {{props.option.name}}</label>
        </template>
      </multiselect>
      <a class="button is-primary new-vehicle-part-btn" @click="isNewVehiclePartActive = true" v-if="allowNew && hasCreateVehiclePartAccess">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
      </a>
      <a class="button is-primary edit-vehicle-part-btn" @click="isEditVehiclePartActive = true" v-if="allowEdit && hasEditVehiclePartAccess && showEditAction && selectedVehiclePartId !== 0">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewVehiclePartActive" class="new-vehicle-part-modal" scroll="keep" width="800px" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <new-vehicle-part
            viewMode="modal"
            actionPortal="modal-footer"
            overviewPortal=""
            headerPortal="modal-header"
            v-on:on-save="onVehiclePartSaved"
            v-on:on-cancel="onVehiclePartCancelled">
          </new-vehicle-part>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="isEditVehiclePartActive" class="edit-vehicle-part-modal"  scroll="keep" width="800px" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <edit-vehicle-part
            :vehiclePartId="selectedVehiclePartId"
            viewMode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onExistingVehiclePartSaved"
            v-on:on-cancel="onCancelEditVehiclePart">
          </edit-vehicle-part>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
      </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import vehiclePartService from '@/services/vehiclePartService'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'VehiclePartSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Vehicle Part'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded vehicle part'
    },
    validateAs: {
      type: String,
      default: 'vehicle part'
    },
    allowNew: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    showEditAction: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  components: {
    'new-vehicle-part': () => import('@/components/parts/NewVehiclePart'),
    'edit-vehicle-part': () => import('@/components/parts/EditVehiclePart')
  },
  data () {
    return {
      isVehiclePartsLoading: false,
      selectedVehiclePart: this.value,
      isNewVehiclePartActive: false,
      data: [],
      instanceName: 'vehicle-part-selector-' + this.$uuid.v4(),
      isEditVehiclePartActive: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    allVehicleParts () {
      return this.data
    },
    selectedVehiclePartId: function () {
      return this.selectedVehiclePart ? this.selectedVehiclePart.id : 0
    },
    hasEditVehiclePartAccess: function () {
      let allowedPermissions = ['vehicle.part.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    hasCreateVehiclePartAccess: function () {
      let allowedPermissions = ['vehicle.part.create']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    }
  },
  watch: {
    selectedVehiclePart: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentVehiclePart: function (newValue, oldValue) {
      this.selectedVehiclePart = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedVehiclePart = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    }
  },
  methods: {
    onVehiclePartSaved: function (newVehiclePart) {
      this.data.push(newVehiclePart)
      this.selectedVehiclePart = newVehiclePart
      this.isNewVehiclePartActive = false
    },
    onVehiclePartCancelled: function () {
      this.isNewVehiclePartActive = false
    },
    clearAllVehicleParts () {
      this.data = []
    },
    onPreviewRoServiceVehicle: function () {
      this.isNewRoServiceVehicleActive = true
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.currentDealer.id,
        sortBy: 'partNumber.asc'
      }
      this.isVehiclePartsLoading = true
      this.clearAllVehicleParts()
      vehiclePartService.allVehicleParts(searchCommand).then(response => {
        this.data = response.results
        this.isVehiclePartsLoading = false
      })
    },
    doVehiclePartServicePartSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (query) {
      this.doVehiclePartServicePartSearch(query)
    },
    onExistingVehiclePartSaved: function (vehiclePart) {
      this.selectedVehiclePart = vehiclePart
      this.isEditVehiclePartActive = false
    },
    onCancelEditVehiclePart: function () {
      this.isEditVehiclePartActive = false
    }
  },
  created () {
  },
  destroyed () {
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .new-vehicle-part-btn {
    margin-left:5px;
    height:40px;
  }

  .edit-vehicle-part-btn {
    margin-left:5px;
    height:40px;
  }

  .new-vehicle-part-modal {
  }

  .edit-vehicle-part-modal {
  }
</style>
