import axios from 'axios'
import baseService from './baseService'
import printRequestData from './data/printRequest'
import PrintRequest from '@/models/PrintRequest'

const printRequestService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('print-request-load-all')
      if (baseService.isTestMode) {
        resolve(printRequestData)
      } else {
        axios
          .post('/printrequest/list', searchCriteria, {...baseService.defaultConfig, requestId: 'print-request-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = PrintRequest.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('print request all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (printRequestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(printRequestData.all.find((x) => x.id === printRequestId))
      } else {
        axios
          .post('/printrequest/get?id=' + printRequestId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (printRequestModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        printRequestModels.forEach(element => {
          printRequestData.all.remove((x) => x.id === element.id)
        })
        resolve(printRequestData.all)
      } else {
        var ids = []
        printRequestModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/printrequest/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (printInstructionModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var printInstruction = {
          id: 999
        }
        printRequestData.all.push(printInstruction)
        resolve(printInstruction)
      } else {
        axios
          .post('/printrequest/save', printInstructionModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getByRoNumber (roNumber, dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(printRequestData.all.find((x) => x.id === 1))
      } else {
        axios
          .post('/printrequest/getByRoNumber?roNumber=' + roNumber + '&dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getByRoNumberAmount (roNumber, dealerId, amount) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(printRequestData.all.find((x) => x.id === 1))
      } else {
        axios
          .post('/printrequest/getByRoNumberAmount?roNumber=' + roNumber + '&dealerId=' + dealerId + '&amount=' + amount, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = PrintRequest.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  sendReminder (printInstructionModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var printInstruction = {
          id: 999
        }
        printRequestData.all.push(printInstruction)
        resolve(printInstruction)
      } else {
        axios
          .post('/printrequest/sendreminder', printInstructionModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearPaymentInProgress (printRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var printInstruction = {
          id: 999
        }
        printRequestData.all.push(printInstruction)
        resolve(printInstruction)
      } else {
        axios
          .post('/printrequest/clearPaymentInProgressPrintRequest', printRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  void (printRequestModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        printRequestModels.forEach(element => {
          printRequestData.all.remove((x) => x.id === element.id)
        })
        resolve(printRequestData.all)
      } else {
        var ids = []
        printRequestModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/printrequest/void', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  archive (printRequestModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        printRequestModels.forEach(element => {
          printRequestData.all.remove((x) => x.id === element.id)
        })
        resolve(printRequestData.all)
      } else {
        var ids = []
        printRequestModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/printrequest/archive', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markActive (printRequestModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        printRequestModels.forEach(element => {
          printRequestData.all.remove((x) => x.id === element.id)
        })
        resolve(printRequestData.all)
      } else {
        var ids = []
        printRequestModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/printrequest/markactive', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getByPartsInvoiceAmount (PartsInvoiceNo, dealerId, amount) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(printRequestData.all.find((x) => x.id === 1))
      } else {
        axios
          .post('/printrequest/getByPartsInvoiceAmount?partsInvoiceNo=' + PartsInvoiceNo + '&dealerId=' + dealerId + '&amount=' + amount, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default printRequestService
