import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import baseService from './baseService'
import inventoryData from './data/inventory'
import Inventory from '../models/Inventory'

const cachedAxios = setupCache(axios.create(), {
  ttl: 60000,
  methods: ['get', 'post'],
  cachePredicate: {
    statusCheck: (status) => status >= 200 && status < 400
  },
  staleIfError: false
})

const appService = {
  decode (vinNoModel) {
    return new Promise((resolve, reject) => {
      if (!vinNoModel.brandId) {
        let brandId = Math.floor(Math.random() * 50) + 1
        vinNoModel.brandId = brandId
      }
      axios.post('/inventory/decode', vinNoModel, {...baseService.defaultConfig, requestId: 'inventory-decode'})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('inventory decode cancelled')
          } else {
            reject(error.response.data)
          }
        })
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('inventory-load-all')
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        let localSearchCriteria = {...searchCriteria}
        if (localSearchCriteria && localSearchCriteria.typeId && !Array.isArray(localSearchCriteria.typeId)) {
          localSearchCriteria.typeId = [localSearchCriteria.typeId]
        }
        if (localSearchCriteria && localSearchCriteria.saleType && !Array.isArray(localSearchCriteria.saleType)) {
          localSearchCriteria.saleType = [localSearchCriteria.saleType]
        }
        axios
          .post('/inventory/list', localSearchCriteria, {...baseService.defaultConfig, requestId: 'inventory-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Inventory.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getWholesaleNow (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getWholesaleNow', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-wholesale-now'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory wholesale now cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getForReview (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getForReview', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-wholesale-now'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory wholesale now cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getWholesaleNowOrDispositionExpired (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getWholesaleNowOrDispositionExpired', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-wholesale-or-disposition'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory wholesale now or disposition expired cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getPendingArrival (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/pendingArrival', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-pending-arrival'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory pending arrival cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getPendingArrivalNew (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/pendingArrivalNew', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-pending-arrival'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory pending arrival cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getPendingArrivalUsed (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/pendingArrivalUsed', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-pending-arrival'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory pending arrival cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getInventoryByCriteria (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/bySoldAndInStockCriteria', searchCriteria, {...baseService.defaultConfig, requestId: searchCriteria.requestId ? searchCriteria.requestId : 'inventory-by-criteria'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory by criteria cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getLoanedOut (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/loanedOut', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-loaned-out'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory loaned out cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getApplicableInspectionTypeCosts (inventoryId, inspectionTypeId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .get('/inventory/getApplicableInspectionTypeCosts?inventoryId=' + inventoryId + '&inspectionTypeId=' + inspectionTypeId, {...baseService.defaultConfig, requestId: 'inventory-inspection-types-costs'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory get inspection type costs cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getInventoryToLocate (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/locate', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-locate'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory locate cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getInventoryToLocateNew (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/locateNew', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-locate'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory locate cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getInventoryToLocateUsed (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/locateUsed', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-locate'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory locate cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getDispositionExpired (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/dispositionExpired', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-disposition-expired'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory disposition expired cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getAllAccessoryEventsByInventory (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getAllAccessoryEvents?id=' + inventory.id, {...baseService.defaultConfig, requestId: 'inventory-accessory-events'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory all accessory events load cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getAllLoanerEventsByInventory (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getAllLoanerEvents?id=' + inventory.id, {...baseService.defaultConfig, requestId: 'inventory-loaner-events'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory all loaner events load cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getImportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getImportList', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-import'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory import load cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getMissingList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/getMissingList', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-missing'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory missing cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  nextStockNo (stockNoModel) {
    return new Promise((resolve, reject) => {
      axios.cancel('inventory-next-stock-no')
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/nextstockno', stockNoModel, {...baseService.defaultConfig, requestId: 'inventory-next-stock-no'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory-next-stock-no cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (inventoryId, requestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all.find((x) => x.id === inventoryId))
      } else {
        let options = requestId === undefined ? baseService.defaultConfig : {...baseService.defaultConfig, requestId: requestId}
        axios
          .post('/inventory/get?id=' + inventoryId, {}, options)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory single cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  save (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = {
          id: 999,
          stockNo: '9999',
          vinNo: '9999',
          status: 'Active'
        }
        inventoryData.all.push(inventory)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/save', inventoryModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === inventoryModel.id)
        if (inventory) {
          inventory.stockNo = inventoryModel.stockNo
          inventory.vinNo = inventoryModel.vinNo
        }
        resolve(inventory)
      } else {
        axios
          .post('/inventory/update', inventoryModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateForBillOfSale (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === inventoryModel.id)
        if (inventory) {
          inventory.stockNo = inventoryModel.stockNo
          inventory.vinNo = inventoryModel.vinNo
        }
        resolve(inventory)
      } else {
        axios
          .post('/inventory/updateForBillOfsale', inventoryModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addCarFaxReport (carFaxModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.vinNo === carFaxModel.vinNo)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/addCarFaxReport', carFaxModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addAppraisal (appraisalModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.vinNo === appraisalModel.vinNo)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/addAppraisal', appraisalModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (inventoryNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === inventoryNoteModel.inventoryId)
        if (inventory) {
          inventory.notes.push(inventoryNoteModel)
        }
        resolve(inventory)
      } else {
        axios
          .post('/inventory/notes', inventoryNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  resetvAutoError (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/resetvAutoError', {id: inventory.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateDispositionIntention (inventory, newDispositionIntentionId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/updateDispositionIntention', {inventoryId: inventory.id, dispositionIntentionId: newDispositionIntentionId}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePublishStatus (inventory, newPublishStatus) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/updatePublishStatus', {inventoryId: inventory.id, doNotPublish: newPublishStatus}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  resetCdkError (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/resetCdkError', {id: inventory.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePricingInfo (inventoryPricingModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updatePricingInfo', inventoryPricingModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (inventoryModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        inventoryModels.forEach(element => {
          inventoryData.all.remove((x) => x.id === element.id)
        })
        resolve(inventoryData.all)
      } else {
        var ids = []
        inventoryModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/inventory/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateAccessoryStatus (accessoryStatusUpdateModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        axios
          .post('/inventory/updateaccessorystatus', accessoryStatusUpdateModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateLoanerStatus (loanerStatusUpdateModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        axios
          .post('/inventory/updateloanerstatus', loanerStatusUpdateModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markReturned (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/markreturned', {id: inventoryItem.id}, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markArrived (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/markarrived', inventoryItem, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markArrivalSkipped (arrivalSkipModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(arrivalSkipModel)
      } else {
        axios
          .post('/inventory/markarrivalskipped', arrivalSkipModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  retireServiceLoaner (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/markServiceLoanerRetired', {id: inventoryItem.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  archive (inventoryModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        inventoryModels.forEach(element => {
          inventoryData.all.remove((x) => x.id === element.id)
        })
        resolve(inventoryData.all)
      } else {
        var ids = []
        inventoryModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/inventory/archive', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restore (inventoryModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        inventoryModels.forEach(element => {
          inventoryData.all.remove((x) => x.id === element.id)
        })
        resolve(inventoryData.all)
      } else {
        var ids = []
        inventoryModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/inventory/restore', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerId (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/findAllByDealerId?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Inventory.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePossessionStatus (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/UpdatePossessionStatus', inventoryModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addSellersAgreementRequest (orderEmailModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/addSellersAgreementRequest', orderEmailModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getPricingHistory (inventory, type) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve([])
      } else {
        axios
          .post('/inventory/getPricingHistory', {inventoryId: inventory.id, pricingType: type}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDispositionHistory (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve([])
      } else {
        axios
          .post('/inventory/getDispositionHistory', {inventoryId: inventory.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addConsignmentAgreementRequest (consignmentAgreementRequest) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/addConsignmentAgreementRequest', consignmentAgreementRequest, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateDivisionOfSaleRates (saleDivisionRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updateDivisionOfSaleRates', saleDivisionRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getUsersWithCheckRequestAccess (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/getUsersWithCheckRequestAccess?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadSignedConsignmentAgreement (signedAgreementUploadModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/uploadSignedConsignmentAgreement', signedAgreementUploadModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadSignedSellersAgreement (signedAgreementUploadModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/uploadSignedSellersAgreement', signedAgreementUploadModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateTitleStatus (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updateTitleStatus', inventory, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateWindowStickerStatus (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updateWindowStickerStatus', inventory, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getTitleInfo (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/inventory/getTitleInfo?id=' + inventory.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getWindowStickerInfo (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/inventory/getWindowStickerInfo?id=' + inventory.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadVehicleReports (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/uploadVehicleReports', inventory, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePayOffDetails (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updatePayOff', {
            id: inventory.id,
            payoffAmount: inventory.payoffAmount,
            goodUntilDate: inventory.goodUntilDate
          }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getVehicleReportInfo (inventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/inventory/getVehicleReportInfo?id=' + inventory.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveAccessoryEvent (inventoryAccessoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({})
      } else {
        axios
          .post('/inventory/saveAccessoryEvent', inventoryAccessoryModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/inventory/list',
          method: 'POST',
          responseType: 'blob',
          data: searchCriteria,
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  createVehicleVerificationRequest (vehicleVerificationRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/createVehicleVerificationRequest', vehicleVerificationRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateMileage (inventoryUsageModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updateMileage', inventoryUsageModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  convertPpa (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/convertppa', inventoryItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  convertLeaseReturn (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/convertLeaseReturn', inventoryItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  convertConsigned (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/convertconsigned', {id: inventoryItem.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  closePpa (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/closeppa', {id: inventoryItem.id, notes: inventoryItem.notes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  approvePpa (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/approveppa', inventoryItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByInventoryId (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/inventoryitemnotes?id=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  decodeVinInventory (vinNoModel) {
    return new Promise((resolve, reject) => {
      if (!vinNoModel.brandId) {
        let brandId = Math.floor(Math.random() * 50) + 1
        vinNoModel.brandId = brandId
      }
      axios.post('/inventory/decodevinfetchinventory', vinNoModel, {...baseService.defaultConfig, requestId: 'inventory-decode'})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('inventory decode cancelled')
          } else {
            reject(error.response.data)
          }
        })
    })
  },
  requestWindowStickerReload (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all.find((x) => x.id === inventoryId))
      } else {
        axios
          .post('/inventory/requestWindowStickerReload?id=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  convertPreEntry (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/convertpreentry', {id: inventoryItem.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  editTags (inventoryTagsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === inventoryTagsModel.id)
        if (inventory) {
          inventory.tags = inventoryTagsModel.tags
        }
        resolve(inventory)
      } else {
        axios
          .post('/inventory/tags', inventoryTagsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getVerifiedEmailList (blacklistEmailCheckModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var blacklistEmailCheck = {
          subscriberId: 0,
          emailAddresses: [],
          errorMessage: ''
        }
        resolve(blacklistEmailCheck)
      } else {
        cachedAxios
          .post('/inventory/getVerifiedEmailList', blacklistEmailCheckModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getPurchaseOffersByInventoryId (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/getpurchaseoffersbyinventoryid?id=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getLeadsByInventoryId (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/getleadsbyinventoryid?id=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getPublishStatusHistoryByInventoryId (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/getpublishstatushistorybyinventoryid?id=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  convertConsignedToTradeIn (inventoryConsignedToTradeInItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryConsignedToTradeInItem)
      } else {
        axios
          .post('/inventory/convertconsignedtotradein', inventoryConsignedToTradeInItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addAutoCheckReport (autoCheckModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.vinNo === autoCheckModel.vinNo)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/addAutoCheckReport', autoCheckModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markForceCdkPush (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === inventoryModel.id)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/markForceCdkPush', inventoryModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getInventoryPurchasedFromCustomer (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/getInventoryPurchasedFromCustomer?id=' + customerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getInventoryPurchasedFromSupplier (supplierId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/getInventoryPurchasedFromSupplier?id=' + supplierId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  generatePdfWithSignature (generatePdfModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var generatePdf = {
          signatureString: 'data:image/png;base64,...',
          inventoryId: 1
        }
        resolve(generatePdf)
      } else {
        axios
          .post('/inventory/generatePdfWithSignature', generatePdfModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDistinctSupplementalCostNamesByDealer (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .post('/inventory/getDistinctSupplementalCostNamesByDealer', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDistinctDealerSupplementalCosts (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .post('/inventory/getDistinctDealerSupplementalCosts', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateLocation (inventory, newLocationId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/updateLocation', {inventoryId: inventory.id, locationId: newLocationId}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveSupplementalCost (supplementalCostModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === supplementalCostModel.inventoryId)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/saveSupplementalCost', supplementalCostModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveSupplementalCosts (inventoryId, supplementalCostModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === supplementalCostModels[0].inventoryId)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/saveSupplementalCosts', {inventoryId: inventoryId, proposedSupplementalCosts: supplementalCostModels}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadLostTitleSignature (generatePdfModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var generatePdf = {
          signatureString: 'data:image/png;base64,...',
          inventoryId: 1
        }
        resolve(generatePdf)
      } else {
        axios
          .post('/inventory/uploadLostTitleSignature', generatePdfModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addLostTitleApplicationRequest (orderEmailModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/addLostTitleApplicationRequest', orderEmailModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadSignedLostTitleApplication  (signedLostTitleApplicationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/uploadSignedLostTitleApplication', signedLostTitleApplicationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markServiceLoanerOutOfService (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/markServiceLoanerOutOfService', { id: inventoryItem.id }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markServiceLoanerInService (inventoryItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryItem)
      } else {
        axios
          .post('/inventory/markServiceLoanerInService', {id: inventoryItem.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markImagesAsDeleted (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.id === inventoryModel.id)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/markImagesAsDeleted', inventoryModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventoryModel: Inventory.fromAPI(response.data.inventoryModel) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  decodeVinPpa (vinNoModel) {
    return new Promise((resolve, reject) => {
      if (!vinNoModel.brandId) {
        let brandId = Math.floor(Math.random() * 50) + 1
        vinNoModel.brandId = brandId
      }
      axios.post('/inventory/decodeVinPpa', vinNoModel, {...baseService.defaultConfig, requestId: 'inventory-decode'})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('inventory decode cancelled')
          } else {
            reject(error.response.data)
          }
        })
    })
  },
  getForPpaApproval (inventoryId, requestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all.find((x) => x.id === inventoryId))
      } else {
        let options = requestId === undefined ? baseService.defaultConfig : {...baseService.defaultConfig, requestId: requestId}
        axios
          .post('/inventory/getForPpaApproval?id=' + inventoryId, {}, options)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory single cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  findPpaConvertedInventory (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all.find((x) => x.id === inventoryId))
      } else {
        axios
          .get('/inventory/findPpaConvertedInventory?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markVehicleInspected (inventoryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryModel)
      } else {
        axios
          .post('/inventory/markInventoryInspected', { id: inventoryModel.id }, baseService.defaultConfig)
          .then(response => {
            resolve(Inventory.fromAPI(response.data))
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getVinHistory (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .get('/inventory/getVinHistory?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  sendTitleDocuments (sendTitleDocumentModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(sendTitleDocumentModel)
      } else {
        axios
          .post('/inventory/sendTitleDocuments', sendTitleDocumentModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByVehicleMakeId (vehicleMakeVersionId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all)
      } else {
        axios
          .get('/inventory/countAllByVehicleMakeVersionId?vehicleMakeVersionId=' + vehicleMakeVersionId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteSupplementalCost (supplementalCostModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/inventory/deleteSupplementalCost', supplementalCostModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addBlackBook (blackBookModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventory = inventoryData.all.find((x) => x.vinNo === blackBookModel.vinNo)
        resolve(inventory)
      } else {
        axios
          .post('/inventory/addBlackBook', blackBookModel, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  syncAttachments (attachmentsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData)
      } else {
        axios
          .post('/inventory/syncattachments', attachmentsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getInventoryByDealerIdVinNo (dealerId, vinNo) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryData.all.find((x) => x.id === dealerId))
      } else {
        axios
          .post('/inventory/getInventoryByDealerIdVinNo?dealerId=' + dealerId + '&vinNo=' + vinNo, baseService.defaultConfig)
          .then(response => {
            resolve({ inventory: Inventory.fromAPI(response.data.inventory) })
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('inventory single cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  inventoryMediaRequest (inventoryInformationMediaRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/createInformationMediaViewRequest', inventoryInformationMediaRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  sendTradeInTitleReassignmentDocument (sendTradeTitleReassignmentModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/sendTradeInTitleReassignmentDocument', sendTradeTitleReassignmentModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCommissionRequestStatus (inventoryId, commissionRequestId, commissionRequestStatus) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/inventory/updateCommissionRequestStatus?inventoryId=' + inventoryId + '&inventoryCommissionRequestId=' + commissionRequestId + '&commissionRequestStatus=' + commissionRequestStatus, baseService.defaultConfig)
          .then(response => {
            resolve(Inventory.fromAPI(response.data))
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService
