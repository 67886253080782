import mutationTypes from '../mutation-types.js'
import insurerService from '../../services/insurerService.js'

const state = {
  currentInsurer: {},
  allDealers: []
}

const getters = {
  allDealers: state => {
    return state.allDealers
  }
}
const mutations = {
  [mutationTypes.SET_DEALERS] (state, dealers) {
    state.allDealers = dealers
  },
  [mutationTypes.SET_CURRENT_INSURER] (state, insurer) {
    state.currentInsurer = insurer
  }
}
const actions = {
  setCurrentInsurer ({commit, state}, insurer) {
    commit(mutationTypes.SET_CURRENT_INSURER, insurer)
    insurerService.single(insurer.id)
      .then((data) => {
        commit(mutationTypes.SET_CURRENT_INSURER, data.insurer)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
