<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect
        :name="instanceName"
        @select="$markDirty()"
        :showLabels="false"
        :disabled="isDisabled"
        :data-vv-scope="scope"
        placeholder="Type to search for a technician"
        :data-vv-as="validateAs"
        data-vv-validate-on="input|close"
        v-validate="{ 'required': required }"
        :class="{ 'is-danger': errors.has(validationName) }"
        v-model="selectedTechnician"
        :options="availableTechnicians"
        label="fullName"
        trackBy="id"
        :hideSelected="true">
      </multiselect>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
  </div>
</template>

<script>

import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'TechnicianSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Technician'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded technician'
    },
    validateAs: {
      type: String,
      default: 'Technician'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  components: {
  },
  data () {
    return {
      selectedTechnician: this.value,
      data: [],
      instanceName: 'technician-selector-' + this.$uuid.v4(),
      storeDealer: this.$store.state.dealer.currentDealer
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['allUsers']),
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    availableTechnicians: function () {
      if (this.currentDealer) {
        let filteredUsers = this.allUsers.filter(function (user) {
          var found = user.designations.filter((y) => y.name.indexOf('Technician') >= 0).length > 0
          return found
        }, this)
        filteredUsers = _.orderBy(filteredUsers, ['fullName'], ['asc'])
        return filteredUsers.filter((x) => x.dealerId === this.currentDealer.id)
      }
      return []
    }
  },
  watch: {
    selectedTechnician: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    selectedDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
      if (this.selectedTechnician && this.selectedTechnician.dealerId !== this.selectedDealer.id) {
        this.selectedTechnician = null
      }
    },
    storeDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedTechnician = newValue
    }
  },
  methods: {
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .new-bank-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 185px);
  }
</style>
