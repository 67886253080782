import axios from 'axios'
import baseService from './baseService'
import roServiceData from './data/roService'
import RoServiceLaborType from '@/models/RoServiceLaborType'
import RoServiceOpCode from '@/models/RoServiceOpCode'
import RoService from '@/models/RoService'

const roServiceService = {
  allLaborTypes (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('roservice-labortypes-load-all')
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios
          .post('/roservicelabortype/list', searchCriteria, {...baseService.defaultConfig, requestId: 'roservice-labortypes-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = RoServiceLaborType.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('roservice labor types all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  allOpCodes (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('roservice-opcodes-load-all')
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios
          .post('/roserviceopcode/list', searchCriteria, {...baseService.defaultConfig, requestId: 'roservice-opcodes-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = RoServiceOpCode.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('roservice opcodes all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  generateRoNumber (roServiceNoGenerationModel) {
    return new Promise((resolve, reject) => {
      axios.cancel('ro-service-next-ro-number')
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/getNextRoNumber', roServiceNoGenerationModel, {...baseService.defaultConfig, requestId: 'ro-service-next-ro-number'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('ro-service-next-ro-number cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('roservice-load-all')
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios
          .post('/roservice/list', searchCriteria, {...baseService.defaultConfig, requestId: 'roservice-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = RoService.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('roservice all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  save (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roService = {
          id: 999,
          stockNo: '9999',
          vinNo: '9999',
          status: 'Active'
        }
        roServiceData.all.push(roService)
        resolve(roService)
      } else {
        axios
          .post('/roservice/save', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateExitMileage (roServiceExitMileageUpdateModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roService = {
          id: 999,
          stockNo: '9999',
          vinNo: '9999',
          status: 'Active'
        }
        roServiceData.all.push(roService)
        resolve(roService)
      } else {
        axios
          .post('/roservice/updateExitMileage', roServiceExitMileageUpdateModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveWorkItems (updateWorkItemsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roService = {
          id: 999,
          stockNo: '9999',
          vinNo: '9999',
          status: 'Active'
        }
        roServiceData.all.push(roService)
        resolve(roService)
      } else {
        axios
          .post('/roservice/saveWorkItems', updateWorkItemsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roService = roServiceData.all.find((x) => x.id === roServiceModel.id)
        if (roService) {
          roService.roNumber = roServiceModel.roNumber
        }
        resolve(roService)
      } else {
        axios
          .post('/roservice/update', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData.all.find((x) => x.id === roServiceId))
      } else {
        axios
          .post('/roservice/get?id=' + roServiceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (roServiceModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceModels.forEach(element => {
          roServiceData.all.remove((x) => x.id === element.id)
        })
        resolve(roServiceData.all)
      } else {
        var ids = []
        roServiceModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roService/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allCustomers (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios
          .post('/roservice/customerlist', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/notes', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByRoServiceId (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .get('/roservice/roservicenotes?id=' + roServiceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateManagerReviewStatus (roServiceId, newStatus) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/updateManagerReviewStatus', {roServiceId: roServiceId, managerReviewRequired: newStatus}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  approveManagerReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/approveManagerReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  declineManagerReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/declineManagerReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  cancelManagerReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/cancelManagerReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createManagerReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/createManagerReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateWarrantyReviewStatus (roServiceId, newStatus) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/updateWarrantyReviewStatus', {roServiceId: roServiceId, warrantyReviewRequired: newStatus}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  approveWarrantyReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/approveWarrantyReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  declineWarrantyReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/declineWarrantyReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  cancelWarrantyReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/cancelWarrantyReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createWarrantyReviewRequest (roService, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/createWarrantyReviewRequest', {roServiceId: roService.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearReviewRequests (roService) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/clearReviewRequests?roServiceId=' + roService.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  merge (mergeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios
          .post('/roservice/merge', mergeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  syncAttachments (attachmentsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios
          .post('/roservice/syncattachments', attachmentsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateRoServiceInfo (roServiceInfoModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/updateroserviceinfo', roServiceInfoModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setRoServiceDeliverySchedule (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/setDeliverySchedule', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markRoServiceDelivered (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/markRoServiceDelivered', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markRoServiceScheduled (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/markRoServiceScheduled', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDistinctVehicleMakeNamesByDealer (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve([''])
      } else {
        axios
          .post('/roservice/getdistinctvehiclemakenamesbydealer', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  closeRos (roServiceModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceModels.forEach(element => {
          roServiceData.all.remove((x) => x.id === element.id)
        })
        resolve(roServiceData.all)
      } else {
        var ids = []
        roServiceModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roService/close', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markRosDelivered (roServiceModels, deliveryDate) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceModels.forEach(element => {
          roServiceData.all.remove((x) => x.id === element.id)
        })
        resolve(roServiceData.all)
      } else {
        var ids = []
        roServiceModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roService/markDelivered', { roServiceIds: ids, deliveryDate: deliveryDate }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markRosReviewed (roServiceModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceModels.forEach(element => {
          roServiceData.all.remove((x) => x.id === element.id)
        })
        resolve(roServiceData.all)
      } else {
        var ids = []
        roServiceModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roService/markReviewed', { roServiceIds: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateRoHatNumber (roServiceHatNumberModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/updateroservicehatnumber', roServiceHatNumberModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setRoProblemVehicleStatus (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/markRoServiceProblemVehicle', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createPaidDeposit (roServiceDepositModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roservice/createpaiddeposit', roServiceDepositModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  archive (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/archive', {id: roServiceId}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restore (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/restore', {id: roServiceId}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearBulkRoReviewRequests (roServiceModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceModels.forEach(element => {
          roServiceData.all.remove((x) => x.id === element.id)
        })
        resolve(roServiceData.all)
      } else {
        var ids = []
        roServiceModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roService/clearBulkReviewRequests', { roServiceIds: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchCustomerById (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/roService/getRoCustomerById?id=' + customerId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCustomer (roServiceCustomerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roService/updateRoServiceCustomer', roServiceCustomerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateLinkedCustomers (roServiceId, ids) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData.all.find((x) => x.id === roServiceId))
      } else {
        axios
          .post('/roService/updateLinkedCustomers', { roServiceId: roServiceId, ids: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setRoServiceInternal (roServiceId, inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roService/setroserviceinternal?roServiceId=' + roServiceId + '&inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearLinkedServiceRequest (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roService/clearLinkedServiceRequest?roServiceId=' + roServiceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  checkRoServiceFlagStatus (vinNo, dealerId, inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roService/checkroserviceflagstatus?vinNo=' + vinNo + '&dealerId=' + dealerId + '&inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markRosOpen (roServiceModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceModels.forEach(element => {
          roServiceData.all.remove((x) => x.id === element.id)
        })
        resolve(roServiceData.all)
      } else {
        var ids = []
        roServiceModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roService/markOpen', { roServiceIds: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markOpen (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/markRoServiceOpen?id=' + roServiceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  sendValetNotification (roServiceValetNotificationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/roService/sendvaletnotification', roServiceValetNotificationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setWashedReady (roServiceId, washedReady) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/setwashedready?id=' + roServiceId + '&washedReady=' + washedReady, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getRoServiceByPrintRequestId (printRequestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData.all.find((x) => x.id === printRequestId))
      } else {
        axios
          .post('/roservice/getroservicebyprintrequestid?printRequestId=' + printRequestId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  changeRoServiceAdvisor (roServiceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/updateRoServiceAdvisor', roServiceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getRoServiceByRoNumber (roServiceRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData.all.find((x) => x.roNumber === roServiceRequestModel.roNumber))
      } else {
        axios
          .post('/roservice/getroservicebyronumber', roServiceRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setValetStatus (roServiceId, valetStatus) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/setvaletstatus?id=' + roServiceId + '&valetStatus=' + valetStatus, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateRoServicePaymentCollection (roServiceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roservice/updateRoServicePaymentCollection?id=' + roServiceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setRoServiceCustomerVerification (roServiceCustomerVerificationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/roService/updateRoServiceCustomerVerification', roServiceCustomerVerificationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceData)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/roservice/list',
          method: 'POST',
          responseType: 'blob',
          data: searchCriteria,
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  }
}

export default roServiceService
