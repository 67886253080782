<template>
  <nav class="navbar is-fixed-top" :class="appHeaderBackgroundColour">
    <div id="logo" class="navbar-brand" style="flex-grow: 1; flex-shrink: 1;">
      <span class="navbar-item is-hidden-desktop is-size-4" @click.stop="onToggleMenu"><i class="fal fa-sm" :class="{'fa-chevron-circle-down': !isSideBarActive, 'fa-chevron-circle-up': isSideBarActive}"></i></span>
      <a class="navbar-item is-hidden-touch is-hidden-mobile" v-if="subscriberLogoValid && showSubscriberLogo">
        <img :src="subscriberLogo" :alt="subscriberName"/>
      </a>
      <a class="navbar-item is-hidden-mobile" v-if="dealerLogoValid">
        <img :src="dealerLogo" :alt="dealerName"/>
      </a>
      <a class="navbar-item is-hidden-mobile"  title="Favourites" @click="onFavouritesClick" v-if="showFavourites">
        <span class="is-size-4"><i class="fas fa-fw fa-star"/></span>
        <span class="is-hidden-touch">Favourites</span>
      </a>
      <a class="navbar-item" title="Notifications" @click="onNotificationsClick" v-if="showNotifications">
        <span class="badge is-badge-small is-size-4" :data-badge="currentNotifications.length"><i class="fas fa-fw fa-bullhorn"/></span>
        <span class="is-hidden-touch">Notifications</span>
      </a>
      <global-search class="navbar-item is-pulled-right is-expanded"></global-search>
      <fast-action-button
        icon-size="medium"
        :position="fabPosition"
        :bg-color="fabBgColor"
        :actions="availableActions"
        @inventory="onNewInventory"
        @deal="onNewDeal"
        @service="onNewServiceRequest"
        @roservice="onNewRoService"
        @loan="onLoanerEvent"
        @inventory-pre-entry="onPreEntryInventory">
      </fast-action-button>
    </div>
    <div id="navbarItems" class="navbar-menu is-hidden-mobile" style="flex-grow: 0; flex-shrink: 0;">
      <div id="profile" class="navbar-end">
        <div class="navbar-item">
          <i class="fal fa-lg clickable" :title="connectionStatus" :class="getConnectionStatusClass()" @click="onReconnect()">&nbsp;
            <progress v-if="isConnecting" class="progress is-small is-danger mt-10" max="100">0%</progress>
          </i>
        </div>
        <figure class="image is-32x32 is-flex" v-if="userLogoValid">
          <img :src="userLogo" />
        </figure>
        <div class="info">
          <profile></profile>
          <span>Administrator</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import logger from '@/services/loggingService'
import defaultAppConfig from '@/config/index'
import Profile from '@/components/Profile'
import GlobalSearch from '@/components/generic/GlobalSearch'
import FastActionButton from '@/components/generic/FastActionButton'
import moment from 'moment'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
import util from '@/utils'

const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapUserActions = createNamespacedHelpers('user').mapActions
const mapUserState = createNamespacedHelpers('user').mapState
const mapSubscriberState = createNamespacedHelpers('subscriber').mapState
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters

const signalR = require('@microsoft/signalr')

export default {
  name: 'AppHeader',
  components: {
    profile: Profile,
    'global-search': GlobalSearch,
    'fast-action-button': FastActionButton
  },
  data () {
    return {
      showSubscriberLogo: false,
      fabPosition: 'top-right',
      fabBgColor: '#ff0000',
      availableActions: [],
      showFavourites: false,
      showNotifications: false,
      isInTabletMode: false,
      connection: null,
      isConnecting: false,
      isConnected: false,
      connectionStatus: 'Not connected',
      connectionTimer: undefined,
      lastConnectedAt: undefined,
      lastDisconnectedAt: undefined,
      isBeingDestroyed: false
    }
  },
  methods: {
    ...mapUserActions(['toggleSideBar']),
    getConnectionStatusClass: function () {
      var connectionStatusResult = this.isConnected === true ? 'fa-signal-alt' : 'fa-signal-alt-slash'
      var connectionColorResult = this.isConnected === true ? 'has-text-primary' : 'has-text-danger'
      let classToAttach = {}
      classToAttach[connectionStatusResult] = true
      classToAttach[connectionColorResult] = true
      return classToAttach
    },
    processDataPacket: function (data) {
      let eventType = data.type
      eventBus.$emit(eventType, data)
    },
    configureSocket: async function (source) {
      logger.logInfo('configureSocket called from source ' + source)
      let that = this
      this.isConnecting = true

      if (this.connection || source === 'reconnect') {
        logger.logInfo('Explicitly calling close socket')
        this.connection.stop()
      }
      this.connection = new signalR.HubConnectionBuilder().withUrl(process.env.VUE_APP_RT_API + 'liveHub', { accessTokenFactory: () => that.$store.state.auth.token, headers: { 'X-App-Version': defaultAppConfig.appVersion } }).build()

      this.connection.on('OnDataReceived', async function (target, data) {
        logger.logInfo('Received new data packet')
        await that.processDataPacket(data)
      })

      this.connection.on('OnSubscribed', function (target, data) {
        // eslint-disable-next-line no-prototype-builtins
        let isReconnect = data.hasOwnProperty('isReconnect') && data.isReconnect === true
        let totalMinutesDisconnected = that.lastDisconnectedAt ? moment.utc(moment.utc().diff(moment(that.lastDisconnectedAt))).format('m') : -1
        console.log('isReconnect - ' + isReconnect)
        console.log('lastConnectedAt - ' + that.lastConnectedAt)
        console.log('lastDisconnectedAt - ' + that.lastDisconnectedAt)
        console.log('Total minutes disconnected - ' + totalMinutesDisconnected)
        that.onDataSubscribed(data)
      })

      this.connection.on('OnUpgrade', function (target, data) {
        that.onUpgradeRequested(data)
      })

      this.connection.onclose((e) => {
        that.isConnected = false
        that.isConnecting = false
        that.connectionStatus = 'Not connected'

        that.lastDisconnectedAt = moment().utc()
        logger.logDanger('RPC disconnected')
        logger.logDanger('that.isBeingDestroyed - ' + that.isBeingDestroyed)
        logger.log(e)
        if (e) {
          logger.logDanger('Closed because of error')
          if ((e.response && e.response.status && e.response.status === 401) || (e.statusCode && e.statusCode === 401)) {
            that.$store.dispatch('auth/logoutRequest')
          } else {
            that.reconnect()
          }
        } else {
          if (that.isBeingDestroyed === true) {
            logger.log('Closed by user')
          } else {
            that.reconnect()
          }
        }
      })

      that.connectionStatus = 'Connecting'
      this.connection.start().then(function () {
        that.isConnected = true
        that.isConnecting = false
        that.connectionStatus = 'Connected'
        that.connection.invoke('Subscribe', {userId: that.currentUser.id, appVersion: defaultAppConfig.appVersion, isReconnect: that.lastConnectedAt !== undefined})
        that.lastConnectedAt = moment().utc()
      }).catch(function (err) {
        that.isConnected = false
        that.isConnecting = false
        that.connectionStatus = 'Not connected'
        logger.logDanger(err.toString())
        if ((err.response && err.response.status && err.response.status === 401) || (err.statusCode && err.statusCode === 401)) {
          that.$store.dispatch('auth/logoutRequest')
        } else {
          that.reconnect()
        }
      })
    },
    reconnect: function () {
      logger.logInfo('Reconnecting')
      let that = this
      clearInterval(that.connectionTimer)
      that.connectionTimer = undefined
      if (that.connectionTimer === undefined) {
        that.connectionTimer = setInterval(function () {
          if (that.isConnecting === false && that.isConnected === false) {
            that.configureSocket('timer')
          }
        }, 3000)
      }
    },
    onReconnect: function () {
      if (!this.isConnected) {
        this.lastConnectedAt = undefined
        this.configureSocket('reconnect')
      }
    },
    removeConnectionBindings: function () {
      console.log('Explicitly removing connections bindings')
      this.connection.off('OnDataRecevied')
    },
    onNewInventory () {
      this.$router.push({name: 'NewInventory'})
    },
    onPreEntryInventory () {
      this.$router.push({name: 'NewInventory', query: {preEntry: true}})
    },
    onNewDeal () {
      this.$router.push({name: 'NewDeal'})
    },
    onNewServiceRequest () {
      this.$router.push({name: 'NewServiceRequest'})
    },
    onLoanerEvent () {
      this.$router.push({name: 'NewLoanerEvent'})
    },
    onNewRoService () {
      this.$router.push({name: 'NewRoService'})
    },
    onToggleMenu: function () {
      this.toggleSideBar()
    },
    toggleMenu () {
      document.getElementById('navMenu').classList.toggle('is-active')
    },
    onFavouritesClick () {

    },
    onNotificationsClick () {
      this.$router.push({name: 'Notifications'})
    },
    getPermittedActions () {
      this.availableActions = []
      if (!this.isInTabletMode) {
        if (this.hasInventoryCreateAccessPermission) {
          this.availableActions.push({
            name: 'inventory',
            icon: 'fa-car',
            tooltip: 'Add a new inventory item',
            color: '#ff0000'
          })
        }
        if (this.hasFeatureAccess('inventory.create.pre-entry')) {
          this.availableActions.push({
            name: 'inventory-pre-entry',
            icon: 'fa-car-tilt',
            tooltip: 'Add a new pre-entry inventory item',
            color: '#ff0000'
          })
        }
        if (this.hasFeatureAccess('deal.create')) {
          this.availableActions.push({
            name: 'deal',
            icon: 'fa-handshake',
            tooltip: 'Add a new deal',
            color: '#ff0000'
          })
        }
        if (this.hasFeatureAccess('servicerequest.create')) {
          this.availableActions.push({
            name: 'service',
            icon: 'fa-wrench',
            tooltip: 'Add a new service request',
            color: '#ff0000'
          })
        }
      }

      if (this.hasFeatureAccess('loanerevent.create')) {
        this.availableActions.push({
          name: 'loan',
          icon: 'fa-arrow-circle-right',
          tooltip: 'Add a new loaner event',
          color: '#ff0000'
        })
      }

      if (this.hasFeatureAccess('ro.service.create')) {
        this.availableActions.push({
          name: 'roservice',
          icon: 'fa-car-mechanic',
          tooltip: 'Add a repair order',
          color: '#ff0000'
        })
      }
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentPendingActions', 'currentNotifications', 'isSideBarActive']),
    ...mapSubscriberState(['currentSubscriber']),
    ...mapUserState(['isAuthenticated', 'currentUser']),
    ...mapAuthGetters(['isSystemReady']),
    dealerLogo () {
      var defaultLogo = require('@/assets/logo.png')
      if (this.currentDealer) {
        return this.currentDealer.fileLocation !== '' ? this.currentDealer.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    dealerName () {
      var defaultName = 'Experience Auto Group'
      if (this.currentDealer) {
        return this.currentDealer.name
      }
      return defaultName
    },
    subscriberLogo () {
      var defaultLogo = require('@/assets/eag_logo.png')
      if (this.currentSubscriber) {
        return this.currentSubscriber.fileLocation !== '' ? this.currentSubscriber.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    subscriberName () {
      var defaultName = 'Experience Auto Group'
      if (this.currentSubscriber) {
        return this.currentSubscriber.name
      }
      return defaultName
    },
    userLogo () {
      var defaultLogo = require('@/assets/default_profile.png')
      if (this.currentUser) {
        return this.currentUser.fileLocation !== '' ? this.currentUser.fileLocation : defaultLogo
      }
    },
    subscriberLogoValid () {
      return this.subscriberLogo !== ''
    },
    dealerLogoValid () {
      return this.dealerLogo !== ''
    },
    userLogoValid () {
      return this.userLogo !== ''
    },
    appHeaderBackgroundColour () {
      return (this.currentSubscriber) ? this.currentSubscriber.appHeaderBackgroundColour : ''
    },
    hasInventoryCreateAccessPermission: function () {
      let allowedPermissions = ['inventory.create', 'inventory.create.type-new', 'inventory.create.type-purchased', 'inventory.create.type-consigned', 'inventory.create.type-tradein', 'inventory.create.type-ppa', 'inventory.create.type-leasereturn']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    }
  },
  mounted: async function () {
    logger.logSuccess('Mounting header components')
    var navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
    if (navbarBurgers.length > 0) {
      navbarBurgers.forEach(function (el) {
        el.addEventListener('click', function () {
          var target = document.getElementById(el.dataset.target)
          el.classList.toggle('is-active')
          target.classList.toggle('is-active')
        })
      })
    }
    this.isInTabletMode = util.isInTabletMode()
    this.getPermittedActions()

    if (this.isAuthenticated && this.isSystemReady) {
      this.configureSocket('mounted')
    }
  },
  beforeDestroy () {
    logger.logDanger('Destroying header components')
    this.isBeingDestroyed = true
    if (this.connectionTimer) {
      logger.logDanger('clearing timer in destroy')
      clearInterval(this.connectionTimer)
      this.connectionTimer = undefined
    }
    if (this.connection) {
      this.removeConnectionBindings()
      this.connection.stop()
    }
  }
}
</script>

<style scoped>
  .navbar-item {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
</style>
