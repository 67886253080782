import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import baseService from './baseService'
import laborTypeData from './data/roServiceLaborTypes'
import RoServiceLaborType from '@/models/RoServiceLaborType'

const cachedAxios = setupCache(axios.create(), {
  ttl: 5000,
  methods: ['get'],
  cachePredicate: {
    statusCheck: (status) => status >= 200 && status < 400
  },
  staleIfError: false
})

const laborTypeService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('op-code-load-all')
      if (baseService.isTestMode) {
        resolve(laborTypeData)
      } else {
        axios
          .post('/roservicelabortype/list', searchCriteria, {...baseService.defaultConfig, requestId: 'labortype-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = RoServiceLaborType.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('labor type all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (roServiceLaborTypeId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(laborTypeData.all.find((x) => x.id === roServiceLaborTypeId))
      } else {
        cachedAxios
          .get('/roservicelabortype/get?id=' + roServiceLaborTypeId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (roServiceLaborTypeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roServiceLaborType = {
          id: 999,
          dealerId: roServiceLaborTypeModel.dealerId,
          subscriberId: roServiceLaborTypeModel.subscriberId,
          status: 'Active',
          code: roServiceLaborTypeModel.code,
          description: roServiceLaborTypeModel.description,
          unitCost: roServiceLaborTypeModel.unitCost
        }
        laborTypeData.all.push(roServiceLaborType)
        resolve(roServiceLaborType)
      } else {
        axios
          .post('/roservicelabortype/save', roServiceLaborTypeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (roServiceLaborTypeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roServiceLaborType = laborTypeData.all.find((x) => x.id === roServiceLaborTypeModel.id)
        if (roServiceLaborType) {
          roServiceLaborType.dealerId = roServiceLaborTypeModel.dealerId
          roServiceLaborType.subscriberId = roServiceLaborTypeModel.subscriberId
          roServiceLaborType.status = roServiceLaborTypeModel.status
          roServiceLaborType.code = roServiceLaborTypeModel.code
          roServiceLaborType.description = roServiceLaborTypeModel.description
          roServiceLaborType.unitCost = roServiceLaborTypeModel.unitCost
        }
        resolve(roServiceLaborType)
      } else {
        axios
          .post('/roservicelabortype/update', roServiceLaborTypeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (roServiceLaborTypeModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceLaborTypeModels.forEach(element => {
          laborTypeData.all.remove((x) => x.id === element.id)
        })
        resolve(laborTypeData.all)
      } else {
        var ids = []
        roServiceLaborTypeModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roservicelabortype/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  roServiceLabourTypeImport (importModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(laborTypeData)
      } else {
        axios
          .post('/roservicelabortype/roServiceLabourTypeImport', importModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default laborTypeService
