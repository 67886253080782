import axios from 'axios'
import baseService from './baseService'
import designationData from './data/designation'
import Designation from '@/models/Designation'

const designationService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(designationData)
      } else {
        axios
          .post('/designation/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Designation.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (designationId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(designationData.all.find((x) => x.id === designationId))
      } else {
        axios
          .post('/designation/get?id=' + designationId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (designationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var designation = {
          id: 999,
          name: designationModel.name,
          status: 'Active'
        }
        designationData.all.push(designation)
        resolve(designation)
      } else {
        axios
          .post('/designation/save', designationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (designationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var designation = designationData.all.find((x) => x.id === designationModel.id)
        if (designation) {
          designation.name = designationModel.name
          designation.status = designationModel.status
        }
        resolve(designation)
      } else {
        axios
          .post('/designation/update', designationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (designationModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        designationModels.forEach(element => {
          designationData.all.remove((x) => x.id === element.id)
        })
        resolve(designationData.all)
      } else {
        var ids = []
        designationModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/designation/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default designationService
