import mutationTypes from '../mutation-types.js'
import moment from 'moment-timezone'

const state = {
  defaultPageSize: 32767,
  maxAccessoryEvents: 5,
  maxLoanerEvents: 5,
  maxServiceRequests: 5,
  maxSupplierContacts: 5,
  maxCustomerCalls: 5,
  maxUserLoginRequests: 25,
  types: {
    allowedDataSourceTypes: {
      Live: 0,
      Cdk: 1,
      Conflict: 2,
      options: [
        {
          id: 0,
          name: 'Live',
          altName: 'Live'
        },
        {
          id: 1,
          name: 'Cdk',
          altName: 'Cdk'
        },
        {
          id: 2,
          name: 'Conflict',
          altName: 'Conflict'
        }
      ]
    },
    allowedPurchaseToleranceTypes: {
      Dollar: 0,
      Percentage: 1,
      options: [
        {
          id: 0,
          name: 'Dollar',
          altName: 'Dollar'
        },
        {
          id: 1,
          name: 'Percentage',
          altName: 'Percentage'
        }
      ]
    },
    allowedLocateTypes: {
      New: 0,
      Used: 1,
      options: [
        {
          id: 0,
          name: 'New',
          altName: 'New'
        },
        {
          id: 1,
          name: 'Used',
          altName: 'Used'
        }
      ]},
    allowedSourceTypes: {
      Factory: 0,
      Wholesaler: 1,
      Individual: 2,
      options: [
        {
          id: 0,
          name: 'Factory',
          altName: 'Factory'
        },
        {
          id: 1,
          name: 'Wholesaler/Dealer',
          altName: 'Wholesaler'
        },
        {
          id: 2,
          name: 'Individual',
          altName: 'Individual'
        }
      ]},
    possessionStatuses: [
      {
        id: 0,
        name: 'Arrived',
        altName: 'Arrived'
      },
      {
        id: 1,
        name: 'Inbound',
        altName: 'Inbound'
      },
      {
        id: 2,
        name: 'Returned',
        altName: 'Returned'
      },
      {
        id: 4,
        name: 'Located Off-Site',
        altName: 'LocatedOffSite'
      },
      {
        id: 5,
        name: 'Locating',
        altName: 'Locating'
      }
    ],
    dispositionIntentionStatuses: [
      {
        id: 0,
        name: 'Retail',
        altName: 'Retail'
      },
      {
        id: 2,
        name: 'Wholesale Immediately',
        altName: 'WholesaleImmediately'
      },
      {
        id: 3,
        name: 'Retail For X Days',
        altName: 'RetailForXDays'
      }
    ],
    leaseReturnIntentionStatuses: {
      TurnItIn: 0,
      BuyOutAndWholeSale: 1,
      BuyOutForRetail: 2,
      options:
      [
        {
          id: 0,
          name: 'Turn It In',
          altName: 'TurnItIn'
        },
        {
          id: 1,
          name: 'Buy Out And WholeSale',
          altName: 'BuyOutAndWholeSale'
        },
        {
          id: 2,
          name: 'Buy Out For Retail',
          altName: 'BuyOutForRetail'
        }
      ]
    },
    cpoStatusTypes: [
      {
        id: 0,
        name: 'Ineligible',
        altName: 'Ineligible'
      },
      {
        id: 1,
        name: 'Eligible',
        altName: 'Eligible'
      },
      {
        id: 2,
        name: 'Cpo',
        altName: 'Cpo'
      }
    ],
    inventoryTypes: [
      {
        id: 0,
        name: 'New',
        altName: 'New'
      },
      {
        id: 1,
        name: 'Purchased',
        altName: 'Purchased'
      },
      {
        id: 2,
        name: 'PPA Closed',
        altName: 'PpaClosed'
      },
      {
        id: 3,
        name: 'Consigned',
        altName: 'Consigned'
      },
      {
        id: 4,
        name: 'Trade In',
        altName: 'TradeIn'
      },
      {
        id: 5,
        name: 'Pre-Purchase Appraisal',
        altName: 'ForPpaAppraisal'
      },
      {
        id: 6,
        name: 'Lease Return',
        altName: 'LeaseReturn'
      }
    ],
    wholeSalers: [
      {
        id: 1,
        name: 'FNA'
      },
      {
        id: 2,
        name: 'MNA'
      },
      {
        id: 3,
        name: 'Left Lane'
      },
      {
        id: 4,
        name: 'FLI'
      },
      {
        id: 5,
        name: 'VCOM'
      },
      {
        id: 6,
        name: 'POSS'
      },
      {
        id: 7,
        name: 'MGC'
      }
    ],
    damagedOptions: [
      {
        id: 0,
        name: 'No',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'Yes',
        ico: 'fas fa-times has-text-danger'
      }
    ],
    carCoverOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        altName: 'No',
        ico: 'fas fa-times has-text-danger'
      },
      {
        id: 2,
        name: 'Owed To Us',
        altName: 'OwedToUs',
        ico: 'fas fa-user-secret has-text-warning'
      }
    ],
    carCoverTrackingOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        altName: 'No',
        ico: 'fas fa-times has-text-danger'
      }
    ],
    bookOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'Owed To Us',
        altName: 'OwedToUs',
        ico: 'fas fa-user-secret has-text-warning'
      }
    ],
    bookTrackingOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        altName: 'No',
        ico: 'fas fa-times has-text-warning'
      }
    ],
    batteryChargerOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        altName: 'No',
        ico: 'fas fa-times has-text-danger'
      },
      {
        id: 2,
        name: 'Owed To Us',
        altName: 'OwedToUs',
        ico: 'fas fa-user-secret has-text-warning'
      }
    ],
    batteryChargerTrackingOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        altName: 'No',
        ico: 'fas fa-times has-text-danger'
      }
    ],
    titleStatusOptions: {
      Yes: 0,
      PayOff: 1,
      Lost: 2,
      Pending: 3,
      options: [
        {
          id: 0,
          name: 'Yes',
          ico: 'fas fa-check has-text-success'
        },
        {
          id: 1,
          name: 'PayOff',
          ico: 'fas fa-times has-text-danger'
        },
        {
          id: 2,
          name: 'Lost',
          ico: 'fas fa-user-secret has-text-warning'
        },
        {
          id: 3,
          name: 'Pending',
          ico: 'fas fa-arrow-right has-text-link'
        }
      ]
    },
    conditionTypes: {
      New: 0,
      Used: 1,
      options: [
        {
          id: 0,
          name: 'New'
        },
        {
          id: 1,
          name: 'Used'
        }
      ]
    },
    windowStickerStatusOptions: [
      {
        id: 0,
        name: 'Yes',
        altName: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        altName: 'No',
        ico: 'fas fa-times has-text-danger'
      }
    ],
    keyOptions: [
      {
        id: 0,
        name: '2',
        altName: 'Two',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: '1',
        altName: 'One',
        ico: 'fas fa-times has-text-danger'
      }
    ],
    dealTypes: [
      {
        id: 5,
        name: 'Ordered',
        altName: 'Ordered',
        widgetOrder: 1,
        icon: 'fal fa-receipt',
        widgetClass: 'notselectable',
        selectableId: [0, 4],
        statusProgressionWizardActive: true
      },
      {
        id: 6,
        name: 'Locating',
        altName: 'Locating',
        widgetOrder: 2,
        icon: 'fal fa-binoculars',
        widgetClass: 'notselectable',
        selectableId: [0, 4],
        statusProgressionWizardActive: true
      },
      {
        id: 8,
        name: 'Pending Credit',
        altName: 'PendingCredit',
        widgetOrder: 3,
        icon: 'fal fa-file-signature',
        widgetClass: 'notselectable',
        selectableId: [0, 4],
        statusProgressionWizardActive: false
      },
      {
        id: 0,
        name: 'Awaiting Arrival',
        altName: 'AwaitingArrival',
        widgetOrder: 4,
        icon: 'fal fa-arrow-circle-down',
        widgetClass: 'notselectable',
        selectableId: [1, 4],
        statusProgressionWizardActive: false
      },
      {
        id: 1,
        name: 'Pending Delivery',
        altName: 'Pending',
        widgetOrder: 5,
        icon: 'fal fa-clock',
        widgetClass: 'notselectable',
        selectableId: [2, 4],
        statusProgressionWizardActive: false
      },
      {
        id: 2,
        name: 'Delivered',
        altName: 'Delivered',
        widgetOrder: 6,
        icon: 'fal fa-truck',
        widgetClass: 'notselectable',
        selectableId: [1, 3, 4],
        statusProgressionWizardActive: true
      },
      {
        id: 3,
        name: 'Booked',
        altName: 'Booked',
        widgetOrder: 7,
        icon: 'fal fa-book',
        widgetClass: 'notselectable',
        selectableId: [4],
        statusProgressionWizardActive: false
      },
      {
        id: 7,
        name: 'Allocated',
        altName: 'Allocated',
        widgetOrder: 8,
        icon: 'fal fa-ballot-check',
        widgetClass: 'notselectable',
        selectableId: [],
        statusProgressionWizardActive: true
      },
      {
        id: 4,
        name: 'Cancelled',
        altName: 'Cancelled',
        widgetOrder: 9,
        icon: 'fal fa-ban',
        widgetClass: 'selectable',
        selectableId: [],
        statusProgressionWizardActive: true
      }
    ],
    dealSaleTypes: [
      {
        id: 0,
        name: 'Retail',
        altName: 'Retail'
      },
      {
        id: 1,
        name: 'Wholesale',
        altName: 'Wholesale'
      }
    ],
    buyerTypes: [
      {
        id: 0,
        name: 'Customer',
        altName: 'Customer'
      },
      {
        id: 1,
        name: 'Supplier',
        altName: 'Supplier'
      },
      {
        id: 2,
        name: 'Ro Customer',
        altName: 'RoServiceCustomer'
      },
      {
        id: 3,
        name: 'Parts Customer',
        altName: 'PartsCustomer'
      }
    ],
    dealStockTypes: [
      {
        id: 0,
        name: 'In Stock',
        altName: 'InStock'
      },
      {
        id: 1,
        name: 'Order',
        altName: 'Order'
      },
      {
        id: 2,
        name: 'Locate',
        altName: 'Locate'
      }
    ],
    dealVehicleTypes: [
      {
        id: -1,
        name: 'All',
        altName: 'All'
      },
      {
        id: 0,
        name: 'New',
        altName: 'New'
      },
      {
        id: 1,
        name: 'All Used',
        altName: 'AllUsed'
      },
      {
        id: 2,
        name: 'CPO',
        altName: 'Cpo'
      },
      {
        id: 3,
        name: 'Non CPO',
        altName: 'NonCpo'
      }
    ],
    timezones: [...moment.tz.names().map((x, i) => ({id: i, name: x}))],
    popPrivacyLevels: ['Standard', 'Email Only', 'Intercom & Email'],
    accessoryEventType: {
      NotSet: null,
      CheckedIn: 0,
      CheckedOut: 1,
      options:
      [
        {
          id: null,
          name: 'Not Set',
          altName: 'NotSet',
          actionName: '',
          ico: 'fas fa-times has-text-danger',
          style: 'has-text-danger'
        },
        {
          id: 0,
          name: 'Checked In',
          altName: 'CheckedIn',
          actionName: 'Checking In',
          ico: 'fas fa-check has-text-success',
          style: 'has-text-success'
        },
        {
          id: 1,
          name: 'Checked Out',
          altName: 'CheckedOut',
          actionName: 'Checking Out',
          ico: 'fas fa-times has-text-danger',
          style: 'has-text-danger'
        }
      ]
    },
    loanerEventType: {
      NotSet: null,
      SalesLoaner: 0,
      Returned: 1,
      ServiceLoaner: 2,
      TestDrive: 3,
      EmployeeDriver: 4,
      options:
      [
        {
          id: null,
          name: 'Not Set',
          altName: 'NotSet',
          displayName: 'Not Set'
        },
        {
          id: 0,
          name: 'Sales Loaner',
          altName: 'SalesLoaner',
          displayName: 'Sales Loaner'
        },
        {
          id: 1,
          name: 'Returned',
          altName: 'Returned',
          displayName: 'Returned'
        },
        {
          id: 2,
          name: 'Service Loaner',
          altName: 'ServiceLoaner',
          displayName: 'Service'
        },
        {
          id: 3,
          name: 'Test Drive',
          altName: 'TestDrive',
          displayName: 'Test Drive'
        },
        {
          id: 4,
          name: 'Employee Driver',
          altName: 'EmployeeDriver',
          displayName: 'Employee'
        }
      ]
    },
    serviceRequestType: {
      FirstRo: 0,
      UsedCarPreDeliveryInspection: 1,
      NewCarPreDeliveryInspection: 2,
      RepairOrder: 3,
      AdditionalReconditioning: 4,
      PrePurchaseInspection: 5,
      GetReady: 6,
      Service: 7,
      FinanceRequestedInstall: 8,
      FinanceProductRequest: 9,
      options:
      [
        {
          id: 0,
          name: 'First RO',
          altName: 'FirstRo'
        },
        {
          id: 1,
          name: 'Used - Pre Delivery Inspection',
          altName: 'UsedCarPreDeliveryInspection'
        },
        {
          id: 2,
          name: 'New - Pre Delivery Inspection',
          altName: 'NewCarPreDeliveryInspection'
        },
        {
          id: 3,
          name: 'Repair Order',
          altName: 'RepairOrder'
        },
        {
          id: 4,
          name: 'Additional Reconditioning',
          altName: 'AdditionalReconditioning'
        },
        {
          id: 5,
          name: 'Pre Purchase Inspection',
          altName: 'PrePurchaseInspection'
        },
        {
          id: 6,
          name: 'Get Ready',
          altName: 'GetReady'
        },
        {
          id: 7,
          name: 'Service',
          altName: 'Service'
        },
        {
          id: 8,
          name: 'Finance Requested Install',
          altName: 'FinanceRequestedInstall'
        },
        {
          id: 9,
          name: 'F&I Product Request',
          altName: 'FinanceProductRequest'
        }
      ]
    },
    serviceRequestStatusType: {
      NotSet: null,
      New: 0,
      PendingDispatch: 1,
      AwaitingApproval: 2,
      Approved: 3,
      InProgress: 4,
      OnHold: 5,
      Declined: 6,
      Complete: 7,
      Cancelled: 8,
      WorkCompleted: 9,
      options:
      [
        {
          id: null,
          name: 'Not Set',
          altName: 'NotSet',
          actionName: '',
          easyName: 'No Get Ready',
          ico: 'fas fa-times has-text-danger',
          style: 'has-text-danger'
        },
        {
          id: 0,
          name: 'New',
          easyName: 'New',
          altName: 'New'
        },
        {
          id: 1,
          name: 'Pending Dispatch',
          easyName: 'Pending Dispatch',
          altName: 'PendingDispatch'
        },
        {
          id: 2,
          name: 'Awaiting Approval',
          easyName: 'Awaiting Approval',
          altName: 'AwaitingApproval'
        },
        {
          id: 3,
          name: 'Approved',
          easyName: 'Approved',
          altName: 'Approved'
        },
        {
          id: 4,
          name: 'In Progress',
          easyName: 'In Progress',
          altName: 'InProgress'
        },
        {
          id: 5,
          name: 'On Hold',
          easyName: 'On Hold',
          altName: 'OnHold'
        },
        {
          id: 6,
          name: 'Declined',
          easyName: 'Declined',
          altName: 'Declined'
        },
        {
          id: 7,
          name: 'Complete',
          easyName: 'Complete',
          altName: 'Complete'
        },
        {
          id: 9,
          name: 'Work Completed',
          easyName: 'Work Completed',
          altName: 'WorkCompleted'
        },
        {
          id: 8,
          name: 'Cancelled',
          easyName: 'Cancelled',
          altName: 'Cancelled'
        }
      ]
    },
    serviceRequestLineStatusType: {
      New: 0,
      PendingDispatch: 1,
      AwaitingApproval: 2,
      Approved: 3,
      InProgress: 4,
      OnHold: 5,
      Declined: 6,
      Complete: 7,
      Cancelled: 8,
      options:
      [
        {
          id: 0,
          name: 'New',
          altName: 'New'
        },
        {
          id: 1,
          name: 'Pending Dispatch',
          altName: 'PendingDispatch'
        },
        {
          id: 2,
          name: 'Awaiting Approval',
          altName: 'AwaitingApproval'
        },
        {
          id: 3,
          name: 'Approved',
          altName: 'Approved'
        },
        {
          id: 4,
          name: 'In Progress',
          altName: 'InProgress'
        },
        {
          id: 5,
          name: 'On Hold',
          altName: 'OnHold'
        },
        {
          id: 6,
          name: 'Declined',
          altName: 'Declined'
        },
        {
          id: 7,
          name: 'Complete',
          altName: 'Complete'
        },
        {
          id: 8,
          name: 'Cancelled',
          altName: 'Cancelled'
        }
      ]
    },
    groupType: [
      {
        id: 0,
        name: 'Sale Date',
        altName: 'SaleDate'
      },
      {
        id: 1,
        name: 'Delivery Date',
        altName: 'DeliveryDate'
      },
      {
        id: 2,
        name: 'Status',
        altName: 'Status'
      },
      {
        id: 3,
        name: 'Salesman',
        altName: 'Salesman'
      },
      {
        id: 4,
        name: 'F&I Manager',
        altName: 'FIManager'
      },
      {
        id: 5,
        name: 'Make',
        altName: 'Make'
      },
      {
        id: 6,
        name: 'Source',
        altName: 'Source'
      },
      {
        id: 7,
        name: 'Customer',
        altName: 'Customer'
      },
      {
        id: 8,
        name: 'Buyer',
        altName: 'Buyer'
      },
      {
        id: 9,
        name: 'Manager',
        altName: 'Manager'
      }
    ],
    inventoryConditionType: [
      {
        id: 0,
        name: 'Excellent',
        altName: 'Excellent'
      },
      {
        id: 1,
        name: 'Good',
        altName: 'Good'
      },
      {
        id: 2,
        name: 'Fair',
        altName: 'Fair'
      },
      {
        id: 3,
        name: 'Poor',
        altName: 'Poor'
      }
    ],
    batchPaymentRequestOptions: [
      {
        id: 0,
        name: 'Yes',
        ico: 'fas fa-check has-text-success'
      },
      {
        id: 1,
        name: 'No',
        ico: 'fas fa-times has-text-danger'
      }
    ],
    serviceRequestPriorityType: {
      Low: 0,
      Medium: 1,
      High: 2,
      options: [
        {
          id: 0,
          name: 'Low',
          ico: 'fal fa-fire has-text-success'
        },
        {
          id: 1,
          name: 'Medium',
          ico: 'fal fa-fire has-text-orange'
        },
        {
          id: 2,
          name: 'High',
          ico: 'fal fa-fire has-text-danger'
        }
      ]
    },
    incentiveTypes: {
      Dollar: 0,
      Percentage: 1,
      options: [
        {
          id: 0,
          name: 'Dollar',
          altName: 'Dollar'
        },
        {
          id: 1,
          name: 'Percentage',
          altName: 'Percentage'
        }
      ]
    },
    inspectionTypes: [
      {
        id: 0,
        name: 'CPO Inspection',
        altName: 'UseCarCpo'
      },
      {
        id: 1,
        name: 'MPI',
        altName: 'MultiPointInspection'
      },
      {
        id: 2,
        name: 'Pre-Delivery Inspection',
        altName: 'PreDeliveryInspection'
      },
      {
        id: 3,
        name: 'Re-Inspection for Delivery',
        altName: 'DeliveryReinspection'
      },
      {
        id: 4,
        name: 'Pre-Purchase Inspection',
        altName: 'PrePurchaseInspection'
      },
      {
        id: 5,
        name: 'Lease Return Inspection',
        altName: 'LeaseReturnInspection'
      }
    ],
    paymentTypes: [
      {
        id: 0,
        name: 'Check',
        altName: 'Check'
      },
      {
        id: 1,
        name: 'Wire',
        altName: 'Wire'
      },
      {
        id: 3,
        name: 'Accounting',
        altName: 'Accounting'
      },
      {
        id: 4,
        name: 'Auto Floorplan',
        altName: 'AutoFloorplan'
      }
    ],
    paymentMethods: [
      {
        id: 0,
        name: 'Check'
      },
      {
        id: 1,
        name: 'Wire'
      }
    ],
    payeeTypes: [
      {
        id: 0,
        name: 'Wholesaler',
        altName: 'Wholesaler'
      },
      {
        id: 1,
        name: 'Customer',
        altName: 'Customer'
      },
      {
        id: 3,
        name: 'Pay Off',
        altName: 'PayOff'
      },
      {
        id: 4,
        name: 'Apply to A/R',
        altName: 'ApplyToAr'
      },
      {
        id: 5,
        name: 'Retain as Deposit',
        altName: 'RetainAsDeposit'
      },
      {
        id: 6,
        name: 'Deal Equity',
        altName: 'DealEquity'
      }
    ],
    costPayeeTypes: [
      {
        id: 1,
        name: 'Wholesale Dealer',
        altName: 'Supplier'
      },
      {
        id: 2,
        name: 'Bank',
        altName: 'Bank'
      },
      {
        id: 3,
        name: 'Customer',
        altName: 'Customer'
      },
      {
        id: 4,
        name: 'Accounting',
        altName: 'Accounting'
      }
    ],
    bankAccountTypes: [
      {
        id: 0,
        name: 'Check'
      },
      {
        id: 1,
        name: 'Savings'
      }
    ],
    transmissionTypes: [
      {
        id: 0,
        name: 'Manual',
        altName: 'Manual'
      },
      {
        id: 1,
        name: 'Automatic',
        altName: 'Automatic'
      },
      {
        id: 2,
        name: 'CVT',
        altName: 'CVT'
      },
      {
        id: 3,
        name: 'F1',
        altName: 'F1'
      },
      {
        id: 4,
        name: 'F1-DCT',
        altName: 'F1DCT'
      }
    ],
    productTypes: {
      Product: 0,
      Warranty: 1,
      options: [
        {
          id: 0,
          name: 'Product'
        },
        {
          id: 1,
          name: 'Warranty'
        }
      ]
    },
    creditCardTypes: {
      Amex: 0,
      MasterCard: 1,
      Visa: 2,
      DinersClub: 3,
      options:
      [
        {
          id: 0,
          name: 'Amex',
          altName: 'Amex'
        },
        {
          id: 1,
          name: 'Master Card',
          altName: 'MasterCard'
        },
        {
          id: 2,
          name: 'Visa',
          altName: 'Visa'
        },
        {
          id: 3,
          name: 'Diners Club',
          altName: 'DinersClub'
        }
      ]
    },
    customerTypes: {
      Individual: 0,
      Company: 1,
      Spouse: 2,
      Lead: 3,
      options:
      [
        {
          id: 0,
          name: 'Individual',
          altName: 'Individual'
        },
        {
          id: 1,
          name: 'Company',
          altName: 'Company'
        },
        {
          id: 2,
          name: 'Spouse',
          altName: 'Spouse'
        },
        {
          id: 3,
          name: 'Lead',
          altName: 'Lead'
        }
      ]
    },
    customerMaritalStatus: {
      Unmarried: 0,
      Married: 1,
      Separated: 2,
      options:
      [
        {
          id: 0,
          name: 'Unmarried'
        },
        {
          id: 1,
          name: 'Married'
        },
        {
          id: 2,
          name: 'Separated'
        }
      ]
    },
    customerEmploymentStatus: {
      Employed: 0,
      Unemployed: 1,
      Retired: 2,
      ActiveMilitary: 3,
      RetiredMilitary: 4,
      Other: 5,
      SelfEmployed: 6,
      Student: 7,
      options:
      [
        {
          id: 0,
          name: 'Employed'
        },
        {
          id: 1,
          name: 'Unemployed'
        },
        {
          id: 2,
          name: 'Retired'
        },
        {
          id: 3,
          name: 'ActiveMilitary'
        },
        {
          id: 4,
          name: 'RetiredMilitary'
        },
        {
          id: 5,
          name: 'Other'
        },
        {
          id: 6,
          name: 'SelfEmployed'
        },
        {
          id: 7,
          name: 'Student'
        }
      ]
    },
    fillTypes: {
      Yes: 0,
      No: 1,
      options:
      [
        {
          id: 0,
          name: 'Yes',
          ico: 'fas fa-check has-text-success'
        },
        {
          id: 1,
          name: 'No',
          ico: 'fas fa-times has-text-danger'
        }
      ]
    },
    dealFinanceTypes: {
      Cash: 0,
      Credit: 1,
      options:
      [
        {
          id: 0,
          name: 'Cash'
        },
        {
          id: 1,
          name: 'Credit'
        }
      ]
    },
    defaultExteriorColors: ['White', 'Silver', 'Black', 'Grey', 'Blue', 'Red', 'Brown', 'Green', 'Orange', 'Green', 'Yellow'],
    defaultInteriorColors: ['White', 'Silver', 'Black', 'Grey', 'Blue', 'Red', 'Brown', 'Green', 'Orange', 'Green', 'Yellow'],
    defaultColorCodes: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', '#c7158577'],
    dealAppointmentTypes: {
      NotSet: 0,
      Set: 1,
      Scheduled: 2,
      Confirmed: 3,
      Cancelled: 4,
      options:
      [
        {
          id: -1,
          name: 'All Appointments',
          altName: 'All Appointments'
        },
        {
          id: 0,
          name: 'Not Set',
          altName: 'NotSet',
          ico: 'fal fa-watch fa-lg has-text-grey-light'
        },
        {
          id: 1,
          name: 'Set',
          altName: 'Set',
          ico: 'fal fa-watch fa-lg has-text-orange'
        },
        {
          id: 2,
          name: 'Scheduled',
          altName: 'Scheduled',
          ico: 'fal fa-watch fa-lg has-text-maroon'
        },
        {
          id: 3,
          name: 'Confirmed',
          altName: 'Confirmed',
          ico: 'fal fa-watch fa-lg has-text-success'
        },
        {
          id: 4,
          name: 'Cancelled',
          altName: 'Cancelled',
          ico: 'fal fa-watch fa-lg has-text-black'
        }
      ]
    },
    defaultSendTypes: {
      sms: 0,
      email: 1,
      options:
      [
        {
          id: 0,
          name: 'Sms'
        },
        {
          id: 1,
          name: 'Email'
        }
      ]
    },
    relationshipTypes: {
      spouse: 0,
      relative: 1,
      regDomPr: 2,
      civilUnion: 3,
      other: 4,
      none: 5,
      options:
      [
        {
          id: 0,
          name: 'Spouse',
          altName: 'Spouse'
        },
        {
          id: 1,
          name: 'Relative',
          altName: 'Relative'
        },
        {
          id: 2,
          name: 'Reg. Domestic Partner',
          altName: 'RegDomPr'
        },
        {
          id: 3,
          name: 'Civil Union',
          altName: 'CivilUnion'
        },
        {
          id: 4,
          name: 'Other',
          altName: 'Other'
        },
        {
          id: 5,
          name: 'None',
          altName: 'None'
        }
      ]
    },
    standardNoteTypes: {
      all: 0,
      inventory: 1,
      deal: 2,
      options:
      [
        {
          id: 0,
          name: 'All',
          altName: 'All'
        },
        {
          id: 1,
          name: 'Inventory',
          altName: 'Inventory'
        },
        {
          id: 2,
          name: 'Deal',
          altName: 'Deal'
        }
      ]
    },
    inventoryDateTypes: [
      {
        id: 0,
        name: 'Days since purchase',
        altName: 'DSP'
      },
      {
        id: 1,
        name: 'Days on lot',
        altName: 'DOL'
      },
      {
        id: 2,
        name: 'Days since marketed',
        altName: 'DSM'
      },
      {
        id: 3,
        name: 'Days to market',
        altName: 'DTM'
      },
      {
        id: 4,
        name: 'Days to recon',
        altName: 'DTR'
      },
      {
        id: 5,
        name: 'Days from entry',
        altName: 'DFE'
      }
    ],
    defaultCostTypes: [
      {
        id: 0,
        name: 'Deal',
        altName: 'Deal'
      },
      {
        id: 1,
        name: 'Inventory',
        altName: 'Inventory'
      }
    ],
    holdbackValueCalculationOptions: [
      {
        id: 0,
        name: 'Total MSRP',
        altName: 'TotalMsrp',
        ico: ''
      },
      {
        id: 1,
        name: 'Base MSRP',
        altName: 'BaseMsrp',
        ico: ''
      }
    ],
    inventoryGroupType: [
      {
        id: 0,
        name: 'Status',
        altName: 'Status'
      },
      {
        id: 1,
        name: 'Source',
        altName: 'Source'
      },
      {
        id: 2,
        name: 'Make',
        altName: 'Make'
      },
      {
        id: 3,
        name: 'Model',
        altName: 'Model'
      },
      {
        id: 5,
        name: 'Deal Status',
        altName: 'DealStatus'
      },
      {
        id: 6,
        name: 'Age',
        altName: 'Age'
      }
      /* ,
      {
        id: 4,
        name: 'Age Buckets',
        altName: 'AgeBuckets'
      },
      {
        id: 6,
        name: 'Quality',
        altName: 'Quality'
      },
      {
        id: 7,
        name: 'Review',
        altName: 'Review'
      } */
    ],
    wholesaleReceivableGroupType: [
      {
        id: 0,
        name: 'Stage',
        altName: 'Stage'
      },
      {
        id: 1,
        name: 'Buyer',
        altName: 'Buyer'
      },
      {
        id: 2,
        name: 'Source',
        altName: 'Source'
      },
      {
        id: 3,
        name: 'Bank',
        altName: 'Bank'
      },
      {
        id: 4,
        name: 'Age',
        altName: 'Age'
      },
      {
        id: 5,
        name: 'Title Status',
        altName: 'Title Status'
      }
    ],
    paymentTrackingStatuses: {
      notYetPaid: 0,
      paid: 1,
      cancelled: 2,
      refund: 3,
      pendingInAccounting: 4,
      options:
      [
        {
          id: 0,
          name: 'Not Yet Paid',
          altName: 'NotYetPaid',
          descriptiveName: 'Not paid yet - Send to request payment'
        },
        {
          id: 1,
          name: 'Paid',
          altName: 'Paid',
          descriptiveName: 'Payment has been received'
        },
        {
          id: 2,
          name: 'Cancelled',
          altName: 'Cancelled',
          descriptiveName: 'Payment has been cancelled'
        },
        {
          id: 3,
          name: 'Refund',
          altName: 'Refund',
          descriptiveName: 'Payment has been refunded'
        },
        {
          id: 4,
          name: 'Pending In Accounting',
          altName: 'PendingInAccounting',
          descriptiveName: 'Receipt Payment that has been received'
        }
      ]
    },
    checkDeliveryTypes: [
      {
        id: 0,
        name: 'Dealer Pickup',
        altName: 'DealerPickup'
      },
      {
        id: 1,
        name: 'Regular Mail',
        altName: 'RegularMail'
      },
      {
        id: 2,
        name: 'Overnight Delivery',
        altName: 'OvernightDelivery'
      }
    ],
    supplierTypes: {
      vehicleSupplier: 0,
      serviceSupplier: 1,
      wholesaleBuyer: 2,
      options:
      [
        {
          id: 0,
          name: 'Vehicle Supplier',
          altName: 'VehicleSupplier'
        },
        {
          id: 1,
          name: 'Service Supplier',
          altName: 'ServiceSupplier'
        },
        {
          id: 2,
          name: 'Wholesale Buyer',
          altName: 'Wholesale Buyer'
        }
      ]
    },
    printRequestTypes: {
      roWorkOrder: 0,
      roInvoice: 1,
      payment: 2,
      subletVendorInvoice: 3,
      vehicleDatabaseInquiry: 4,
      vehicleMaintenanceInquiry: 5,
      applicableActivities: 6,
      serviceEntryList: 7,
      currentServiceEntropy: 8,
      diagnosticTestResult: 9,
      warranty: 10,
      miscellaneous: 11,
      mpi: 12,
      partsInvoice: 13,
      partsTicket: 14,
      media: 15,
      other: 16,
      options:
      [
        {
          id: 0,
          name: 'RO Work Order',
          altName: 'RoWorkOrder'
        },
        {
          id: 1,
          name: 'RO Invoice',
          altName: 'RoInvoice'
        },
        {
          id: 2,
          name: 'Payment',
          altName: 'Payment'
        },
        {
          id: 3,
          name: 'Sublet Vendor Invoice',
          altName: 'SubletVendorInvoice'
        },
        {
          id: 4,
          name: 'Vehicle Database Inquiry',
          altName: 'VehicleDatabaseInquiry'
        },
        {
          id: 5,
          name: 'Vehicle Maintenance Inquiry',
          altName: 'VehicleMaintenanceInquiry'
        },
        {
          id: 6,
          name: 'Applicable Activities',
          altName: 'ApplicableActivities'
        },
        {
          id: 7,
          name: 'Service Entry List',
          altName: 'ServiceEntryList'
        },
        {
          id: 8,
          name: 'Current Service Entropy',
          altName: 'CurrentServiceEntropy'
        },
        {
          id: 9,
          name: 'Diagnostic Test Result',
          altName: 'DiagnosticTestResult'
        },
        {
          id: 10,
          name: 'Warranty',
          altName: 'Warranty'
        },
        {
          id: 11,
          name: 'Miscellaneous',
          altName: 'Miscellaneous'
        },
        {
          id: 12,
          name: 'MPI',
          altName: 'MPI'
        },
        {
          id: 13,
          name: 'Parts Invoice',
          altName: 'PartsInvoice'
        },
        {
          id: 14,
          name: 'Parts Ticket',
          altName: 'PartsTicket'
        },
        {
          id: 15,
          name: 'Media',
          altName: 'Media'
        },
        {
          id: 16,
          name: 'Other',
          altName: 'Other'
        },
        {
          id: 17,
          name: 'Parts Request',
          altName: 'PartsRequest'
        }
      ]
    },
    printRequestDealers: {
      ferrariFortLauderdale: 0,
      maseratiFortLauderdale: 1,
      alfaRomeoFortLauderdale: 2,
      goldCoast: 3,
      jetSkiMiami: 4,
      options:
      [
        {
          id: 0,
          name: 'Ferrari of Fort Lauderdale',
          altName: 'FerrariFortLauderdale'
        },
        {
          id: 1,
          name: 'Maserati of Fort Lauderdale',
          altName: 'MaseratiFortLauderdale'
        },
        {
          id: 2,
          name: 'Alfa Romeo of Fort Lauderdale',
          altName: 'AlfaRomeoFortLauderdale'
        },
        {
          id: 3,
          name: 'Gold Coast',
          altName: 'GoldCoast'
        },
        {
          id: 4,
          name: 'Jet Ski of Miami',
          altName: 'JetSkiMiami'
        }
      ]
    },
    roServiceStatusCodeTypes: {
      PreEntry: -5,
      AllLabPosted: 0,
      Opened: 1,
      PreInvoiced: 2,
      Preassigned: 3,
      ReadyToPost: 4,
      VehDisabled: 5,
      Working: 6,
      Closed: 7,
      options:
      [
        {
          id: -5,
          name: 'Pre Entry',
          altName: 'PreEntry'
        },
        {
          id: 0,
          name: 'All Lab. Posted',
          altName: 'AllLabPosted'
        },
        {
          id: 1,
          name: 'Opened',
          altName: 'Opened'
        },
        {
          id: 2,
          name: 'Pre-Invoiced',
          altName: 'PreInvoiced'
        },
        {
          id: 3,
          name: 'Preassigned',
          altName: 'Preassigned'
        },
        {
          id: 4,
          name: 'Ready to Post',
          altName: 'ReadyToPost'
        },
        {
          id: 5,
          name: 'Veh. Disabled',
          altName: 'VehDisabled'
        },
        {
          id: 6,
          name: 'Working',
          altName: 'Working'
        },
        {
          id: 7,
          name: 'Closed',
          altName: 'Closed'
        }
      ]
    },
    printRequestPayerTypes: {
      customer: 0,
      supplier: 1,
      roServiceCustomer: 2,
      partsCustomer: 3,
      options:
      [
        {
          id: 0,
          name: 'Customer',
          altName: 'Customer'
        },
        {
          id: 1,
          name: 'RO Invoice',
          altName: 'RoInvoice'
        },
        {
          id: 2,
          name: 'RO Service Customer',
          altName: 'RoServiceCustomer'
        },
        {
          id: 3,
          name: 'Parts Customer',
          altName: 'PartsCustomer'
        },
        {
          id: 4,
          name: 'Employee',
          altName: 'Employee'
        }
      ]
    },
    dateRangeTypes: {
      oneMonth: 0,
      threeMonths: 1,
      sixMonths: 2,
      oneYear: 3,
      options:
      [
        {
          id: 0,
          name: '1 Month',
          altName: 'OneMonth'
        },
        {
          id: 1,
          name: '3 Months',
          altName: 'ThreeMonths'
        },
        {
          id: 2,
          name: '6 Months',
          altName: 'SixMonths'
        },
        {
          id: 3,
          name: 'One Year',
          altName: 'OneYear'
        }
      ]
    },
    leadType: {
      Active: 0,
      Future: 1,
      Sold: 2,
      Dropped: 3,
      Invalid: 4,
      Trash: 5,
      options:
      [
        {
          id: 0,
          name: 'Active',
          altName: 'Active'
        },
        {
          id: 1,
          name: 'Future',
          altName: 'Future'
        },
        {
          id: 2,
          name: 'Sold',
          altName: 'Sold'
        },
        {
          id: 3,
          name: 'Dropped',
          altName: 'Dropped'
        },
        {
          id: 4,
          name: 'Invalid',
          altName: 'Invalid'
        },
        {
          id: 5,
          name: 'Trash',
          altName: 'Trash'
        }
      ]
    },
    ppaRevisionReasonType: {
      ConditionDifferent: 0,
      MileageVariance: 1,
      AccessoriesMissing: 2,
      ReportChanged: 3,
      ChangingValueDeal: 4,
      PayoffValueWrong: 5,
      CarWorthMore: 6,
      FirstAppraisal: 7,
      options:
      [
        {
          id: 7,
          name: 'First Appraisal',
          altName: 'FirstAppraisal'
        },
        {
          id: 0,
          name: 'Condition different than when appraised',
          altName: 'ConditionDifferent'
        },
        {
          id: 1,
          name: 'Mileage variance',
          altName: 'MileageVariance'
        },
        {
          id: 2,
          name: 'Accessories missing',
          altName: 'AccessoriesMissing'
        },
        {
          id: 3,
          name: 'Carfax/AutoCheck report changed',
          altName: 'ReportChanged'
        },
        {
          id: 4,
          name: 'Changing value to make deal',
          altName: 'ChangingValueDeal'
        },
        {
          id: 5,
          name: 'Payoff value was wrong',
          altName: 'PayoffValueWrong'
        },
        {
          id: 6,
          name: 'Car is worth more than orignally estimated',
          altName: 'CarWorthMore'
        }
      ]
    },
    paymentStatus: {
      pending: 0,
      waitingOnTitle: 1,
      onHold: 2,
      payImmediately: 3,
      paid: 4,
      poScheduled: 5,
      needsReview: 6,
      options:
      [
        {
          id: 5,
          name: 'Po Scheduled',
          altName: 'PoScheduled'
        },
        {
          id: 0,
          name: 'Pending',
          altName: 'Pending'
        },
        {
          id: 1,
          name: 'Waiting On Title',
          altName: 'WaitingOnTitle'
        },
        {
          id: 2,
          name: 'On Hold',
          altName: 'OnHold'
        },
        {
          id: 3,
          name: 'Pay Immediately',
          altName: 'PayImmediately'
        },
        {
          id: 4,
          name: 'Paid',
          altName: 'Paid'
        },
        {
          id: 6,
          name: 'Needs Review',
          altName: 'NeedsReview'
        }
      ]
    },
    customerIdentityVerificationStatus: {
      pending: 0,
      sent: 1,
      complete: 2,
      options:
      [
        {
          id: 0,
          name: 'Pending'
        },
        {
          id: 1,
          name: 'Sent'
        },
        {
          id: 2,
          name: 'Completed'
        }
      ]
    },
    squareErrorCodes: [
      {
        code: 'ADDRESS_VERIFICATION_FAILURE',
        detail: 'The card issuer declined the request because the postal code is invalid.'
      },
      {
        code: 'CARDHOLDER_INSUFFICIENT_PERMISSIONS',
        detail: 'The card issuer has declined the transaction due to restrictions on where the card can be used. For example, a gift card is limited to a single merchant.'
      },
      {
        code: 'CARD_EXPIRED',
        detail: 'The card issuer declined the request because the card is expired.'
      },
      {
        code: 'CARD_NOT_SUPPORTED',
        detail: 'The card is not supported either in the geographic region or by the merchant category code (MCC).'
      },
      {
        code: 'CARD_TOKEN_EXPIRED',
        detail: 'The provided card token (nonce) has expired.'
      },
      {
        code: 'CARD_TOKEN_USED',
        detail: 'The provided card token (nonce) was already used to process payment.'
      },
      {
        code: 'CVV_FAILURE',
        detail: 'The card issuer declined the request because the CVV value is invalid.'
      },
      {
        code: 'EXPIRATION_FAILURE',
        detail: 'The card expiration date is either invalid or indicates that the card is expired.'
      },
      {
        code: 'GENERIC_DECLINE',
        detail: 'Square received a decline without any additional information. If the payment information seems correct, the buyer can contact their issuer to ask for more information.'
      },
      {
        code: 'GIFT_CARD_AVAILABLE_AMOUNT',
        detail: 'When a Gift Card is a payment source, you can allow taking a partial payment by adding the accept_partial_authorization parameter in the request. However, taking such a partial payment does not work if your request also includes tip_money, app_fee_money, or both. Square declines such payments and returns the GIFT_CARD_AVAILABLE_AMOUNT error.'
      },
      {
        code: 'INSUFFICIENT_FUNDS',
        detail: 'The funding source has insufficient funds to cover the payment.'
      },
      {
        code: 'INSUFFICIENT_PERMISSIONS',
        detail: 'The Square account does not have the permissions to accept this payment. For example, Square may limit which merchants are allowed to receive gift card payments.'
      },
      {
        code: 'INVALID_ACCOUNT',
        detail: 'The issuer was not able to locate the account on record.'
      },
      {
        code: 'INVALID_CARD',
        detail: 'The credit card cannot be validated based on the provided details.'
      },
      {
        code: 'INVALID_CARD_DATA',
        detail: 'Generic error - the provided card data is invalid.'
      },
      {
        code: 'INVALID_EMAIL_ADDRESS',
        detail: 'The provided email address is invalid.'
      },
      {
        code: 'INVALID_EXPIRATION',
        detail: 'The expiration date for the payment card is invalid. For example, it indicates a date in the past.'
      },
      {
        code: 'INVALID_FEES',
        detail: 'The app_fee_money on a payment is too high.'
      },
      {
        code: 'INVALID_LOCATION',
        detail: 'The Square account cannot take payments in the specified region. A Square account can take payments only from the region where the account was created.'
      },
      {
        code: 'INVALID_PIN',
        detail: 'The card issuer declined the request because the PIN is invalid.'
      },
      {
        code: 'INVALID_POSTAL_CODE',
        detail: 'The postal code is incorrectly formatted.'
      },
      {
        code: 'MANUALLY_ENTERED_PAYMENT_NOT_SUPPORTED',
        detail: 'The card must be swiped, tapped, or dipped. Payments attempted by manually entering the card number are declined.'
      },
      {
        code: 'PAN_FAILURE',
        detail: 'The specified card number is invalid. For example, it is of incorrect length or is incorrectly formatted.'
      },
      {
        code: 'PAYMENT_LIMIT_EXCEEDED',
        detail: 'Square declined the request because the payment amount exceeded the processing limit for this merchant.'
      },
      {
        code: 'TRANSACTION_LIMIT',
        detail: 'The card issuer has determined the payment amount is either too high or too low. Most cases the card reached the credit limit. However, sometimes the issuer bank can indicate the error for debit or prepaid cards (for example, card has insufficient funds).'
      },
      {
        code: 'VOICE_FAILURE',
        detail: 'The card issuer declined the request because the issuer requires voice authorization from the cardholder.'
      },
      {
        code: 'ALLOWABLE_PIN_TRIES_EXCEEDED',
        detail: 'The card has exhausted its available pin entry retries set by the card issuer. Resolving the error typically requires the card holder to contact the card issuer.'
      },
      {
        code: 'BAD_EXPIRATION',
        detail: 'The card expiration date is either missing or incorrectly formatted.'
      },
      {
        code: 'CARD_DECLINED_VERIFICATION_REQUIRED',
        detail: 'The payment card was declined with a request for additional verification.'
      },
      {
        code: 'CHIP_INSERTION_REQUIRED',
        detail: 'The card issuer requires that the card be read using a chip reader.'
      },
      {
        code: 'CARD_PROCESSING_NOT_ENABLED',
        detail: 'The location provided in the API call is not enabled for credit card processing.'
      },
      {
        code: 'TEMPORARY_ERROR',
        detail: 'A temporary internal error occurred. You can safely retry'
      }
    ],
    ppaIntentionType: {
      purchase: 0,
      trade: 1,
      consigned: 3,
      options:
      [
        {
          id: 0,
          name: 'Purchase'
        },
        {
          id: 1,
          name: 'Trade'
        },
        {
          id: 3,
          name: 'Consigned'
        }
      ]
    },
    roServiceWorkingStatus: {
      Open: 0,
      Scheduled: 1,
      Delivered: 2,
      options:
      [
        {
          id: 0,
          name: 'Open'
        },
        {
          id: 1,
          name: 'Scheduled'
        },
        {
          id: 2,
          name: 'Delivered'
        }
      ]
    },
    callTrackingReceiverType: {
      bank: 0,
      supplier: 1,
      customer: 2,
      roServiceCustomer: 3,
      department: 4,
      user: 5,
      options:
      [
        {
          id: 0,
          name: 'Bank',
          altName: 'Bank'
        },
        {
          id: 1,
          name: 'Supplier',
          altName: 'Supplier'
        },
        {
          id: 2,
          name: 'Customer',
          altName: 'Customer'
        },
        {
          id: 3,
          name: 'RO Service Customer',
          altName: 'RoServiceCustomer'
        },
        {
          id: 4,
          name: 'Department',
          altName: 'Department'
        },
        {
          id: 5,
          name: 'User',
          altName: 'User'
        }
      ]
    },
    paymentTrackingTypes: {
      wire: 0,
      square: 1,
      cash: 2,
      check: 3,
      other: 4,
      squareTerminal: 5,
      options:
      [
        {
          id: 0,
          name: 'Wire',
          altName: 'Wire'
        },
        {
          id: 1,
          name: 'Square',
          altName: 'Square'
        },
        {
          id: 2,
          name: 'Cash',
          altName: 'Cash'
        },
        {
          id: 3,
          name: 'Check',
          altName: 'Check'
        },
        {
          id: 4,
          name: 'Other',
          altName: 'Other'
        },
        {
          id: 5,
          name: 'Square Terminal',
          altName: 'SquareTerminal'
        }
      ]
    },
    dealContractTypes: {
      retail: 0,
      lease: 1,
      options:
      [
        {
          id: 0,
          name: 'Retail',
          altName: 'Retail'
        },
        {
          id: 1,
          name: 'Lease',
          altName: 'Lease'
        }
      ]
    },
    dealLeaseContractTermTypes: {
      twelve: 0,
      twentyFour: 1,
      thirtySix: 2,
      fourtyEight: 3,
      sixty: 4,
      thirtyNine: 5,
      fourtyTwo: 6,
      twentySeven: 7,
      options:
      [
        {
          id: 0,
          name: '12',
          altName: 'Twelve'
        },
        {
          id: 1,
          name: '24',
          altName: 'TwentyFour'
        },
        {
          id: 2,
          name: '36',
          altName: 'ThirtySix'
        },
        {
          id: 3,
          name: '48',
          altName: 'FourtyEight'
        },
        {
          id: 4,
          name: '60',
          altName: 'Sixty'
        },
        {
          id: 5,
          name: '39',
          altName: 'ThirtyNine'
        },
        {
          id: 6,
          name: '42',
          altName: 'FourtyTwo'
        },
        {
          id: 7,
          name: '27',
          altName: 'TwentySeven'
        }
      ]
    },
    paymentTrackingAttachmentTypes: {
      CdkReceipt: 0,
      General: 1,
      options:
      [
        {
          id: 0,
          name: 'CDK Receipt',
          altName: 'CdkReceipt'
        },
        {
          id: 1,
          name: 'General',
          altName: 'General'
        }
      ]
    },
    defaultCostDispositionIntentions: {
      All: 0,
      Retail: 1,
      Wholesale: 2,
      options:
      [
        {
          id: 0,
          name: 'All',
          altName: 'All'
        },
        {
          id: 1,
          name: 'Retail',
          altName: 'Retail'
        },
        {
          id: 2,
          name: 'Wholesale',
          altName: 'Wholesale'
        }
      ]
    },
    defaultCostProcessingActions: {
      AccountingEntry: 0,
      RequestCheck: 1,
      PoSchedule: 2,
      options:
      [
        {
          id: 0,
          name: 'Accounting Entry',
          altName: 'AccountingEntry'
        },
        {
          id: 1,
          name: 'Request a Check',
          altName: 'RequestCheck'
        },
        {
          id: 2,
          name: 'Put On PO Schedule',
          altName: 'PoSchedule'
        }
      ]
    },
    wholesaleReceivablePaymentStatus: [
      {
        id: 0,
        name: 'Pending CDK',
        altName: 'PendingCdk'
      },
      {
        id: 1,
        name: 'Booked In CDK',
        altName: 'BookedInCdk'
      },
      {
        id: 2,
        name: 'Payment Pending',
        altName: 'PaymentPending'
      },
      {
        id: 3,
        name: 'Awaiting Payoff',
        altName: 'AwaitingPayoff'
      },
      {
        id: 4,
        name: 'Awaiting Title',
        altName: 'AwaitingTitle'
      },
      {
        id: 5,
        name: 'Paid Without Payoff',
        altName: 'PaidWithoutPayOff'
      },
      {
        id: 6,
        name: 'Paid Without Title',
        altName: 'PaidWithoutTitle'
      },
      {
        id: 7,
        name: 'Paid',
        altName: 'Paid'
      }
    ],
    wholesaleReceivableTitleStatus: {
      PendingPayoffCheckToBeSent: 0,
      PayoffSent: 1,
      CustomerBringingTitle: 2,
      NeedToApplyForDuplicate: 4,
      AwaitingLienRelease: 5,
      AwaitingTitleAppliedForDuplicate: 6,
      NeedPoa: 7,
      AwaitingSmogCertification: 8,
      AwaitingVinVerification: 9,
      NeedOosInspection: 10,
      TitleEnRoute: 11,
      TitleSentToWholesalerOrAuction: 12,
      TitleReceived: 13,
      TitleMissing: 14,
      TitleIssue: 15,
      options: [
        {
          id: 0,
          name: 'Pending payoff check to be sent',
          altName: 'PendingPayoffCheckToBeSent'
        },
        {
          id: 1,
          name: 'Payoff Sent - Awaiting title arrival',
          altName: 'PayoffSent'
        },
        {
          id: 2,
          name: 'Customer bringing title',
          altName: 'CustomerBringingTitle'
        },
        {
          id: 4,
          name: 'Need to apply for a duplicate',
          altName: 'NeedToApplyForDuplicate'
        },
        {
          id: 5,
          name: 'Awaiting lien release',
          altName: 'AwaitingLienRelease'
        },
        {
          id: 6,
          name: 'Applied for Duplicate - Awaiting title Arrival',
          altName: 'AwaitingTitleAppliedForDuplicate'
        },
        {
          id: 7,
          name: 'Need POA',
          altName: 'NeedPoa'
        },
        {
          id: 8,
          name: 'Need Smog Certification',
          altName: 'NeedSmogCertification'
        },
        {
          id: 9,
          name: 'Need Vin Verification',
          altName: 'NeedVinVerification'
        },
        {
          id: 10,
          name: 'Need OOS inspection',
          altName: 'NeedOosInspection'
        },
        {
          id: 11,
          name: 'Title en route',
          altName: 'TitleEnRoute'
        },
        {
          id: 12,
          name: 'Title Sent to (wholesaler or auction)',
          altName: 'TitleSentToWholesalerOrAuction'
        },
        {
          id: 13,
          name: 'Title received',
          altName: 'TitleReceived'
        },
        {
          id: 14,
          name: 'Title missing',
          altName: 'TitleMissing'
        },
        {
          id: 15,
          name: 'Title issue - other',
          altName: 'TitleIssue'
        }
      ]
    },
    wholesaleReceivableStageStatus: {
      PendingPayOffToBeSent: 0,
      AwaitingTitlePayOffSent: 1,
      NeedToApplyForDuplicate: 2,
      AwaitingTitleAppliedForDuplicate: 3,
      CustomerBringing: 4,
      TitleSentToPurchaser: 5,
      TitleReceivedNeedToNotifyPurchaser: 6,
      Paid: 7,
      TitleIssue: 8,
      AwaitingPaymentReadyAndNotified: 9,
      PartiallyPaid: 10,
      options: [
        {
          id: 8,
          name: 'Title issue',
          altName: 'TitleIssue',
          sortOrder: 0,
          color: 'yellow - immediatly'
        },
        {
          id: 5,
          name: 'Title sent to Purchaser for immediate payment',
          altName: 'TitleSentToPurchaser',
          sortOrder: 1,
          color: 'red - after period of 2 days'
        },
        {
          id: 6,
          name: 'Title received - need to notify purchaser',
          altName: 'TitleReceivedNeedToNotifyPurchaser',
          sortOrder: 2,
          color: ''
        },
        {
          id: 9,
          name: 'Awaiting Payment - Ready and notified',
          altName: 'AwaitingPaymentReadyAndNotified',
          sortOrder: 3,
          color: ''
        },
        {
          id: 4,
          name: 'Customer bringing',
          altName: 'CustomerBringing',
          sortOrder: 4,
          color: ''
        },
        {
          id: 2,
          name: 'Need to apply for a duplicate',
          altName: 'NeedToApplyForDuplicate',
          sortOrder: 5,
          color: ''
        },
        {
          id: 0,
          name: 'Pending payoff to be sent',
          altName: 'PendingPayOffToBeSent',
          sortOrder: 6,
          color: ''
        },
        {
          id: 1,
          name: 'Awaiting title - payoff sent',
          altName: 'AwaitingTitlePayOffSent',
          sortOrder: 7,
          color: ''
        },
        {
          id: 3,
          name: 'Awaiting title - applied for a duplicate',
          altName: 'AwaitingTitleAppliedForDuplicate',
          sortOrder: 8,
          color: ''
        },
        {
          id: 7,
          name: 'Paid',
          altName: 'Paid',
          sortOrder: 9,
          color: ''
        },
        {
          id: 10,
          name: 'Partially paid',
          altName: 'PartiallyPaid',
          sortOrder: 10,
          color: ''
        }
      ]
    },
    dealCancellationReasons: {
      Duplicate: 0,
      UnableToFinance: 1,
      CustomerCancelled: 2,
      DealLoggedInError: 3,
      options: [
        {
          id: 0,
          name: 'Duplicate',
          altName: 'Duplicate'
        },
        {
          id: 1,
          name: 'Unable To Finance',
          altName: 'UnableToFinance'
        },
        {
          id: 2,
          name: 'Customer Cancelled',
          altName: 'CustomerCancelled'
        },
        {
          id: 3,
          name: 'Deal Logged In Error',
          altName: 'DealLoggedInError'
        }
      ]
    },
    dealInboundStatusTypes: {
      InboundFromPortDealer: 0,
      ScheduledToLeavePort: 1,
      ArrivedInPort: 2,
      InboundOnTheWater: 3,
      InProduction: 4,
      options: [
        {
          id: 0,
          name: 'Inbound from port/dealer',
          altName: 'InboundFromPortDealer'
        },
        {
          id: 1,
          name: 'Scheduled to leave port',
          altName: 'ScheduledToLeavePort'
        },
        {
          id: 2,
          name: 'Arrived in port',
          altName: 'ArrivedInPort'
        },
        {
          id: 3,
          name: 'Inbound on the water',
          altName: 'InboundOnTheWater'
        },
        {
          id: 4,
          name: 'In production',
          altName: 'InProduction'
        }
      ]
    },
    dealDocumentTypes: {
      SaleDocument: 0,
      CreditCard: 1,
      BuyersAgreement: 2,
      Invoice: 3,
      Other: 4,
      Wire: 5,
      General: 6,
      FundingLetter: 7,
      Square: 8,
      Cash: 9,
      Check: 10,
      DealJacket: 11,
      CustomerVerification: 12,
      CdkRecap: 13,
      DealPresentation: 14,
      BankCreditApplicationApproval: 15,
      VehicleCost: 16,
      CdkWashout: 17,
      options: [
        {
          id: 0,
          name: 'Sale Document',
          altName: 'SaleDocument'
        },
        {
          id: 1,
          name: 'Credit Card',
          altName: 'CreditCard'
        },
        {
          id: 2,
          name: 'Buyers Agreement',
          altName: 'BuyersAgreement'
        },
        {
          id: 3,
          name: 'Invoice',
          altName: 'Invoice'
        },
        {
          id: 4,
          name: 'Other',
          altName: 'Other'
        },
        {
          id: 5,
          name: 'Wire',
          altName: 'Wire'
        },
        {
          id: 6,
          name: 'General',
          altName: 'General'
        },
        {
          id: 7,
          name: 'Funding Letter',
          altName: 'FundingLetter'
        },
        {
          id: 8,
          name: 'Square',
          altName: 'Square'
        },
        {
          id: 9,
          name: 'Cash',
          altName: 'Cash'
        },
        {
          id: 10,
          name: 'Check',
          altName: 'Check'
        },
        {
          id: 11,
          name: 'Deal Jacket',
          altName: 'DealJacket'
        },
        {
          id: 12,
          name: 'Customer Verification',
          altName: 'CustomerVerification'
        },
        {
          id: 13,
          name: 'CDK Recap',
          altName: 'CdkRecap'
        },
        {
          id: 14,
          name: 'Deal Presentation',
          altName: 'DealPresentation'
        },
        {
          id: 15,
          name: 'Bank Credit Application Approval',
          altName: 'BankCreditApplicationApproval'
        },
        {
          id: 16,
          name: 'Vehicle Cost',
          altName: 'VehicleCost'
        },
        {
          id: 17,
          name: 'CDK Washout',
          altName: 'CdkWashout'
        }
      ]
    },
    dealCreditApplicationStatus: {
      Approved: 0,
      NeedPOI: 1,
      NeedPOR: 2,
      NeedSSCard: 3,
      NeedInterview: 4,
      SingleVehicleOnly: 5,
      options: [
        {
          id: 0,
          name: 'Approved',
          altName: 'Approved',
          sortOrder: 0,
          color: 'green - check mark'
        },
        {
          id: 1,
          name: 'Need Proof of Income',
          altName: 'NeedPOI',
          sortOrder: 1,
          color: 'green - exclamation'
        },
        {
          id: 2,
          name: 'Need Proof of Residence',
          altName: 'NeedPOR',
          sortOrder: 2,
          color: 'green - exclamation'
        },
        {
          id: 3,
          name: 'Need Social Security Card',
          altName: 'NeedSSCard',
          sortOrder: 3,
          color: 'green - exclamation'
        },
        {
          id: 4,
          name: 'Need Interview',
          altName: 'NeedInterview',
          sortOrder: 4,
          color: 'green - exclamation'
        },
        {
          id: 5,
          name: 'Single Vehicle Only',
          altName: 'SingleVehicleOnly',
          sortOrder: 5,
          color: 'green - exclamation'
        },
        {
          id: 6,
          name: 'Awaiting Deal Structure from Sales',
          altName: 'AwaitingDealStructure',
          sortOrder: 6,
          color: 'green - exclamation'
        },
        {
          id: 7,
          name: 'Conditional Approval',
          altName: 'ConditionalApproval',
          sortOrder: 7,
          color: 'green - exclamation'
        }
      ]
    },
    paymentScheduleGroupType: [
      {
        id: 0,
        name: 'Status',
        altName: 'Status'
      },
      {
        id: 1,
        name: 'Stock No.',
        altName: 'Stock No.'
      },
      {
        id: 2,
        name: 'Provider',
        altName: 'Provider'
      },
      {
        id: 3,
        name: 'Requested By',
        altName: 'Requested By'
      },
      {
        id: 4,
        name: 'Customer / Payee',
        altName: 'Customer / Payee'
      }
    ],
    schedulePaymentSourceType: {
      DealSupplementalCost: 0,
      CheckRequest: 1,
      InventorySupplementalCost: 2,
      DepositRefund: 3,
      PayeeMismatchRefund: 4,
      WholesaleReceivable: 5,
      TradeInPayoff: 6,
      PurchasePayoff: 7,
      options: [
        {
          id: 0,
          name: 'Deal Supplemental Cost',
          altName: 'DealSupplementalCost'
        },
        {
          id: 1,
          name: 'Check Request',
          altName: 'CheckRequest'
        },
        {
          id: 2,
          name: 'Inventory Supplemental Cost',
          altName: 'InventorySupplementalCost'
        },
        {
          id: 3,
          name: 'Deposit Refund',
          altName: 'DepositRefund'
        },
        {
          id: 4,
          name: 'Payee Mismatch Refund',
          altName: 'PayeeMismatchRefund'
        },
        {
          id: 5,
          name: 'Wholesale Receivable',
          altName: 'WholesaleReceivable'
        },
        {
          id: 6,
          name: 'Trade In Payoff',
          altName: 'TradeInPayoff'
        },
        {
          id: 7,
          name: 'Purchase Payoff',
          altName: 'PurchasePayoff'
        }
      ]
    },
    roServiceListGroupType: [
      {
        id: 0,
        name: 'Status',
        altName: 'Status'
      },
      {
        id: 1,
        name: 'Age',
        altName: 'Age'
      },
      {
        id: 2,
        name: 'Advisor',
        altName: 'Advisor'
      }
    ],
    valetTypes: [
      {
        id: 0,
        name: 'In Person Collection',
        altName: 'InPersonCollection'
      },
      {
        id: 1,
        name: 'Delivery',
        altName: 'Delivery'
      }
    ],
    valetWashingNotificationType: {
      VehicleWashing: 0,
      Valet: 1,
      VehicleWashingThenValet: 2,
      options:
      [
        {
          id: 0,
          name: 'Vehicle Washing',
          altName: 'VehicleWashing'
        },
        {
          id: 1,
          name: 'Valet',
          altName: 'Valet'
        },
        {
          id: 2,
          name: 'Vehicle Washing Then Valet',
          altName: 'VehicleWashingThenValet'
        }
      ]
    },
    valetWashingStatus: {
      Pending: 0,
      InProgress: 1,
      Completed: 2,
      options:
      [
        {
          id: 0,
          name: 'Pending',
          altName: 'Pending'
        },
        {
          id: 1,
          name: 'In Progress',
          altName: 'InProgress'
        },
        {
          id: 2,
          name: 'Completed',
          altName: 'Completed'
        }
      ]
    },
    roServiceWorkItemStatusType: {
      New: 0,
      Approved: 1,
      InProgress: 2,
      OnHold: 3,
      Declined: 4,
      Complete: 5,
      Cancelled: 6,
      PendingDispatch: 7,
      AwaitingApproval: 8,
      options:
      [
        {
          id: 0,
          name: 'New',
          altName: 'New'
        },
        {
          id: 1,
          name: 'Approved',
          altName: 'Approved'
        },
        {
          id: 2,
          name: 'In Progress',
          altName: 'InProgress'
        },
        {
          id: 3,
          name: 'On Hold',
          altName: 'OnHold'
        },
        {
          id: 4,
          name: 'Declined',
          altName: 'Declined'
        },
        {
          id: 5,
          name: 'Complete',
          altName: 'Complete'
        },
        {
          id: 6,
          name: 'Cancelled',
          altName: 'Cancelled'
        },
        {
          id: 7,
          name: 'Pending Dispatch',
          altName: 'PendingDispatch'
        },
        {
          id: 8,
          name: 'Awaiting Approval',
          altName: 'AwaitingApproval'
        }
      ]
    },
    roServiceLaborFormulaeTypes: {
      FlatSellRate: 0,
      FlatHours: 1,
      TotalHours: 3,
      options:
      [
        {
          id: 0,
          name: 'Flat Sell Rate',
          altName: 'FlatSellRate'
        },
        {
          id: 1,
          name: 'Flat Hours',
          altName: 'FlatHours'
        },
        {
          id: 3,
          name: 'Total Hours',
          altName: 'TotalHours'
        }
      ]
    },
    vehiclePartOrderStatusType: {
      New: 0,
      Requested: 1,
      Approved: 2,
      Declined: 3,
      Cancelled: 4,
      options:
      [
        {
          id: 0,
          name: 'New',
          altName: 'New'
        },
        {
          id: 1,
          name: 'Requested',
          altName: 'Requested'
        },
        {
          id: 2,
          name: 'Approved',
          altName: 'Approved'
        },
        {
          id: 3,
          name: 'Declined',
          altName: 'Declined'
        },
        {
          id: 4,
          name: 'Cancelled',
          altName: 'Cancelled'
        }
      ]
    },
    dealSaleVehicleType: {
      New: 0,
      Used: 1,
      options:
      [
        {
          id: 0,
          name: 'New',
          altName: 'New'
        },
        {
          id: 1,
          name: 'Used',
          altName: 'Used'
        }
      ]
    },
    inventoryCommissionRequestStatusTypes: [
      {
        id: 0,
        name: 'Requested',
        altName: 'Requested'
      },
      {
        id: 1,
        name: 'Approved',
        altName: 'Approved'
      },
      {
        id: 2,
        name: 'Rejected',
        altName: 'Rejected'
      },
      {
        id: 3,
        name: 'Paid',
        altName: 'Paid'
      },
      {
        id: 4,
        name: 'Archived',
        altName: 'Archived'
      }
    ],
    dealCommissionRequestStatusTypes: [
      {
        id: 0,
        name: 'Requested',
        altName: 'Requested'
      },
      {
        id: 1,
        name: 'Approved',
        altName: 'Approved'
      },
      {
        id: 2,
        name: 'Rejected',
        altName: 'Rejected'
      },
      {
        id: 3,
        name: 'Paid',
        altName: 'Paid'
      },
      {
        id: 4,
        name: 'Archived',
        altName: 'Archived'
      }
    ],
    roServiceLabourTypesPayTypes: [
      {
        id: 0,
        name: 'C',
        altName: 'C'
      },
      {
        id: 1,
        name: 'I',
        altName: 'I'
      },
      {
        id: 2,
        name: 'W',
        altName: 'W'
      }
    ],
    yesNoOptions: [
      {
        id: 0,
        name: 'Yes'
      },
      {
        id: 1,
        name: 'No'
      }
    ],
    roServiceOpcodeLaborFormulaeTypes: {
      FlatSellRate: 0,
      FlatHours: 1,
      TotalHours: 3,
      options:
      [
        {
          id: 0,
          name: 'Flat Sell Rate',
          altName: 'FlatSellRate'
        },
        {
          id: 1,
          name: 'Flat Hours',
          altName: 'FlatHours'
        },
        {
          id: 3,
          name: 'Total Hours',
          altName: 'TotalHours'
        }
      ]
    }
  }
}

const getters = {
  defaultListPageSize: state => {
    return state.defaultPageSize
  },
  definedTypes: state => {
    return state.types
  }
}

const mutations = {
  [mutationTypes.SET_DEFAULT_PAGE_SIZE] (state, pageSize) {
    state.defaultPageSize = pageSize
  }
}

const actions = {
  setDefaultPageSize ({commit, state}, pageSize) {
    commit(mutationTypes.SET_DEFAULT_PAGE_SIZE, pageSize)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
