import axios from 'axios'
import baseService from './baseService'
import partsData from './data/parts'
import PartInvoice from '@/models/PartInvoice'

const partsService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('parts-load-all')
      if (baseService.isTestMode) {
        resolve(partsData)
      } else {
        axios
          .post('/parts/list', searchCriteria, {...baseService.defaultConfig, requestId: 'parts-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = PartInvoice.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('parts all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (partsId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(partsData.all.find((x) => x.id === partsId))
      } else {
        axios
          .post('/parts/get?id=' + partsId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (partsModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        partsModels.forEach(element => {
          partsData.all.remove((x) => x.id === element.id)
        })
        resolve(partsData.all)
      } else {
        var ids = []
        partsModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/parts/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allCustomers (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(partsData)
      } else {
        axios
          .post('/parts/customerlist', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/parts/notes', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/parts/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/parts/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/parts/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/parts/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByPartsId (partsId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .get('/parts/partsnotes?id=' + partsId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  merge (mergeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(partsData)
      } else {
        axios
          .post('/parts/merge', mergeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  syncAttachments (attachmentsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(partsData)
      } else {
        axios
          .post('/parts/syncattachments', attachmentsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePartsInfo (partsInfoModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/parts/updatepartsinfo', partsInfoModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDistinctVehicleMakeNamesByDealer (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve([''])
      } else {
        axios
          .post('/parts/getdistinctvehiclemakenamesbydealer', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  closeParts (partsModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        partsModels.forEach(element => {
          partsData.all.remove((x) => x.id === element.id)
        })
        resolve(partsData.all)
      } else {
        var ids = []
        partsModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/parts/close', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  archive (partsId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/parts/archive', { id: partsId }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createPaidDeposit (partsDepositModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/parts/createpartspaiddeposit', partsDepositModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchCustomerById (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/parts/getCustomerById?id=' + customerId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCustomer (partsCustomerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/parts/updatePartsCustomer', partsCustomerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default partsService
