<template>
<div class="box customer-info-container">
  <customer-section-header title="Customer Info" :collapsed="isCollapsed" v-on:collapsed-status-changed="onCollapsedStatusChanged" :syncingFailed="syncingFailed" :isSyncing="isSyncing" v-on:sync-clicked="syncCustomer()"></customer-section-header>
  <div class="columns is-mobile is-multiline is-centered" v-if="customer" style="margin-top: 5px;margin-bottom: 20px !important;padding: 0">
    <div class="column is-12">
      <div>
        <label class="is-inline-block is-size-7 has-text-success refresh-status-label" v-if="isSyncing"> Syncing customer...</label>
      </div>
    </div>
    <template>
      <div class="column is-4">
        <div class="field">
          <label class="label">
            {{customerNameLabel}}
            <dealer-peak-control v-if="customer" :key="currentCustomerId" :customer="customer" :customerId="currentCustomerId"></dealer-peak-control>
          </label>
          <div class="field is-size-7">
            {{customer.fullName}}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Contact Number</label>
          <div class="field is-size-7">
            <span class="has-text-link clickable" v-tooltip="customer.mobile + ' - Click to copy contact mobile to your clipboard'" @click.stop.prevent="onCopyInfo(customer.mobile)">{{ customer.mobile }}</span>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Contact Email</label>
          <div class="field is-size-7">
            <span class="has-text-link clickable" v-tooltip="customer.email + ' - Click to copy contact email to your clipboard'" @click.stop.prevent="onCopyInfo(customer.email)">{{ customer.email }}</span>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="field">
          <label class="label">Dealer Peak Lead Status</label>
          <div class="field is-size-7">
            {{activeLeadStatus}}
          </div>
        </div>
      </div>
    </template>
  </div>
</div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import utilitiesMixin from '@/mixins/generic/utilities'
import dealerPeakService from '@/services/dealerPeakService'
import CustomerSectionHeader from '@/components/generic/CustomerSectionHeader'

import DealerPeakControl from '@/components/generic/DealerPeakControl'

const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'CustomerInfoControl',
  mixins: [utilitiesMixin],
  components: {
    'customer-section-header': CustomerSectionHeader,
    'dealer-peak-control': DealerPeakControl
  },
  props: {
    customerId: {
      type: Number,
      default: 0
    },
    customerNameLabel: {
      type: String,
      default: 'Customer Name'
    },
    customerMobile: {
      type: String,
      default: ''
    },
    customerEmail: {
      type: String,
      default: ''
    },
    displayMode: {
      type: String,
      default: 'Standard'
    }
  },
  data () {
    return {
      serverErrors: [],
      serverErrorMessage: '',
      isSyncing: false,
      syncingFailed: false,
      currentCustomerId: this.customerId,
      customer: null,
      isCollapsed: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    activeLeadStatus: function () {
      let status = '-'
      if (this.customer) {
        let leadIndex = this.customer.customerLeads.findIndex((x) => x.status === 'Active')
        if (leadIndex !== -1) {
          status = this.customer.customerLeads[leadIndex].leadStatus
        }
      }
      return status
    }
  },
  methods: {
    syncCustomer: async function () {
      this.syncingFailed = false
      this.isSyncing = true
      let model = {
        number: this.unmask(this.customerMobile),
        email: this.customerEmail,
        customerType: 'Lead',
        customerId: this.currentCustomerId,
        source: 'LIVE',
        clearExistingLeads: true,
        openLeadsOnly: true
      }
      try {
        let response = await dealerPeakService.syncCustomer(model)
        if (response.data !== null) {
          this.customer = response.data.customer
        }
        this.isSyncing = false
      } catch (err) {
        this.syncingFailed = true
        this.isSyncing = false
      }
    },
    onCollapsedStatusChanged: function (newValue) {
      this.isCollapsed = newValue
    },
    onCopyInfo: function (text) {
      let that = this
      this.$copyText(text).then(function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: text + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
      }, function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: 'Could not copy info to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
      })
    }
  },
  watch: {
    customerId: function (newValue, oldValue) {
      this.currentCustomerId = newValue
    }
  },
  mounted: function () {
    // If there are no appraisals for the vehicle then run one
    if (this.currentCustomerId) {
      this.syncCustomer()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tolerance-error-icon {
    margin-left:5px;
  }

  .refresh-status-label {
    margin-left:1em;
  }
</style>
