import { render, staticRenderFns } from "./GlobalQuickView.vue?vue&type=template&id=20c442d0&scoped=true"
import script from "./GlobalQuickView.vue?vue&type=script&lang=js"
export * from "./GlobalQuickView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c442d0",
  null
  
)

export default component.exports