import axios from 'axios'
import baseService from './baseService'
import vehicleMakeData from './data/vehicleMake'
import vehicleMakeVersionData from './data/vehicleMakeVersion'
import VehicleMake from '@/models/VehicleMake'

const appService = {
  allVehicleMakes (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(vehicleMakeData.all)
      } else {
        axios
          .get('/vehiclemake/list?', baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = VehicleMake.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response)
          })
      }
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(vehicleMakeData)
      } else {
        axios
          .post('/vehiclemake/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = VehicleMake.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (vehicleMakeId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(vehicleMakeData.all.find((x) => x.id === vehicleMakeId))
      } else {
        axios
          .post('/vehiclemake/get?id=' + vehicleMakeId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (vehicleMakeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var vehicleMake = {
          id: 999,
          name: 'Test',
          status: 'Active'
        }
        vehicleMakeData.all.push(vehicleMake)
        resolve(vehicleMake)
      } else {
        axios
          .post('/vehiclemake/save', vehicleMakeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (vehicleMakeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var vehicleMake = vehicleMakeData.all.find((x) => x.id === vehicleMakeModel.id)
        if (vehicleMake) {
          vehicleMake.name = vehicleMakeModel.name
        }
        resolve(vehicleMake)
      } else {
        axios
          .post('/vehiclemake/update', vehicleMakeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (vehicleMakeModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        vehicleMakeModels.forEach(element => {
          vehicleMakeData.all.remove((x) => x.id === element.id)
        })
        resolve(vehicleMakeData.all)
      } else {
        var ids = []
        vehicleMakeModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/vehiclemake/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  cloneMakeVersions (vehicleMakeVersions, year) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        vehicleMakeVersions.forEach(element => {
          vehicleMakeVersionData.all.remove((x) => x.id === element.id)
        })
        resolve(vehicleMakeVersionData.all)
      } else {
        var ids = []
        vehicleMakeVersions.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/vehiclemake/cloneVehicleMakeVersions', { vehicleMakeVersionIds: ids, year: year }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService
