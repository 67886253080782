<template>
  <div class="is-inline-block">
    <portal to="global-modal-portal" v-if="isCreditAppReassignmentActive">
      <b-modal :active.sync="isCreditAppReassignmentActive" scroll="keep" :has-modal-card="true" class="credit-application-modal" id="credit-application-modal">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Customer Credit Application Re-Assignment</p>
          </header>
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="notification is-warning is-flex">
                  <div><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></div>
                  <div class="columns ml-10 is-multiline">
                    <div class="column is-12">
                      <div v-if="isDealTypePendingCredit">The current status of this deal is Pending Credit, a credit application will automatically be resent to the re-assigned customer when the deal is saved.</div>
                      <div v-else>You have changed the current customer. The credit application will be reset and need to be resent, either by selecting the checkbox below or at a later stage.</div>
                    </div>
                    <div class="column is-12" v-if="!isDealTypePendingCredit">
                      <div class="field is-size-7">
                        <b-checkbox
                          :native-value="false"
                          name="chkResendApplication"
                          type="is-info"
                          :disabled="isDealTypePendingCredit"
                          v-model="resendApplication">
                          Resend Credit Application (select to send application on save)
                        </b-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  <span class="tag is-primary">{{ creditApplicationStatusDescription }}</span>
                </p>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-warning" type="button" @click="closeCreditAppReassignment">Cancel Customer Change</button>
            <button class="button is-danger" @click="onReassignCurrentApplication">Re-assign Credit Application</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'CreditApplicationReassignmentAction',
  mixins: [],
  components: {
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    isDealTypePendingCredit: {
      type: Boolean,
      default: false
    },
    isCreditAppReassignmentActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      resendApplication: false
    }
  },
  computed: {
    creditApplicationStatusDescription: function () {
      let creditAppStatus = this.currentDeal.creditApplicationStatus
      if (creditAppStatus === 'Sent') return 'Credit application has been sent. Awaiting customer completion.'
      if (creditAppStatus === 'Viewed') return 'Credit application has been opened by customer.'
      if (creditAppStatus === 'Pending') return 'Credit application has been issued. Waiting for response from dealer track.'
      if (creditAppStatus === 'Failed') return 'Credit application has failed send to dealer track.'
      if (creditAppStatus === 'Completed') return 'Credit application has been successfully processed by dealer track.'
      return ''
    }
  },
  methods: {
    closeCreditAppReassignment: function () {
      this.$emit('credit-app-reassignment-close')
    },
    onReassignCurrentApplication: function () {
      this.$emit('reassign-credit-application', this.resendApplication)
    }
  },
  mounted: function () {
    if (this.isDealTypePendingCredit) {
      this.resendApplication = true
    }
  },
  watch: {
  }
}

</script>

<style scoped>
.credit-application-modal .modal-card-body {
  position:relative;
  max-height: 65vh; /* max 80% of the viewport height */
  max-width: 35vw; /* max 80% of the viewport height */
  height: unset !important;
  overflow-y: auto;
  .animation-content {
      max-width: unset !important;
  }
}

@media screen and (min-width: 769px), print {
  .credit-application-modal .modal-card-body {
    position:relative;
    width: 35vw !important;
    max-height: 80vh !important; /* max 80% of the viewport height */
    max-width: 35vw; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: auto;
  }
}

@media screen and (min-width: 769px), print {
  .credit-application-modal .modal-content, .modal-card {
      position:relative;
      width: 99% !important;
      overflow: auto;
      min-width: unset !important;
      max-width: unset !important;
      width: unset !important;
  }
}
</style>
