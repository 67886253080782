<template>
  <div class="is-inline-block mr-3 mt-8" :style="isMobile ? 'padding: 5px !important;' : ''">
    <button v-if="canShowLink && !isMobile" type="button" class="button is-link is-pulled-left" @click="onOpenCreditApplication()">{{ actionDisplayText }}</button>
    <button type="button" class="button is-link" @click="onOpenCreditApplication()" v-else-if="canShowLink && isMobile"><b-icon pack="fal" icon="eye" custom-size="fa-lg"></b-icon>{{ actionDisplayText }}</button>
    <user-pin-control
      style="padding-top: 8px;"
      class="is-pulled-right"
      v-if="hasFeatureAccess('customer.ssn')"
      useIcon="fa-eye"
      :label="'Enter pin to unlock Ssn'"
      :usePortal="false"
      v-on:on-pin-verified="unlockSsn">
    </user-pin-control>
  </div>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'
import customerService from '@/services/customerService'
import UserPinControl from '@/components/generic/UserPinControl.vue'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'CustomerCreditApplicationAction',
  mixins: [utilitiesMixin],
  components: {
    'user-pin-control': UserPinControl
  },
  props: {
    currentCustomer: {
      type: Object,
      default: null
    },
    currentCustomerId: {
      type: Number,
      default: null
    },
    isListMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      creditApplicationLink: '',
      isLoading: false,
      ssn: '',
      isMobile: window.innerWidth <= 700
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapUserGetters(['hasFeatureAccess']),
    actionDisplayText: function () {
      if (this.isLoading) return 'Loading...'
      if (this.creditApplicationLink) return this.isMobile ? this.ssn !== '' ? ' - ' + this.currentCustomer.fullName + '(' + this.ssn + ')' : ' - ' + this.currentCustomer.fullName : this.ssn !== '' ? 'View Application - ' + this.currentCustomer.fullName + '(' + this.ssn + ')' : 'View Application - ' + this.currentCustomer.fullName
      return 'Not Available'
    },
    canShowLink: function () {
      return this.creditApplicationLink && this.creditApplicationLink.length > 0
    }
  },
  methods: {
    onOpenCreditApplication: function () {
      window.open(this.creditApplicationLink)
    },
    fetchCreditApplicationLink: function () {
      this.isLoading = true
      let customerId = this.isListMode ? this.currentCustomerId : this.currentCustomer.id
      customerService.createCreditApplicationLink(customerId).then(response => {
        this.creditApplicationLink = response
        this.isLoading = false
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong while fetching customer credit application link')
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    unlockSsn () {
      if (!this.currentCustomer || this.currentCustomer.id === 0) return

      let that = this
      that.isLoading = true
      customerService.getCustomerSsn(that.currentCustomer.id).then(response => {
        that.ssnModel = response
        if (response.errors) {
          this.failedToast('Oops! Something went wrong')
        } else {
          that.ssnModel = response.ssnModel
          that.ssn = that.ssnModel.value
        }
        that.isLoading = false
      }).catch(() => {
        that.isLoading = false
      })
    }
  },
  created: function () {
    if ((this.currentCustomer && this.currentCustomer.id !== 0) || (this.currentCustomerId !== null && this.currentCustomerId > 0)) {
      this.fetchCreditApplicationLink()
    }
  },
  mounted: function () {
  },
  watch: {
  }
}

</script>

<style scoped>
</style>
