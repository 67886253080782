export default {
  all: [
    {
      id: 1,
      status: 'Active',
      subscriberId: 1,
      opCode: 'OPC0000001',
      listName: 'OPC0000001 - Replace air conditioning filter',
      description: 'Replace air conditioning filter',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dispatchCodeId: 1,
      flatSellRate: 1500.00,
      flatHours: 15,
      estimatedDuration: 13.5,
      link: ''
    },
    {
      id: 2,
      status: 'Active',
      subscriberId: 1,
      opCode: 'OPC0000002',
      listName: 'OPC0000002 - Replace tyres',
      description: 'Replace tyres',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dispatchCodeId: 1,
      flatSellRate: 3500.00,
      flatHours: 3,
      estimatedDuration: 3,
      link: ''
    },
    {
      id: 3,
      status: 'Active',
      subscriberId: 1,
      opCode: 'OPC0000003',
      listName: 'OPC0000003 - Replace spark plugs',
      description: 'Replace spark plugs',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dispatchCodeId: 1,
      flatSellRate: 500.00,
      flatHours: 1,
      estimatedDuration: 1,
      link: ''
    },
    {
      id: 4,
      status: 'Active',
      subscriberId: 1,
      opCode: 'OPC0000004',
      listName: 'OPC0000004 - Replace engine',
      description: 'Replace engine',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dispatchCodeId: 1,
      flatSellRate: 50000.00,
      flatHours: 48,
      estimatedDuration: 48,
      link: ''
    },
    {
      id: 5,
      status: 'Active',
      subscriberId: 1,
      opCode: 'OPC0000005',
      listName: 'OPC0000005 - Replace worn rubbers',
      description: 'Replace worn rubbers',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dispatchCodeId: 1,
      flatSellRate: 750.00,
      flatHours: 3,
      estimatedDuration: 3,
      link: ''
    },
    {
      id: 6,
      status: 'Active',
      subscriberId: 1,
      opCode: 'OPC0000006',
      listName: 'OPC0000006 - Replace windscreen wipers',
      description: 'Replace windscreen wipers',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dispatchCodeId: 1,
      flatSellRate: 800.00,
      flatHours: 6,
      estimatedDuration: 6,
      link: ''
    }
  ]
}
