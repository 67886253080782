var mixin = {
  methods: {
    ensureIdDataTypeSafety: function (data) {
      if (data === undefined || data === null) return data
      // console.log('Checking new object or array - ', data)
      if (Array.isArray(data)) {
        // console.log('Checking new array')
        data.forEach((x) => {
          if (x.id) {
            // console.log('Array object has id property - ', x)
            x.id = isNaN(x.id) ? 0 : x.id
          }
        })
      } else {
        // console.log('Checking new object')
        if (data.id) {
          // console.log('New object has id property - ', data)
          data.id = isNaN(data.id) ? 0 : data.id
        }
      }
      return data
    },
    ensureSpecifiedDataTypeSafety: function (data, specifiedProp) {
      if (data === undefined || data === null) return data
      // console.log('Checking new object or array - ', data)
      if (Array.isArray(data)) {
        // console.log('Checking new array')
        data.forEach((x) => {
          if (x[specifiedProp]) {
            // console.log('Array object has id property - ', x)
            x[specifiedProp] = isNaN(x[specifiedProp]) ? 0 : x[specifiedProp]
          }
        })
      } else {
        // console.log('Checking new object')
        if (data[specifiedProp]) {
          // console.log('New object has ' + specifiedProp + ' property - ', data)
          data[specifiedProp] = isNaN(data[specifiedProp]) ? 0 : data[specifiedProp]
        }
      }
      return data
    },
    cleanNote: function (note) {
      if (note === 'C' || note === 'H' || !note) {
        return ''
      }
      if (!note.startsWith('C') && !note.startsWith('H')) {
        return note
      }
      return note.slice(1)
    },
    getFileType: function (fileName) {
      if (!fileName) {
        return ''
      }
      var extension = fileName.split('.').pop()
      extension = extension.toLowerCase()
      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif') {
        return 'image/' + extension
      }
      if (extension === 'pdf' || extension === 'doc' || extension === 'txt') {
        return 'application/' + extension
      }
      if (extension === 'mp4') {
        return 'video/' + extension
      }
      return 'unknown'
    },
    getServiceRequestClassTypeByFileType: function (fileName) {
      if (!fileName) {
        return 'Other'
      }
      var extension = fileName.split('.').pop()
      extension = extension.toLowerCase()
      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif') {
        return 'Image'
      }
      if (extension === 'pdf' || extension === 'doc' || extension === 'txt') {
        return 'Other'
      }
      if (extension === 'mp4') {
        return 'Media'
      }
      return 'Other'
    },
    getRoServiceClassTypeByFileType: function (fileName) {
      if (!fileName) {
        return 'Media'
      }
      var extension = fileName.split('.').pop()
      extension = extension.toLowerCase()
      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif') {
        return 'Media'
      }
      if (extension === 'pdf' || extension === 'doc' || extension === 'txt') {
        return 'Report'
      }
      if (extension === 'mp4') {
        return 'Video'
      }
      return 'File'
    },
    getRoServiceWorkItemClassTypeByFileType: function (fileName) {
      if (!fileName) {
        return 'File'
      }
      var extension = fileName.split('.').pop()
      extension = extension.toLowerCase()
      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif') {
        return 'Image'
      }
      if (extension === 'pdf' || extension === 'doc' || extension === 'txt') {
        return 'File'
      }
      if (extension === 'mp4') {
        return 'Video'
      }
      return 'File'
    },
    round2Digits: function (number) {
      return Math.round(Math.round(number * 1000) / 10) / 100
    },
    round: function (number) {
      return Math.round(number)
    },
    successToast (message, timout) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
        duration: timout || 5000,
        position: 'is-bottom'
      })
    },
    failedToast (message, timout) {
      this.$buefy.toast.open({
        duration: timout || 5000,
        message: message,
        type: 'is-danger',
        position: 'is-bottom'
      })
    },
    formatNumberToTwoDecimalPlaces: function (number) {
      return parseFloat(number).toFixed(2)
    },
    toCamelCase: function (str) {
      if (!str) return str
      return str.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
    },
    uniqueDefaultCosts: function (defaultCosts) {
      let sortedArray = defaultCosts.sort(this.compare_name)
      let arr = []
      for (var i = 0; i < sortedArray.length; i++) {
        let name = sortedArray[i].name
        let index = arr.findIndex(element => {
          if (element.name === name) {
            return true
          }
          return false
        })
        if (index === -1) {
          arr.push(sortedArray[i])
        }
      }
      return arr
    },
    compare_name: function (a, b) {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() === b.name.toLowerCase()) {
        if (a.forVehicleMakeId === null) {
          return -1
        }
        if (b.forVehicleMakeId === null) {
          return 1
        }
        if (a.forVehicleMakeId === b.forVehicleMakeId) {
          return 0
        }
      }
      return 0
    },
    unmask: function (input) {
      if (!input || input === '') {
        return ''
      }
      return input.replace('+1', '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
    },
    addDays (date, days) {
      var result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    }
  }
}

export default mixin
