import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules/index'

Vue.use(Vuex)

const isDebug = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'ppd' && process.env.NODE_ENV !== 'beta'

const store = new Vuex.Store({
  strict: isDebug,
  modules: {
    namespaced: true,
    ...modules
  }
})

export default store
