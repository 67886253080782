export default class BaseModel {
  constructor (data = {}) {
    // Dynamically assign all properties from the data object
    Object.assign(this, this.parseData(data))
  }

  // Helper method to parse incoming data
  parseData (data) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      // Handle different data types
      acc[key] = this.parseValue(value)
      return acc
    }, {})
  }

  // Helper method to parse individual values
  parseValue (value) {
    if (value === null || value === undefined) return null

    // Handle arrays
    if (Array.isArray(value)) {
      return value.map(item => this.parseValue(item))
    }

    // Handle dates (basic ISO date string check)
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*Z$/.test(value)) {
      return new Date(value)
    }

    // Handle nested objects
    if (typeof value === 'object') {
      return this.parseData(value)
    }

    return value
  }

  // Helper method to prepare data for API
  toAPI () {
    return Object.entries(this).reduce((acc, [key, value]) => {
      acc[key] = this.serializeValue(value)
      return acc
    }, {})
  }

  // Helper method to serialize values for API
  serializeValue (value) {
    if (value === null || value === undefined) return null

    // Handle arrays
    if (Array.isArray(value)) {
      return value.map(item => this.serializeValue(item))
    }

    // Handle dates
    if (value instanceof Date) {
      return value.toISOString()
    }

    // Handle nested objects
    if (typeof value === 'object') {
      return Object.entries(value).reduce((acc, [k, v]) => {
        acc[k] = this.serializeValue(v)
        return acc
      }, {})
    }

    return value
  }

  // Helper method to check if model is valid (customize as needed)
  isValid () {
    // Example validation: Ensure required fields are present
    return true
  }

  // Static method to parse a single record
  static fromAPI (data) {
    if (!data) return undefined

    return new this(data)
  }

  // Static method to parse an array of records
  static fromAPIList (dataArray) {
    if (!Array.isArray(dataArray)) return []

    return dataArray.map(data => new this(data))
  }

  // Alternative method if you need to filter out invalid records
  static fromAPIListFiltered (dataArray) {
    if (!Array.isArray(dataArray)) return []

    return dataArray
      .map(data => new this(data))
      .filter(record => record.isValid())
  }
}
