<template>
  <div class="inline-block" :name="instanceId">
    <section class="inventory-pricing-history-container">
      <section v-if="hasPriceHistory && isListMode" class="is-flex">
        <i class="icon fal fa-md fa-history clickable" :class="{'is-loading': isLoading}" title="Click to view history of changed for this pricing field" @click="onPricingHistoryClick"/>
      </section>
      <div :id="'inventory-pricing-history-container-' + instanceId" @click="onPricingHistoryClick" v-if="hasPriceHistory && !isListMode">
        <i class="icon fal fa-md fa-history clickable" :class="{'is-loading': isLoading}" title="Click to view history of changed for this pricing field"/>
      </div>

      <portal to="global-modal-portal" :disabled="!usePortal" v-if="showPricingHistoryActive">
        <b-modal :active.sync="showPricingHistoryActive" scroll="keep" :width="640" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Historical Price Changes - {{priceFieldIdentifier}}</p>
            </header>
            <section class="modal-card-body">
              <table class="table pricing-table is-bordered is-narrow is-hoverable">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{priceFieldIdentifier}} Value</td>
                    <td>Modified At</td>
                    <td>Modified By</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(historyEntry, index) in allRecordedPricingHistory" :key="historyEntry.id" :title="historyEntry.description">
                    <td>{{index}}.</td>
                    <td>{{historyEntry.value | currency}}</td>
                    <td>{{historyEntry.createdAt | formatDate}}</td>
                    <td>{{historyEntry.createdBy}}</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <footer class="modal-card-foot">
                <button type="button" class="button is-success" @click="onClosePricingHistory()">Close</button>
            </footer>
          </div>
        </b-modal>
      </portal>
    </section>
  </div>
</template>

<script>

import inventoryService from '@/services/inventoryService'

export default {
  name: 'PricingHistoryComponent',
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    pricingType: {
      type: String,
      default: ''
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    isListMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      showPricingHistoryActive: false,
      allRecordedPricingHistory: [],
      isLoading: false,
      serverErrorMessage: ''
    }
  },
  methods: {
    onClosePricingHistory: function () {
      this.showPricingHistoryActive = false
      this.allRecordedPricingHistory = []
    },
    onPricingHistoryClick: function () {
      this.isLoading = true
      inventoryService.getPricingHistory(this.currentInventory, this.priceFieldIdentifier).then(response => {
        this.showPricingHistoryActive = true
        this.allRecordedPricingHistory = response || []
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
        this.allRecordedPricingHistory = []
      })
    }
  },
  computed: {
    priceFieldIdentifier: function () {
      var result = ''
      switch (this.pricingType) {
        case 'msrp':
          result = 'Msrp'
          break
        case 'purchaseCost':
          result = 'Purchase'
          break
        case 'askingPrice':
          result = 'Asking'
          break
        case 'leasePrice':
          result = 'Lease'
          break
        case 'spiff':
          result = 'Spiff'
          break
        case 'generalLedgerBalance':
          result = 'GlBalance'
          break
        case 'reconCost':
          result = 'Recon'
          break
        case 'commission':
          result = 'Purchase Commission'
          break
      }
      return result
    },
    hasPriceHistory: function () {
      return this.currentInventory && this.currentInventory[this.pricingType]
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal.is-active .modal-card-foot {
    padding: 10px !important
  }

  .modal-card-head, .modal-card-body{
    padding: 10px !important
  }

  .inventory-pricing-history-container {
    display:inline-flex;
    vertical-align: middle;
  }

  .table {
    background-color: #f8fafc !important;
    color: #6c6c7a;
    font-size: 0.7rem;
    width: 100%;
  }

  .pricing-table {
    background-color: #f8fafc !important;
    color: #6c6c7a;
    font-size: 0.75rem;
    width: 100%;
  }

</style>
