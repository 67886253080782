import axios from 'axios'
import baseService from './baseService'
import serviceVideoData from './data/serviceVideo'
import InventoryServiceVideo from '@/models/InventoryServiceVideo'

const serviceVideoService = {
  getServiceVideosByVin (vinNo, dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceVideoData)
      } else {
        axios
          .post('/serviceVideo/getServiceVideosByVin?vinNo=' + vinNo + '&dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = InventoryServiceVideo.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  serviceMediaRequest (serviceMediaRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/serviceVideo/createServiceMediaViewRequest', serviceMediaRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default serviceVideoService
