import axios from 'axios'
import baseService from './baseService'
import leadSourceData from './data/leadSource'
import LeadSource from '@/models/LeadSource'

const leadSourceService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(leadSourceData)
      } else {
        axios
          .post('/leadsource/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = LeadSource.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (leadsourceId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(leadSourceData.all.find((x) => x.id === leadsourceId))
      } else {
        axios
          .post('/leadsource/get?id=' + leadsourceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (leadSourceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var leadSource = {
          id: 999,
          name: leadSourceModel.name,
          status: 'Active'
        }
        leadSourceData.all.push(leadSource)
        resolve(leadSource)
      } else {
        axios
          .post('/leadsource/save', leadSourceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (leadSourceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var leadSource = leadSourceData.all.find((x) => x.id === leadSourceModel.id)
        if (leadSource) {
          leadSource.name = leadSourceModel.name
          leadSource.status = leadSourceModel.status
        }
        resolve(leadSource)
      } else {
        axios
          .post('/leadsource/update', leadSourceModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (leadSourceModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        leadSourceModel.forEach(element => {
          leadSourceData.all.remove((x) => x.id === element.id)
        })
        resolve(leadSourceData.all)
      } else {
        var ids = []
        leadSourceModel.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/leadsource/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allLeadSources () {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(leadSourceData.all)
      } else {
        axios
          .get('/leadsource/list?', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default leadSourceService
