import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import baseService from './baseService'
import opCodeData from './data/roServiceOpCode'
import RoServiceOpCode from '@/models/RoServiceOpCode'

const cachedAxios = setupCache(axios.create(), {
  ttl: 5000,
  methods: ['get'],
  cachePredicate: {
    statusCheck: (status) => status >= 200 && status < 400
  },
  staleIfError: false
})

const opCodeService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('op-code-load-all')
      if (baseService.isTestMode) {
        resolve(opCodeData)
      } else {
        axios
          .post('/roserviceopcode/list', searchCriteria, {...baseService.defaultConfig, requestId: 'opcode-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = RoServiceOpCode.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('opcode all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (roServiceOpCodeId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(opCodeData.all.find((x) => x.id === roServiceOpCodeId))
      } else {
        cachedAxios
          .get('/roserviceopcode/get?id=' + roServiceOpCodeId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (roServiceOpCodeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roServiceOpCode = {
          id: 999,
          dealerId: roServiceOpCodeModel.dealerId,
          subscriberId: roServiceOpCodeModel.subscriberId,
          status: 'Active',
          opCode: roServiceOpCodeModel.opCode,
          description: roServiceOpCodeModel.description,
          dispatchCodeId: roServiceOpCodeModel.dispatchCodeId,
          flatSellRate: roServiceOpCodeModel.flatSellRate,
          priority: roServiceOpCodeModel.priority,
          groupName: roServiceOpCodeModel.groupName,
          flatHours: roServiceOpCodeModel.flatHours,
          estimatedDuration: roServiceOpCodeModel.estimatedDuration,
          link: roServiceOpCodeModel.link
        }
        opCodeData.all.push(roServiceOpCode)
        resolve(roServiceOpCode)
      } else {
        axios
          .post('/roserviceopcode/save', roServiceOpCodeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (roServiceOpCodeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roServiceOpCode = opCodeData.all.find((x) => x.id === roServiceOpCodeModel.id)
        if (roServiceOpCode) {
          roServiceOpCode.dealerId = roServiceOpCodeModel.dealerId
          roServiceOpCode.subscriberId = roServiceOpCodeModel.subscriberId
          roServiceOpCode.status = roServiceOpCodeModel.status
          roServiceOpCode.opCode = roServiceOpCodeModel.opCode
          roServiceOpCode.description = roServiceOpCodeModel.description
          roServiceOpCode.dispatchCodeId = roServiceOpCodeModel.dispatchCodeId
          roServiceOpCode.flatSellRate = roServiceOpCodeModel.flatSellRate
          roServiceOpCode.flatHours = roServiceOpCodeModel.flatHours
          roServiceOpCode.groupName = roServiceOpCodeModel.groupName
          roServiceOpCode.priority = roServiceOpCodeModel.priority
          roServiceOpCode.estimatedDuration = roServiceOpCodeModel.estimatedDuration
          roServiceOpCode.link = roServiceOpCodeModel.link
        }
        resolve(roServiceOpCode)
      } else {
        axios
          .post('/roserviceopcode/update', roServiceOpCodeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (roServiceOpCodeModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceOpCodeModels.forEach(element => {
          opCodeData.all.remove((x) => x.id === element.id)
        })
        resolve(opCodeData.all)
      } else {
        var ids = []
        roServiceOpCodeModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/roserviceopcode/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default opCodeService
