<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title is-size-6 has-text-weight-semibold">Configure Missing Vehicle</p>
    </header>
    <section class="modal-card-body">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <p v-if="!isSold">What would you like to do with the missing inventory?</p>
        <p v-else>We still have the vehicle accessories, please hand these to the customer.</p>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-primary" :class="{'is-loading': isArchiving}" @click="onArchive()" v-if="!hasDeals">Archive</button>
      <button class="button is-primary" @click="onAddDeal()" v-if="!isSold">Add Deal</button>
      <button class="button is-danger" type="button" @click="onCancel()">Close</button>
    </footer>

    <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'
import SectionHeader from '@/components/generic/SectionHeader'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

export default {
  name: 'InventoryMissingCaptureDetail',
  components: {
    'section-header': SectionHeader,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    inventoryId: {
      type: Number,
      default: null
    },
    hasAccessories: {
      type: Boolean,
      default: false
    },
    isMissingCaptureActive: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentInventory: {
      },
      serverErrors: [],
      serverErrorMessage: '',
      hasDeals: false,
      isSold: false,
      isSaving: false,
      isLoading: false,
      isArchiving: false
    }
  },
  computed: {
  },
  methods: {
    onCancel: function () {
      this.$emit('missing-capture-complete')
    },
    onComplete: function () {
      eventBus.$emit('reload-missing-list')
      this.$emit('missing-capture-complete')
    },
    loadInventory: function (id) {
      this.currentInventoryId = id
      this.isLoading = true
      this.serverErrorMessage = ''

      inventoryService.single(this.currentInventoryId).then(response => {
        this.currentInventory = {
          ...response.inventory
        }
      }).catch((error) => {
        this.onError(error)
      })

      dealService.byInventory(this.currentInventoryId).then(response => {
        if ((response.results && response.results.filter((x) => x.type === 'Booked' || x.type === 'Delivered').length > 0) && this.hasAccessories) {
          this.isSold = true
        }

        if (response.results && response.results.filter((x) => x.type !== 'Cancelled').length > 0) {
          this.hasDeals = true
        }
        this.isLoading = false
      }).catch((error) => {
        this.onError(error)
      })
    },
    onError: function (error) {
      this.serverErrorMessage = error.message
      this.isLoading = false
      this.isSaving = false
    },
    onAddDeal: function () {
      this.$router.push({name: 'NewDeal', params: { inventoryItem: this.currentInventory }})
    },
    onArchive () {
      this.isArchiving = true
      inventoryService.archive([this.currentInventory]).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.onComplete()
        }
        this.isArchiving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isArchiving = false
      })
    }
  },
  watch: {
  },
  mounted: function () {
    this.loadInventory(this.inventoryId)
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card-body {
    max-height: 100px !important;
    min-height: 100px !important;
    height: 100px !important;
  }
</style>
