import serviceRequestService from '@/services/serviceRequestService'
var mixin = {
  methods: {
    verifyDealProductDelete: async function (dealId, dealItemProductId) {
      return new Promise((resolve, reject) => {
        serviceRequestService.verifyDealProductDelete(dealId, dealItemProductId).then(response => {
          resolve(response.result)
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
export default mixin
