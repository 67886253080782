import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import baseService from './baseService'
import vehiclePartData from './data/vehiclePart'
import VehiclePart from '@/models/VehiclePart'

const cachedAxios = setupCache(axios.create(), {
  ttl: 5000,
  methods: ['get'],
  cachePredicate: {
    statusCheck: (status) => status >= 200 && status < 400
  },
  staleIfError: false
})

const vehiclePartService = {
  allVehicleParts () {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(vehiclePartData.all)
      } else {
        axios
          .get('/vehiclepart/list?', baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = VehiclePart.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response)
          })
      }
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('vehicle-part-load-all')
      if (baseService.isTestMode) {
        resolve(vehiclePartData.all)
      } else {
        axios
          .post('/vehiclepart/list', searchCriteria, {...baseService.defaultConfig, requestId: 'vehicle-part-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = VehiclePart.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('vehicle-part-load-all all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (vehiclePartId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(vehiclePartData.all.find((x) => x.id === vehiclePartId))
      } else {
        cachedAxios
          .get('/vehiclepart/get?id=' + vehiclePartId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (vehiclePartModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var vehiclePart = {
          id: 999,
          dealerId: vehiclePartModel.dealerId,
          subscriberId: vehiclePartModel.subscriberId,
          status: 'Active',
          partNumber: vehiclePartModel.partNumber,
          description: vehiclePartModel.description,
          listName: vehiclePartModel.listName,
          unitCost: vehiclePartModel.unitCost,
          quantityInStock: vehiclePartModel.quantityInStock,
          createdAt: vehiclePartModel.createdAt,
          modifiedAt: vehiclePartModel.modifiedAt
        }
        vehiclePartData.all.push(vehiclePart)
        resolve(vehiclePart)
      } else {
        axios
          .post('/vehiclepart/save', vehiclePartModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (vehiclePartModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var vehiclePart = vehiclePartData.all.find((x) => x.id === vehiclePartModel.id)
        if (vehiclePart) {
          vehiclePart.dealerId = vehiclePartModel.dealerId
          vehiclePart.subscriberId = vehiclePartModel.subscriberId
          vehiclePart.status = vehiclePartModel.status
          vehiclePart.partNumber = vehiclePartModel.partNumber
          vehiclePart.description = vehiclePartModel.description
          vehiclePart.listName = vehiclePartModel.listName
          vehiclePart.unitCost = vehiclePartModel.unitCost
          vehiclePart.quantityInStock = vehiclePartModel.quantityInStock
          vehiclePart.createdAt = vehiclePartModel.createdAt
          vehiclePart.modifiedAt = vehiclePartModel.modifiedAt
        }
        resolve(vehiclePart)
      } else {
        axios
          .post('/vehiclepart/update', vehiclePartModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (roServiceOpCodeModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roServiceOpCodeModels.forEach(element => {
          vehiclePartData.all.remove((x) => x.id === element.id)
        })
        resolve(vehiclePartData.all)
      } else {
        var ids = []
        roServiceOpCodeModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/vehiclepart/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/vehiclepart/notes', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/vehiclepart/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/vehiclepart/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/vehiclepart/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/vehiclepart/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByVehiclePartId (partsId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .get('/vehiclepart/vehiclePartNotes?id=' + partsId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default vehiclePartService
