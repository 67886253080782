import axios from 'axios'
import baseService from './baseService'
import bankData from './data/bank'
import Bank from '@/models/Bank'

const bankService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(bankData)
      } else {
        axios
          .post('/bank/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Bank.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allBanks (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(bankData)
      } else {
        axios
          .get('/bank/list?', baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Bank.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (bankId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(bankData.all.find((x) => x.id === bankId))
      } else {
        axios
          .post('/bank/get?id=' + bankId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (bankModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var bank = {
          id: 999,
          name: bankModel.name,
          firstName: bankModel.firstName,
          lastName: bankModel.lastName,
          email: bankModel.email,
          office: bankModel.office,
          fax: bankModel.fax,
          status: 'Active'
        }
        bankData.all.push(bank)
        resolve(bank)
      } else {
        axios
          .post('/bank/save', bankModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (bankModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var bank = bankData.all.find((x) => x.id === bankModel.id)
        if (bank) {
          bank.name = bankModel.name
          bank.firstName = bankModel.firstName
          bank.lastName = bankModel.lastName
          bank.email = bankModel.email
          bank.office = bankModel.office
          bank.fax = bankModel.fax
          bank.status = bankModel.status
        }
        resolve(bank)
      } else {
        axios
          .post('/bank/update', bankModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (bankModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        bankModels.forEach(element => {
          bankData.all.remove((x) => x.id === element.id)
        })
        resolve(bankData.all)
      } else {
        var ids = []
        bankModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/bank/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default bankService
