<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect
        @select="$markDirty()"
        :showLabels="false"
        :name="instanceName"
        :disabled="isDisabled"
        :searchable="true"
        data-vv-validate-on="input|close"
        :data-vv-as="this.validateAs"
        v-validate="{'required': required}"
        :class="{'is-danger': errors.has(validationName) }"
        v-model="selectedSquareLocation"
        :options="allLocations"
        :placeholder="placeholder"
        label="name"
        trackBy="id"
        :loading="isSquareLocationLoading"
        :internal-search="true"
        :options-limit="300"
        :data-vv-scope="scope"
        :hideSelected="true">
      </multiselect>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
  </div>
</template>

<script>

import squareService from '@/services/squareService'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'SquareLocationSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Square Location'
    },
    placeholder: {
      type: String,
      default: 'type to search for a square location'
    },
    validateAs: {
      type: String,
      default: 'Square Location'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    },
    isRoInvoice: {
      type: Boolean,
      default: false
    },
    isPartsDeposit: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      isSquareLocationLoading: false,
      selectedSquareLocation: this.value,
      data: [],
      instanceName: 'square-location-selector-' + this.$uuid.v4()
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    allLocations () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    selectedSquareLocation: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      this.selectedSquareLocation = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    }
  },
  methods: {
    clearAllLocations () {
      this.data = []
    },
    loadAsyncData: function () {
      this.isSquareLocationLoading = true
      this.clearAllLocations()
      squareService.getLocations().then(response => {
        this.isSquareLocationLoading = false
        if (response.errors) {
          if (response.errors[0]) {
            this.$emit('has-square-location-error', {message: response.errors[0].errorMessage})
          }
        } else {
          response.forEach((item) => {
            this.data.push(item)
          })
          this.setDefaultLocation()
        }
      }).catch((error) => {
        this.isSquareLocationLoading = false
        this.$emit('has-square-location-error', {message: error.message})
      })
    },
    setDefaultLocation: function () {
      if (this.selectedSquareLocation) {
        return
      }

      let sqLocationName = this.isRoInvoice || this.isPartsDeposit ? this.currentDealer.sqLocationNamePrRo : this.currentDealer.sqLocationName
      let squarelocation = this.allLocations.find(x => x.name.toLowerCase() === sqLocationName.toLowerCase())

      if (squarelocation) {
        this.selectedSquareLocation = squarelocation
      } else {
        // default location must be set or throw error
        let errMessage = 'Could not map this configured Dealer Square location of ' + sqLocationName + ' with Square. Please update Dealer Square location in configuration menu and try again.'
        if (this.isRoInvoice || this.isPartsDeposit) {
          errMessage = 'Could not map this configured Dealer RO Square location of ' + sqLocationName + ' with Square. Please update Dealer RO Square location in configuration menu and try again.'
        }
        this.$emit('has-square-location-error', {message: errMessage})
      }
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  },
  mounted: function () {
    this.loadAsyncData()
  }
}

</script>

<style scoped>

</style>
