<template>
  <div>
    <div class="is-inline-block">
      <div v-if="currentInventory">
        <div :id="instanceId" :name="instanceId" class="quick-action">
          <button class="button" @click.stop.prevent="onPreview" :title="getIconTitle(currentInventory.leaseReturnedAgreementStatusId)">
            <i data-type="L" class="icon fal fa-signature fa-lg" :class="getColorClassByAgreementStatus(currentInventory.leaseReturnedAgreementStatusId)"/>
          </button>
        </div>
        <b-dropdown
          ref="actionDropdown"
          aria-role="list"
          class="lease-returned-document-control"
          v-if="isSignedOrComplete"
          :scrollable="true"
          :close-on-click="false"
          :mobile-modal="true"
          :expanded="true"
          :autoClose="true">
          <b-dropdown-item aria-role="listitem" @click="onDowloadLastSignedDocument">Download Last Signed</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <pdf-modal
      :pdfFile="leaseReturnedDocument"
      :usePortal="true"
      v-on:on-pdf-downloaded="onPreviewed"
      v-on:on-pdf-closed="onPreviewClosed"
      v-if="leaseReturnedDocument && leaseReturnedDocument.src">
    </pdf-modal>
  </div>
</template>

<script>
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  mixins: [formattingMixin, utilitiesMixin],
  components: {
    'pdf-modal': PdfModalDisplayComponent
  },
  name: 'InventoryLeaseReturnedStatus',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      currentInventory: null,
      leaseReturnedDocument: null,
      wasPreviewed: false
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    isSignedOrComplete: function () {
      return this.currentInventory && (this.currentInventory.leaseReturnedAgreementStatusId === 10 || this.currentInventory.leaseReturnedAgreementStatusId === 3)
    },
    lastSignedAgreement: function () {
      if (this.currentInventory && this.isSignedOrComplete) {
        var allSignedAgreements = this.currentInventory.attachments.filter((x) => x.classType === 'SignedLeaseReturnedAgreement' && x.status === 'Active')
        if (allSignedAgreements.length > 0) {
          return allSignedAgreements[allSignedAgreements.length - 1].fileLocation
        }
      }
      return ''
    }
  },
  methods: {
    onDowloadLastSignedDocument: function () {
      if (this.lastSignedAgreement === '') return
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedAgreement)
    },
    getIconTitle: function (status) {
      var result = 'Not Available'
      switch (status) {
        case 0:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          result = 'Lease Returned Agreement Incomplete'
          break
        case 1:
        case 2:
        case 4:
          result = 'Lease Returned Agreement Pending Completion'
          break
        case 10:
        case 3:
        case 11:
          result = 'Lease Returned Agreement Completed. Click to Download.'
          break
      }

      return result
    },
    onPreview: function () {
      if (this.isSignedOrComplete) {
        if (this.$refs.actionDropdown && this.wasPreviewed) {
          this.$refs.actionDropdown.toggle()
          return
        }

        this.leaseReturnedDocument = {
          name: 'Lease Returned Agreement',
          src: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/loaner/lease-returned/' + this.currentInventory.id + '/' + this.currentUser.id,
          fileName: 'lease_returned_agreement_' + this.currentInventory.id + '.pdf',
          key: this.$uuid.v4()
        }
      }
    },
    onPreviewed: function () {
      this.wasPreviewed = true
    },
    onPreviewClosed: function () {
      this.wasPreviewed = true
      this.leaseReturnedDocument = null
    }
  },
  watch: {
    inventoryItem: function (newValue, oldValue) {
      if (newValue) {
        this.currentInventory = newValue
      }
    }
  },
  mounted: function () {
    this.currentInventory = this.inventoryItem
  }
}
</script>

<style scoped>
</style>
