<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :key="instanceName"
      :taggable="true"
      @tag="customerAdded"
      :tagPlaceholder="tagPlaceholder"
      tagPosition="bottom"
      :searchable="true"
      :hideSelected="true"
      :disabled="isDisabled"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedCustomer"
      :options="allCustomers"
      label="fullName"
      trackBy="id"
      :loading="isCustomerLoading"
      placeholder="Type to search for a customer"
      :internal-search="false"
      :options-limit="300"
      @search-change="onCustomerSearch"
      :data-vv-scope="scope"
      ref="selector">
        <template slot="beforeList">
          <div class="columns is-multiline is-mobile" style="margin:1px">
            <div class="column is-4">
              <label class="has-text-weight-bold">Name</label>
            </div>
            <div class="column is-4">
              <label class="has-text-weight-bold">Email</label>
            </div>
            <div class="column is-2">
              <label class="has-text-weight-bold">Mobile</label>
            </div>
            <div class="column is-2">
              <label class="has-text-weight-bold">DP Status</label>
            </div>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="columns is-multiline is-mobile">
            <div class="column is-4">
              <label>{{props.option.fullName}}</label>
            </div>
            <div class="column is-4">
              <label>{{props.option.email}}</label>
            </div>
            <div class="column is-2">
              <label>{{props.option.mobile}}</label>
            </div>
            <div class="column is-2">
              <i class="fal fa-check fa-lg has-text-success" v-if="customerInDealerPeak(props.option.customerId)"></i>
              <i class="fal fa-times fa-lg has-text-danger" v-else></i>
            </div>
          </div>
        </template>
      </multiselect>
      <a class="button is-primary new-customer-btn" @click="checkForExistingLead = true" v-if="allowNew && showNewAction">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
      <a class="button is-primary new-customer-btn" @click="editCustomerActive = true" v-if="allowEdit && showEditAction && hasEditCustomerAccess && selectedCustomerId !== 0">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <br/>
    <div v-if="rejected" class="field is-size-7 is-pulled-right">
      <b-checkbox
        :native-value="false"
        name="includeActive"
        type="is-info"
        v-model="includeActiveCustomers">
        Include active customers
      </b-checkbox>
    </div>

    <b-modal :active.sync="isNewCustomerActive" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="min-width:400px !important; height:75vh;">
          <new-customer
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onCustomerSaved"
            v-on:on-cancel="onCustomerCancelled"
            :startWith="nameOfCustomerToCreate"
            :customerLead="selectedCustomerMatch"
            :rejected=rejected
            :contactData="contactData"
            :requiredParentId="parentId">
          </new-customer>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>

    <b-modal id="customer-lead-lookup" :width="550" :active.sync="checkForExistingLead" :has-modal-card="true" v-if="checkForExistingLead" :no-close-on-backdrop="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Dealer Peak Customer Lead lookup</p>
        </header>
        <section class="modal-card-body has-background-grey-lighter">
          <div class="box">
            <div class="notification is-flex is-info mt-5">
              <span><i class="fas fa-info-circle is-info fa-2x"></i></span>
              <span class="ml-10">
                Please capture an email or mobile number to check if a Lead exists
              </span>
            </div>
            <div class="columns is-multiline">
              <div class="column is-6">
                <label class="label">Mobile</label>
                <div class="control">
                  <input name="mobile" :class="{'input': true }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="mobile" @change="findByMobile" data-lpignore="true" autocomplete="off">
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Email Address</label>
                <div class="control">
                  <input name="email" :class="{'input': true }" type="text" placeholder="Email" v-model="email" @change="findByEmail" data-lpignore="true" autocomplete="off">
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot is-inline-block">
          <button class="button is-success" type="button" @click="findByContactInfo">Find</button>
          <button class="button is-danger" type="button" @click="skipLeadLookup">Skip</button>
          <button class="button is-danger" type="button" @click="cancelLeadLookup">Close</button>
          <span class="tag is-danger is-medium is-pulled-right" v-if="noCustomerMatch">No customer match found.</span>
        </footer>
        <b-loading :is-full-page="false" :active.sync="isCheckingForLead" :canCancel="false"></b-loading>
      </div>
    </b-modal>

    <b-modal id="customer-selection" :width="400" :active.sync="showCustomerMatches" :has-modal-card="true" v-if="showCustomerMatches" :no-close-on-backdrop="true">
      <div class="modal-card" style="width: 500px">
        <header class="modal-card-head">
          <div class="columns call-overview is-fullwidth is-multiline is-hidden-mobile">
            <div class="column is-12" style="padding-bottom:30px">
              <label class="label">
                The following customer matches was found in dealer peak.
                <br/>
                Please select the one that matches best.
                </label>
            </div>
            </div>
        </header>
        <section class="modal-card-body call-log-body-fit">
          <button v-for="customer in customerMatches" :key="customer.dealerPeakUserId" type=button :class="{'button': true}" @click="onCustomerSelection(customer)">
            <label class="label">{{customer.fullName}}</label>
          </button>
        </section>
        <footer class="modal-card-foot is-inline-block">
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="editCustomerActive" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="min-width:400px !important; height:75vh;">
          <edit-customer
            :customerId="selectedCustomerId"
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onExistingCustomerSaved"
            v-on:on-cancel="onCancelEditCustomer"
            :fulfillForDepositCaptureMode="false">
          </edit-customer>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
      </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import NewCustomer from '@/components/configuration/customers/NewCustomer'
import dealerPeakService from '@/services/dealerPeakService'
import customerService from '@/services/customerService'
import EditCustomer from '@/components/configuration/customers/EditCustomer'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'CustomerSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Customer'
    },
    validateAs: {
      type: String,
      default: 'customer'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    showNewAction: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    showEditAction: {
      type: Boolean,
      default: true
    },
    tagPlaceholder: {
      type: String,
      default: 'Press enter to create a new customer'
    },
    rejected: {
      type: Boolean,
      default: false
    },
    contactData: {
      type: Object,
      default: null
    },
    parentId: {
      type: Number,
      default: 0
    }
  },
  components: {
    'new-customer': NewCustomer,
    'edit-customer': EditCustomer
  },
  data () {
    return {
      isCustomerLoading: false,
      isNewCustomerActive: false,
      selectedCustomer: this.value,
      nameOfCustomerToCreate: '',
      data: [],
      instanceName: 'customer-selector-' + this.$uuid.v4(),
      storeDealer: this.$store.state.dealer.currentDealer,
      checkForExistingLead: false,
      email: '',
      mobile: '',
      selectedCustomerMatch: null,
      customerMatches: [],
      isCheckingForLead: false,
      noCustomerMatch: false,
      showCustomerMatches: false,
      defaultDealerPeakId: '00000000-0000-0000-0000-000000000000',
      editCustomerActive: false,
      includeActiveCustomers: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    allCustomers () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    selectedCustomerId: function () {
      return this.selectedCustomer ? this.selectedCustomer.id : 0
    },
    hasEditCustomerAccess: function () {
      let allowedPermissions = ['customer.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    }
  },
  watch: {
    selectedCustomer: async function (newValue, oldValue) {
      this.$emit('input', newValue)
      if (newValue) {
        if (this.customerInDealerPeak(newValue.customerId) === false) {
          await this.syncCustomer()
        }
      }
    },
    value: function (newValue, oldValue) {
      this.selectedCustomer = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    },
    selectedDealer: function (newValue, oldValue) {
      // Removed the code below as customers are now shared across dealers
      /*
      this.selectedDealer = newValue
      // Only reset the customer on dealer change if its not the same dealer as the customer already belongs to
      if (this.selectedCustomer && this.selectedCustomer.dealerId !== this.selectedDealer.id) {
        this.selectedCustomer = null
      }
      */
    },
    storeDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
    }
  },
  methods: {
    customerInDealerPeak: function (dealerPeakId) {
      return dealerPeakId !== null && dealerPeakId !== '' && dealerPeakId !== this.defaultDealerPeakId
    },
    customerAdded: function (data) {
      if (this.allowNew) {
        this.nameOfCustomerToCreate = data
        if (this.storeDealer.isWholesaleDealer) {
          this.isNewCustomerActive = true
          this.resetFields()
        } else {
          if (this.rejected) {
            this.isNewCustomerActive = true
          } else {
            this.checkForExistingLead = true
            this.resetFields()
          }
        }
      }
    },
    onCustomerSaved: function (newCustomer) {
      this.data.push(newCustomer)
      this.selectedCustomer = newCustomer
      this.isNewCustomerActive = false
    },
    onCustomerCancelled: function () {
      this.isNewCustomerActive = false
    },
    clearAllCustomers () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 20,
        query: query,
        dealerId: this.selectedDealer.id,
        sortBy: 'firstName.asc',
        requiresStatistics: false,
        showRejectedCustomers: this.rejected,
        includeActiveCustomers: this.includeActiveCustomers
      }
      this.isCustomerLoading = true
      this.clearAllCustomers()
      customerService.all(searchCommand).then(response => {
        this.data = response.results || response
        this.isCustomerLoading = false
      })
    },
    doCustomerSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onCustomerSearch (query) {
      if (!query || query === '') {
        return
      }
      this.doCustomerSearch(query)
    },
    cancelLeadLookup: function () {
      this.checkForExistingLead = false
      this.noCustomerMatch = false
      this.resetFields()
    },
    skipLeadLookup: function () {
      this.checkForExistingLead = false
      this.isNewCustomerActive = true
      this.resetFields()
    },
    findByContactInfo: function () {
      if (this.mobile !== '') {
        this.findByMobile()
      } else if (this.email !== '') {
        this.findByEmail()
      }
    },
    findByEmail: function () {
      this.noCustomerMatch = false
      if (this.email !== '') {
        this.isCheckingForLead = true
        let that = this
        this.query = this.email
        customerService.findDealerPeakMatchByEmail(that.email).then((response) => {
          if (response.result === false) {
            throw response
          }
          that.processCustomerMatchResponse(response)
        }).catch((error) => {
          console.log('Exception: ' + error.message)
          this.isCheckingForLead = false
          this.noCustomerMatch = true
        })
      }
    },
    findByMobile: function () {
      this.noCustomerMatch = false
      if (this.mobile !== '') {
        this.isCheckingForLead = true
        let that = this
        this.query = this.mobile
        customerService.findDealerPeakMatchByMobile(that.unmask(that.mobile)).then((response) => {
          if (response.result === false) {
            throw response
          }
          that.processCustomerMatchResponse(response)
        }).catch((error) => {
          console.log('Exception: ' + error.message)
          this.isCheckingForLead = false
          this.noCustomerMatch = true
        })
      }
    },
    processCustomerMatchResponse: function (response) {
      if (response.total === 0) {
        this.isCheckingForLead = false
        this.noCustomerMatch = true
        return
      }
      this.customerMatches = response.results
      this.isCheckingForLead = false
      this.showCustomerMatches = true
    },
    onCustomerSelection: function (customer) {
      this.showCustomerMatches = false
      this.checkForExistingLead = false
      this.customerMatches = []
      this.selectedCustomerMatch = customer
      this.isNewCustomerActive = true
      this.resetFields()
    },
    resetFields: function () {
      this.mobile = ''
      this.email = ''
    },
    unmask: function (input) {
      if (!input || input === '') {
        return ''
      }
      return input.replace('+1', '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
    },
    syncCustomer: async function () {
      if (!this.selectedCustomer) {
        return
      }
      let model = {
        firstName: this.selectedCustomer.firstName,
        lastName: this.selectedCustomer.lastName,
        number: this.selectedCustomer.mobile,
        email: this.selectedCustomer.email,
        customerType: 'Lead',
        dealerId: this.storeDealer.id,
        dealerName: this.storeDealer.name,
        sequenceId: this.selectedCustomer.id,
        source: 'LIVE',
        crmKey: this.$store.state.user.currentUser.crmKey,
        subject: 'Customer Profile Sync',
        createLeadIfNotExist: true
      }

      let response = await dealerPeakService.syncCustomer(model)
      if (this.selectedCustomer && !this.selectedCustomer.customerLeads && response && response.data && response.data.customer && response.data.customer.customerLeads) {
        this.selectedCustomer = Object.assign(this.selectedCustomer, {customerLeads: response.data.customer.customerLeads})
      }
    },
    onExistingCustomerSaved: function (customer) {
      if (this.selectedCustomer && customer && this.selectedCustomer.fullName !== customer.fullName) {
        this.selectedCustomer.fullName = customer.fullName
      }
      this.editCustomerActive = false
    },
    onCancelEditCustomer: function () {
      this.editCustomerActive = false
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}

</script>

<style scoped>
  .new-customer-btn {
    margin-left:5px;
    height:40px;
  }

</style>
