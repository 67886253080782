<template>
  <div>
    <b-modal :active.sync="value.isAddingCost" :width="640" scroll="keep" has-modal-card>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Product</p>
        </header>
        <section class="modal-card-body">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form data-vv-scope="product-costs-form">
            <div class="box">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect
                        placeholder="Enter or select a product"
                        ref="name"
                        :showLabels="false"
                        :taggable="true"
                        @tag="onNameAdded"
                        v-model="selectedProduct"
                        :options="availableProducts"
                        data-vv-as="name"
                        data-vv-scope="product-costs-form"
                        v-validate="{'required': true}"
                        :class="{'is-danger': errors.has('product-costs-form.selectedProduct') }"
                        name="selectedProduct"
                        label="listName"
                        trackBy="id"
                        :disabled="isReadOnly"
                        :hideSelected="true">
                      </multiselect>
                    </div>
                    <span v-show="errors.has('product-costs-form.selectedProduct')" class="help is-danger"><i v-show="errors.has('product-costs-form.selectedProduct')" class="fas fa-exclamation-triangle"></i> {{ errors.first('product-costs-form.selectedProduct') }}</span>
                  </div>
                </div>
                <div class="column is-12">
                  <label class="label">Description<b-icon pack="fas" icon="" class="fa-ss" data-vv-scope="product-costs-form"></b-icon></label>
                  <div class="control">
                    <input name="description" :class="{'input': true}" type="text" v-model="value.description" :disabled="isReadOnly">
                  </div>
                </div>
                <div class="column is-6">
                  <label class="label">Cost Price<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control has-icons-left">
                    <money name="cost" data-vv-as="cost" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('product-costs-form.cost') }" type="text" placeholder="0000.00" v-model="value.cost" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                    <span v-show="errors.has('product-costs-form.cost')" class="help is-danger">
                      <i v-show="errors.has('product-costs-form.cost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('product-costs-form.cost') }}
                    </span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
                <div class="column is-6">
                  <label class="label">Selling Price<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control has-icons-left">
                    <money name="sellingPrice" data-vv-as="price" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('product-costs-form.sellingPrice') }" type="text" placeholder="0000.00" v-model="value.sellingPrice" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                    <span v-show="errors.has('product-costs-form.sellingPrice')" class="help is-danger">
                      <i v-show="errors.has('product-costs-form.sellingPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('product-costs-form.sellingPrice') }}
                    </span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
                <div class="column is-6">
                  <label class="label">Product Count<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control">
                    <input name="productCount" data-vv-as="product count" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('product-costs-form.productCount') }" type="number" placeholder="0" v-model="value.productCount" :disabled="!canEdit">
                    <span v-show="errors.has('product-costs-form.productCount')" class="help is-danger">
                      <i v-show="errors.has('product-costs-form.productCount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('product-costs-form.productCount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6">
                  <switch-selector type="is-info" label="Create F&I Service Request" v-model="value.autoCreateFinanceServiceRequest"></switch-selector>
                </div>
                <div class="column is-6" v-if="value.autoCreateFinanceServiceRequest">
                  <switch-selector type="is-info" label="Only when Booked" v-model="value.onlyWhenBooked"></switch-selector>
                </div>
                <div class="column is-12">
                  <button-list-selector name="selectedType" validateAs="product type" label="Type" :required="true" v-model="selectedType" :availableValues="availableProductTypes" scope="product-costs-form" :enabled="!isReadOnly"></button-list-selector>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onSave()" type="button" :disabled="isReadOnly">Confirm</button>
          <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import productService from '@/services/productService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import SwitchSelector from '@/components/generic/SwitchSelector'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'ProductCostCapture',
  props: {
    value: {
      type: Object,
      default: null
    },
    productCosts: {
      type: Array,
      default: () => []
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'error-display-component': ErrorDisplayComponent,
    'switch-selector': SwitchSelector
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      allNames: [],
      selectedType: null,
      selectedName: null,
      availableProducts: [],
      selectedProduct: null,
      serverErrors: [],
      serverErrorMessage: ''
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess']),
    availableProductTypes: function () {
      return this.definedTypes.productTypes.options
    },
    isEditMode: function () {
      return this.value && this.value.isEdit
    },
    canEdit: function () {
      return this.isEditMode ? this.hasFeatureAccess('product.modify.count') : true
    }
  },
  methods: {
    onNameAdded: function (data) {
      let product = {
        id: 0,
        name: data,
        listName: data
      }
      this.availableProducts.push(product)
      this.selectedProduct = product
    },
    onCancel: function () {
      this.value.isAddingCost = false
    },
    onSave: function () {
      this.$validator.validateAll('product-costs-form').then((result) => {
        if (result) {
          this.$delete(this.productCosts, this.value.index)
          this.value.name = this.selectedProduct.name
          this.value.sourceId = this.selectedProduct.id
          this.value.type = this.selectedType.name
          this.productCosts.push(this.value)
          this.value.isAddingCost = false
        }
      })
    },
    loadAsyncProductData: function (addSelectedProduct) {
      var dealerId = this.$store.state.dealer.currentDealer.id
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isLoading = true
      productService.findAllByDealerId(dealerId).then(response => {
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.availableProducts = response.results
          if (addSelectedProduct && this.selectedProduct) {
            this.availableProducts.push(this.selectedProduct)
          }
        }
      }).catch((error) => {
        this.isLoading = false
        this.availableProducts = []
        this.serverErrorMessage = error.message
      })
    },
    initInputValues: function () {
      this.selectedProduct = null
      if (this.value && this.value.type) {
        this.selectedProduct = {
          id: this.value.sourceId,
          name: this.value.name,
          listName: this.value.productListName,
          sourceId: this.value.sourceId,
          cost: this.value.cost,
          sellingPrice: this.value.sellingPrice,
          description: this.value.description,
          productCount: this.value.productCount,
          autoCreateFinanceServiceRequest: this.value.autoCreateFinanceServiceRequest,
          onlyWhenBooked: this.value.onlyWhenBooked,
          productListName: this.value.productListName,
          type: this.value.type
        }
        this.loadAsyncProductData(true)
      } else {
        this.loadAsyncProductData(false)
      }
      this.selectedType = this.availableProductTypes.find((x) => x.name === this.value.type)
    }
  },
  watch: {
    productCosts: function (newValue, oldValue) {
      this.$emit('input', {productCosts: newValue})
    },
    value: function (newValue, oldValue) {
      this.initInputValues()
    },
    selectedProduct: function (newValue, oldValue) {
      if (newValue) {
        this.value.sourceId = newValue.id
        this.value.productListName = newValue.listName
        this.value.cost = newValue.cost
        this.value.sellingPrice = newValue.sellingPrice
        this.value.description = newValue.description
        this.value.productCount = newValue.productCount
        this.value.autoCreateFinanceServiceRequest = newValue.autoCreateFinanceServiceRequest
        this.value.onlyWhenBooked = newValue.onlyWhenBooked
        this.selectedType = this.availableProductTypes.find((x) => x.name === newValue.type)
      }
    }
  },
  mounted: function () {
    this.initInputValues()
  }
}
</script>

<style scoped>
  .box {
      background-color: #f6f8fa;
    }
</style>
