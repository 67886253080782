<template>
  <span v-if="!isNewNoteActive">
    <span :id="instanceId" :name="instanceId" class="icon is-inline-block has-text-centered" :class="noteColorClass" v-tooltip="applicableTitle" @click.stop.prevent="onAddNewNote()" v-if="isListMode">
      <i v-if="isHotNote" :data-type="getDataType" class="fal fa-comment fa-lg"/>
      <i v-else class="fal fa-comment fa-lg"/>
    </span>
    <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button class="button" @click.stop.prevent="onAddNewNote()" v-tooltip="applicableTitle">
          <b-icon pack="fal" icon="comment" custom-size="fa-lg"></b-icon>
        </button>
        <span v-if="label.length > 0">{{label}}</span>
    </div>
  </span>
  <portal to="global-modal-portal" v-else>
    <b-modal :active.sync="isNewNoteActive" :width="640" scroll="keep" :has-modal-card="true" v-if="deal">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Adding new note for deal {{deal.code}}</p>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container">
            <div class="columns is-multiline">
              <div class="column is-6">
                <div class="field">
                  <label class="label">
                    Customer
                  </label>
                  <div class="field">
                    {{ deal.customerName }} {{ deal.supplierName }}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">
                    Vehicle
                  </label>
                  <div class="field">
                    {{buildVehicleInfo}}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <label class="label">
                  Last Finance Entry
                </label>
                <div class="field">
                  {{getLastFinanceNote}}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">
                  Last Sale Entry
                </label>
                <div class="field">
                  {{getLastSaleNote }}
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">
                    Add Entry
                  </label>
                  <div class="control">
                    <note-capture v-model="inlineNotes" :showDealNoteTypeSelector="true" label="Content" :entityId="this.deal.id"></note-capture>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <note-timeline-view v-model="dealNotes" entity="deal" :showDeleteModal="false"></note-timeline-view>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="saveNote()">Save</button>
          <button class="button is-danger" @click="isNewNoteActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>

import dealService from '@/services/dealService'
import NoteCapture from '@/components/generic/NoteCapture'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import eventBus from '@/eventBus'

export default {
  name: 'DealNoteCapture',
  components: {
    'note-capture': NoteCapture,
    'error-display-component': ErrorDisplayComponent,
    'note-timeline-view': NoteTimelineView
  },
  props: {
    deal: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'is-width-600'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isNewNoteActive: false,
      inlineNotes: [],
      isSaving: false,
      instanceId: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      dealNotes: []
    }
  },
  methods: {
    onAddNewNote: function () {
      this.inlineNotes = []
      this.isNewNoteActive = true
      this.fetchNotes()
    },
    saveNote: function () {
      if (this.inlineNotes.length > 0) {
        let inlineNote = this.inlineNotes[0]
        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true
        dealService.addNote({dealId: this.deal.id, ...inlineNote, type: inlineNote.dealNoteType}).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            if (inlineNote.dealNoteType === 'Sale') {
              this.deal.lastSaleNote = inlineNote.note
              this.deal.isHotSaleNote = inlineNote.isHotNote
            } else {
              this.deal.lastFinanceNote = inlineNote.note
              this.deal.isHotFinanceNote = inlineNote.isHotNote
            }
            if (this.deal.notes) {
              this.deal.notes = response.deal.notes
            }
            this.inlineNotes = []
            this.isNewNoteActive = false
            eventBus.$emit('reload-deal-list')
            eventBus.$emit('reload-deal')
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    fetchNotes: function () {
      dealService.fetchNotesByDealId(this.deal.id).then(response => {
        this.dealNotes = response.results
      }).catch((error) => {
        this.serverErrorMessage = error.message
      })
    }
  },
  computed: {
    buildVehicleInfo: function () {
      if (this.deal.vehicleInfo) {
        return this.deal.stockNo + ' - ' + this.deal.makeName + ' ' + this.deal.vehicleInfo
      }

      if (this.deal.items && this.deal.items[0]) {
        return this.deal.items[0].forInventory.listName
      }
    },
    getLastFinanceNote: function () {
      if (this.deal.lastFinanceNote) {
        return this.deal.lastFinanceNote
      }
      if (this.deal.notes) {
        let financeNotes = this.deal.notes.filter((x) => x.type === 'Finance')
        if (financeNotes.length > 0) {
          return financeNotes[0].note
        }
      }
      return ''
    },
    getLastSaleNote: function () {
      if (this.deal.lastSaleNote) {
        return this.deal.lastSaleNote
      }
      if (this.deal.notes) {
        let saleNotes = this.deal.notes.filter((x) => x.type === 'Sale')
        if (saleNotes.length > 0) {
          return saleNotes[0].note
        }
      }
      return ''
    },
    isHotNote: function () {
      return this.deal && (this.deal.isHotSaleNote || this.deal.isHotFinanceNote)
    },
    applicableTitle: function () {
      let defaultNote = 'Click to add a new note to this deal'
      if (this.getLastSaleNote !== '' && this.getLastFinanceNote !== '') {
        return 'Sale: ' + this.getLastSaleNote + '\r\n' + 'Finance: ' + this.getLastFinanceNote + '\r\n\r\n' + defaultNote
      } else if (this.getLastSaleNote !== '' && this.getLastFinanceNote === '') {
        return 'Sale: ' + this.getLastSaleNote + '\r\n\r\n' + defaultNote
      } else if (this.getLastSaleNote === '' && this.getLastFinanceNote !== '') {
        return 'Finance: ' + this.getLastFinanceNote + '\r\n\r\n' + defaultNote
      } else {
        return defaultNote
      }
    },
    noteColorClass: function () {
      let baseColor = 'has-text-black'
      if (this.getLastSaleNote !== '' || this.getLastFinanceNote !== '') {
        baseColor = 'has-text-primary'
      }
      if (this.isHotNote) {
        baseColor = 'has-text-danger'
      }

      return baseColor
    },
    getDataType: function () {
      return this.isHotNote ? '\u0021' : ''
    }
  },
  watch: {
  },
  mounted () {
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }
</style>
