import axios from 'axios'
import baseService from './baseService'
import serviceRequestdata from './data/serviceRequest'
import ServiceRequest from '@/models/ServiceRequest'

const serviceRequestService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('service-request-load-all')
      if (baseService.isTestMode) {
        resolve(serviceRequestdata)
      } else {
        axios
          .post('/serviceRequest/list', searchCriteria, {...baseService.defaultConfig, requestId: 'inventory-load-all'})
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = ServiceRequest.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('service request all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (serviceRequestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceRequestdata.all.find((x) => x.id === serviceRequestId))
      } else {
        axios
          .post('/serviceRequest/get?id=' + serviceRequestId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (serviceRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var serviceRequest = {
          id: 999,
          serviceRequestdata: serviceRequestModel.name,
          status: 'Active'
        }
        serviceRequestdata.all.push(serviceRequest)
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/save', serviceRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (serviceRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequestModel.id)
        if (serviceRequest) {
          serviceRequest.name = serviceRequestModel.name
          serviceRequest.status = serviceRequestModel.status
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/update', serviceRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateLineStatusType (serviceRequestId, serviceRequestLineId, statusId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequestId)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/updateLineStatusType', {
            serviceRequestId: serviceRequestId,
            serviceRequestLineId: serviceRequestLineId,
            statusId: statusId
          }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateStatusType (serviceRequestId, statusId, serviceMileage) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequestId)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/updateStatusType', {
            serviceRequestId: serviceRequestId,
            statusId: statusId,
            serviceMileage: serviceMileage
          }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateRoNumber (serviceRequest) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        let serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequest.id)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/updateRoNumber', {
            serviceRequestId: serviceRequest.id,
            roNumber: serviceRequest.roNumber
          }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleExtendedHold (serviceRequest) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        let serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequest.id)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/toggleExtendedHold', {
            id: serviceRequest.id,
            extendedHold: serviceRequest.extendedHold,
            extendedHoldReason: serviceRequest.extendedHoldReason,
            extendedHoldExpirationDate: serviceRequest.extendedHoldExpirationDate,
            extendedHoldResetDate: serviceRequest.extendedHoldResetDate
          }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePriority (serviceRequest, newPriorityValue) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        let serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequest.id)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/updatePriority', {
            serviceRequestId: serviceRequest.id,
            priorityValue: newPriorityValue
          }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (serviceRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        serviceRequestModel.forEach(element => {
          serviceRequestdata.all.remove((x) => x.id === element.id)
        })
        resolve(serviceRequestdata.all)
      } else {
        var ids = []
        serviceRequestModel.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/servicerequest/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (serviceRequestNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequestNoteModel.inventoryId)
        if (serviceRequest) {
          serviceRequest.notes.push(serviceRequestNoteModel)
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/notes', serviceRequestNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequest/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequest/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequest/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequest/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByServiceRequestId (serviceRequestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceRequestdata.all)
      } else {
        axios
          .get('/servicerequest/servicerequestnotes?id=' + serviceRequestId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  previouslyDeclinedWorkItemsByInventory (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceRequestdata.all)
      } else {
        axios
          .get('/servicerequest/previouslyDeclinedWorkItemsByInventory?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  approveRequestLineItems (serviceRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        serviceRequestModel.forEach(element => {
          serviceRequestdata.all.remove((x) => x.id === element.id)
        })
        resolve(serviceRequestdata.all)
      } else {
        axios
          .post('/servicerequest/approveRequestLineItems', serviceRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateWorkItemsAndStatus (serviceRequestId, roNumber, proposedServiceRequestStatus, proposedWorkItemChanges) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        let serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequest.id)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        let postModel = {
          id: serviceRequestId,
          roNumber: roNumber,
          serviceRequestStatusId: proposedServiceRequestStatus,
          workItems: proposedWorkItemChanges
        }
        axios
          .post('/servicerequest/updateServiceRequestWorkItemsAndStatus', postModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  serviceRequestComplete (serviceRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        let serviceRequest = serviceRequestdata.all.find((x) => x.id === serviceRequest.id)
        if (serviceRequest) {
        }
        resolve(serviceRequest)
      } else {
        axios
          .post('/servicerequest/serviceRequestComplete', serviceRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceRequestdata)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/servicerequest/list',
          method: 'POST',
          responseType: 'blob',
          data: searchCriteria,
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  syncAttachments (attachmentsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceRequestdata)
      } else {
        axios
          .post('/servicerequest/syncServiceRequestAttachments', attachmentsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  resyncMyKaarma (type, id) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceRequestdata)
      } else {
        axios
          .get('/servicerequest/resyncMyKaarma?type=' + type + '&id=' + id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  verifyDealProductDelete (dealId, dealItemProductId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({canBeDeleted: false})
      } else {
        axios
          .get('/servicerequest/verifyDealProductDelete?dealId=' + dealId + '&dealItemProductId=' + dealItemProductId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default serviceRequestService
