import axios from 'axios'
import baseService from './baseService'
import locationData from './data/location'
import Location from '@/models/Location'

const locationService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(locationData)
      } else {
        axios
          .post('/location/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Location.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allLocations (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(locationData)
      } else {
        axios
          .get('/location/list?', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (locationId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(locationData.allLocations.find((x) => x.id === locationId))
      } else {
        axios
          .post('/location/get?id=' + locationId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (locationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var location = locationData.allLocations.find((x) => x.id === locationModel.id)
        if (location) {
          location.name = locationModel.name
          location.status = locationModel.status
        }
        resolve(location)
      } else {
        axios
          .post('/location/update', locationModel, baseService.defaultConfig)
          .then(reponse => {
            resolve(reponse.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (locationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var location = {
          id: 999,
          name: locationModel.name,
          status: 'Active'
        }
        locationModel.alllocations.push(location)
        resolve(location)
      } else {
        axios
          .post('/location/save', locationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (locationModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        locationModels.forEach(element => {
          locationData.alllocations.remove((x) => x.id === element.id)
        })
        resolve(locationData.alllocations)
      } else {
        var ids = []
        locationModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/location/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default locationService
