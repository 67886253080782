<template>
  <div id="qviewApp" ref="qviewApp" class="quickview" v-keydown-outside="onKeyDown" v-mousedown-outside="onMouseDown">
    <header class="quickview-header" :class="getQuickViewHeaderClasses">
      <div class="is-flex" style="width: 100%;">
        <div class="is-inline-block" style="width: 100%;">
          <portal-target name="qv-header">
            <p class="title">Quick View</p>
          </portal-target>
        </div>
        <div style="width: 75px; display: flex; align-items: center">
          <span title="Click to expand quickview size" class="btn icon has-text-white has-background-grey-light is-size-7 quickview-top-button" @click="onQuickviewWidthToggle()">
            <i class="fal fa-arrows-alt-h"></i>
          </span>
          <span title="refresh quickview" class="btn icon has-text-white has-background-grey-light is-size-7 quickview-top-button" @click="onQuickviewRefresh()">
            <i class="far fa-redo-alt"></i>
          </span>
          <span title="close quickview" class="delete is-medium has-background-grey-light" data-dismiss="quickview" @click="closeGlobalQuickView"></span>
        </div>
      </div>
    </header>
    <div class="quickview-body" :class="getQuickViewBodyClasses">
      <div class="quickview-block">
        <component v-if="quickViewComponent" :is="quickViewComponent.component" v-bind="quickViewComponent.props" v-on:close="closeGlobalQuickView" v-on:dirty-state-change="onDirtyStateChanged"></component>
      </div>
    </div>
    <footer class="quickview-footer" :class="getQuickViewFooterClasses">
      <div class="columns" style="width:100%">
        <div class="column is-paddingless">
          <portal-target name="qv-footer-toolbar-left" class="actions" slim/>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import util from '@/utils'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')

export default {
  name: 'GlobalQuickView',
  inject: {
    $validator: '$validator'
  },
  data () {
    return {
      quickViewComponent: null,
      isDirty: false,
      expansionIndex: 0
    }
  },
  computed: {
    getQuickViewHeaderClasses: function () {
      var result = ''
      if (this.quickViewComponent && this.quickViewComponent.qvHeaderClass) {
        result = this.quickViewComponent.qvHeaderClass
      }
      let classToAttach = {}
      if (result !== '') {
        classToAttach[result] = true
        return classToAttach
      }
    },
    getQuickViewBodyClasses: function () {
      var result = ''
      if (this.quickViewComponent && this.quickViewComponent.qvBodyClass) {
        result = this.quickViewComponent.qvBodyClass
      }
      let classToAttach = {}
      if (result !== '') {
        classToAttach[result] = true
        return classToAttach
      }
    },
    getQuickViewFooterClasses: function () {
      var result = ''
      if (this.quickViewComponent && this.quickViewComponent.qvFooterClass) {
        result = this.quickViewComponent.qvFooterClass
      }
      let classToAttach = {}
      if (result !== '') {
        classToAttach[result] = true
        return classToAttach
      }
    }
  },
  methods: {
    ...mapActions(['setToolBarState']),
    closeGlobalQuickView: function () {
      if (this.quickViewComponent) {
        this.quickViewComponent = null
        util.closeQuickView()
        document.body.classList.toggle('is-noscroll', false)
        this.setToolBarState(true)

        // always set z-index back to original value, it has a higher value when its needed to be above the modal background in some instances
        let quickView = document.getElementById('qviewApp')
        quickView.classList.remove('z-index-20k2')
        quickView.classList.add('z-index-9k')
      }
    },
    onMouseDown: function (evt, el) {

    },
    onKeyDown: function (evt, el) {
      evt = evt || window.event
      if (evt.key === 'Escape') {
        // do not close the quickview when Progression/Booking widget is open
        let statusProgressionWizardModal = document.getElementById('status-progression-wizard-modal')
        let bookDealCaptureModal = document.getElementById('book-deal-capture-modal')
        if (statusProgressionWizardModal || bookDealCaptureModal) return

        this.closeGlobalQuickView()
      }
    },
    onDirtyStateChanged: function (newState) {
      this.isDirty = newState
    },
    onQuickviewWidthToggle: function () {
      this.expansionIndex++
      if (this.expansionIndex > 3) {
        this.expansionIndex = 0
      }
      let quickView = document.getElementById('qviewApp')
      if (this.expansionIndex === 0) {
        quickView.classList.remove('quickview-expanded-1', 'quickview-expanded-2', 'quickview-expanded-3', 'quickview-expanded-4')
        return
      }
      if (this.expansionIndex > 1) {
        quickView.classList.remove('quickview-expanded' + (this.expansionIndex - 1))
      }
      quickView.classList.add('quickview-expanded-' + this.expansionIndex)
    },
    onQuickviewRefresh: function () {
      eventBus.$emit('quickview-refresh')
    }
  },
  mounted: function () {
    eventBus.$on('global-quick-view', data => {
      var quickView = document.getElementById('qviewApp')
      quickView.classList.add('is-active')
      this.quickViewComponent = data
      document.body.classList.toggle('is-noscroll', true)
      this.setToolBarState(false)
      this.isDirty = false
    })
    eventBus.$on('close-global-quick-view', data => {
      this.closeGlobalQuickView()
    })
    this.expansionIndex = window.localStorage.getItem('config-quickview-size-index') ? +window.localStorage.getItem('config-quickview-size-index') : 0
    if (this.expansionIndex > 0) {
      let quickView = document.getElementById('qviewApp')
      if (quickView) {
        quickView.classList.add('quickview-expanded-' + this.expansionIndex)
      }
    }
  },
  updated: function () {

  },
  destroyed: function () {

  },
  watch: {
    expansionIndex: function (newVal) {
      window.localStorage.setItem('config-quickview-size-index', newVal)
    }
  }
}
</script>

<style scoped>

</style>
