export default {
  all: [
    {
      id: 1,
      dealerId: 1,
      status: 'Active',
      subscriberId: 1,
      partNumber: '0000001',
      listName: '0000001 - Brake pads',
      description: 'Brake pads',
      unitCost: 1500.00,
      quantityInStock: 36,
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000'
    },
    {
      id: 2,
      dealerId: 1,
      status: 'Active',
      subscriberId: 1,
      partNumber: '0000001',
      listName: '0000001 - CLUTCH PLATE',
      description: 'CLUTCH PLATE',
      unitCost: 2500.00,
      quantityInStock: 40,
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000'
    }
  ]
}
