import axios from 'axios'
import baseService from './baseService'
import paymentTrackingData from './data/paymentTracking'
import PaymentTracking from '@/models/PaymentTracking'

const appService = {
  allPaymentTracking (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(paymentTrackingData)
      } else {
        axios
          .post('/paymentTracking/List', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = PaymentTracking.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateStatus (paymentTrackingId, statusType) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/UpdateStatus?paymentTrackingId=' + paymentTrackingId + '&statusType=' + statusType, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (paymentTrackingId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(paymentTrackingData.all.find((x) => x.id === paymentTrackingId))
      } else {
        axios
          .post('/paymentTracking/get?id=' + paymentTrackingId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  writeOff (paymentTrackingId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/WriteOff?paymentTrackingId=' + paymentTrackingId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addPaymentEvent (paymentEvent) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/AddPaymentEvent', paymentEvent, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  create (paymentTracking) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/save', paymentTracking, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  refundDeposit (refundDeposit) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/refundDeposit', refundDeposit, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCancellationType (paymentTrackingLite) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/updatecancelledtype', paymentTrackingLite, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  confirmPaymentProcessedInCdk (confirmPaymentProcessedInCdkDetails) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/confirmpaymentprocessedincdk', confirmPaymentProcessedInCdkDetails, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  processBulkPayments (paymentEvents) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/processbulkpayments', paymentEvents, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (paymentTrackingNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var paymentTracking = paymentTrackingData.all.find((x) => x.id === paymentTrackingNoteModel.paymentTrackingId)
        if (paymentTracking) {
          paymentTracking.notes.push(paymentTrackingNoteModel)
        }
        resolve(paymentTracking)
      } else {
        axios
          .post('/paymenttracking/addNote', paymentTrackingNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/paymenttracking/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/paymenttracking/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/paymenttracking/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/paymenttracking/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByPaymentTrackingId (paymentTrackingId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(paymentTrackingData.all)
      } else {
        axios
          .get('/paymenttracking/paymenttrackingnotes?id=' + paymentTrackingId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getByRoNumber (roNumber, dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(paymentTrackingData.all.find((x) => x.roNumber === roNumber))
      } else {
        axios
          .post('/paymentTracking/getByRoNumber?roNumber=' + roNumber + '&dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  resendPaymentTrackingWireRequest (paymentTrackingId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({result: true})
      } else {
        axios
          .get('/paymenttracking/resendPaymentTrackingWireRequest?id=' + paymentTrackingId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  checkMatchingPaymentRequests (paymentTrackingModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(paymentTrackingData)
      } else {
        axios
          .post('/paymentTracking/checkMatchingPaymentRequests', paymentTrackingModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  syncAttachments (attachmentsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(paymentTrackingData)
      } else {
        axios
          .post('/paymentTracking/syncPaymentTrackingAttachments', attachmentsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  sendCustomerUrlPaymentNotification (paymentTrackingUrlNotificationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/paymentTracking/sendCustomerUrlPaymentNotification', paymentTrackingUrlNotificationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService
