import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import requiresAuth from './route_guards'
import inventoryRoutes from './inventory'
import conversationRoutes from './conversations'
import userRoutes from './users'
import vehicleMakes from './vehicleMakes'
import designationRoutes from './designations'
import Dashboard from '@/components/dashboard/Dashboard'
import Examples from '@/components/Examples'
import dealerRoutes from './dealers'
import insurerRoutes from './insurers'
import locationRoutes from './locations'
import departmentRoutes from './departments'
import leadSources from './leadSources'
import customerRoutes from './customers'
import productRoutes from './products'
import subscriberRoute from './subscriber'
import dealRoutes from './deal'
import supplierRoutes from './suppliers'
import vendorRoutes from './vendors'
import roleRoutes from './roles'
import bankRoutes from './banks'
import NotFoundComponent from '@/components/generic/404'
import serviceRequestRoutes from './serviceRequests'
import partsRoutes from './parts'
import policyRoutes from './policyExpenses'
import binRoutes from './accessoryBin'
import loanerEventRoutes from './loanerEvents'
import EChart from '@/components/echarts'
import eventBus from '@/eventBus'
import Redirect from '@/components/generic/Redirect'
import teamRoutes from './teams'
import signRoutes from './signature'
import accountRoutes from './accounting'
import toolRoutes from './tools'
import tagRoutes from './tags'
import Login from '@/components/auth/Login'
import ResetPassword from '@/components/auth/ResetPassword'
import RemoteLanding from '@/components/external/RemoteLanding'
import TabletLogin from '@/components/auth/TabletLogin'
import wholesaleReceivableRoutes from './wholesaleReceivable'

Vue.use(Router)

var allRoutes = [
  { path: '*', component: NotFoundComponent },
  {
    path: '/',
    alias: '/index.html',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        name: 'Notifications',
        path: 'notifications',
        meta: {
          tabIndex: 3
        }
      }
    ],
    beforeEnter: requiresAuth
  },
  {
    name: 'Redirect',
    path: '/redir/:destination',
    component: Redirect,
    props: true
  },
  {
    path: '/examples',
    name: 'Examples',
    component: Examples,
    beforeEnter: requiresAuth
  },
  {
    path: '/echarts',
    name: 'ECharts',
    component: EChart,
    beforeEnter: requiresAuth
  },
  {
    name: 'Login',
    path: '/auth/login',
    component: Login,
    props: true
  },
  {
    name: 'ResetPassword',
    path: '/auth/resetpassword',
    component: ResetPassword,
    props: true
  },
  {
    name: 'RemoteLanding',
    path: '/external/remotelanding',
    component: RemoteLanding,
    beforeEnter: requiresAuth
  },
  {
    name: 'TabletLogin',
    path: '/auth/tabletlogin',
    component: TabletLogin,
    props: true
  },
  ...inventoryRoutes,
  ...conversationRoutes,
  ...teamRoutes,
  ...userRoutes,
  ...vehicleMakes,
  ...designationRoutes,
  ...dealerRoutes,
  ...insurerRoutes,
  ...locationRoutes,
  ...departmentRoutes,
  ...leadSources,
  ...customerRoutes,
  ...productRoutes,
  ...subscriberRoute,
  ...dealRoutes,
  ...supplierRoutes,
  ...vendorRoutes,
  ...roleRoutes,
  ...bankRoutes,
  ...serviceRequestRoutes,
  ...partsRoutes,
  ...policyRoutes,
  ...binRoutes,
  ...loanerEventRoutes,
  ...signRoutes,
  ...accountRoutes,
  ...tagRoutes,
  ...toolRoutes,
  ...wholesaleReceivableRoutes
]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.meta && to.meta.quickViewComponent) {
        return {}
      }

      if (from.meta && from.meta.quickViewComponent) {
        return { x: 0, y: 0 }
      }
      return { x: 0, y: 0 }
    }
  },
  routes: allRoutes
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
    if (from.name === null && to.name.toLocaleLowerCase().indexOf('quickview') >= 0) {
      let newRoute = 'List' + to.name.replace('QuickView', '')
      next({name: newRoute})
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.meta && to.meta.quickViewComponent) {
    if (from && from.matched[0]) {
      to.matched[0].components = from.matched[0].components
    }
    eventBus.$emit('global-quick-view', to.meta.quickViewComponent)
  } else {
    eventBus.$emit('close-global-quick-view', {})
  }

  if (!(to.meta && to.meta.quickViewComponent) && !(from.meta && from.meta.quickViewComponent) && !(to.name.toLowerCase().startsWith('list'))) {
    var markerElement = document.getElementById('scroll-marker')
    if (markerElement) {
      document.getElementById('scroll-marker').scrollIntoView()
    }
  }
  NProgress.done()
})

export default router
