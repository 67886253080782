<template>
  <portal to="global-modal-portal" v-if="isTradeAllowanceApproveActive" :disabled="!usePortal">
    <b-modal :key="instanceId" :active.sync="isTradeAllowanceApproveActive" scroll="keep" :has-modal-card="true" v-if="currentInventory" :canCancel="false" id="trade-allowance-approve-modal">
      <div class="modal-card" style="min-width: max-content; max-height: 90vh; overflow-y: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Trade Allowance / Vehicle Inspection</p>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container" @submit.prevent="validateBeforeSubmit">
            <div class="columns is-multiline">
              <div class="column is-12">
              <!--
                <lease-renewal-capture
                  v-for="(tradeItem, index) in tradeDealItems"
                  :key="index"
                  v-model="tradeDealItems[index]"
                  :showPrice="true"
                  :showStockNoLabel="true"
                  :isReadOnly="false"
                  :isTitleStatusRequiredOveride="false"
                  :partialMode="true"
                  :useValidator="thisValidator">
                </lease-renewal-capture>
              -->
                <lease-renewal-capture
                  v-if="tradeItem"
                  v-model="tradeItem"
                  :showPrice="true"
                  :showStockNoLabel="true"
                  :isReadOnly="false"
                  :isTitleStatusRequiredOveride="false"
                  :partialMode="true"
                  :useValidator="thisValidator">
                </lease-renewal-capture>
              </div>
              <div class="column">
                <switch-selector type="is-info" label="Has vehicle been visually inspected?" v-model="examined"></switch-selector>
                <label class="label-frontgp is-pulled-left" v-tooltip="'Examined By/Examined At'" v-if="examined && tradeInventoryItem.ppaExaminedByName !== ''">{{ppaInfo}}</label>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="columns" style="width: 100%;">
            <div class="column is-12">
              <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="validateBeforeSubmit()" :disabled="!examined">Save</button>
              <button class="button is-danger" @click="onCancelled()">Cancel</button>
            </div>
          </div>
        </footer>
        <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
      </div>
    </b-modal>
  </portal>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import dealService from '@/services/dealService'
import LeaseRenewalCapture from '@/components/generic/sales/LeaseRenewalCapture'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import SwitchSelector from '@/components/generic/SwitchSelector'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'TradeAllowanceApproveCapture',
  mixins: [utilitiesMixin, formattingMixin, validateBeforeSubmitMixin],
  components: {
    'lease-renewal-capture': LeaseRenewalCapture,
    'error-display-component': ErrorDisplayComponent,
    'switch-selector': SwitchSelector
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      isLoading: false,
      isTradeAllowanceApproveActive: false,
      currentDeal: null,
      examined: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    dealId: function () {
      return this.currentInventory && this.currentInventory.dealId ? this.currentInventory.dealId : 0
    },
    tradeDealItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Trade') : []
    },
    tradeAllowanceApproved: function () {
      return this.tradeDealItems[0].tradeAllowanceApproved
    },
    tradeItem: function () {
      // return this.tradeDealItems ? this.tradeDealItems[0] : null
      if (this.tradeDealItems.length > 0) {
        let foundTradeItem = this.tradeDealItems.find(x =>
          x.forInventory !== null &&
          x.forInventory !== undefined &&
          x.forInventory.id === this.currentInventory.id)

        if (foundTradeItem) {
          return foundTradeItem
        }
      }

      return null
    },
    tradeInventoryItem: function () {
      return this.tradeItem && this.tradeItem.forInventory ? this.tradeItem.forInventory : null
    },
    ppaInfo: function () {
      return this.tradeInventoryItem && (this.tradeInventoryItem.ppaExaminedByName !== '' && this.tradeInventoryItem.ppaExaminedAt !== null) ? this.tradeInventoryItem.ppaExaminedByName + ' - ' + this.$options.filters.formatDateOnlyShort(this.tradeInventoryItem.ppaExaminedAt) : ''
    }
  },
  methods: {
    onCancelled: function () {
      this.isTradeAllowanceApproveActive = false
      this.currentInventory.actionCancelled = true
      this.$emit('trade-allowance-complete', this.currentInventory)
    },
    onUpdateTradeAllowance: function () {
      this.loadDeal()
      this.isTradeAllowanceApproveActive = true
    },
    onSave: function () {
      // this.currentDeal.items.filter((x) => x.dealItemType === 'Trade')[0].forInventory.ppaExamined = this.examined

      let foundTradeItem = this.currentDeal.items.find(x =>
        x.forInventory !== null &&
        x.forInventory !== undefined &&
        x.forInventory.id === this.currentInventory.id)

      if (foundTradeItem) {
        foundTradeItem.forInventory.ppaExamined = this.examined
      }

      let dealModel = {
        ...this.currentDeal
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      dealService.update(dealModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.isTradeAllowanceApproveActive = false
          this.currentInventory.actionCancelled = false
          this.$emit('trade-allowance-complete', this.currentInventory)
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    loadDeal () {
      if (this.dealId !== 0) {
        this.serverErrorMessage = ''
        this.isLoading = true
        dealService.single(this.dealId).then(response => {
          this.currentDeal = response.deal
          this.examined = this.tradeInventoryItem.ppaExamined
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          this.serverErrorMessage = error.message
        })
      }
    }
  },
  mounted: function () {
    if (this.showDialog === true) {
      this.onUpdateTradeAllowance()
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  #trade-allowance-approve-modal div.animation-content {
    width: 100% !important;
  }

  #trade-allowance-approve-modal section.modal-card-body {
    max-height: 400px !important;
    min-height: 400px !important;
    height: 300px !important;
    padding: 20px !important;
  }

  #trade-allowance-approve-modal div.modal-card {
    overflow: auto;
    min-width: 500px !important;
    max-width: 500px !important;
    width: 500px !important;
  }

</style>
