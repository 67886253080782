<template>
  <section>
    <form data-vv-scope="service-request-line" @submit.prevent="validateBeforeSubmit" class="view-container" @change="clearErrors">
      <div class="columns is-mobile is-multiline is-marginless has-background-light mt-10" style="margin-bottom: 10px !important;">
        <div class="column is-10">
          <div class="columns is-multiline">
            <div class="column is-11">
              <button-list-selector
                validateAs="status"
                label="Status"
                :required="true"
                scope="service-request-line"
                v-model="selectedServiceRequestLineStatus"
                :disabled="!allowedServiceRequestLineStatusValues"
                :activeValues="allowedServiceRequestLineStatusValues"
                :availableValues="this.definedTypes.serviceRequestLineStatusType.options"
                :enabled="specialModifyRights || !inspectionCompleted"></button-list-selector>
            </div>

            <div class="column is-1">
              <p><span v-if="currentServiceRequestLine && currentServiceRequestLine.isDefaultItem" class="tag is-purple is-pulled-right" title="This is a default item added from inspection definitions">Inspection Default</span></p>
            </div>

            <div class="column is-12" style="padding-right: 24px;padding-left: 24px;" v-if="currentServiceRequestLine.approvedAt">
              <div class="columns is-multiline has-background-success">
                <div class="column is-12"></div>
                <div class="column is-4 no-bottom-padding no-top-padding"><label class="label" style="color:#fff !important;">Approved By</label></div>
                <div class="column is-4 no-bottom-padding no-top-padding"><label class="label" style="color:#fff !important;">Approved At</label></div>
                <div class="column is-4 no-bottom-padding no-top-padding"><label class="label" style="color:#fff !important;">Approved Total</label></div>
                <div class="column is-4 no-top-padding field" style="color:#fff !important;">{{ currentServiceRequestLine.approvedByFullName }}</div>
                <div class="column is-4 no-top-padding field" style="color:#fff !important;">{{ currentServiceRequestLine.approvedAt | formatDateNonMilitary }}</div>
                <div class="column is-4 no-top-padding field" style="color:#fff !important;">{{ currentServiceRequestLine.approvedTotal | currency }}</div>
              </div>
            </div>

            <div class="column is-6">
              <ro-service-opcode-selector
                label="Op Code"
                :required="false"
                v-model="selectedRoServiceOpCode"
                scope="service-request-line"
                :selectedDealer="currentDealer"
                :allowNew="true"
                :allowEdit="true"
                :isDisabled="!specialModifyRights || isMyKaarmaSynced"
                validateAs="opcode for line item">
              </ro-service-opcode-selector>
            </div>

            <div class="column is-6" v-if="selectedRoServiceOpCode">
              <label class="label">Op Code Defined Settings</label>
              <div class="is-flex" style="justify-content: space-between">
                <span class="help has-text-link">Flat Sell Rate - {{ selectedRoServiceOpCode.flatSellRate || 'n/a' }}</span>
                <span class="help has-text-link">Flat Hours - {{ selectedRoServiceOpCode.flatHours || 'n/a' }}</span>
                <span class="help has-text-link">Estimated Duration - {{ selectedRoServiceOpCode.estimatedDuration || 'n/a' }}</span>
              </div>
            </div>

            <div class="column is-4">
              <button-list-selector
                label="Labor Formulae"
                scope="work-item"
                :required="false"
                buttonSize="medium"
                v-model="selectedLaborFormula"
                :availableValues="allowedLaborFormulaeValues"
                :enabled="specialModifyRights && !isMyKaarmaSynced">
              </button-list-selector>
            </div>

            <div class="column is-2">
              <switch-selector type="is-info" label="Auto Calc. Costs" v-model="autoCalculateTotalCosts"></switch-selector>
            </div>

            <div class="column is-6">
              <technician-selector
                label="Assigned Technician"
                scope="work-item"
                :required="isTechnicianRequired"
                v-model="selectedTechnician"
                :selectedDealer="currentDealer"
                :isDisabled="!specialModifyRights || isMyKaarmaSynced"
                :allowNew="false"
                validateAs="technician for line item">
              </technician-selector>
            </div>

            <div class="column is-12 ">
              <label class="label">Work Description<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control">
                <textarea
                  placeholder="Enter a detailed description of the work required"
                  name="workRequired"
                  v-validate="'required'"
                  data-vv-as="work required"
                  :class="{'textarea': true, 'is-danger': errors.has('service-request-line.workRequired') }"
                  rows="4"
                  v-model="currentServiceRequestLine.workRequired"
                  :disabled="inspectionCompleted || !specialModifyRights || isMyKaarmaSynced">
                </textarea>
                <span v-show="errors.has('service-request-line.workRequired')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.workRequired')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.workRequired') }}
                </span>
              </div>
            </div>

            <div class="column is-12" v-if="currentServiceRequestLine.description">
              <label class="label">Instructions/Recommendation</label>
              <textarea class="textarea has-background-success white-text" width="100%" rows="5" readonly v-model="currentServiceRequestLine.description"></textarea>
            </div>

            <div class="column is-4">
              <ro-service-labor-type-selector
                label="Labor Type"
                :required="false"
                v-model="selectedRoServiceLaborType"
                scope="service-request-line"
                :selectedDealer="currentDealer"
                :allowNew="true"
                :allowEdit="true"
                :isDisabled="!specialModifyRights || isMyKaarmaSynced"
                validateAs="labor type for line item">
              </ro-service-labor-type-selector>
            </div>

            <div class="column is-4">
              <label class="label">Work Rate<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control has-icons-left">
                <money name="workRate" data-vv-as="work rate" v-validate="'required'" class="is-width-220" :class="{'input': true, 'is-danger': errors.has('service-request-line.workRate')}" type="text" placeholder="0000.00" v-model="currentServiceRequestLine.workRate" v-bind="$globalMoneyFormat" disabled></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
              <span v-show="errors.has('service-request-line.workRate')" class="help is-danger"><i v-show="errors.has('service-request-line.workRate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.workRate') }}</span>
            </div>

            <div class="column is-4" key="totalHours">
              <label class="label">Total Hours<b-icon pack="fas" icon="star" class="fa-ss" v-if="isTotalHoursRequired"></b-icon></label>
              <div class="control">
                <input :name="'totalHours'" class="is-width-220" data-vv-as="total hours" v-validate="isTotalHoursRequired ? 'required|decimal': ''" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('service-request-line.totalHours') }" type="number" placeholder="0" v-model="currentServiceRequestLine.totalHours" :disabled="!specialModifyRights || isMyKaarmaSynced">
                <span v-show="errors.has('service-request-line.totalHours')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.totalHours')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.totalHours') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="labourCost">
              <label class="label" title="Total labor cost as defined by the op code selected">Labor Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isLabourCostRequired && !isNoCharge"></b-icon></label>
              <div class="control has-icons-left">
                <money name="labourCost" class="is-width-220" data-vv-as="labor cost" v-validate="isLabourCostRequired && !isNoCharge ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.labourCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.labourCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.labourCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.labourCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.labourCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="partsCost">
              <label class="label" title="Total parts cost as defined by the parts added below">Parts Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isPartsCostRequired && !isNoCharge"></b-icon></label>
              <div class="control has-icons-left">
                <money name="partsCost" class="is-width-220" data-vv-as="parts cost" v-validate="isPartsCostRequired && !isNoCharge ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.partsCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.partsCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.partsCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.partsCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.partsCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="otherCost">
              <label class="label" title="Other costs such as sublet costs, etc">Other Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isOtherCostRequired && !isNoCharge"></b-icon></label>
              <div class="control has-icons-left">
                <money name="otherCost" class="is-width-220" data-vv-as="other cost" v-validate="isOtherCostRequired && !isNoCharge ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.otherCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.otherCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.otherCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.otherCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.otherCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="totalCost">
              <label class="label" title="Calculated total cost from labor, parts and other costs">Total Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isTotalCostRequired && !isNoCharge"></b-icon></label>
              <div class="control has-icons-left">
                <money name="totalCost" class="is-width-220" data-vv-as="total cost" v-validate="isTotalCostRequired && !isNoCharge ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.totalCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.totalCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.totalCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.totalCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.totalCost') }}
                </span>
                <pop-fill-control
                  title="Click to use this previously approved value as the total cost"
                  :value="currentServiceRequestLine.totalCost"
                  :fillableValue="currentServiceRequestLine.approvedTotal"
                  :onFillAction="onRestoreApprovedTotalCost"
                  :doComparison="true"
                  :applyCurrencyFormat="true"
                  v-if="currentServiceRequestLine.approvedAt"
                  :displayBlock="true" class="is-width-220"></pop-fill-control>
              </div>
            </div>

            <div class="column is-4" key="discountPercentage">
              <label class="label">Discount Percentage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control has-icons-left">
                <input name="discountPercentage" class="is-width-220" data-vv-as="discount percentage" v-validate="'required|decimal:2'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('service-request-line.discountPercentage') }" type="text" placeholder="0.00" v-model.number="currentServiceRequestLine.discountPercentage" :disabled="!specialModifyRights || isMyKaarmaSynced">
                <span v-show="errors.has('service-request-line.discountPercentage')" class="help is-danger"><i v-show="errors.has('service-request-line.discountPercentage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.discountPercentage') }}</span>
                <span class="icon is-small is-left">
                  <i class="fas fa-percent"></i>
                </span>
              </div>
            </div>

            <div class="column is-4" key="nettCost">
              <label class="label" title="The final price billed to the customer. Discounts can be applied here" >Nett Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isNettCostRequired && !isNoCharge"></b-icon></label>
              <div class="control has-icons-left">
                <money name="nettCost" class="is-width-220" data-vv-as="nett cost" v-validate="isNettCostRequired && !isNoCharge ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.nettCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.nettCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.nettCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.nettCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.nettCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="shopCost">
              <label class="label" title="The total shop costs for this line item" >Shop Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isShopCostRequired"></b-icon></label>
              <div class="control has-icons-left">
                <money name="shopCost" class="is-width-220" data-vv-as="shop cost" v-validate="isShopCostRequired ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.shopCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.shopCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.shopCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.shopCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.shopCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="taxCost">
              <label class="label" title="The final total tax for this line item" >Taxes<b-icon pack="fas" icon="star" class="fa-ss" v-if="isTaxCostRequired"></b-icon></label>
              <div class="control has-icons-left">
                <money name="taxCost" class="is-width-220" data-vv-as="taxes" v-validate="isTaxCostRequired ? 'required|decimal': ''" :class="{'input': true, 'is-danger': errors.has('service-request-line.taxCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.taxCost" v-bind="$globalMoneyFormat" :disabled="!specialModifyRights || isMyKaarmaSynced"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.taxCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.taxCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.taxCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="poNumber">
              <label class="label">P.O. Number<b-icon pack="fas" icon="star" class="fa-ss" v-if="isPoNumberRequired"></b-icon></label>
              <div class="control is-vertical-center">
                  <input name="poNumber" data-vv-as="p.o. number" v-validate="isPoNumberRequired ? 'required' : ''" class="is-width-220" :class="{'input': true, 'is-danger': errors.has('service-request-line.poNumber') }" type="text" placeholder="#####" v-model="currentServiceRequestLine.poNumber" :disabled="!specialModifyRights || isMyKaarmaSynced">
              </div>
              <span v-show="errors.has('service-request-line.poNumber')" class="help is-danger"><i v-show="errors.has('service-request-line.poNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.poNumber') }}</span>
            </div>

            <div class="column is-4" key="isNoCharge">
              <switch-selector :isDisabled="isMyKaarmaSynced" type="is-info" label="No Charge" v-model="currentServiceRequestLine.isNoCharge"></switch-selector>
            </div>

            <div class="column is-4" key="mfgCost">
              <label class="label">Mfg. Cost</label>
              <div class="control has-icons-left">
                <money name="manufacturerCost" data-vv-as="manufacturer cost" class="is-width-220"  v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('manufacturerCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.manufacturerCost" v-bind="$globalMoneyFormat" :disabled="inspectionCompleted && !specialModifyRights"></money>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <span v-show="errors.has('service-request-line.manufacturerCost')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.manufacturerCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.manufacturerCost') }}
                </span>
              </div>
            </div>

            <div class="column is-4" key="mfgHours">
              <label class="label">Mfg. Hours</label>
              <div class="control has-icons-left">
                <input name="manufacturerHours" data-vv-as="manufacturer hours" class="is-width-220"  v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('manufacturerHours') }" type="text" placeholder="0" v-model="currentServiceRequestLine.manufacturerHours" :disabled="inspectionCompleted && !specialModifyRights">
                <span class="icon is-small is-left">
                  <i class="fas fa-clock"></i>
                </span>
                <span v-show="errors.has('service-request-line.manufacturerHours')" class="help is-danger">
                  <i v-show="errors.has('service-request-line.manufacturerHours')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.manufacturerHours') }}
                </span>
              </div>
            </div>

            <div class="column is-12 mt-10">
              <label class="label">Parts<b-icon pack="fas" icon="star" class="fa-ss" v-if="isPartsRequired"></b-icon></label>
              <b-table
                class="lists mb-10"
                :data="validServiceRequestLineParts"
                @click="onEditLinePart">

                <template slot-scope="props">
                  <b-table-column field="partCode" label="Part Code" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                    {{ props.row.partCode }}
                  </b-table-column>

                <b-table-column field="partName" label="Part Name" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                    {{ props.row.partName }}
                </b-table-column>

                <b-table-column field="partDescription" label="Part Description" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.partDescription }}
                </b-table-column>

                <b-table-column field="binLocation" label="Bin Location" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                    {{ props.row.binLocation }}
                </b-table-column>

                <b-table-column field="quantity" label="Quantity" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.quantity }}
                </b-table-column>

                  <b-table-column field="unitCost" label="Unit Cost" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                    {{ props.row.unitCost | currency }}
                  </b-table-column>

                  <b-table-column field="totalCost" label="Total Cost" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                    {{ props.row.totalCost | currency }}
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="has-text-centered">
                      <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                      <p>No parts have been added.</p>
                    </div>
                  </section>
                </template>
              </b-table>
              <button class="button is-info is-small" @click.prevent="addNewLinePart()"  :disabled="!allowNewPart || isMyKaarmaSynced">Add Part</button>
            </div>
            <div class="column is-12 mt-10">
              <label class="label">Notes & Comments</label>
              <note-capture v-model="notes" :required="false" label="Details"></note-capture>
              <note-timeline-view v-model="currentServiceRequestLine.notes" entity="service-request-line" :showDeleteModal="false"></note-timeline-view>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="column is-12 ">
            <file-selector
            v-model="requestLineAttachments"
            :enabled="specialModifyRights || (!inspectionCompleted && hasFeatureAccess(editAccessPermission))"
            label="Attachments"
            :multiple="true"
            :isBoxed="false"
            :usePortalViewer="false"
            @uploadStarted="uploadStarted"
            @uploadComplete="uploadComplete">
            </file-selector>
          </div>
        </div>
      </div>

      <portal :to="useFooterPortal">
        <div class="field is-grouped">
          <p class="control" v-if="hasFeatureAccess(editAccessPermission)">
            <button key="service-line-close" class="button is-primary" type="button" @click="validateBeforeSubmit" :disabled="(inspectionCompleted && !specialModifyRights) || isUploadingFile">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" key="service-line-cancel" @click.prevent="onCancel()">Cancel</a>
          </p>
          <p class="control" v-if="hasFeatureAccess(deleteAccessPermission)">
            <button class="button is-warning" key="service-line-delete" type="button" @click.prevent="onDelete" :disabled="inspectionCompleted && !specialModifyRights">Delete</button>
          </p>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Delete Action</p>
        </header>
        <section class="modal-card-body">
          Are you sure you wish to delete this work item?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirmDelete()">Delete</button>
          <button class="button" @click="isDeleteConfirmationActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="isLinePartDefinition" scroll="keep" width="800px" :has-modal-card="true" v-if="isLinePartDefinition" :canCancel="false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Line Item Part - Details</p>
        </header>
        <section class="modal-card-body small-top-padding">
          <ro-service-work-item-part
            useFooterPortal="modal-footer"
            :currentWorkItemPartId="currentServiceRequestLinePartId"
            :forRoServiceWorkItem="currentServiceRequestLine"
            v-on:save-edit-work-item-part="onSaveEditLinePart"
            v-on:save-new-work-item-part="onSaveLinePart"
            v-on:cancel-work-item-part="onCancelLinePart()">
          </ro-service-work-item-part>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import RoServiceOpCodeSelector from '@/components/generic/RoServiceOpCodeSelector'
import RoServiceLaborTypeSelector from '@/components/generic/RoServiceLaborTypeSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import TechnicianSelector from '@/components/generic/roservice/TechnicianSelector'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import SwitchSelector from '@/components/generic/SwitchSelector'
import roServiceOpCodeService from '@/services/roServiceOpCodeService'
import roServiceLaborTypeService from '@/services/roServiceLaborTypeService'
import RoServiceWorkItemPart from '@/components/roservice/RoServiceWorkItemPart'
import NoteCapture from '@/components/generic/NoteCapture'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import PopFillControl from '@/components/generic/PopFillControl'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ServiceRequestLine',
  mixins: [utilitiesMixin],
  props: {
    useFooterPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    forServiceRequest: {
      type: Object,
      default: function () {
        return {
          id: 0,
          lines: []
        }
      }
    },
    currentDealer: {
      type: Object,
      default: null
    },
    currentServiceRequestLineId: {
      default: function () {
        return '0'
      }
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector,
    'ro-service-opcode-selector': RoServiceOpCodeSelector,
    'ro-service-labor-type-selector': RoServiceLaborTypeSelector,
    'technician-selector': TechnicianSelector,
    'pop-fill-control': PopFillControl,
    'ro-service-work-item-part': RoServiceWorkItemPart,
    'switch-selector': SwitchSelector,
    'note-capture': NoteCapture,
    'note-timeline-view': NoteTimelineView
  },
  data () {
    return {
      currentServiceRequestLine: {
        id: this.$uuid.v4(),
        status: 'Active',
        workRequired: '',
        serviceRequestLineStatusId: null,
        requestLineAttachments: [],
        parts: [],
        isNoCharge: false,
        laborFormulaeId: null,
        workRate: this.forServiceRequest ? this.forServiceRequest.workRate : null,
        laborTypeId: null,
        laborType: '',
        statusId: null,
        partsCost: 0,
        labourCost: 0,
        otherCost: 0,
        nettCost: 0,
        totalCost: 0,
        totalHours: 0,
        technicianId: null,
        discountPercentage: 0,
        poNumber: '',
        description: '',
        shopCost: 0,
        taxCost: 0,
        notes: [],
        isDefaultItem: false
      },
      isDeleteConfirmationActive: false,
      selectedServiceRequestLineStatus: null,
      requestLineAttachments: [],
      createAccessPermission: 'servicerequest.create',
      viewAccessPermission: 'servicerequest.view',
      editAccessPermission: 'servicerequest.modify',
      deleteAccessPermission: 'servicerequest.delete',
      isUploadingFile: false,
      fileUploadCount: 0,
      selectedRoServiceOpCode: null,
      selectedRoServiceLaborType: null,
      selectedLaborFormula: null,
      mode: 0,
      isPartsCostRequired: true,
      isLabourCostRequired: true,
      isOtherCostRequired: true,
      isLinePartDefinition: false,
      isTechnicianRequired: false,
      isPartsRequired: false,
      isTotalCostRequired: true,
      isNettCostRequired: true,
      isTotalHoursRequired: true,
      isPoNumberRequired: false,
      isShopCostRequired: false,
      isTaxCostRequired: false,
      selectedTechnician: null,
      allowNewPart: true,
      currentServiceRequestLinePartId: '0',
      notes: [],
      autoCalculateTotalCosts: false,
      isNewServiceRequestLine: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapConfigGetters(['definedTypes']),
    isMyKaarmaSynced: function () {
      return this.currentServiceRequestLine && this.currentServiceRequestLine.myKaarmaId && this.currentServiceRequestLine.myKaarmaId !== ''
    },
    allowedLaborFormulaeValues: function () {
      let available = this.definedTypes.roServiceLaborFormulaeTypes.options
      return available
    },
    inspectionCompleted: function () {
      return (this.forServiceRequest && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.forServiceRequest.requestStatusTypeId)) && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.forServiceRequest.requestStatusTypeId).altName === 'Complete'
    },
    validServiceRequestLineParts: function () {
      return this.currentServiceRequestLine.parts.filter(x => x.status !== 'Deleted')
    },
    allowedServiceRequestLineStatusValues: function () {
      return this.hasFeatureAccess('servicerequest.line-status.modify') ? this.definedTypes.serviceRequestLineStatusType.options : this.definedTypes.serviceRequestLineStatusType.options.filter(x => x.id !== 0)
    },
    allowStatusDelete: function () {
      return true
    },
    isNoCharge: function () {
      return this.currentServiceRequestLine.isNoCharge !== null && this.currentServiceRequestLine.isNoCharge
    },
    isExistingServiceLine: function () {
      return this.currentServiceRequestLine.id !== '0'
    },
    specialModifyRights: function () {
      return this.hasFeatureAccess('servicerequest.special.edit', false)
    },
    isTotalHoursBasedCalculation: function () {
      return this.selectedLaborFormula && this.selectedLaborFormula.id === this.definedTypes.roServiceLaborFormulaeTypes.TotalHours
    }
  },
  methods: {
    onRestoreApprovedTotalCost: function () {
      this.currentServiceRequestLine.totalCost = this.currentServiceRequestLine.approvedTotal
    },
    addNewLinePart: function () {
      this.currentServiceRequestLinePartId = '0'
      this.mode = 1
      this.isLinePartDefinition = true
    },
    onEditLinePart: function (linePart) {
      this.currentServiceRequestLinePartId = linePart.id
      this.mode = 1
      this.isLinePartDefinition = true
    },
    onCancelLinePart: function () {
      this.currentServiceRequestLinePartId = '0'
      this.mode = 0
      this.isLinePartDefinition = false
    },
    onSaveLinePart: function (linePart) {
      this.currentServiceRequestLinePartId = '0'
      this.currentServiceRequestLine.parts.push(linePart)
      this.mode = 0
      this.isLinePartDefinition = false
      this.updatePartsDependantCostings()
    },
    onSaveEditLinePart: function (linePart, typeStatus) {
      var that = this
      if (this.currentServiceRequestLinePartId !== undefined) {
        this.currentServiceRequestLine.parts = this.$lodash.filter(this.currentServiceRequestLine.parts, function (current) {
          return current.id !== that.currentServiceRequestLinePartId
        })
        this.currentServiceRequestLinePartId = '0'
      }
      this.currentServiceRequestLine.parts.push(linePart)
      this.mode = 0
      this.isLinePartDefinition = false
      this.updatePartsDependantCostings()
    },
    validateBeforeSubmit () {
      this.$validator.validateAll('service-request-line').then((result) => {
        if (result) {
          this.onSave()
        } else {
          this.$goToValidationError(this.$validator.errors.items[0].id)
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
    },
    onConfirmDelete: function () {
      this.isDeleteConfirmationActive = false
      this.currentServiceRequestLine.status = 'Deleted'
      this.$emit('save-edit-service-request-line', this.currentServiceRequestLine, this.selectedServiceRequestLineStatus.id)
    },
    onDelete: function () {
      this.isDeleteConfirmationActive = true
    },
    onSave: function () {
      this.notes.forEach(function (item) {
        var note = {
          note: item.note,
          status: 'Active',
          isHotNote: item.isHotNote,
          pinned: item.pinned
        }

        this.currentServiceRequestLine.notes.push(note)
      }, this)
      this.currentServiceRequestLine.requestLineAttachments = this.requestLineAttachments
      if (this.currentServiceRequestLine.new === true) {
        this.currentServiceRequestLine.new = false
        this.$emit('save-new-service-request-line', this.currentServiceRequestLine, this.selectedServiceRequestLineStatus.id)
      } else {
        this.$emit('save-edit-service-request-line', this.currentServiceRequestLine, this.selectedServiceRequestLineStatus.id)
      }
    },
    onCancel: function () {
      this.$emit('cancel-service-request-line', {})
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    loadOpCode: function (opCodeId) {
      this.isLoading = true
      this.serverErrors = []
      this.serverErrorMessage = ''
      roServiceOpCodeService.single(opCodeId).then(response => {
        this.selectedRoServiceOpCode = response.roServiceOpCode
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    loadLaborType: function (laborTypeId) {
      this.isLoading = true
      this.serverErrors = []
      this.serverErrorMessage = ''
      roServiceLaborTypeService.single(laborTypeId).then(response => {
        this.selectedRoServiceLaborType = response.roServiceLaborType
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    updateOpCodeDependantCostings: function () {
      if (!this.selectedRoServiceOpCode) return
      this.currentServiceRequestLine.labourCost = 0
      this.currentServiceRequestLine.totalHours = 0
      if (this.selectedRoServiceOpCode.laborTypeId > 0) {
        this.loadLaborType(this.selectedRoServiceOpCode.laborTypeId)
      }
      this.selectedLaborFormula = this.definedTypes.roServiceLaborFormulaeTypes.options.find(x => x.id === this.selectedRoServiceOpCode.laborFormulaeId)
      if (this.selectedLaborFormula.id === this.definedTypes.roServiceLaborFormulaeTypes.FlatSellRate) {
        this.currentServiceRequestLine.labourCost = this.selectedRoServiceOpCode.flatSellRate || 0
        this.currentServiceRequestLine.totalHours = 0
      } else if (this.selectedLaborFormula.id === this.definedTypes.roServiceLaborFormulaeTypes.FlatHours) {
        this.currentServiceRequestLine.labourCost = (this.selectedRoServiceOpCode.flatHours || 0) * this.currentWorkItem.workRate
        this.currentServiceRequestLine.totalHours = this.selectedRoServiceOpCode.flatHours || 0
      } else if (this.selectedLaborFormula.id === this.definedTypes.roServiceLaborFormulaeTypes.TotalHours) {
        this.currentServiceRequestLine.totalHours = this.selectedRoServiceOpCode.flatHours || 0
      }
    },
    updatePartsDependantCostings: function () {
      let totalPartsCost = this.currentServiceRequestLine.parts.reduce((acc, x) => acc + x.totalCost, 0)
      this.currentServiceRequestLine.partsCost = totalPartsCost
    },
    updateTotalCosts: function () {
      if (this.autoCalculateTotalCosts === false) return
      this.currentServiceRequestLine.estimatedCost = (this.currentServiceRequestLine.labourCost || 0) + (this.currentServiceRequestLine.partsCost || 0) + (this.currentServiceRequestLine.otherCost || 0)
      this.currentServiceRequestLine.totalCost = this.currentServiceRequestLine.estimatedCost
      this.currentServiceRequestLine.nettCost = this.currentServiceRequestLine.totalCost - (this.currentServiceRequestLine.totalCost * (this.currentServiceRequestLine.discountPercentage / 100.00))
    },
    loadTechnician: function (technicianId, technicianName) {
      this.selectedTechnician = {
        id: technicianId,
        fullName: technicianName
      }
    }
  },
  watch: {
    autoCalculateTotalCosts: function (newValue, oldValue) {
      if (newValue === oldValue) return
      if (newValue === true) {
        this.updateTotalCosts()
      }
    },
    'currentServiceRequestLine.otherCost': function (newValue, oldValue) {
      if (newValue === oldValue) return
      this.updateTotalCosts()
    },
    'currentServiceRequestLine.labourCost': function (newValue, oldValue) {
      if (newValue === oldValue) return
      this.updateTotalCosts()
    },
    'currentServiceRequestLine.partsCost': function (newValue, oldValue) {
      if (newValue === oldValue) return
      this.updateTotalCosts()
    },
    'currentServiceRequestLine.totalHours': function (newValue, oldValue) {
      if (!this.isTotalHoursBasedCalculation) return
      if (newValue === oldValue) return
      this.currentServiceRequestLine.labourCost = this.currentServiceRequestLine.totalHours * this.currentServiceRequestLine.workRate
    },
    'currentServiceRequestLine.discountPercentage': function (newValue, oldValue) {
      this.currentServiceRequestLine.nettCost = this.currentServiceRequestLine.totalCost - (this.currentServiceRequestLine.totalCost * (this.currentServiceRequestLine.discountPercentage / 100.00))
    },
    'currentServiceRequestLine.nettCost': function (newValue, oldValue) {
      this.currentServiceRequestLine.discountPercentage = this.currentServiceRequestLine.nettCost === 0 ? 0 : (((this.currentServiceRequestLine.totalCost - this.currentServiceRequestLine.nettCost) / this.currentServiceRequestLine.totalCost) * 100).toFixed(2)
    },
    selectedLaborFormula: function (newValue, oldValue) {
      if (newValue) {
        if (!this.selectedRoServiceOpCode) return
        this.currentServiceRequestLine.laborFormulaeId = newValue.id
        if (newValue.id === this.definedTypes.roServiceLaborFormulaeTypes.FlatSellRate) {
          this.currentServiceRequestLine.totalHours = 0
          this.currentServiceRequestLine.labourCost = this.selectedRoServiceOpCode.flatSellRate || 0
        } else if (newValue.id === this.definedTypes.roServiceLaborFormulaeTypes.FlatHours) {
          this.currentServiceRequestLine.totalHours = this.selectedRoServiceOpCode.flatHours || 0
          this.currentServiceRequestLine.labourCost = (this.selectedRoServiceOpCode.flatHours || 0) * this.currentServiceRequestLine.workRate
        } else if (newValue.id === this.definedTypes.roServiceLaborFormulaeTypes.TotalHours) {
          this.currentServiceRequestLine.totalHours = 0
          this.currentServiceRequestLine.labourCost = this.currentServiceRequestLine.totalHours * this.currentServiceRequestLine.workRate
        }
      }
    },
    selectedRoServiceOpCode: function (newValue, oldValue) {
      if (newValue) {
        let oldOpCodeId = this.currentServiceRequestLine.opCodeId
        let isOpCodeChanged = oldOpCodeId !== newValue.id
        this.currentServiceRequestLine.opCodeId = newValue.id
        this.currentServiceRequestLine.code = newValue.opCode
        this.currentServiceRequestLine.opCodeListName = newValue.listName

        if (this.currentServiceRequestLine.new === false && !isOpCodeChanged) return

        if (isOpCodeChanged) {
          this.currentServiceRequestLine.parts.forEach((x) => {
            x.status = 'Deleted'
          })
        }
        if (this.isNewServiceRequestLine) {
          this.currentServiceRequestLine.workRequired = newValue.description
        }
        this.currentServiceRequestLine.labourCost = 0
        this.currentServiceRequestLine.partsCost = 0
        this.currentServiceRequestLine.otherCost = 0
        this.currentServiceRequestLine.poNumber = ''
        this.currentServiceRequestLine.totalCost = 0
        this.currentServiceRequestLine.totalHours = 0
        this.currentServiceRequestLine.estimatedCost = 0
        this.currentServiceRequestLine.discountPercentage = 0.00
        this.currentServiceRequestLine.nettCost = 0
        this.updateOpCodeDependantCostings()
        this.updatePartsDependantCostings()
        this.updateTotalCosts()
      }
    },
    selectedRoServiceLaborType: function (newValue, oldValue) {
      if (newValue) {
        this.currentServiceRequestLine.laborTypeId = newValue.id
        this.currentServiceRequestLine.laborType = newValue.code
        this.currentServiceRequestLine.workRate = newValue.unitCost
        this.updateTotalCosts()
      } else {
        this.currentServiceRequestLine.laborTypeId = null
        this.currentServiceRequestLine.laborType = ''
        this.currentServiceRequestLine.workRate = this.forServiceRequest.workRate
      }
    },
    selectedTechnician: function (newValue, oldValue) {
      if (newValue) {
        this.currentServiceRequestLine.technicianId = newValue.id
        this.currentServiceRequestLine.technicianName = newValue.fullName
      }
    }
  },
  mounted: function () {
    if (this.currentServiceRequestLineId !== '0') {
      var that = this
      let found = this.forServiceRequest.lines.find(function (x) {
        return x.id === that.currentServiceRequestLineId
      })

      if (found) {
        this.currentServiceRequestLine = Object.assign({}, found)
        this.currentServiceRequestLine.new = false
        this.selectedServiceRequestLineStatus = this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentServiceRequestLine.serviceRequestLineStatusId)
        this.selectedLaborFormula = this.definedTypes.roServiceLaborFormulaeTypes.options.find(x => x.id === this.currentServiceRequestLine.laborFormulaeId)
        if (this.currentServiceRequestLine.opCodeId && this.currentServiceRequestLine.opCodeId > 0) {
          this.loadOpCode(this.currentServiceRequestLine.opCodeId)
        }
        if (this.currentServiceRequestLine.laborTypeId && this.currentServiceRequestLine.laborTypeId > 0) {
          this.loadLaborType(this.currentServiceRequestLine.laborTypeId)
        }
        if (this.currentServiceRequestLine.technicianId && this.currentServiceRequestLine.technicianId !== 0) {
          this.loadTechnician(this.currentServiceRequestLine.technicianId, this.currentServiceRequestLine.technicianName)
        }
        if (!this.currentServiceRequestLine.workRate) {
          this.currentServiceRequestLine.workRate = this.forServiceRequest.workRate ? this.forServiceRequest.workRate : ((this.currentServiceRequestLine.labourCost || 0) / (this.currentServiceRequestLine.totalHours || 1)) || 0
        }
      }

      this.currentServiceRequestLine.requestLineAttachments.forEach((attach) => {
        if (attach.id) {
          var requestLineAttachments = {
            id: attach.id,
            loaded: true,
            imageData: attach.fileLocation,
            fileName: attach.fileName,
            uniqueId: attach.fileName,
            fileType: this.getFileType(attach.fileName),
            fileLocation: attach.fileLocation,
            name: attach.name
          }
          this.requestLineAttachments.push(requestLineAttachments)
        } else {
          this.requestLineAttachments.push(attach)
        }
      })
      // this.autoCalculateTotalCosts = !this.isMyKaarmaSynced
    } else {
      this.isNewServiceRequestLine = true
      this.autoCalculateTotalCosts = true
      if (this.hasFeatureAccess('servicerequest.line-status.modify')) {
        this.selectedServiceRequestLineStatus = { id: 0, name: 'New', altName: 'New' }
      } else {
        this.selectedServiceRequestLineStatus = { id: 2, name: 'Awaiting Approval', altName: 'AwaitingApproval' }
      }
    }
    if (!this.selectedLaborFormula) {
      this.selectedLaborFormula = this.definedTypes.roServiceLaborFormulaeTypes.options.find(x => x.id === this.definedTypes.roServiceLaborFormulaeTypes.TotalHours)
    }
  }
}
</script>

<style scoped>
  .white-text {
    color: #fff !important;
  }
</style>
