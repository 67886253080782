import axios from 'axios'
import baseService from './baseService'
import dealerData from './data/dealer'
import departmentData from './data/department'
import designationData from './data/designation'
import Dealer from '@/models/Dealer'
import Department from '@/models/Department'
import Designation from '@/models/Designation'

const dealerService = {
  allDealers (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealerData.all)
      } else {
        let url = params ? '/dealer/list?' + params : '/dealer/list'
        axios
          .get(url, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Dealer.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allDepartments (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(departmentData.all)
      } else {
        let url = params ? '/department/list?' + params : '/department/list'
        axios
          .get(url, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Department.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allDesignations (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(designationData.all)
      } else {
        let url = params ? '/designation/list?' + params : '/designation/list'
        axios
          .get(url, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Designation.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealerData)
      } else {
        axios
          .post('/dealer/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Dealer.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealerData.all.find((x) => x.id === dealerId))
      } else {
        axios
          .post('/dealer/index?id=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (dealerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var dealer = {
          id: 999,
          name: dealerModel.name,
          status: 'Active'
        }
        dealerData.all.push(dealer)
        resolve(dealer)
      } else {
        axios
          .post('/dealer/save', dealerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (dealerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var dealer = dealerData.all.find((x) => x.id === dealerModel.id)
        if (dealer) {
          dealer.name = dealerModel.name
          dealer.status = dealerModel.status
        }
        resolve(dealer)
      } else {
        axios
          .post('/dealer/update', dealerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (dealerModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        dealerModels.forEach(element => {
          dealerData.all.remove((x) => x.id === element.id)
        })
        resolve(dealerData.all)
      } else {
        var ids = []
        dealerModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/dealer/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateSquareInfo (dealerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var dealer = dealerData.all.find((x) => x.id === dealerModel.id)
        if (dealer) {
          dealer.name = dealerModel.name
          dealer.status = dealerModel.status
        }
        resolve(dealer)
      } else {
        axios
          .post('/dealer/updatesquaredefinition', dealerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default dealerService
