import { render, staticRenderFns } from "./FileSelector.vue?vue&type=template&id=5c810c30&scoped=true"
import script from "./FileSelector.vue?vue&type=script&lang=js"
export * from "./FileSelector.vue?vue&type=script&lang=js"
import style0 from "./FileSelector.vue?vue&type=style&index=0&id=5c810c30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c810c30",
  null
  
)

export default component.exports