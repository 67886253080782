import axios from 'axios'
import baseService from './baseService'
import subscriberData from './data/subscriber'
import Subscriber from '@/models/Subscriber'

const subscriberService = {
  single (subscriberId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(subscriberData.all.find((x) => x.id === subscriberId))
      } else {
        axios
          .post('/subscriber/get?id=' + subscriberId, baseService.defaultConfig)
          .then(response => {
            resolve(Subscriber.fromAPI(response.data.subscriber))
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getSecurityToken (subscriberId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({securityToken: '1234567890'})
      } else {
        axios
          .post('/subscriber/getSst?id=' + subscriberId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (subscriberModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var subscriber = subscriberData.all.find((x) => x.id === subscriberModel.id)
        if (subscriber) {
          subscriber.name = subscriberModel.name
          subscriber.status = subscriberModel.status
        }
        resolve(subscriber)
      } else {
        axios
          .post('/subscriber/update', subscriberModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default subscriberService
