<template>
  <section>
    <div class="is-inline-block">
      <div class="quick-action" v-if="canSendCheckRequest && isSourceTrusted">
        <a class="button" target="_blank" @click="updateRequestState(true)" v-tooltip="{content: buildActionTitle, color: 'success'}">
          <b-icon pack="fal" icon="envelope-open-dollar" custom-size="fa-lg" :class="getColorClassByBatchPaymentRequestStatus(activeInventory ? activeInventory.paymentRequestStatus : '', getBatchPaidStatus(activeInventory), activeInventory)"></b-icon>
        </a>
      </div>
      <div class="quick-action" v-if="canSendCheckRequest && !isSourceTrusted">
        <a class="button" target="_blank" title="Please verify title status, sellers agreement status or access permissions">
          <b-icon pack="fal" icon="envelope-open-dollar" custom-size="fa-lg" class="has-text-grey-light"></b-icon>
        </a>
      </div>
    </div>

    <title-status-capture
      :currentInventory="activeInventory"
      :isListMode="false"
      :subscribeToOpenCloseEventListener="true"
      :showDialog="true"
      v-on:title-capture-complete="onTitleCaptureComplete"
      v-on:title-capture-skipped="onTitleCaptureSkipped"
      :allowTitleSkip="allowPaymentWithoutTitle"
      :isBatchRequest="true"
      :key="'check-request-' + activeInventory.id"
      v-if="activeDialog === 'TitleCapture'">
    </title-status-capture>

    <portal to="global-modal-portal" v-if="activeDialog === 'BatchPaymentRequest'" :order="1" :disabled="!usePortal">
      <b-modal :key="'CW'+instanceId" :active.sync="isNewBatchPaymentRequest" scroll="keep" :has-modal-card="true" :full-screen="true" :canCancel="false" id="batch-payment-request-modal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head is-block">
            <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Configure Check/Wire Request</div>
            <el-steps :active="activeTab" :space="300" finish-status="success" simple>
              <el-step title="Title Status" icon="fal fa-list-alt fa-lg" @click.native="proceedStep(0)"></el-step>
              <el-step title="Payments" icon="fal fa-money-check-alt fa-lg" @click.native="proceedStep(1)"></el-step>
            </el-steps>
          </header>
          <section class="modal-card-body view-container" style="padding: 12px">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div name="Title Status" v-show="activeTab === 0">
              <title-capture-detail
                scope="page-1"
                v-model="currentTitleDetails"
                :disabled="true"
                :isBatchRequest="true"
                :allowTitleSkip="allowPaymentWithoutTitle"
                :useValidator="this.$validator">
              </title-capture-detail>
            </div>
            <div name="Payment Requests" v-show="activeTab === 1">
              <div v-if="!isNewPaymentRequest">

                <section class="level">
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Purchase Cost</p>
                      <p class="title is-size-5">{{ purchaseCostFormatted }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Paid</p>
                      <p class="title is-size-5 has-text-primary">{{ totalPaidFormatted }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Pending</p>
                      <p class="title is-size-5 has-text-danger">{{ totalPendingFormatted }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">New Requests</p>
                      <p class="title is-size-5 has-text-danger">{{ totalNewRequestsFormatted }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Unallocated</p>
                      <p class="title is-size-5">{{ unallocatedFormatted }}</p>
                    </div>
                  </div>
                </section>

                <div class="is-flex">
                  <batch-payment-list-control
                    :value="activeInventory"
                    :key="'bpcj' + activeInventory.id">
                  </batch-payment-list-control>

                  <span class="quick-action">
                    <a class="button" target="_blank" title="Click to view check/wire request history" @click="showHistory()">
                      <b-icon icon="fal fa-history" custom-size="fa-lg"></b-icon>
                    </a>
                  </span>
                </div>

                <div v-if="!isDefaultAllocationFulfilled">
                  <b-table class="lists box" :data="currentBatchPaymentRequest.paymentRequests">
                    <template slot-scope="props">
                      <b-table-column field="payeeType" label="Payee Type" sortable @click.native="onEditPaymentRequest(props.row)">
                        {{ getPayeeTypeDisplayName(props.row.payeeType) }}
                      </b-table-column>
                      <b-table-column field="type" label="Payment Type" sortable @click.native="onEditPaymentRequest(props.row)">
                        {{ props.row.paymentType }}
                      </b-table-column>
                      <b-table-column field="buyerName" label="Payee" sortable @click.native="onEditPaymentRequest(props.row)">
                        {{ props.row.buyerName }}
                      </b-table-column>
                      <b-table-column field="amount" label="Amount" sortable @click.native="onEditPaymentRequest(props.row)" v-tooltip="props.row.note">
                        {{ props.row.amount | currency('$', 2)  }}
                      </b-table-column>
                      <b-table-column field="hasPayoffAddress" label="Status" class="is-flex" style="align-items: baseline;">
                        <i class="grid-icon fa-fw fal status-icon fa-lg" :class="getColorClassByPaymentRequestError(props.row)" v-tooltip="getPaymentRequestErrorTitle(props.row)"/>

                        <verify-supplier-control v-if="props.row.payeeType === 'Wholesaler' && props.row.paymentType === 'Wire'" :supplierId="props.row.buyerId"
                          :isVerifiedLocalProp="props.row.verified" :hasCompletedRequestedWireQuestions="props.row.verifiedWire" :usePortal="false" v-on:on-sync-supplier-callback="onSyncSupplierCallBack"
                          :isWireRequest="true" :overridePaymentType="props.row.paymentType">
                        </verify-supplier-control>

                        <verify-supplier-control v-if="props.row.payeeType === 'Wholesaler' && props.row.paymentType === 'Check'" :supplierId="props.row.buyerId"
                          :isVerifiedLocalProp="props.row.verified" :usePortal="false" v-on:on-sync-supplier-callback="onSyncSupplierCallBack" :isWireRequest="false"
                          :overridePaymentType="props.row.paymentType">
                        </verify-supplier-control>

                        <verify-customer-control v-if="props.row.payeeType === 'Customer' && props.row.paymentType === 'Wire'" :customerId="props.row.buyerId" :isVerifiedLocalProp="props.row.verified" :hasCompletedRequestedWireQuestions="props.row.verifiedWire" :usePortal="false" v-on:on-sync-customer-callback="onSyncCustomerCallBack" :isWireRequest="false" :overridePaymentType="props.row.paymentType"></verify-customer-control>

                        <verify-customer-control v-if="props.row.payeeType === 'Customer' && props.row.paymentType === 'Check'" :customerId="props.row.buyerId" :isVerifiedLocalProp="props.row.verified" :usePortal="false" v-on:on-sync-customer-callback="onSyncCustomerCallBack" :isWireRequest="false" :overridePaymentType="props.row.paymentType"></verify-customer-control>

                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No payment requests defined</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer">
                      <td colspan="5" style="text-align:right">
                        <div class="columns is-multiline" style="margin-right:50px;">
                          <div class="column is-12">
                            <span class="has-text-weight-bold" style="margin-right:10px;">Total:</span>
                            <span class="is-inline-block" style="width:75px">{{ totalPaymentRequests | currency('$', 2) }}</span>
                          </div>
                          <div class="column is-12">
                            <span class="has-text-weight-bold" style="margin-right:10px;">Available Purchase Cost:</span>
                            <span style="width:75px" :class="{'is-inline-block': true, 'has-text-danger': !this.isValueReconciled, 'has-text-primary': this.isValueReconciled}">{{ availablePurchaseCostFormatted }}</span>
                          </div>
                          <div class="column is-12">
                            <span class="has-text-weight-bold" style="margin-right:10px;">Unallocated:</span>
                            <span class="is-inline-block" style="width:75px">{{ unallocatedFormatted }}</span>
                          </div>
                        </div>
                      </td>
                    </template>
                  </b-table>
                  <br/>
                  <div class="is-flex">
                    <button class="button is-info is-small" @click.prevent="updateNewPaymentRequestState(true)">New Payment</button>
                    <div class="is-inline-block" v-if="canSendCheckRequest">
                      <div class="quick-action">
                        <p>
                          <a class="button" target="_blank" title="Click to view check/wire request history" @click="showHistory()">
                            <b-icon icon="fal fa-history" custom-size="fa-lg"></b-icon>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <br/>
                </div>
                <div class="notification is-warning is-flex" v-if="hasCurrentCommissionRequest && currentCommissionRequestStatus === 'Requested'">
                  <span><i class="fas fa-exclamation-triangle is-warning fa-lg"></i></span>
                  <span class="ml-10">
                    <ul class="error-list">
                      <li v-if="hasCurrentCommissionRequest">There are requested unapproved commission requests to {{ currentCommissionRequest.commissionToName }} for the value of {{ currentCommissionRequest.commission | currency }}. Please have them approved before proceeding with this check request</li>
                    </ul>
                  </span>
                </div>
                <br/>
                <div class="notification is-danger is-flex" v-if="paymentRequestHasErrors">
                  <span><i class="fas fa-exclamation-triangle is-danger fa-lg"></i></span>
                  <span class="ml-10">
                    <ul class="error-list" v-if="isDefaultAllocationFulfilled">
                      <li>Allocation is fulfilled.</li>
                    </ul>
                    <ul class="error-list" v-else>
                      <li v-if="anyPayOffBankDetailsInvalid">Payoff bank has missing details. Please click the entry above to amend.</li>
                      <li v-if="isPayOffDateInvalid">Payoff values are expired or close to expiry. Contact bank for current payoff details.</li>
                      <li v-if="verifiedCheckFailed"><span v-html="verifiedCheckText"></span></li>
                      <li v-if="anyPaymentTypesInvalid">Bank or address details missing. Please click entry above to amend.</li>
                      <li v-if="anySendingChecksInvalid">How we sending the check selection is missing. Please click entry above to amend.</li>
                      <li v-if="anyNotVisuallyVerified">Each payment need to be visually verified by reviewing each entry above. Please click entry above to view.</li>
                      <li v-if="!isValueReconciled"><span v-if="unallocated > 0">There is an unallocated amount of </span><span v-if="unallocated < 0">There is an over allocated amount of </span>{{ this.unallocatedFormatted }}</li>
                    </ul>
                  </span>
                </div>
              </div>
              <capture-payment-request
                v-if="isNewPaymentRequest"
                :unallocatedAmount="this.unallocated"
                :currentPaymentRequestId="currentPaymentRequestId"
                :forBatchPaymentRequest="currentBatchPaymentRequest"
                v-on:save-edit-payment-request="onSaveEditPaymentRequest"
                v-on:save-new-payment-request="onSavePaymentRequest"
                v-on:delete-payment-request="onDeletePaymentRequest"
                v-on:cancel-payment-request="onCancelPaymentRequest()"
                :inventory="activeInventory">
              </capture-payment-request>
            </div>
          </section>
          <footer class="modal-card-foot" v-show="!isNewPaymentRequest">
            <div class="columns is-mobile" style="width: 100%">
              <div class="column is-narrow">
                <button class="button is-link" @click="previous" v-if="activeTab > 0">Previous</button>
                <button class="button is-danger" @click="updateRequestState(false)">Cancel</button>
              </div>
              <div class="column is-fullwidth is-vcentered">
                <h3 class="title is-6">{{ inventoryDetails }}</h3>
              </div>
              <div class="column is-narrow">
                <button class="button is-link is-pulled-right" @click="next" v-if="activeTab < 1">Next</button>
                <button class="button is-primary is-pulled-right" :class="{'is-loading': isSaving }" @click="onSendBatchPaymentRequest(activeInventory)" :disabled="isSaveBtnDisabled || isSaving" v-if="activeTab === 1">Send</button>
              </div>
            </div>
          </footer>
          <portal-target name="modal-card-foot" class="modal-card-foot" v-show="isNewPaymentRequest" />
        </div>
        <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="isAlreadySentWarningActive" :disabled="!usePortal">
      <b-modal :active.sync="isAlreadySentWarningActive" :width="640" scroll="keep" :has-modal-card="true" class="warning-modal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title"><i class="fal fa-lg fa-exclamation-triangle has-text-warning"></i> Warning</p>
          </header>
          <section class="modal-card-body">
            <span class="subtitle is-size-6">A check/wire request has already been issued for this inventory item.</span>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="isAlreadySentWarningActive = false">Close</button>
            <a class="button" style="margin-left: auto;" target="_blank" title="Click to view check/wire request history" @click="showHistory()">
              <b-icon icon="fal fa-history" custom-size="fa-lg"></b-icon>
            </a>
          </footer>
        </div>
      </b-modal>
    </portal>

    <payment-history-action
      :showHistoryActive.sync="showHistoryActive"
      :isNewBatchPaymentRequest.sync="isNewBatchPaymentRequest"
      :currentInventory="activeInventory"
      :newCheckRequestAllowed="!isAlreadySentWarningActive">
    </payment-history-action>

    <portal to="global-modal-portal" v-if="showSendTitleConfirmation">
      <b-modal :active.sync="showSendTitleConfirmation" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Send Lost Title Agreement</p>
          </header>
          <section class="modal-card-body">
            {{getSendLostTitleHeader}}
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="sendLostTitle()">Yes</button>
            <button class="button" @click="dontSendLostTitle()">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>
    <inventory-lost-title
      ref="titleLostAction"
      :currentInventory="activeInventory"
      v-on:close-lost-title="onCloseLostTitle"
      :showDialog="showLostTitleAction"
      :key="'L' + currentInventory.id"
      :showButton="false">
    </inventory-lost-title>
  </section>
</template>

<script>

import logger from '@/services/loggingService'
import moment from 'moment'
import batchPaymentRequestService from '@/services/batchPaymentRequestService'
import bankService from '@/services/bankService'
import supplierService from '@/services/supplierService'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import CapturePaymentRequest from '@/components/inventory/CapturePaymentRequest'
import TitleCaptureDetail from '@/components/generic/inventory/InventoryTitleCaptureDetail'
import InventoryBatchPaymentRequestHistoryAction from '@/components/inventory/InventoryBatchPaymentRequestHistoryAction'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import utilitiesMixin from '@/mixins/generic/utilities'
import InventoryTitleCapture from '@/components/generic/inventory/InventoryTitleCapture'
import VerifySupplierControl from '@/components/generic/VerifySupplierControl'
import customerService from '@/services/customerService'
import { Steps, Step } from 'element-ui'
import VerifyCustomerControl from '@/components/generic/VerifyCustomerControl'
import InventoryLostTitleAction from '@/components/inventory/InventoryLostTitleAction.vue'
import InventoryBatchPaymentListControl from '@/components/inventory/InventoryBatchPaymentListControl'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters

export default {
  name: 'InventoryBatchPaymentRequestAction',
  mixins: [utilitiesMixin, humanizedSwitchMixin, validateBeforeSubmitMixin, dealInventoryStyleClasses],
  components: {
    'capture-payment-request': CapturePaymentRequest,
    'title-capture-detail': TitleCaptureDetail,
    'el-steps': Steps,
    'el-step': Step,
    'error-display-component': ErrorDisplayComponent,
    'payment-history-action': InventoryBatchPaymentRequestHistoryAction,
    'verify-supplier-control': VerifySupplierControl,
    'title-status-capture': InventoryTitleCapture,
    'verify-customer-control': VerifyCustomerControl,
    'inventory-lost-title': InventoryLostTitleAction,
    'batch-payment-list-control': InventoryBatchPaymentListControl
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeInventory: null,
      instanceId: this.$uuid.v4(),
      isNewBatchPaymentRequest: false,
      isNewPaymentRequest: false,
      currentPaymentRequestId: 0,
      activeTab: 0,
      currentBatchPaymentRequest: {
        id: this.$uuid.v4(),
        dealerId: 0,
        sameTitle: false,
        titleReassigned: false,
        lienholderListed: false,
        lienholderSatisfied: false,
        vehicleControl: false,
        paymentRequests: [],
        isAutoPayoff: false
      },
      showHistoryActive: false,
      currentSupplier: null,
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      isLoading: false,
      currentCommissionSupplier: null,
      titleCaptureSkipped: false,
      isAlreadySentWarningActive: false,
      currentCustomer: null,
      showSendTitleConfirmation: false,
      isMobile: window.innerWidth <= 700,
      showLostTitleAction: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    inventoryDetails () {
      return this.activeInventory ? this.activeInventory.name + ' - ' + this.activeInventory.stockNo + ' - ' + this.activeInventory.vinNo : ''
    },
    isPayOffRequired: function () {
      // other options here this.activeInventory.isPayOff && this.activeInventory.payOffRequired. Need to discuss the history with Garrett here
      return this.activeInventory && this.activeInventory.titleStatus === 'PayOff'
    },
    isValueReconciled: function () {
      return this.availablePurchaseCost === this.totalPaymentRequests
    },
    checkRequestIssued: function () {
      if (this.activeInventory) {
        if (this.activeInventory.isAllPaymentRequestsArchived === true) return false
        return this.activeInventory.paymentRequestStatus === 'Queued' || this.activeInventory.paymentRequestStatus === 'Sent'
      }
      return false
    },
    currentCommissionRequest: function () {
      if (this.activeInventory && this.activeInventory.currentCommissionRequest) {
        return this.activeInventory.currentCommissionRequest
      }
      return null
    },
    hasCurrentCommissionRequest: function () {
      if (this.currentCommissionRequest) {
        return true
      }
      return false
    },
    currentCommissionRequestStatus: function () {
      if (this.currentCommissionRequest && this.currentCommissionRequest.commissionRequestStatus) {
        return this.currentCommissionRequest.commissionRequestStatus
      }
      return ''
    },
    activeDialog: function () {
      let result = ''
      if (this.isNewBatchPaymentRequest) {
        if (this.mustShowTitleDialog) {
          result = 'TitleCapture'
        } else if (this.showLostTitleAction) {
          result = 'LostTitle'
        } else {
          result = 'BatchPaymentRequest'
        }
      }
      logger.logWithGroup('Active Dialog', result)
      return result
    },
    buildActionTitle: function () {
      var result = '<p>Click to generate a new check/wire request instruction.</p>'
      if (this.activeInventory && this.activeInventory.paymentRequestTitle) {
        result += this.activeInventory.paymentRequestTitle
        result = result.replace('CreatedAt', this.$options.filters.formatDate(this.activeInventory.paymentRequestCompletedAt))
      }
      return result
    },
    currentTitleDetails: function () {
      let result = {
        sameTitle: this.getAttributeValue('SameTitle', 'false').toLowerCase() === 'true',
        titleReassigned: this.getAttributeValue('TitleReassigned', 'false').toLowerCase() === 'true',
        lienholderListed: this.isPayOffRequired ? true : this.getAttributeValue('LienholderListed', 'false').toLowerCase() === 'true',
        lienholderSatisfied: this.getAttributeValue('LienholderSatisfied', 'false').toLowerCase() === 'true',
        payOff: this.getAttributeValue('LienholderSatisfied', 'false').toLowerCase() === 'false',
        vehicleControl: this.activeInventory.possessionStatus === 'Arrived' ? true : this.getAttributeValue('VehicleControl', 'false').toLowerCase() === 'true',
        vehicleArrived: this.activeInventory.possessionStatus === 'Arrived',
        originalTitleReceived: this.activeInventory.originalTitleReceived,
        frontTitleFiles: this.constructFileList('TitleFront'),
        backTitleFiles: this.constructFileList('TitleBack'),
        payOffInfoFiles: this.constructFileList('PayOffInfo'),
        lostTitleAffidavitFiles: this.constructFileList('LostTitleAffidavit'),
        payOffLienSatisfactionFiles: this.constructFileList('PayOffLienSatisfaction'),
        inventoryHasTitleHolder: this.isPayOffRequired,
        inventoryId: this.activeInventory.id,
        possessionStatus: this.activeInventory.possessionStatus,
        titleStatus: this.activeInventory.titleStatus,
        bankId: this.activeInventory.bankId,
        bankName: this.activeInventory.bankName,
        accNo: this.activeInventory.accNo,
        payoffAmount: this.activeInventory.payoffAmount,
        monthlyPaymentDue: this.activeInventory.monthlyPaymentDue,
        goodUntilDate: this.activeInventory.goodUntilDate,
        purchasedFromName: this.activeInventory.purchasedFromName
      }
      return result
    },
    totalPaymentRequests: function () {
      if (this.paymentRequests.length > 0) {
        var sum = this.paymentRequests.reduce(function (a, b) {
          return a + b.amount
        }, 0)
        return this.round2Digits(sum)
      }
      return 0
    },
    allPaymentTypes: function () {
      return this.definedTypes.paymentTypes
    },
    allPayeeTypes: function () {
      return this.definedTypes.payeeTypes
    },
    allBatchPaymentRequestOptions: function () {
      return this.definedTypes.batchPaymentRequestOptions
    },
    hasBatchPaymentRequestAccess: function () {
      let allowedPermissions = ['inventory.issue.batch-payment-request']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    canSendCheckRequest: function () {
      return this.lastSignedSellersAgreement !== '' && this.hasBatchPaymentRequestAccess && this.activeInventory && this.activeInventory.purchasedFromType !== 'Factory'
      // && this.activeInventory.isCdkSynced
    },
    lastSignedSellersAgreement: function () {
      if (this.activeInventory && (this.activeInventory.sellersAgreementStatus === 'Complete' || this.activeInventory.sellersAgreementStatus === 'Signed')) {
        var allSignedSellersAgreements = this.activeInventory.attachments.filter((x) => x.classType === 'SellersAgreement' && x.status === 'Active')
        if (allSignedSellersAgreements && allSignedSellersAgreements.length > 0) {
          return allSignedSellersAgreements[allSignedSellersAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    isSaveBtnDisabled: function () {
      return this.paymentRequestHasErrors
    },
    payoffAddressCheckFailed: function () {
      return (this.paymentRequests.length > 0 && this.paymentRequests.filter((x) => !x.hasPayoffAddress).length > 0)
    },
    isPayOffDateInvalid: function () {
      return (this.paymentRequests.length > 0 && this.paymentRequests.filter((x) => x.isPayoffDateExpired).length > 0)
    },
    verifiedCheckFailed: function () {
      return this.verifiedWireFailed || this.verifiedFailed
    },
    verifiedWireFailed: function () {
      // disable all wire rechecks for now
      return false
      // return this.paymentRequests && this.paymentRequests.length > 0 && this.paymentRequests.filter((x) => !x.verifiedWire && x.paymentType === 'Wire').length > 0
    },
    verifiedFailed: function () {
      // disable all verification rechecks for now
      // return this.paymentRequests && this.paymentRequests.length > 0 && this.paymentRequests.filter((x) => !x.verified).length > 0
      return false
    },
    verifiedCheckText: function () {
      let failedText = ''
      if (this.verifiedWireFailed && this.verifiedFailed) {
        failedText = 'Please contact accounting department to verify the vendor before you can process the check request.<br>Please verify question 4 in vendor verification before you can process the check request.'
      } else if (this.verifiedWireFailed) {
        failedText = 'Please verify question 4 in vendor verification before you can process the check request.'
      } else {
        failedText = 'Please contact accounting department to verify the vendor before you can process the check request.'
      }
      return failedText
    },
    isSourceTrusted: function () {
      // if (this.activeInventory && this.activeInventory.purchasedFromType === 'Wholesaler') {
      //   if (this.currentSupplier && this.currentSupplier.trusted === true) {
      //     return true
      //   } else if ((this.currentSupplier && this.currentSupplier.trusted === false) && (this.activeInventory && this.activeInventory.titleUploadStatus === 2)) {
      //     return true
      //   } else {
      //     return false
      //   }
      // } else {
      return true
      // }
    },
    paymentRequests: function () {
      return this.currentBatchPaymentRequest && this.currentBatchPaymentRequest.paymentRequests ? this.currentBatchPaymentRequest.paymentRequests : []
    },
    allowPaymentWithoutTitle: function () {
      // We allow title skip if the seller is a supplier that allows payment without title
      if (this.currentSupplier) {
        return this.currentSupplier.allowPaymentWithoutTitle === true
      } else if (this.currentCustomer) {
        return this.currentCustomer.allowPaymentWithoutTitle === true
      } else {
        // We DO NOT allow title skip if the vehicle is a trade in
        if (this.activeInventory.type === 'TradeIn') return false
        return true
      }
    },
    requiresTitleSelection: function () {
      return (this.activeInventory.titleStatus === '' || this.activeInventory.titleStatus === 'Pending')
    },
    mustShowTitleDialog: function () {
      return this.requiresTitleSelection && !this.titleCaptureSkipped
    },
    hasAutoPayoffRequest: function () {
      return this.currentBatchPaymentRequest.paymentRequests.find((x) => x.isAutoPayoff)
    },
    originalTitleStatus: function () {
      return this.activeInventory.titleStatus
    },
    anyPayOffBankDetailsInvalid: function () {
      let payOffs = this.paymentRequests.filter((x) => x.payeeType === 'PayOff')
      if (payOffs.length === 0) return false
      return payOffs.filter((x) => x.hasPayoffAddress !== true).length > 0
    },
    anyPaymentTypesInvalid: function () {
      return this.invalidPaymentTypes && this.invalidPaymentTypes.length > 0
    },
    invalidPaymentTypes: function () {
      return this.paymentRequests.filter((x) => x.paymentTypeInvalidText !== '')
    },
    anySendingChecksInvalid: function () {
      return this.invalidSendingChecks && this.invalidSendingChecks.length > 0
    },
    anyNotVisuallyVerified: function () {
      let result = this.paymentRequests.filter((x) => x.isVisuallyVerified !== true).length > 0
      return result
    },
    invalidSendingChecks: function () {
      return this.paymentRequests.filter((x) => (x.checkDeliveryType === '' || x.checkDeliveryType === null || x.checkDeliveryType === undefined) && x.paymentType === 'Check')
    },
    paymentRequestHasErrors: function () {
      return this.payoffAddressCheckFailed ||
        this.isPayOffDateInvalid ||
        this.verifiedCheckFailed ||
        !this.isValueReconciled ||
        this.anyPaymentTypesInvalid ||
        this.anySendingChecksInvalid ||
        this.anyPayOffBankDetailsInvalid ||
        this.anyNotVisuallyVerified ||
        this.isDefaultAllocationFulfilled
    },
    getSendLostTitleHeader: function () {
      if (this.currentInventory && this.currentInventory.purchasedFromName) {
        let message = 'Would you like to send lost title agreement to ' + this.currentInventory.purchasedFromName + '?'
        return message
      }
      return 'Would you like to send lost title agreement?'
    },
    totalPaid: function () {
      let total = this.activeInventory.allActiveCheckRequests.reduce(function (prev, cur) {
        return prev + (!cur.isPaid ? +0 : +cur.amountPaid)
      }, 0)
      return total
    },
    totalPaidFormatted: function () {
      return this.$options.filters.currency(this.totalPaid)
    },
    availablePurchaseCost: function () {
      let total = this.purchaseCost - (this.totalPaid + this.totalPending)
      return total > 0 ? total : 0
    },
    availablePurchaseCostFormatted: function () {
      return this.$options.filters.currency(this.availablePurchaseCost)
    },
    purchaseCost: function () {
      return this.activeInventory.purchaseCostTotal
    },
    purchaseCostFormatted: function () {
      return this.$options.filters.currency(this.purchaseCost)
    },
    totalPending: function () {
      let total = this.activeInventory.allActiveCheckRequests.reduce(function (prev, cur) {
        return prev + (cur.isPaid ? +0 : +cur.amount)
      }, 0)
      return total
    },
    totalPendingFormatted: function () {
      return this.$options.filters.currency(this.totalPending)
    },
    isDefaultAllocationFulfilled: function () {
      return this.availablePurchaseCost < 1
    },
    totalNewRequestsFormatted: function () {
      return this.$options.filters.currency(this.totalPaymentRequests)
    },
    unallocated: function () {
      return this.availablePurchaseCost - this.totalPaymentRequests
    },
    unallocatedFormatted: function () {
      return this.$options.filters.currency(this.unallocated)
    },
    anyActiveCommissionRequests: function () {
      let result = this.paymentRequests.filter((x) => x.isBrokerFee === true).length > 0
      return result
    }
  },
  methods: {
    onTitleCaptureComplete: function (inventory) {
      if (inventory.titleStatus !== 'Pending') {
        this.activeInventory = inventory
        this.addLienHolderPaymentRequest(this.activeInventory, this.currentBatchPaymentRequest.paymentRequests)
      } else {
        this.updateRequestState(false)
      }
    },
    onTitleCaptureSkipped: function () {
      this.titleCaptureSkipped = true
      this.updateRequestState(true)
    },
    findInventoryAttachment: function (type) {
      if (this.activeInventory && this.activeInventory.attachments && this.activeInventory.attachments.filter((y) => y.classType === type).length > 0) {
        var allDocs = this.activeInventory.attachments.filter((y) => y.classType === type)
        if (allDocs && allDocs.length > 0) {
          return allDocs[allDocs.length - 1]
        }
      }
      return null
    },
    constructFileList: function (type) {
      let document = this.findInventoryAttachment(type)
      if (document) {
        return [{
          id: document.id,
          loaded: true,
          imageData: document.fileLocation,
          fileName: document.fileName,
          uniqueId: document.fileName,
          fileType: this.getFileType(document.fileLocation),
          fileLocation: document.fileLocation,
          name: document.fileName,
          classType: type,
          verified: document.verified,
          verifiedAt: document.verifiedAt,
          verifiedByName: document.verifiedByName,
          createdBy: document.createdBy,
          createdAt: document.createdAt
        }]
      }
      return []
    },
    getAttributeValue: function (attrName, defaultValue) {
      if (this.activeInventory && this.activeInventory.attributes) {
        let attribute = this.activeInventory.attributes.find((x) => x.name === attrName)
        if (attribute && attribute.value !== '') {
          return attribute.value
        }
      }
      return defaultValue
    },
    proceedStep: function (step) {
      if (this.activeTab > step) {
        this.previous()
      } else if (this.activeTab < step) {
        this.next()
      }
    },
    next: function () {
      let that = this
      var scope = 'page-' + (this.activeTab + 1) + '.*'
      switch (this.activeTab) {
        case 0: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              that.autoAddLienHolderPaymentRequest(that.currentBatchPaymentRequest.paymentRequests)
              that.activeTab++
            } else {
              that.$goToFirstValidationError(that.$validator.errors.items)
            }
          })
          break
        }
      }
    },
    previous: function () {
      if (this.activeTab-- < 1) this.activeTab = 0
    },
    addSellerPaymentRequest: function (activeInventory, paymentRequests) {
      var payeeType = 'Wholesaler'
      if (activeInventory.purchasedFromType === 'Individual') {
        payeeType = 'Customer'
      }
      if (activeInventory.type === 'TradeIn') {
        payeeType = 'DealEquity'
      }

      let buyerName = activeInventory.purchasedFromName || ''
      let paymentAmount = this.availablePurchaseCost - ((this.isPayOffRequired ? activeInventory.payoffAmount : 0.00) + this.totalPaymentRequests)

      // if there isnt amount to pay, exit method
      if (paymentAmount <= 0) return false

      var defaultCustomerNote = 'Payment to seller for agreed purchase cost.' + '\n\n'
      defaultCustomerNote += 'Payment Details:\n'
      defaultCustomerNote += `\tAmount: ${this.$formatCurrency(paymentAmount)}\n`
      defaultCustomerNote += '\n\nAdd additional notes below this line'
      defaultCustomerNote += '\n----------------------------------------------- \n\n\n'

      if (payeeType === 'DealEquity') {
        defaultCustomerNote = `Dealer purchased a ${activeInventory.name}, stock no ${activeInventory.stockNo} as a trade, from Supplier/Customer ${activeInventory.purchasedFromName} `
        defaultCustomerNote += `on ${this.$options.filters.formatDateOnlyShort(activeInventory.createdAt)}. ${this.$formatCurrency(paymentAmount)} is being used as deal equity on a current deal`
        if (activeInventory.tradeSourceName) {
          defaultCustomerNote += ` with stock number ${activeInventory.tradeSourceName}.`
        } else {
          defaultCustomerNote += '.'
        }
      }

      let verified = this.currentSupplier ? this.currentSupplier.verified : (this.currentCustomer ? this.currentCustomer.verified : true)
      let isPaymentTypeDetailsValid = this.isPaymentTypeDetailsValid(activeInventory)

      // make this always true for now, disable recheck
      // let verifiedWire = this.currentSupplier === null
      let verifiedWire = true

      var purchasePaymentRequest = {
        id: this.$uuid.v4(),
        subscriberId: activeInventory.subscriberId,
        dealerId: activeInventory.dealerId,
        buyerId: activeInventory.purchasedFromId,
        buyerName: buyerName,
        amount: paymentAmount,
        note: defaultCustomerNote,
        status: 'Pending',
        payeeType: payeeType,
        paymentType: (activeInventory.type !== 'TradeIn') ? activeInventory.purchasedFromDefaultPaymentMethod : 'Accounting',
        autoGenerated: true,
        hasPayoffAddress: true,
        verified: verified,
        isPayoffDateExpired: false,
        verifiedWire: verifiedWire,
        isBrokerFee: false,
        paymentTypeInvalidText: !isPaymentTypeDetailsValid ? this.getPaymentTypeInvalidText(activeInventory) : '',
        checkDeliveryType: null,
        isVisuallyVerified: false
      }

      paymentRequests.push(purchasePaymentRequest)
    },
    addLienHolderPaymentRequest (activeInventory, paymentRequests) {
      let payOffIndex = paymentRequests.findIndex((x) => x.payeeType === 'PayOff')
      if (payOffIndex >= 0) {
        paymentRequests.splice(payOffIndex, 1)
      }
      if (this.isPayOffRequired) {
        if (activeInventory.bankName !== '') {
          var defaultNote = 'Balance owed to lienholder.' + '\n\n'
          defaultNote += 'Pay Off Details:\n'
          if (activeInventory.accNo) {
            defaultNote += `\tAccount No: ${activeInventory.accNo}\n`
          }
          if (activeInventory.payoffAmount) {
            defaultNote += `\tPay Off Amount: ${this.$formatCurrency(activeInventory.payoffAmount)}\n`
          }
          if (activeInventory.payoffAmount) {
            defaultNote += `\tGood Until: ${this.$options.filters.formatDateOnly(activeInventory.goodUntilDate)}\n`
          }
          defaultNote += '\n'

          bankService.single(activeInventory.bankId).then(response => {
            let bank = {
              ...response.bank
            }

            defaultNote += '\nAdd additional notes below this line' + '\n----------------------------------------------- \n\n\n'

            let hasPayoffAddress = false
            if (bank && (bank.fullPayoffAddress !== '' && bank.firstName !== '' && bank.lastName !== '')) {
              hasPayoffAddress = true
            }

            let isPayoffExpiredError = false
            if (activeInventory && activeInventory.goodUntilDate) {
              let currentDate = moment().format('YYYY/MM/DD')
              let goodUntilDate = moment(activeInventory.goodUntilDate).subtract(2, 'days').format('YYYY/MM/DD')
              isPayoffExpiredError = !moment(goodUntilDate).isAfter(currentDate)
            }

            var payOffPaymentRequest = {
              id: this.$uuid.v4(),
              subscriberId: activeInventory.subscriberId,
              dealerId: activeInventory.dealerId,
              buyerId: activeInventory.bankId,
              buyerName: activeInventory.bankName,
              amount: activeInventory.payoffAmount,
              note: defaultNote,
              status: 'Pending',
              payeeType: 'PayOff',
              paymentType: 'Check',
              autoGenerated: true,
              hasPayoffAddress: hasPayoffAddress,
              isPayoffDateExpired: isPayoffExpiredError,
              verified: true,
              isBrokerFee: false,
              paymentTypeInvalidText: '',
              checkDeliveryType: null,
              isVisuallyVerified: false
            }

            paymentRequests.push(payOffPaymentRequest)
          }).catch((error) => {
            throw error
          })
        }
      }
    },
    autoAddLienHolderPaymentRequest (paymentRequests) {
      // Important to note here that we checking the active title details here and not the inventory to determine if payoff is required and to what amounts to use
      let payOffIndex = paymentRequests.findIndex((x) => x.payeeType === 'PayOff')
      if (payOffIndex >= 0) {
        paymentRequests.splice(payOffIndex, 1)
      }
      if (this.currentTitleDetails.titleStatus === 'PayOff') {
        if (this.currentTitleDetails.bankName !== '') {
          var defaultNote = 'Balance owed to lienholder.' + '\n\n'
          defaultNote += 'Pay Off Details:\n'
          if (this.currentTitleDetails.accNo) {
            defaultNote += `\tAccount No: ${this.currentTitleDetails.accNo}\n`
          }
          if (this.currentTitleDetails.payoffAmount) {
            defaultNote += `\tPay Off Amount: ${this.$formatCurrency(this.currentTitleDetails.payoffAmount)}\n`
          }
          if (this.currentTitleDetails.payoffAmount) {
            defaultNote += `\tGood Until: ${this.$options.filters.formatDateOnly(this.currentTitleDetails.goodUntilDate)}\n`
          }
          defaultNote += '\n'

          bankService.single(this.currentTitleDetails.bankId).then(response => {
            let bank = {
              ...response.bank
            }

            defaultNote += '\n\nAdd additional notes below this line' + '\n----------------------------------------------- \n\n\n'

            let hasPayoffAddress = false
            if (bank && (bank.fullPayoffAddress !== '' && bank.firstName !== '' && bank.lastName !== '')) {
              hasPayoffAddress = true
            }

            let isPayoffExpiredError = false
            if (this.currentTitleDetails.goodUntilDate) {
              let currentDate = moment().format('YYYY/MM/DD')
              let goodUntilDate = moment(this.currentTitleDetails.goodUntilDate).subtract(2, 'days').format('YYYY/MM/DD')
              isPayoffExpiredError = !moment(goodUntilDate).isAfter(currentDate)
            }

            var payOffPaymentRequest = {
              id: this.$uuid.v4(),
              subscriberId: this.activeInventory.subscriberId,
              dealerId: this.activeInventory.dealerId,
              buyerId: this.currentTitleDetails.bankId,
              buyerName: this.currentTitleDetails.bankName,
              amount: this.currentTitleDetails.payoffAmount,
              note: defaultNote,
              status: 'Pending',
              payeeType: 'PayOff',
              paymentType: 'Check',
              autoGenerated: true,
              hasPayoffAddress: hasPayoffAddress,
              isPayoffDateExpired: isPayoffExpiredError,
              verified: true,
              isAutoPayoff: true,
              isBrokerFee: false,
              paymentTypeInvalidText: '',
              checkDeliveryType: null,
              isVisuallyVerified: false
            }

            paymentRequests.push(payOffPaymentRequest)
          }).catch((error) => {
            throw error
          })
        }
      }
    },
    addDefaultPaymentRequests: function (activeInventory, paymentRequests) {
      if (activeInventory.commissionToType && activeInventory.commissionToId) {
        this.addCommissionPaymentRequest(activeInventory, paymentRequests)
      }
      this.addSellerPaymentRequest(activeInventory, paymentRequests)
      this.addLienHolderPaymentRequest(activeInventory, paymentRequests)
    },
    updateRequestState: function (state) {
      if (this.currentUser.id !== 1 && this.currentUser.id !== 14018 && this.currentUser.id !== 15049) {
        if (state && this.checkRequestIssued) {
          this.isAlreadySentWarningActive = true
          return
        }
      }
      this.isNewBatchPaymentRequest = state
      if (state === true) {
        this.activeTab = 0
        this.currentBatchPaymentRequest.dealerId = this.activeInventory.dealerId
        this.currentBatchPaymentRequest.paymentRequests = []
        this.addDefaultPaymentRequests(this.activeInventory, this.currentBatchPaymentRequest.paymentRequests)
      }

      if (state === false) {
        this.activeInventory.actionCancelled = true
        this.$emit('batch-payment-request-complete', this.activeInventory)
      }
    },
    onSendBatchPaymentRequest: function (inventory) {
      try {
        this.isSaving = true
        var inventoryAttachments = []
        if (this.currentTitleDetails.frontTitleFiles.length > 0) {
          var frontTitleFile = this.currentTitleDetails.frontTitleFiles.map((x) => {
            return {
              id: x.id,
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'TitleFront'
            }
          })

          inventoryAttachments.push(...frontTitleFile)
        }

        if (this.currentTitleDetails.backTitleFiles.length > 0) {
          var backTitleFile = this.currentTitleDetails.backTitleFiles.map((x) => {
            return {
              id: x.id,
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'TitleBack'
            }
          })

          inventoryAttachments.push(...backTitleFile)
        }
        if (this.currentTitleDetails.payOffInfoFiles.length > 0) {
          var payOffInfoFile = this.currentTitleDetails.payOffInfoFiles.map((x) => {
            return {
              id: x.id,
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'PayOffInfo'
            }
          })

          inventoryAttachments.push(...payOffInfoFile)
        }

        if (this.currentTitleDetails.lostTitleAffidavitFiles.length > 0) {
          var lostTitleAffidavitFile = this.currentTitleDetails.lostTitleAffidavitFiles.map((x) => {
            return {
              id: x.id,
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'LostTitleAffidavit'
            }
          })

          inventoryAttachments.push(...lostTitleAffidavitFile)
        }

        if (this.currentTitleDetails.payOffLienSatisfactionFiles.length > 0) {
          var payOffLienSatisfactionFile = this.currentTitleDetails.payOffLienSatisfactionFiles.map((x) => {
            return {
              id: x.id,
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'PayOffLienSatisfaction'
            }
          })

          inventoryAttachments.push(...payOffLienSatisfactionFile)
        }

        var inventoryAttributes = []
        inventoryAttributes.push({name: 'SameTitle', value: this.currentTitleDetails.sameTitle})
        inventoryAttributes.push({name: 'TitleReassigned', value: this.currentTitleDetails.titleReassigned})
        inventoryAttributes.push({name: 'LienholderListed', value: this.currentTitleDetails.lienholderListed})
        inventoryAttributes.push({name: 'LienholderSatisfied', value: this.currentTitleDetails.lienholderSatisfied})
        inventoryAttributes.push({name: 'VehicleControl', value: this.currentTitleDetails.vehicleControl})

        var batchPaymentRequest = {
          subscriberId: inventory.subscriberId,
          dealerId: inventory.dealerId,
          inventoryId: inventory.id,
          paymentRequests: this.ensureIdDataTypeSafety(this.currentBatchPaymentRequest.paymentRequests),
          sameTitle: this.currentTitleDetails.sameTitle,
          titleReassigned: this.currentTitleDetails.titleReassigned,
          lienholderListed: this.currentTitleDetails.lienholderListed,
          lienholderSatisfied: this.currentTitleDetails.lienholderSatisfied,
          vehicleControl: this.currentTitleDetails.vehicleControl,
          originalTitleReceived: this.currentTitleDetails.originalTitleReceived,
          inventoryAttachments: this.ensureIdDataTypeSafety(inventoryAttachments),
          inventoryAttributes: inventoryAttributes,
          titleStatus: this.currentTitleDetails.titleStatus,
          bankId: this.currentTitleDetails.bankId,
          accNo: this.currentTitleDetails.accNo,
          payoffAmount: this.currentTitleDetails.payoffAmount,
          monthlyPaymentDue: this.currentTitleDetails.monthlyPaymentDue,
          goodUntilDate: this.currentTitleDetails.goodUntilDate,
          type: 'Inventory'
        }
        let that = this
        batchPaymentRequest.paymentRequests.forEach((item) => {
          item = that.ensureSpecifiedDataTypeSafety(item, 'batchPaymentRequestId')
        })
        batchPaymentRequestService.queueBatchPaymentRequest(batchPaymentRequest).then(response => {
          if (response.errors) {
            that.serverErrors = response.errors
          } else {
            if ((that.currentTitleDetails.titleStatus === 'Lost' || that.currentTitleDetails.titleStatus === '3') && that.currentTitleDetails.lostTitleAffidavitFiles.length === 0) {
              that.isNewBatchPaymentRequest = false
              that.showSendTitleConfirmation = true
            } else {
              that.isNewBatchPaymentRequest = false
              that.activeInventory.paymentRequestStatus = 'Queued'
              that.activeInventory.paymentRequestCompletedBy = that.currentUser.fullName
              that.activeInventory.paymentRequestCompletedAt = moment.tz(new Date(), moment.tz.guess()).format('YYYY-MM-DD hh:mm')
              that.$emit('batch-payment-request-complete', that.activeInventory)
            }
            eventBus.$emit('quickview-refresh')
          }
          that.isSaving = false
        }).catch((error) => {
          that.serverErrorMessage = error.message
          that.isSaving = false
          throw error
        })
      } catch (error) {
        this.serverErrorMessage = error.message
        this.isSaving = false
      }
    },
    onEditPaymentRequest: function (item) {
      this.currentPaymentRequestId = item.id
      this.updateNewPaymentRequestState(true)
    },
    updateNewPaymentRequestState: function (state) {
      this.isNewPaymentRequest = state
    },
    onSaveEditPaymentRequest: function (paymentRequest) {
      var that = this
      this.currentBatchPaymentRequest.paymentRequests = this.$lodash.filter(this.currentBatchPaymentRequest.paymentRequests, function (current) {
        return current.id !== that.currentPaymentRequestId
      })

      this.currentPaymentRequestId = '0'
      this.currentBatchPaymentRequest.paymentRequests.push(paymentRequest)

      this.updateNewPaymentRequestState(false)
    },
    onDeletePaymentRequest: function () {
      var that = this
      this.currentBatchPaymentRequest.paymentRequests = this.$lodash.filter(this.currentBatchPaymentRequest.paymentRequests, function (current) {
        return current.id !== that.currentPaymentRequestId
      })

      this.currentPaymentRequestId = '0'
      this.updateNewPaymentRequestState(false)
    },
    onSavePaymentRequest: function (paymentRequest) {
      this.currentPaymentRequestId = '0'
      this.currentBatchPaymentRequest.paymentRequests.push(paymentRequest)
      this.$forceUpdate()
      this.updateNewPaymentRequestState(false)
    },
    onCancelPaymentRequest: function () {
      this.currentPaymentRequestId = '0'
      this.updateNewPaymentRequestState(false)
    },
    getColorClassByPaymentRequestError: function (row) {
      let classToAttach = {}
      if (row.hasPayoffAddress === false || row.isPayoffDateExpired === true || row.verified === false || row.paymentTypeInvalidText !== '' || !this.isCheckCheckDeliveryMethodSet(row) || !this.isVisuallyVerified(row)) {
        classToAttach['fa-times-circle'] = true
        classToAttach['has-text-danger'] = true
      } else {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    getPaymentRequestErrorTitle: function (row) {
      let title = ''

      if (row.paymentTypeInvalidText && row.paymentTypeInvalidText !== '') {
        title = row.paymentTypeInvalidText + ' <br />'
      }

      if (row.payeeType !== 'PayOff') {
        if (row.verified === false) {
          if (row.payeeType === 'Wholesaler') {
            title = title === '' ? 'Wholesaler not verified.' : title + '<br /> Wholesaler not verified.'
          } else {
            title = title === '' ? 'Customer not verified.' : title + '<br /> Customer not verified.'
          }
        }
      } else {
        if (row.hasPayoffAddress === false) {
          title = title === '' ? 'Needs additional bank details.' : title + '<br /> Needs additional bank details.'
        }
        if (row.isPayoffDateExpired === true) {
          title = title === '' ? 'Payoff details have expired.' : title + '<br /> Payoff details have expired.'
        }
        if (row.verified === false) {
          title = title === '' ? 'Wholesaler not verified.' : title + '<br /> Wholesaler not verified.'
        }
      }

      if (!this.isCheckCheckDeliveryMethodSet(row)) {
        title = title === '' ? 'How are we sending the check needs to be selected.' : title + '<br /> How are we sending the check needs to be selected.'
      }

      if (!this.isVisuallyVerified(row)) {
        title = title === '' ? 'Visual verification of payment request is required.' : title + '<br /> Visual verification of payment request is required.'
      }

      return title === '' ? 'No issues found.' : title
    },
    isVisuallyVerified: function (row) {
      return row.isVisuallyVerified
    },
    isCheckCheckDeliveryMethodSet: function (row) {
      if (row.paymentType === 'Check') {
        if (row.checkDeliveryType === null || row.checkDeliveryType === undefined || row.checkDeliveryType === '') {
          return false
        }
      }
      return true
    },
    showHistory: function () {
      this.showHistoryActive = true
      this.isNewBatchPaymentRequest = false
    },
    loadSupplier: function () {
      this.isLoading = true
      supplierService.single(this.activeInventory.purchasedFromId).then(response => {
        this.isLoading = false
        this.currentSupplier = {
          ...response.supplier
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    loadCustomer: function () {
      this.isLoading = true
      customerService.single(this.activeInventory.purchasedFromId).then(response => {
        this.isLoading = false
        this.currentCustomer = {
          ...response.customer
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    loadCommissionSupplier: function () {
      this.isLoading = true
      supplierService.single(this.activeInventory.commissionToId).then(response => {
        this.isLoading = false
        this.currentCommissionSupplier = {
          ...response.supplier
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onSyncSupplierCallBack: function (supplier) {
      // find and update payment request rows of verified supplier
      this.currentBatchPaymentRequest.paymentRequests.filter(x => x.buyerId === supplier.id).forEach((item) => {
        item.verified = true
        item.verifiedWire = true
      })

      // issue with computed properties not updating so have to use object.assign
      this.currentBatchPaymentRequest = Object.assign({}, this.currentBatchPaymentRequest)

      // if the purchased from supplier is the supplier that got verified, update its verified status
      if (this.currentSupplier && this.currentSupplier.id === supplier.id) {
        this.currentSupplier.verified = true
      }

      // if the commission to supplier is the supplier that got verified, update its verified status
      if (this.currentCommissionSupplier && this.currentCommissionSupplier.id === supplier.id) {
        this.currentCommissionSupplier.verified = true
      }
    },
    addCommissionPaymentRequest: function (activeInventory, paymentRequests) {
      // if there isnt amount to pay, exit method
      let unallocated = this.availablePurchaseCost - ((this.isPayOffRequired ? activeInventory.payoffAmount : 0.00) + this.totalPaymentRequests)
      if (unallocated <= 0) return false

      // if commission is already paid, exit method
      if (activeInventory.commissionIsPaid) return false

      // if there are any active broker fee payment requests, exit method
      if (this.anyActiveCommissionRequests) return false

      var payeeType = 'Wholesaler'
      var commissionToName = ''
      if (activeInventory.commissionToType === 'Individual') {
        payeeType = 'Customer'
      }
      commissionToName = activeInventory.commissionToName
      let paymentAmount = activeInventory.commission

      var defaultCustomerNote = 'This is a bird-dog / broker fee payment for this vehicle.' + '\n'
      defaultCustomerNote += 'Payment Details:\n'
      defaultCustomerNote += `\tAmount: ${this.$formatCurrency(paymentAmount)}\n`
      defaultCustomerNote += '\n ' + activeInventory.commissionToPaymentDetails.replace(/,/g, '\n\t')
      defaultCustomerNote += '\n\nAdd additional notes below this line'
      defaultCustomerNote += '\n----------------------------------------------- \n\n\n'

      let verified = this.currentCommissionSupplier ? this.currentCommissionSupplier.verified : true

      // make verifiedWire always true for now, disable recheck
      // let verifiedWire = this.currentCommissionSupplier === null
      let verifiedWire = true

      var purchasePaymentRequest = {
        id: this.$uuid.v4(),
        subscriberId: activeInventory.subscriberId,
        dealerId: activeInventory.dealerId,
        buyerId: activeInventory.commissionToId,
        buyerName: commissionToName,
        amount: paymentAmount,
        note: defaultCustomerNote,
        status: 'Pending',
        payeeType: payeeType,
        paymentType: activeInventory.commissionToDefaultPaymentMethod,
        autoGenerated: true,
        hasPayoffAddress: true,
        verified: verified,
        isPayoffDateExpired: false,
        verifiedWire: verifiedWire,
        isBrokerFee: true,
        isVisuallyVerified: false
      }
      paymentRequests.push(purchasePaymentRequest)
    },
    getPayeeTypeDisplayName: function (payeeType) {
      let type = this.allPayeeTypes.find(x => x.name === payeeType || x.altName === payeeType)
      return (type) ? type.name : ''
    },
    initialize: function () {
      logger.logInfo('Batch payment request control is initializing')
      this.activeInventory = this.currentInventory
      if (this.activeInventory && this.activeInventory.purchasedFromType === 'Wholesaler' && this.activeInventory.purchasedFromId) {
        this.loadSupplier()
      }

      if (this.activeInventory && this.activeInventory.purchasedFromType === 'Individual' && this.activeInventory.purchasedFromId) {
        this.loadCustomer()
      }

      if (this.activeInventory && this.activeInventory.commissionToType === 'Wholesaler' && this.activeInventory.commissionToId) {
        this.loadCommissionSupplier()
      }

      if (this.showDialog === true) {
        this.updateRequestState(true)
      }
    },
    isPaymentTypeDetailsValid: function (activeInventory) {
      let entity = (this.currentSupplier) ? this.currentSupplier : this.currentCustomer
      let isNotValid = false
      let isValid = true

      // always return true when default payment method not set
      if (activeInventory.purchasedFromDefaultPaymentMethod !== 'Check' && activeInventory.purchasedFromDefaultPaymentMethod !== 'Wire') {
        return isValid
      }

      // always return true when tradeIn (Acccounting payment type)
      if (activeInventory.type === 'TradeIn') {
        return isValid
      }

      // if payment type is wire, check if bank details is set for payee
      if (activeInventory.purchasedFromDefaultPaymentMethod === 'Wire' && entity) {
        if (entity.bankName && entity.accountHolder && entity.accountNo && entity.swiftNo) {
          return isValid
        } else {
          return isNotValid
        }
      }

      // if payment type is check, check address details is set for payee
      if (activeInventory.purchasedFromDefaultPaymentMethod === 'Check' && entity) {
        if (entity.fullAddress && entity.fullAddress !== '') {
          return isValid
        } else {
          return isNotValid
        }
      }

      return isValid
    },
    getPaymentTypeInvalidText: function (activeInventory) {
      if (activeInventory.purchasedFromDefaultPaymentMethod === 'Wire') {
        return 'An issue was found with missing banking details for this payee. Please complete the payee banking details.'
      }

      if (activeInventory.purchasedFromDefaultPaymentMethod === 'Check') {
        return 'An issue was found with missing address details for this payee. Please complete the payee address details.'
      }

      return ''
    },
    onSyncCustomerCallBack: function (customer) {
      // find and update payment request rows of verified customer
      this.currentBatchPaymentRequest.paymentRequests.filter(x => x.buyerId === customer.id).forEach((item) => {
        item.verified = true
        item.verifiedWire = true
      })

      // issue with computed properties not updating so have to use object.assign
      this.currentBatchPaymentRequest = Object.assign({}, this.currentBatchPaymentRequest)

      if (this.currentCustomer && this.currentCustomer.id === customer.id) {
        this.currentCustomer.verified = true
      }
    },
    sendLostTitle () {
      this.showSendTitleConfirmation = false
      this.showLostTitleAction = true
    },
    dontSendLostTitle () {
      this.showSendTitleConfirmation = false
      this.activeInventory.paymentRequestStatus = 'Queued'
      this.activeInventory.paymentRequestCompletedBy = this.currentUser.fullName
      this.activeInventory.paymentRequestCompletedAt = moment.tz(new Date(), moment.tz.guess()).format('YYYY-MM-DD hh:mm')
      this.$emit('batch-payment-request-complete', this.activeInventory)
    },
    onCloseLostTitle () {
      this.showLostTitleAction = false
      this.activeInventory.paymentRequestStatus = 'Queued'
      this.activeInventory.paymentRequestCompletedBy = this.currentUser.fullName
      this.activeInventory.paymentRequestCompletedAt = moment.tz(new Date(), moment.tz.guess()).format('YYYY-MM-DD hh:mm')
      this.$emit('batch-payment-request-complete', this.activeInventory)
    }
  },
  watch: {
    currentInventory: function (newValue, oldValue) {
      this.initialize()
    }
  },
  mounted: function () {
    this.initialize()
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .el-steps--simple {
    padding-left :0;
    padding-right :0;
    padding-bottom :0;
  }

  .warning-modal .modal-card {
    overflow: auto;
    max-height: 300px !important;
    min-height: 300px !important;
    max-width: 400px !important;
    min-width: 400px !important;
    width: 400px !important;
  }
  .warning-modal .modal-card-body {
    position:relative;
    max-height: 150px !important;
    min-height: 150px !important;
    height: 150px !important;
    max-width: 400px !important;
    min-width: 400px !important;
    width: 400px !important;
    overflow-y: auto;
  }

  .error-list {
    list-style: auto;
    margin-top: 0 !important;
    padding-left: 1em !important;
  }

  .level {
    position: inherit !important;
}
</style>
