<template>
  <div :class="{box:isBoxed, 'top-margin-is-1':!isBoxed}" :name="instanceName + '.header'">
    <div class="columns">
      <div class="column field">
        <label class="label" style="margin-left:10px;">
          {{label}}
          <b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
          <i v-if="enabled" :id="instanceName + '.paste'" class="fal fa-paste fa-lg has-text-orange" @paste="handlePaste" title="Click here to paste an image from the clipboard. Press CTRL+V to paste" style="margin-left:5px;"></i>
        </label>
      </div>
      <div class="column" v-if="showVerifiedCheck && showFileOnly">
        <div class="is-pulled-right tags has-addons">
          <span v-if="buildVerifiedByOverview !== ''" class="tag is-success is-pulled-right">{{buildVerifiedByOverview}}</span>
          <span class="tag is-light"><b-checkbox  v-model="verified" :disabled="isVerifiedDisabled">Verified</b-checkbox></span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div v-if="!inline" v-viewer="{title: false}" class="item-container">
          <div class="is-inline-flex iPhoto" v-if="enabled">
            <input type="hidden" :data-vv-scope="scope" :id="instanceName" :name="instanceName" data-vv-validate-on="change|input" :data-vv-as="validateAs" v-validate="{'required': required}" v-model="hasFiles">
            <b-upload v-model="uploadValue"
                type="is-info"
                :multiple="multiple"
                :disabled="shouldBeDisabled"
                accept="pdf/*|image/*|video/*"
                @input="loadLocalFiles"
                drag-drop>
                <div class="upload-instructions">
                  <div class="has-text-centered">
                    <p>Drop your files here or click to upload</p>
                    <i class="fal fa-upload" style="margin-top:10px;"></i>
                  </div>
                </div>
            </b-upload>
          </div>
          <div class="iPhoto" v-if="showItemsInTable && validFiles.length > 0">
            <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>By</th>
                    <th>At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tr v-for="(file, index) in validFiles" :key="index">
                  <td title="Click to preview this uploaded file">
                    <figure class="image table-image-size">
                      <img v-if="isPdf(file)" src="@/assets/pdf.png" class="table-image-size" @click.stop.prevent="showFile(file)">
                      <img v-else-if="isImage(file)" :src="file.imageData" class="table-image-size">
                      <img v-else-if="isVideo(file)" poster="@/assets/video.gif" class="table-image-size" @click.stop.prevent="showVideo(file)">
                      <img v-else src="@/assets/file.jpg" class="table-image-size">
                      <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
                    </figure>
                  </td>
                  <td>
                    <span :tile="file.uploadErrorDescription" v-if="file.isUploadError">{{ file.uploadErrorDescription }}</span>
                    <span :title="file.fileName || file.name" v-if="!file.isUploadError && !file.isUploading">{{ shortened(file.fileName || file.name, 20) }}</span>
                    <span :title="file.uploadPercentage" v-if="!file.isUploadError && file.isUploading">upload progress: {{ file.uploadPercentage }}%</span>
                  </td>
                  <td>
                    {{ buildFileUploadedBy(file) }}
                  </td>
                  <td>
                    {{ buildFileUploadedAt(file, true) }}
                  </td>
                  <td>
                    <div style="display:flex">
                      <span v-if="enabled" class="tag is-delete" @click="deleteFile(selectedFiles, file)"></span>
                      <i class="tag fal fa-download clickable has-text-primary" title="Click to download this file directly." @click.stop.prevent="onDownloadFile(file)"></i>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
          <div v-else class="is-inline-flex iPhoto" :style="getPhotoContainerStyle" v-for="(file, index) in validFiles" :key="index">
            <div :class="{'box': !showFileOnly, 'is-file-container': !showFileOnly}" class="is-flex" :title="file.name || file.fileName">
              <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
              <span class="audit-container" :title="buildFileAudit(file)">{{buildFileAuditCompact(file)}}</span>
              <figure class="image" :class="containerDisplaySizeClass">
                <img v-if="isPdf(file)" src="@/assets/pdf.png" :style="imageDisplaySizeStyle" @click.stop.prevent="showFile(file)">
                <img v-else-if="isImage(file)" :src="file.imageData" :style="imageDisplaySizeStyle">
                <video v-else-if="isVideo(file)" poster="@/assets/video.gif" :style="videoDisplaySizeStyle" @click.stop.prevent="showVideo(file)"></video>
                <img v-else src="@/assets/file.jpg" :style="imageDisplaySizeStyle">
              </figure>
              <div class="tags has-addons extra-top-margin" v-if="enabled && !showFileOnly">
                <span class="tag is-link" v-if="file.isUploadError">{{ file.uploadErrorDescription }}</span>
                <span class="tag is-link" v-if="!file.isUploadError && !file.isUploading">{{ shortened(file.fileName || file.name) }}</span>
                <span class="tag is-link" v-if="!file.isUploadError && file.isUploading">upload progress: {{ file.uploadPercentage }}%</span>
                <span v-if="enabled" class="tag is-delete" @click="deleteFile(selectedFiles, file)"></span>
                <i class="tag fal fa-download clickable has-text-primary" title="Click to download this file directly." @click.stop.prevent="onDownloadFile(file)"></i>
              </div>
            </div>
          </div>
          <br/>
        </div>
        <div v-else class="item-container">
          <div class="is-inline-flex iPhoto" v-if="enabled">
            <input type="hidden" :data-vv-scope="scope" :id="instanceName" :name="instanceName" data-vv-validate-on="change|input" :data-vv-as="validateAs" v-validate="{'required': required}" v-model="hasFiles">
            <b-upload v-model="uploadValue"
                type="is-info"
                :multiple="multiple"
                :disabled="shouldBeDisabled"
                accept="pdf/*|image/*|video/*"
                @input="loadLocalFiles"
                drag-drop>
                <div class="upload-instructions">
                  <textarea></textarea>
                  <div class="has-text-centered">
                    <p>Drop your files here or click to upload</p>
                    <i class="fal fa-upload" style="margin-top:10px;"></i>
                  </div>
                </div>
            </b-upload>
          </div>
          <div class="is-inline-flex" :style="getPhotoContainerStyle" v-for="(file, index) in validFiles" :key="index">
            <div :class="{'box': !showFileOnly, 'is-file-container': !showFileOnly}" class="is-flex" :title="buildFileTitle(file)" style="flex-direction: column">
              <figure class="image" :class="containerDisplaySizeClass" style="align-self: flex-start; z-index:1000">
                <img v-if="isPdf(file)" src="@/assets/pdf.png" :style="imageDisplaySizeStyle" @click.stop.prevent="showFile(file)">
                <img v-else-if="isImage(file)" :src="file.imageData" :style="imageDisplaySizeStyle" @click.prevent="showFullImage = true">
                <img v-else-if="isVideo(file)" poster="@/assets/video.gif" :style="videoDisplaySizeStyle"  @click.stop.prevent="showVideo(file)">
                <img v-else src="@/assets/file.jpg" :style="imageDisplaySizeStyle">
                <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
              </figure>
              <div  style="align-self: flex-start" class="large-top-margin" v-if="isImage(file) && showFullImage" v-viewer>
                <div class="inline-action-container">
                  <i class="fal fa-download fa-lg has-text-success clickable" @click.prevent="onDownloadFile(file)" v-if="file.imageData.startsWith('http')"></i>
                  <i class="fal fa-print fa-lg has-text-link clickable ml-5" @click.prevent="onPrintImage(file)"></i>
                  <span class="title is-7 ml-5">{{buildFileAudit(file)}}</span>
                  <span title="close file" class="delete is-medium has-background-grey-light is-pulled-right" v-if="isImage(file) && showFullImage" @click.prevent="showFullImage = false"></span>
                </div>
                <div class="notification is-flex is-warning" v-if="isImage(file) && printLicense" >
                  <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
                  <span class="ml-10">
                    {{driversLicenseExactSizeModalText}}
                  </span>
                </div>
                <div class="inline-action-container" v-if="isImage(file) && printLicense">
                  <span>
                    <i class="fal fa-print fa-lg has-text-info clickable ml-5" @click.prevent="onPrintLicenseImage(file)" title="Print license exact size."></i>
                  </span>
                  <span>
                    <i class="fal fa-pencil fa-lg has-text-info clickable ml-5" @click.prevent="openCustomerRecord" title="Edit Customer" v-if="canEditCustomer" ></i>
                  </span>
                </div>
                <div>
                  <br/>
                </div>
                <img :src="file.imageData">
              </div>
            </div>
          </div>
          <br/>
        </div>
      </div>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger">
      <i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}
    </span>
    <video-player-control
      ref="video-player"
      title="Video"
      :url="currentVideo"
      :usePortal="usePortalViewer"
      v-if="currentVideo"
      v-on:on-video-closed="currentVideo = undefined">
    </video-player-control>
    <pdf-modal
        :pdfFile="pdfModalFile"
        :usePortal="usePortalViewer"
        :portalOrder="2"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="pdfModalFile && pdfModalFile.src && !inline"
        :fullScreenMode="true">
    </pdf-modal>
    <div class="box" v-if="!usePortalViewer && isPdfViewerActive && inline">
      <div class="inline-action-container modal-card-head columns">
        <div class="column is-narrow">
          <i class="fal fa-download fa-lg has-text-success clickable" @click.prevent="onDownloadFile(selectedFile)" v-if="selectedFile.imageData.startsWith('http')"></i>
        </div>
        <div class="column is-narrow">
          <i class="fal fa-print fa-lg has-text-link clickable ml-5" @click.prevent="onPrint"></i>
        </div>
        <div class="column has-text-centered">
          <span class="title is-7 ml-5">{{buildFileAudit(selectedFile)}}</span>
        </div>
        <div class="column is-narrow">
          <span title="close file" class="delete is-medium has-background-grey-light is-pulled-right" style="z-index:10000"  @click.prevent="closePdf"></span>
        </div>
      </div>
      <pdf-inline
        ref="pdfViewer"
        :scale="0.4"
        :pdfFile="selectedFile"
        v-on:on-pdf-downloaded="isLoading = false">
      </pdf-inline>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import utilitiesMixin from '@/mixins/generic/utilities'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
import PdfInlineDisplayComponent from '@/components/generic/PdfInlineDisplayComponent'
import VideoPlayerControl from '@/components/generic/VideoPlayerControl.vue'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  components: {
    'pdf-inline': PdfInlineDisplayComponent,
    'video-player-control': VideoPlayerControl,
    'pdf-modal': PdfModalDisplayComponent
  },
  name: 'FileSelector',
  mixins: [utilitiesMixin],
  props: {
    value: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: 'File'
    },
    validateAs: {
      type: String,
      default: 'File'
    },
    required: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    isBoxed: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    displaySize: {
      type: String,
      default: '64'
    },
    showFileOnly: {
      type: Boolean,
      default: false
    },
    usePortalViewer: {
      type: Boolean,
      default: true
    },
    useValidator: {
      type: Object,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    formatImages: {
      type: Boolean,
      default: false
    },
    croppedCustomerImages: {
      type: Array,
      default: () => []
    },
    showVerifiedCheck: {
      type: Boolean,
      default: false
    },
    showItemsInTable: {
      type: Boolean,
      default: false
    },
    showDeleted: {
      type: Boolean,
      default: false
    },
    printLicense: {
      type: Boolean,
      default: false
    },
    selectedStateName: {
      type: String,
      default: ''
    },
    customerId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      uploadValue: null,
      selectedFiles: this.value,
      instanceName: 'file-select-' + this.$uuid.v4(),
      isPdfViewerActive: false,
      isLoading: false,
      selectedFile: null,
      hasFiles: (this.value && this.value.length > 0) ? 'YES' : null,
      showFullImage: false,
      verified: false,
      verifiedByOverview: '',
      cheker: '',
      savedFiles: [],
      deletedFiles: [],
      downloadFrameIdentifier: 'dfFrame',
      downloadUrl: '',
      pdfModalFile: null,
      currentVideo: null
    }
  },
  computed: {
    ...mapUserGetters(['currentUser', 'hasFeatureAccess']),
    filesValid: function () {
      return this.selectedFiles && this.selectedFiles.length > 0
    },
    shouldBeDisabled: function () {
      if (this.multiple === true) {
        return false
      } else {
        if (this.validFiles.length > 0) {
          return true
        }
      }
      return false
    },
    getPhotoContainerStyle: function () {
      if (this.formatImages) {
        return 'auto'
      } else {
        return {
          width: this.enabled ? 'auto' : '100%'
        }
      }
    },
    validFiles: function () {
      if (this.showDeleted) {
        return this.selectedFiles ? this.selectedFiles.filter((x) => x.loaded === true) : []
      } else {
        return this.selectedFiles ? this.selectedFiles.filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted')) : []
      }
    },
    imageDisplaySizeStyle: function () {
      let key = 'max-height'
      let result = {}
      result[key] = this.displaySize + 'px'
      key = 'max-width'
      result[key] = this.displaySize + 'px'
      key = 'cursor'
      result[key] = 'pointer'
      return result
    },
    videoDisplaySizeStyle: function () {
      let result = {}
      let key = 'max-width'
      result[key] = '112px'
      key = 'cursor'
      result[key] = 'pointer'
      return result
    },
    imageDisplaySizeClass: function () {
      let key = 'is-' + this.displaySize + 'x' + this.displaySize
      let result = {}
      result[key] = true
      return result
    },
    containerDisplaySizeClass: function () {
      let key = 'is-' + this.displaySize + 'xauto'
      let result = {}
      result[key] = true
      return result
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    showErrors: function () {
      return this.$validator.errors
    },
    isVerifiedDisabled: function () {
      return this.validFiles.length === 0
    },
    buildVerifiedByOverview: function () {
      if (this.validFiles.length === 0 || this.validFiles[0].verifiedByName === undefined) {
        return ''
      }
      let file = this.validFiles[0]
      let verifiedAtFormatted = file.verifiedAt !== undefined && file.verifiedAt !== '' ? this.$options.filters.formatDateOnlyShort(file.verifiedAt) : ''
      let verifedBytext = (file.verifiedByName !== '' && file.verifiedAt !== '') ? file.verifiedByName + ' - ' + verifiedAtFormatted : ''
      return verifedBytext
    },
    driversLicenseExactSizeModalText: function () {
      let result = 'Copies of drivers license are required to match their exact size. Use the icon below to print a copy at the exact size.'
      if (this.selectedStateName && this.canEditCustomer) {
        result = 'The state of ' + this.selectedStateName + ' requires the copies of drivers licenses match the original documents exact size. Use the icon below to print a copy at the exact size. If the image requires alteration you can redirect to edit customer screen using the pencil icon below.'
      } else if (this.selectedStateName) {
        result = 'The state of ' + this.selectedStateName + ' requires the copies of drivers licenses match the original documents exact size. Use the icon below to print a copy at the exact size.'
      } else if (this.hasFeatureAccess('customer.modify')) {
        result = result + ' If the image requires alteration you can redirect to edit customer screen using the pencil icon below.'
      }
      return result
    },
    canEditCustomer: function () {
      if (this.customerId > 0 && this.hasFeatureAccess('customer.modify')) {
        return true
      }
      return false
    }
  },
  methods: {
    handlePaste: function (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }
      var items = pasteEvent.clipboardData.items
      if (items === undefined) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }
      for (var i = 0; i < items.length; i++) {
        var blob = items[i].getAsFile()
        if (blob) this.loadLocalFiles([blob])
      }
    },
    buildFileUploadedBy: function (file) {
      return file.createdBy || ''
    },
    buildFileUploadedAt: function (file, showTime) {
      return file.createdAt ? (showTime ? this.$options.filters.formatDate(file.createdAt) : this.$options.filters.formatDateOnlyShort(file.createdAt)) : ''
    },
    buildFileAuditCompact: function (file) {
      let uploadedBy = file.createdBy || ''
      let uploadedAt = file.createdAt ? this.$options.filters.formatDateOnlyShort(file.createdAt) : ''
      let result = ''
      if (uploadedBy !== '') {
        result = file.createdBy
      }
      if (uploadedAt !== '') {
        if (result === '') {
          result += uploadedAt
        } else {
          result += ' (' + uploadedAt + ')'
        }
      }
      return result
    },
    buildFileAudit: function (file) {
      let uploadedBy = file.createdBy || ''
      let uploadedAt = file.createdAt ? this.$options.filters.formatDate(file.createdAt) : ''
      let result = ''
      if (uploadedBy !== '') {
        result = 'Added by ' + file.createdBy
      }
      if (uploadedAt !== '') {
        if (result === '') {
          result += 'Added at ' + uploadedAt
        } else {
          result += ' on ' + uploadedAt
        }
      }
      return result
    },
    buildFileTitle: function (file) {
      let uploadedBy = file.createdBy || ''
      let uploadedAt = file.createdAt ? this.$options.filters.formatDate(file.createdAt) : ''
      let result = file.name
      if (uploadedBy !== '') {
        result += ' - Added by ' + file.createdBy
      }
      if (uploadedAt !== '') {
        if (result === file.name) {
          result += ' - Added at ' + uploadedAt
        } else {
          result += ' on ' + uploadedAt
        }
      }
      return result
    },
    shortened (name, size = 10) {
      return this.$lodash.truncate(name, { 'length': size, 'separator': ' ' }).toLowerCase()
    },
    isPdf (file) {
      return (file.fileType) ? file.fileType === 'application/pdf' : (file.type) ? file.type === 'application/pdf' : false
    },
    isImage (file) {
      return (file.fileType) ? file.fileType === 'image/png' || file.fileType === 'image/jpg' || file.fileType === 'image/jpeg' || file.fileType === 'image/gif' : (file.type) ? file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' : false
    },
    isVideo (file) {
      return (file.fileType) ? file.fileType === 'video/mp4' : (file.type) ? file.type === 'video/mp4' : false
    },
    showVideo (file) {
      this.currentVideo = file.imageData
      let that = this
      this.$nextTick(() => {
        that.$refs['video-player'].onPlayVideo(true)
      })
      return false
    },
    showFile (file) {
      if (this.isPdfViewerActive) {
        this.isPdfViewerActive = false
        return
      }
      this.onPreview(file)
      return false
    },
    closePdf () {
      this.isPdfViewerActive = false
    },
    onPrintImage: function (imageFile) {
      let imageHtml = '<html><head><scri' + 'pt>function step1(){\n' +
                "setTimeout('step2()', 10);}\n" +
                'function step2(){window.print();window.close()}\n' +
                '</scri' + "pt></head><body onload='step1()'>\n" +
                "<img style='max-width:100%;max-height:100%;' src='" + imageFile.imageData + "' /></body></html>"
      let pagelink = 'about:blank'
      var pwa = window.open(pagelink, '_new')
      pwa.document.open()
      pwa.document.write(imageHtml)
      pwa.document.close()
    },
    onPrintLicenseImage: function (imageFile) {
      let imageHtml = '<html><head><scri' + 'pt>function step1(){\n' +
                "setTimeout('step2()', 10);}\n" +
                'function step2(){window.print();window.close()}\n' +
                '</scri' + "pt></head><body onload='step1()'>\n" +
                "<img style='max-width:324px;max-height:204px;' src='" + imageFile.imageData + "' /></body></html>"
      let pagelink = 'about:blank'
      var pwa = window.open(pagelink, '_new')
      pwa.document.open()
      pwa.document.write(imageHtml)
      pwa.document.close()
    },
    onPrint: function () {
      var pdfControls = this.$refs.pdfViewer
      pdfControls.print(300)
    },
    onDownloadFile: function (file) {
      if (!file && !file.imageData) {
        alert('File is invalid and cannot be downloaded!')
        return
      }
      let completeUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(file.imageData)
      this.triggerDownload(completeUrl)
    },
    triggerDownload: function (url) {
      let downloadFrame = document.getElementById(this.downloadFrameIdentifier)
      if (downloadFrame) {
        downloadFrame.setAttribute('src', url)
      } else {
        downloadFrame = document.createElement('iframe')
        downloadFrame.setAttribute('id', this.downloadFrameIdentifier)
        downloadFrame.setAttribute('src', url)
        downloadFrame.style.width = '0px'
        downloadFrame.style.height = '0px'
        document.body.appendChild(downloadFrame)
      }
    },
    deleteFile (list, file) {
      var fileIndex = list.findIndex((x) => x.name === file.name)
      var savedIndex = this.savedFiles.findIndex((x) => x.name === file.name)
      this.deletedFiles.push(list[fileIndex])
      if (file.status) {
        this.$set(file, 'status', 'Deleted')
      } else {
        list.splice(fileIndex, 1)
      }
      this.savedFiles.splice(savedIndex, 1)
      this.$emit('input', this.selectedFiles)
      this.hasFiles = (this.validFiles.length > 0) ? 'YES' : null
    },
    loadLocalFiles (fileList) {
      if (!Array.isArray(fileList)) {
        fileList = [fileList]
      }
      // remove duplicates
      let newFiles = _.differenceWith(fileList, this.savedFiles, (arrVal, othVal) => arrVal.name === othVal.name)
      // remove deleted files
      this.deletedFiles.forEach(x => {
        let index = newFiles.findIndex((y) => y.name === x.name)
        if (index >= 0) {
          newFiles.splice(index, 1)
        }
      })

      newFiles.forEach(function (file, index) {
        let found = this.selectedFiles.find(x => x.name === file.name && x.size === file.size && x.status === 'Active')
        if (!file.imageData && !found) {
          var uniqueFileName = this.$uuid.v4() + this.getFileExtension(file.name)
          uniqueFileName = uniqueFileName.replace(/-/g, '')
          var reader = new FileReader()
          reader.onload = (e) => {
            var clone = { size: file.size, name: file.name, type: this.eagType(file), fileType: file.type }
            clone.imageData = e.target.result
            clone.loaded = true
            clone.isUploaded = false
            clone.isUploading = false
            clone.uploadPercentage = 0
            clone.isUploadError = false
            clone.uploadErrorDescription = ''
            clone.uniqueId = uniqueFileName
            clone.status = 'Active'
            clone.verified = false
            this.selectedFiles.push(clone)
            this.savedFiles.push(clone)
            this.uploadFile(file, uniqueFileName, clone)
          }
          reader.readAsDataURL(file)
        }
      }, this)
    },
    getFileExtension: function (fileName) {
      return '.' + fileName.split('.').pop()
    },
    resetUploadStatus: function (clone) {
      clone.uploadPercentage = 0
      clone.isUploading = true
      clone.isUploadError = false
      clone.uploadErrorDescription = ''
    },
    uploadFile: function (file, uniqueName, clone) {
      let that = this
      let uploadErrorDescription = 'An error occurred uploading your file ' + file.name + '. Please verify that your file is valid and try again: <br/>'
      this.resetUploadStatus(clone)
      var formData = new FormData()
      formData.append(uniqueName, file)
      this.$emit('uploadStarted')
      axios.post('/upload/receive', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          clone.uploadPercentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
        }
      }).then((response) => {
        clone.isUploading = false
        const item = response.data.results[0]
        if (!item.isValid) {
          clone.isUploadError = true
          that.deleteFile(that.selectedFiles, file)
          that.failedToast(uploadErrorDescription + item.error, 10000)
          that.$emit('uploadComplete', false)
        } else {
          clone.isUploaded = true
          clone.uploadErrorDescription = ''
          that.$emit('uploadComplete', true)
        }
      }).catch((error) => {
        clone.isUploadError = true
        that.deleteFile(that.selectedFiles, file)
        that.failedToast(uploadErrorDescription + error.message, 10000)
        that.$emit('uploadComplete', false)
      })
    },
    getAllFileNameIndexes: function (arr, val) {
      const getAllIndexes = (arr, pred) => arr.flatMap((v, i) => pred(v) ? i : [])
      let result = getAllIndexes(arr, o => o.name === val)
      return result
    },
    eagType: function (file) {
      if (this.isPdf(file)) {
        return 'Pdf'
      }
      if (this.isImage(file)) {
        return 'Image'
      }
      if (this.isVideo(file)) {
        return 'Video'
      }
      return 'Other'
    },
    openCustomerRecord: function () {
      this.$router.push({name: 'EditCustomer', params: { id: this.customerId, defaultTab: 'Photo' }})
    },
    onPreview: function (file) {
      if (this.inline) {
        let that = this
        that.isLoading = true
        this.$nextTick(() => {
          let completeUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(file.imageData)
          file.src = file.imageData.startsWith('http') ? completeUrl : file.imageData
          that.selectedFile = file
          that.isPdfViewerActive = true
          that.$emit('filePreviewed', file)
        })
      } else {
        var fileData = file.imageData
        if (fileData.startsWith('http')) {
          var extractedFileName = fileData.split('/').pop()
          var docUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(fileData)
          this.pdfModalFile = {
            name: extractedFileName,
            src: docUrl,
            fileName: extractedFileName,
            key: this.$uuid.v4()
          }
        } else {
          this.pdfModalFile = {
            name: file.name,
            src: fileData,
            fileName: file.name,
            key: this.$uuid.v4()
          }
        }
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.pdfModalFile = null
    }
  },
  watch: {
    selectedFiles: function (newValue, oldValue) {
      if (this.enabled) {
        this.$emit('input', newValue)
        if (this.required && this.$validator) {
          this.$validator.validate(this.validationName)
        }
      }
    },
    value: function (newValue, oldValue) {
      if (newValue === null) {
        this.selectedFiles = []
      } else {
        this.selectedFiles = newValue
      }
      this.hasFiles = (this.validFiles.length > 0) ? 'YES' : null
    },
    isPdfViewerActive: function (newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        document.body.classList.toggle('is-noscroll', false)
      }
    },
    croppedCustomerImages: function (newValue, oldValue) {
      if (newValue && newValue.length > 0) {
        this.loadLocalFiles(newValue)
      }
    },
    isVerifiedDisabled: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        this.verified = false
      }
    },
    verified: function (newValue, oldValue) {
      if (newValue !== null && newValue !== undefined) {
        this.selectedFiles.forEach((file) => {
          file.verified = newValue
        })
        this.$emit('input', this.selectedFiles)
      }
    }
  },
  mounted: function () {
    if (this.selectedFiles.length > 0) {
      this.verified = this.selectedFiles[0].verified
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.iPhoto {
  margin: 5px;
}

.item-container {
  display: flex;
  flex-wrap: wrap;
}

.is-file-container {
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding:1rem;
}

.columns {
  display:flex;
}

.column {
  padding:0 !important
}

.top-margin-is-1 {
  margin-top:12px
}

.is-128xauto {
  display: -webkit-box;
  height:128px;
  width:auto;
  align-items: center;
  justify-content: center
}

.table-image-size {
  max-height: 24px;
  max-width: 24px;
  cursor: pointer;
}

.is-64xauto {
  display: -webkit-box;
  height:64px;
  width:auto;
  align-items: center;
  justify-content: center
}

.image img {
  display: block;
  height:auto;
  width:auto;
}

.upload-instructions {
  width:118px;
  height:97px;
  margin:1em;
}

.extra-top-margin {
  margin-top:10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 769px), print {
  .modal-content, .modal-card {
      position:relative;
  }
}

.verify {
  align-items: flex-start;
}

.inline-action-container {
  position: relative;
  z-index:10000;
}

.audit-container {
  font-size: 0.7rem;
}

.paste-ctrl-focused {
  color: red;
}
</style>
