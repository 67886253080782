import axios from 'axios'
import baseService from './baseService'
import roleData from './data/role'
import Role from '@/models/Role'

const roleService = {
  allRoles (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roleData.all)
      } else {
        axios
          .get('/role/list?', baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Role.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roleData)
      } else {
        axios
          .post('/role/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            let apiResults = {...response.data}
            apiResults.results = Role.fromAPIList(response.data.results)
            resolve(apiResults)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (roleId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roleData.all.find((x) => x.id === roleId))
      } else {
        axios
          .post('/role/get?id=' + roleId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (roleModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var role = {
          id: 999,
          name: roleModel.name,
          status: 'Active'
        }
        roleData.all.push(role)
        resolve(role)
      } else {
        axios
          .post('/role/save', roleModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (roleModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var role = roleData.all.find((x) => x.id === roleModel.id)
        if (role) {
          role.name = roleModel.name
          role.status = roleModel.status
        }
        resolve(role)
      } else {
        axios
          .post('/role/update', roleModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (roleModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        roleModels.forEach(element => {
          roleData.all.remove((x) => x.id === element.id)
        })
        resolve(roleData.all)
      } else {
        var ids = []
        roleModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/role/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default roleService
