<template>
  <div class="box">
    <div class="columns is-multiline">
      <div class="column is-6">
        <div>
          <inventory-selector
            v-if="!partialMode"
            :required="true"
            v-model="selectedLeaseInventory"
            :selectedDealer="this.selectedDealer"
            :useValidator="this.$validator"
            :validateAs="(mode === 'Trade') ? 'trade inventory' : 'lease inventory'"
            :soldStockNo="soldStockNo"
            :filteredTypes="allowedInventoryTypes"
            :createDefaultType="defaultInventoryCreateType"
            :createDefaultSalesPerson="defaultSalesPerson"
            :createDefaultSellerType="defaultSellerType"
            :createDefaultSeller="defaultSeller"
            :showInstockOnly="inStockOnly"
            :isInventorySelectorDisabled="isReadOnly"
            :allowNew="!isReadOnly"
            :scope="scope"
            :filteredStatuses="allowedInventoryStatuses"
            :soldStatus="[0]"
            :showPreTrades="showPreTrades">
          </inventory-selector>
          <div v-if="mode === 'Trade' && !partialMode" class="field is-size-7 is-pulled-right">
            <b-checkbox
              :native-value="false"
              data-vv-scope="recipient-form"
              name="chkPreTrades"
              type="is-info"
              v-model="showPreTrades">
              include PreTrades
            </b-checkbox>
          </div>
        </div>
        <div class="mt-35 field" v-if="showStockNoLabel && selectedLeaseInventory && selectedLeaseInventory.stockNo && !partialMode">
          <label class="label">Trade Stock No</label>
          <div class="control">
            <input disabled :class="{'input': true}" type="text" placeholder="auto fill" :value="this.selectedLeaseInventory.stockNo">
          </div>
        </div>
        <div class="field" v-if="showPrice && selectedLeaseInventory">
          <label class="label" style="width: max-content;">{{ tradeAllowanceLabel }}<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>

          <div class="field has-addons">
            <div class="control has-icons-left">
              <money :name="instanceName + '.salePrice'"  :data-vv-scope="scope" data-vv-as="trade allowance" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has(validationName + '.salePrice') }" type="text" placeholder="0000.00" v-model="value.salePrice" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
              <span v-show="errors.has(validationName + 'salePrice')" class="help is-danger">
                <i v-show="errors.has(validationName + 'salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'salePrice') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
            </div>
            <button type="button" v-if="showTradeAllowanceApproveBtn" :disabled="!hasApproveTradeAllowanceAccess && isReadOnly" class="button" style="margin-left: 3px;" @click="onApproveTradeAllowance()">
              <i class="fal fa-lg has-text-danger fa-exclamation-circle" v-tooltip="getTradeAllowanceApproveIconTitle"></i>
            </button>
            <i v-if="showTradeAllowanceApprovedIcon" class="ml-5 grid-icon fa-fw fal fa-check-circle has-text-success fa-lg" style="line-height: normal;" v-tooltip="getTradeAllowanceApproveIconTitle"/>
          </div>
        </div>
        <div class="field" v-if="mode === 'Trade' && showStockNoLabel && selectedLeaseInventory && selectedLeaseInventory.stockNo && !partialMode">
          <div class="control">
            <button-list-selector validateAs="title status" label="Title Status" :scope="scope" :required="titleStatusRequired" v-model="selectedTitleStatus" :availableValues="allowedTitleStatusOptions" :enabled="!isReadOnly"></button-list-selector>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field" v-if="!partialMode">
          <label class="label">Comment</label>
          <div class="control has-icons-left has-icons-right">
            <textarea name="lineComment" data-vv-as="value.lineComment" :class="{'textarea': true }" rows="6" v-model="value.lineComment" style="height:100%" :disabled="isReadOnly"></textarea>
          </div>
        </div>
        <div class="field" v-if="showPrice && selectedLeaseInventory && !partialMode">
          <label class="label">{{acvPurchasePriceLabel}}<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="field has-addons">
            <div class="control has-icons-left" v-if="isForPpaAppraisalType">
              <money
                :data-vv-scope="scope" :name="instanceName + '.proposedCost'"  data-vv-as="proposed cost"
                v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has(instanceName + '.proposedCost') }" type="text" placeholder="0000.00"
                v-model="selectedLeaseInventory.proposedCost" v-bind="$globalMoneyFormat">
              </money>
              <span v-show="errors.has(validationName + 'proposedCost')" class="help is-danger">
                <i v-show="errors.has(validationName + 'proposedCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'proposedCost') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
            </div>
            <div class="control has-icons-left" v-else>
              <money
                :disabled="isPurchasePriceLocked || isReadOnly" :data-vv-scope="scope" :name="instanceName + '.purchasePrice'"  data-vv-as="purchase price"
                v-validate="{'required': true, decimal:2, 'max_value': purchasedCostLimit}"
                :class="{'input': true, 'is-danger': errors.has(instanceName + '.purchasePrice') }" type="text" placeholder="0000.00"
                v-model="selectedLeaseInventory.purchaseCost" v-bind="$globalMoneyFormat">
              </money>
              <span v-show="errors.has(validationName + 'purchasePrice')" class="help is-danger">
                <i v-show="errors.has(validationName + 'purchasePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'purchasePrice') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
            </div>
            <button type="button" :disabled="isReadOnly || !hasPurchaseCostAccess" class="button control lock-control" style="margin-left: 3px;" @click="togglePurchasePriceLockStatus()">
              <span class="icon is-small">
                <i class="has-text-primary" :class="{'fal': true, 'fa-unlock': isPurchasePriceLocked, 'fa-lock': !isPurchasePriceLocked}"></i>
              </span>
            </button>
          </div>
        </div>
        <div class="field" v-if="mode === 'Trade' && selectedTitleStatus && selectedTitleStatus.name === 'Yes' && !partialMode">
          <div class="control is-flex">
            <switch-selector type="is-info" label="Hard Copy Received" v-model="originalTitleReceived" :isDisabled="isReadOnly"></switch-selector>
            <label class="ml-10 is-pulled-left has-text-info is-size-7" v-if="titleReceivedLabel">{{ titleReceivedLabel }}</label>
          </div>
        </div>
      </div>
      <div class="box column is-12" v-if="mode === 'Trade' && showTitleStatus && showTitleDocs && !partialMode">
        <section-header title="Title Documentation"></section-header>
        <div class="columns">
          <div class="column is-4">
            <file-selector
            v-model="frontTitleFile"
            :scope="scope"
            label="Title Photo (front)"
            :required="isTitleDocsRequired"
            :multiple="false"
            :isBoxed="false"
            v-if="showTitleDocs"
            :enabled="!isReadOnly"
            :usePortalViewer="false"
            @uploadStarted="uploadStarted"
            @uploadComplete="uploadComplete">
            </file-selector>
            <file-selector
            v-model="backTitleFile"
            :scope="scope" label="Title Photo (back)"
            :required="isTitleDocsRequired"
            :multiple="false"
            :isBoxed="false"
            v-if="showTitleDocs"
            :enabled="!isReadOnly"
            :usePortalViewer="false"
            @uploadStarted="uploadStarted"
            @uploadComplete="uploadComplete">
            </file-selector>
          </div>
          <div class="column is-4">
            <file-selector
            v-model="payOffInfoFile"
            :scope="scope"
            label="Payoff Info"
            :required="isTitlePayOffDocsRequired"
            :multiple="false"
            :isBoxed="false"
            v-if="showTitleDocs"
            :enabled="!isReadOnly"
            :usePortalViewer="false"
            @uploadStarted="uploadStarted"
            @uploadComplete="uploadComplete">
            </file-selector>
            <file-selector
            v-model="payOffLienSatisfactionFile"
            label="Payoff Satisfaction Letter"
            :required="false"
            :multiple="false"
            :isBoxed="false"
            v-if="showTitleDocs"
            :enabled="!isReadOnly"
            :usePortalViewer="false"
            @uploadStarted="uploadStarted"
            @uploadComplete="uploadComplete">
            </file-selector>
          </div>
          <div class="column is-4">
            <file-selector
            v-model="factoryInvoiceFiles"
            :scope="scope"
            label="Factory Invoice"
            :required="false"
            :multiple="false"
            :isBoxed="false"
            v-if="showTitleDocs"
            :enabled="!isReadOnly"
            :usePortalViewer="false"
            @uploadStarted="uploadStarted"
            @uploadComplete="uploadComplete">
            </file-selector>
          </div>
        </div>
      </div>
      <div class="box column is-12" v-if="mode === 'Trade' && isTitlePayOff && !partialMode">
        <section-header title="Banking Details"></section-header>
        <div class="columns">
          <div class="column is-6">
            <bank-selector v-model="selectedBank" label="Lienholder Bank" validateAs="bank" :required="true" :isDisabled="isReadOnly" :scope="scope"></bank-selector>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Account No<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control">
                <input data-vv-as="account no" :data-vv-scope="scope" :name="instanceName + '.accNo'"  v-validate="{'required': true}" :class="{'input': true, 'is-danger': errors.has(validationName + 'accNo') }" type="text" placeholder="#####" v-model="value.forInventory.accNo" :disabled="isReadOnly">
                <span v-show="errors.has(validationName + 'accNo')" class="help is-danger"><i v-show="errors.has(validationName + 'accNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'accNo') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label no-content-wrap">Payoff Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control has-icons-left">
                <money :name="instanceName + '.payoffAmount'" :data-vv-scope="scope" data-vv-as="payoff amount" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(validationName + 'payoffAmount') }" type="text" placeholder="0.00" v-model="value.forInventory.payoffAmount" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                <span v-show="errors.has(validationName + 'payoffAmount')" class="help is-danger"><i v-show="errors.has(validationName + 'payoffAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'payoffAmount') }}</span>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label no-content-wrap">Monthly Payment Due<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control has-icons-left">
                <money :name="instanceName + '.monthlyPaymentDue'" :data-vv-scope="scope" data-vv-as="monthly payment due" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(validationName + 'monthlyPaymentDue') }" type="text" placeholder="0.00" v-model="value.forInventory.monthlyPaymentDue" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                <span v-show="errors.has(validationName + 'monthlyPaymentDue')" class="help is-danger"><i v-show="errors.has(validationName + 'monthlyPaymentDue')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'monthlyPaymentDue') }}</span>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label no-content-wrap">Good Until Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control has-icons-left">
                <el-date-picker
                  v-model="goodUntilDate"
                  type="date"
                  data-vv-as="good until date"
                  v-validate="{'required': true}"
                  data-vv-validate-on="input|close"
                  :name="instanceName + '.goodUntilDate'"
                  :data-vv-scope="scope"
                  placeholder="Click to select..."
                  popper-class="datepicker-container"
                  :disabled="isReadOnly">
                </el-date-picker>
                <span v-show="errors.has(validationName + 'goodUntilDate')" class="help is-danger"><i v-show="errors.has(validationName + 'goodUntilDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'goodUntilDate') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="purchasePriceWarningActive" scroll="keep" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning: {{acvPurchasePriceLabel}} Adjustment</p>
        </header>
        <section class="modal-card-body">
          <div class="notification is-warning is-flex">
            <i class="fas fa-exclamation-triangle is-warning fa-2x"></i>
            <div class="ml-10">
              Updating the {{acvPurchasePriceLabel}} will automatically update the {{acvPurchasePriceLabel}} on stock no {{this.selectedLeaseInventory ? this.selectedLeaseInventory.stockNo : '-'}}.
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="purchasePriceWarningActive = false" type="button" :disabled="isReadOnly">Ok</button>
      </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import InventorySelector from '@/components/generic/InventorySelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import BankSelector from '@/components/generic/BankSelector'
import SectionHeader from '@/components/generic/SectionHeader'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'LeaseRenewalCapture',
  mixins: [utilitiesMixin],
  components: {
    'inventory-selector': InventorySelector,
    'button-list-selector': ButtonListSelector,
    'switch-selector': SwitchSelector,
    'file-selector': FileSelector,
    'bank-selector': BankSelector,
    'section-header': SectionHeader
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    showPrice: {
      type: Boolean,
      default: false
    },
    showStockNoLabel: {
      type: Boolean,
      default: false
    },
    soldStockNo: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'Trade'
    },
    defaultSalesPerson: {
      type: Object,
      default: null
    },
    defaultSellerType: {
      type: Object,
      default: null
    },
    defaultSeller: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    isTitleStatusRequiredOveride: {
      type: Boolean,
      default: false
    },
    partialMode: {
      type: Boolean,
      default: false
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: 'lease-renewal-capture-' + this.$uuid.v4(),
      selectedLeaseInventory: null,
      isPurchasePriceLocked: true,
      purchasePriceWarningActive: false,
      selectedPossessionStatus: null,
      selectedSource: null,
      selectedTitleStatus: null,
      selectedTypeStatus: null,
      originalTitleReceived: false,
      frontTitleFile: [],
      payOffInfoFile: [],
      backTitleFile: [],
      payOffLienSatisfactionFile: [],
      factoryInvoiceFiles: [],
      selectedBank: null,
      goodUntilDate: null,
      tradeAllowanceApproved: false,
      tradeAllowanceApprovedAt: '',
      tradeAllowanceApprovedByName: '',
      isUploadingFile: false,
      fileUploadCount: 0,
      showPreTrades: false
    }
  },
  computed: {
    ...mapUserGetters(['currentUser', 'hasFeatureAccess']),
    ...mapConfigGetters(['definedTypes']),
    validationName: function () {
      return (this.scope) ? this.scope + '.' + this.instanceName + '.' : this.instanceName + '.'
    },
    allowedInventoryTypes: function () {
      if (this.mode === 'Trade') {
        return [1, 3, 4, 5]
      } else {
        return [6]
      }
    },
    defaultInventoryCreateType: function () {
      if (this.mode === 'Trade') {
        return this.definedTypes.inventoryTypes.find(x => x.id === 4)
      } else {
        return this.definedTypes.inventoryTypes.find(x => x.id === 6)
      }
    },
    inStockOnly: function () {
      if (this.mode === 'Trade') {
        return false
      } else {
        return true
      }
    },
    showTitleStatus: function () {
      if (!this.value) {
        return true
      }

      if (!this.value.forInventory || (this.value.forInventory && !this.value.forInventory.type)) {
        return true
      } else {
        if (this.value.forInventory && this.value.forInventory.type === 'New') {
          return !(this.selectedSource && this.selectedSource.id === this.definedTypes.allowedSourceTypes.Factory)
        } else {
          return true
        }
      }
    },
    titleStatusRequired: function () {
      if (this.isTitleStatusRequiredOveride) {
        return true
      }
      let isIncoming = this.selectedPossessionStatus && this.selectedPossessionStatus.id === 1
      let isFactorySource = this.selectedSource && this.selectedSource.id === this.definedTypes.allowedSourceTypes.Factory
      return !isIncoming && !isFactorySource
    },
    allowedTitleStatusOptions: function () {
      let options = this.definedTypes.titleStatusOptions.options
      if (!this.selectedTypeStatus) {
        options = this.definedTypes.titleStatusOptions.options
      } else {
        if (this.selectedTypeStatus.id === 0) {
          if (this.selectedSource && this.selectedSource.id !== this.definedTypes.allowedSourceTypes.Factory) {
            options = this.definedTypes.titleStatusOptions.options.filter((x) => x.id !== this.definedTypes.titleStatusOptions.Lost && x.id !== this.definedTypes.titleStatusOptions.PayOff)
          } else {
            options = this.definedTypes.titleStatusOptions.options
          }
        } else {
          options = this.definedTypes.titleStatusOptions.options
        }
      }

      return (this.isTitleStatusRequiredOveride) ? this.definedTypes.titleStatusOptions.options.filter((x) => x.id !== this.definedTypes.titleStatusOptions.Pending) : options
    },
    showTitleDocs: function () {
      if (!this.value) {
        return false
      }

      return this.value.forInventory && this.selectedTitleStatus && (this.selectedTitleStatus.id === 1 || this.selectedTitleStatus.id === 0)
    },
    isTitlePayOff: function () {
      return (this.selectedTitleStatus && this.selectedTitleStatus.id === 1 && this.value.forInventory)
    },
    isTitleYes: function () {
      return this.selectedTitleStatus && this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Yes
    },
    isTitlePayOffDocsRequired: function () {
      return this.isTitlePayOff && this.showTitleDocs && this.isTitleStatusRequiredOveride
    },
    isTitleDocsRequired: function () {
      return this.isTitleYes && this.showTitleDocs
    },
    allowedInventoryStatuses: function () {
      return [0, 6, 7, 8]
    },
    getTradeAllowanceApproveIconTitle () {
      let approvedText =
      'Trade allowance is approved. <br/>' +
      'Approved By: ' + this.tradeAllowanceApprovedByName + '<br/>' +
      'Approved Date: ' + this.formattedTradeAllowanceApprovedDate
      let notApprovedText = 'Trade allowance is not approved.'
      notApprovedText += this.hasApproveTradeAllowanceAccess ? '<br/> Click to approve trade allowance.' : '<br/> Please contact used car manager to approve this value.'
      return this.tradeAllowanceApproved ? approvedText : notApprovedText
    },
    formattedTradeAllowanceApprovedDate () {
      if (this.tradeAllowanceApprovedAt && this.tradeAllowanceApprovedAt !== '') {
        return this.$options.filters.formatDateOnlyShort(this.tradeAllowanceApprovedAt)
      }
      return ''
    },
    hasApproveTradeAllowanceAccess () {
      return this.hasFeatureAccess('deal.approvetradeallowance')
    },
    showTradeAllowanceApproveBtn () {
      return this.tradeAllowanceApproved === false
    },
    showTradeAllowanceApprovedIcon () {
      return this.tradeAllowanceApproved === true
    },
    hasPurchaseCostAccess () {
      return this.hasFeatureAccess('deal.modify.unlockpurchasecost')
    },
    acvPurchasePriceLabel () {
      return this.isForPpaAppraisalType === true ? 'ACV/Proposed Purchase Cost' : 'ACV/Purchase Cost'
    },
    isForPpaAppraisalType () {
      return this.value && this.value.forInventory && this.value.forInventory.type === 'ForPpaAppraisal'
    },
    isPpaConverted () {
      return this.value.forInventory && this.value.forInventory.isPpaConverted
    },
    purchasedCostLimit () {
      if (!this.value.forInventory) return 0
      if (!this.isPpaConverted || this.hasExceedPpaProposedCostAccess) return 99999999.99
      return (this.value.forInventory.cloneSourceInventoryId > 0 && this.value.forInventory.cloneSourceProposedCost > 0) ? this.value.forInventory.cloneSourceProposedCost : 99999999.99
    },
    hasExceedPpaProposedCostAccess () {
      return this.hasFeatureAccess('inventory.modify.exceed-ppa-proposed-cost', false)
    },
    titleReceivedLabel: function () {
      if (this.selectedLeaseInventory && this.selectedLeaseInventory.titleReceivedStatusModifiedByFullName && this.selectedLeaseInventory.titleReceivedStatusModifiedAt) {
        return this.selectedLeaseInventory.titleReceivedStatusModifiedByFullName + ' ' + this.$options.filters.formatDate(this.selectedLeaseInventory.titleReceivedStatusModifiedAt)
      }
      return ''
    },
    tradeAllowanceLabel: function () {
      if (this.partialMode && this.selectedLeaseInventory) return 'Trade Allowance ( ' + this.selectedLeaseInventory.stockNo + ')'
      return 'Trade Allowance'
    }
  },
  methods: {
    togglePurchasePriceLockStatus: function () {
      this.isPurchasePriceLocked = !this.isPurchasePriceLocked
      if (this.isPurchasePriceLocked === false) {
        this.purchasePriceWarningActive = true
      }
    },
    initValues: function (setselectedLeaseInventory) {
      if (this.value && this.value.forInventory) {
        if (setselectedLeaseInventory) {
          this.selectedLeaseInventory = this.value.forInventory
        }

        if (this.value.tradeAllowanceApproved !== undefined && this.value.tradeAllowanceApproved !== null) {
          this.tradeAllowanceApprovedAt = this.value.tradeAllowanceApprovedAt !== undefined && this.value.tradeAllowanceApprovedAt !== null ? this.value.tradeAllowanceApprovedAt : ''
          this.tradeAllowanceApprovedByName = this.value.tradeAllowanceApprovedByName !== undefined && this.value.tradeAllowanceApprovedByName !== null ? this.value.tradeAllowanceApprovedByName : ''
          this.tradeAllowanceApproved = this.value.tradeAllowanceApproved
        }

        if (this.value.forInventory.status !== 'Imported') {
          this.selectedPossessionStatus = this.definedTypes.possessionStatuses.find((x) => (x.name === this.value.forInventory.possessionStatus || x.altName === this.value.forInventory.possessionStatus))
        }

        this.selectedSource = this.value.forInventory.purchasedFromType === null ? null : this.definedTypes.allowedSourceTypes.options.find((x) => (x.name === this.value.forInventory.purchasedFromType || x.altName === this.value.forInventory.purchasedFromType))
        if ((this.isTitleStatusRequiredOveride && this.value.forInventory.titleStatus !== 'Pending') || !this.isTitleStatusRequiredOveride) {
          this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.name === this.value.forInventory.titleStatus || x.altName === this.value.forInventory.titleStatus))
        }

        this.originalTitleReceived = this.value.forInventory.originalTitleReceived
        this.goodUntilDate = this.value.forInventory.goodUntilDate ? new Date(this.value.forInventory.goodUntilDate) : null

        if (this.value.forInventory.bankId !== null && this.value.forInventory.bankId !== 0) {
          this.selectedBank = {
            id: this.value.forInventory.bankId,
            name: this.value.forInventory.bankName
          }
        }

        this.frontTitleFile = []
        this.payOffInfoFile = []
        this.backTitleFile = []
        this.payOffLienSatisfactionFile = []
        this.factoryInvoiceFiles = []
        this.value.forInventory.attachments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)
          if (x.classType === 'TitleFront') {
            this.frontTitleFile.push(x)
          } else if (x.classType === 'TitleBack') {
            this.backTitleFile.push(x)
          } else if (x.classType === 'PayOffInfo') {
            this.payOffInfoFile.push(x)
          } else if (x.classType === 'PayOffLienSatisfaction') {
            this.payOffLienSatisfactionFile.push(x)
          } else if (x.classType === 'FactoryInvoice') {
            this.factoryInvoiceFiles.push(x)
          }
        })

        if (this.value.forInventory.sold) {
          this.showPreTrades = true
        }
      }
    },
    onTradeAllowanceChange: function () {
      let isPpaAppraisal = this.value && this.value.forInventory && this.value.forInventory.type === 'ForPpaAppraisal'
      let valueForDeduction = 0
      // let valueForDeduction = isPpaAppraisal ? (this.selectedLeaseInventory.proposedCost || 0) : (this.selectedLeaseInventory.purchaseCost || 0)

      if (isPpaAppraisal && this.selectedLeaseInventory) {
        if (this.selectedLeaseInventory.proposedCost) {
          valueForDeduction = (this.selectedLeaseInventory.proposedCost || 0)
        }
      } else if (this.selectedLeaseInventory && this.selectedLeaseInventory.purchaseCost) {
        valueForDeduction = (this.selectedLeaseInventory.purchaseCost || 0)
      }

      this.value.acvDifference = this.value.salePrice - valueForDeduction
      this.resetTradeAllowanceValues()
    },
    onApproveTradeAllowance: function () {
      if (this.tradeAllowanceApproved === false && this.hasApproveTradeAllowanceAccess === true) {
        this.tradeAllowanceApprovedAt = this.$options.filters.formatDateOnlyShort(new Date())
        this.tradeAllowanceApprovedByName = this.currentUser.fullName
        this.tradeAllowanceApproved = true

        this.value.tradeAllowanceApproved = true
        this.value.tradeAllowanceApprovedAt = this.$options.filters.formatDateOnlyShort(new Date())
        this.value.tradeAllowanceApprovedByName = this.currentUser.fullName
      }
    },
    resetTradeAllowanceValues: function () {
      if (this.tradeAllowanceApproved === true) {
        this.tradeAllowanceApprovedAt = ''
        this.tradeAllowanceApprovedByName = ''
        this.tradeAllowanceApproved = false

        this.value.tradeAllowanceApproved = false
        this.value.tradeAllowanceApprovedAt = ''
        this.value.tradeAllowanceApprovedByName = ''
      }
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
      eventBus.$emit('file-uploads-started')
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
        eventBus.$emit('file-uploads-completed')
      }
    },
    clearSelectedInventory: function () {
      this.frontTitleFile = []
      this.payOffInfoFile = []
      this.backTitleFile = []
      this.payOffLienSatisfactionFile = []
      this.factoryInvoiceFiles = []
      this.value.forInventory = null
      this.selectedLeaseInventory = null
    }
  },
  watch: {
    selectedLeaseInventory: function (newValue, oldValue) {
      if (!newValue && !this.value && !this.value.forInventory && newValue.id === this.value.forInventory.id) {
        return
      }

      if (newValue !== null) {
        if (newValue.purchaseCost === null || newValue.purchaseCost === undefined || newValue.purchaseCost === '') {
          // we need to set the purchase to 0.00 default if not set
          newValue.purchaseCost = 0.00
        }
        if (newValue.proposedCost === null || newValue.proposedCost === undefined || newValue.proposedCost === '') {
          // we need to set the proposedCost to 0.00 default if not set
          newValue.proposedCost = 0.00
        }
        this.value.salePrice = newValue.proposedTradeAllowance
        this.value.forInventory = newValue
        this.initValues(false)
      }
    },
    goodUntilDate: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.value.forInventory.goodUntilDate = newValue
      }
    },
    selectedBank: function (newValue, oldValue) {
      if (newValue) {
        this.value.forInventory.bankId = newValue ? newValue.id : null
      }
    },
    frontTitleFile: function (newValue, oldValue) {
      if (newValue) {
        if (this.frontTitleFile.length > 0) {
          let frontTitleFile = this.frontTitleFile.filter((y) => !y.id || y.id <= 0).map((x) => {
            return {
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'TitleFront'
            }
          })

          this.value.forInventory.attachments.push(...frontTitleFile)
        }
      }
    },
    payOffInfoFile: function (newValue, oldValue) {
      if (newValue) {
        if (this.payOffInfoFile.length > 0) {
          let payOffInfoFile = this.payOffInfoFile.filter((y) => !y.id || y.id <= 0).map((x) => {
            return {
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'PayOffInfo'
            }
          })
          this.value.forInventory.attachments.push(...payOffInfoFile)
        }
      }
    },
    backTitleFile: function (newValue, oldValue) {
      if (newValue) {
        if (this.backTitleFile.length > 0) {
          let backTitleFile = this.backTitleFile.filter((y) => !y.id || y.id <= 0).map((x) => {
            return {
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'TitleBack'
            }
          })
          this.value.forInventory.attachments.push(...backTitleFile)
        }
      }
    },
    payOffLienSatisfactionFile: function (newValue, oldValue) {
      if (newValue) {
        if (this.payOffLienSatisfactionFile.length > 0) {
          let payOffLienSatisfactionFile = this.payOffLienSatisfactionFile.filter((y) => !y.id || y.id <= 0).map((x) => {
            return {
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'PayOffLienSatisfaction'
            }
          })
          this.value.forInventory.attachments.push(...payOffLienSatisfactionFile)
        }
      }
    },
    factoryInvoiceFiles: function (newValue, oldValue) {
      if (newValue) {
        if (this.factoryInvoiceFiles.length > 0) {
          let factoryInvoiceFiles = this.factoryInvoiceFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
            return {
              uploadName: x.name,
              uploadSize: x.size,
              uniqueId: x.uniqueId,
              type: x.type,
              classType: 'FactoryInvoice'
            }
          })
          this.value.forInventory.attachments.push(...factoryInvoiceFiles)
        }
      }
    },
    selectedTitleStatus: function (newValue, oldValue) {
      if (newValue) {
        this.value.forInventory.titleStatus = this.selectedTitleStatus.name
      }
    },
    originalTitleReceived: function (newValue, oldValue) {
      this.value.forInventory.originalTitleReceived = this.originalTitleReceived
    },
    'value.salePrice': function (newValue, oldValue) {
      this.onTradeAllowanceChange()
    },
    'value.tradeAllowanceApproved': function (newValue, oldValue) {
      this.tradeAllowanceApproved = newValue
    },
    'selectedLeaseInventory.purchaseCost': function (newValue, oldValue) {
      if (this.value && this.selectedLeaseInventory) {
        let isPpaAppraisal = this.value && this.value.forInventory && this.value.forInventory.type === 'ForPpaAppraisal'
        let valueForDeduction = isPpaAppraisal ? (this.selectedLeaseInventory.proposedCost || 0) : (this.selectedLeaseInventory.purchaseCost || 0)
        this.value.acvDifference = this.value.salePrice - valueForDeduction
      }
    },
    showPreTrades: function (newValue, oldValue) {
      if (!this.selectedLeaseInventory) return

      if (newValue === false) {
        // if selected inventory is sold, we must remove it
        if (this.selectedLeaseInventory.sold) {
          this.clearSelectedInventory()
        }
      }
    }
  },
  mounted: function () {
    this.initValues(true)
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<style scoped>
  .box {
      background-color: #f6f8fa;
  }

  .lock-control {
    margin-left:5px;
  }

   .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card {
    overflow-y: auto !important;
    max-width: 480px !important;
  }

  .modal-card-body {
    height: calc(25vh - 40px);
  }
</style>
