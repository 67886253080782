<template>
  <portal to="global-modal-portal" :disabled="!usePortal">
    <b-modal :active.sync="isModalShowing" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
        </header>
        <section class="modal-card-body">
          <slot></slot>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirmDelete">Delete</button>
          <button class="button" @click="onCancelDelete">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>
export default {
  name: 'ConfirmDeleteModalComponent',
  props: {
    title: {
      type: String,
      default: 'Confirm Delete Action'
    },
    usePortal: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      isModalShowing: true
    }
  },
  computed: {
  },
  methods: {
    onConfirmDelete: function () {
      this.$emit('on-confirm-delete')
      this.isModalShowing = false
    },
    onCancelDelete: function () {
      this.isModalShowing = false
    }
  },
  watch: {
    isModalShowing: function (newValue, oldValue) {
      if (!newValue) {
        this.$emit('on-cancel-delete')
      }
    }
  },
  mounted () {
    this.isModalShowing = true
  }
}
</script>

<style>

</style>
