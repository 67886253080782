import axios from 'axios'
import baseService from './baseService'

const serviceRequestLineService = {
  addNote (serviceRequestNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequestline/addnote', serviceRequestNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequestline/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequestline/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequestline/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/servicerequestline/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByServiceRequestLineId (serviceRequestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .get('/servicerequestline/servicerequestlinenotes?id=' + serviceRequestId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default serviceRequestLineService
