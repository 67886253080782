<template>
    <span v-if="!sendViewMediaControlActive">
      <span class="list-icon clickable" :title="listIconTitle" @click.stop.prevent="openModal()" v-if="isListMode">
        <i class="fal fa-envelope fa-lg" :id="instanceName"></i>
      </span>
      <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button class="button" :id="instanceName" @click.stop.prevent="openModal()" :title="listIconTitle" >
          <b-icon pack="fal" icon="envelope" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </span>
    <portal to="global-modal-portal" :disable="true" v-else>
      <b-modal :active.sync="sendViewMediaControlActive" scroll="keep" :has-modal-card="true" :full-screen="true" >
        <div class="modal-card">
          <form @submit.prevent="onValidate" data-vv-scope="recipient-form">
            <header class="modal-card-head">
              <p class="modal-card-title">Send request to view vehicle service information and media </p>
            </header>
            <section class="modal-card-body view-container" style="padding:12px" v-if="!isLoading">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                <div class="columns is-multiline is-mobile">
                  <div class="column is-6">
                    <button-list-selector
                      validateAs="recipient type"
                      label="Recipient Type"
                      :required="true"
                      v-model="selectedRecipientType"
                      :allowDeselection="false"
                      :availableValues="recipientTypes"
                      :useValidator="thisValidator"
                      :enabled="true"
                      data-vv-scope="recipient-form"
                      scope="recipient-form">
                    </button-list-selector>
                  </div>
                  <div id="entity-selector" class="column is-12">
                    <supplier-selector
                      v-if="isSupplierRecipient"
                      class="is-fullwidth"
                      v-validate="'required'"
                      label="Recipient Supplier"
                      validateAs="Recipient Supplier"
                      placeholder="Type to search for a loaded supplier"
                      v-model="selectedRecipientSupplier"
                      :required="true"
                      :useValidator="thisValidator"
                      data-vv-scope="recipient-form">
                    </supplier-selector>

                    <customer-selector
                      v-if="isCustomerRecipient"
                      label="Recipient Customer"
                      validateAs="Recipient Customer"
                      class="is-fullwidth"
                      v-validate="'required'"
                      v-model="selectedRecipientCustomer"
                      :selectedDealer="selectedDealer"
                      :required="true"
                      :showNewAction="false"
                      :useValidator="thisValidator"
                      tagPlaceholder="press enter to create a customer to send a inventory media view request to"
                      data-vv-scope="recipient-form">
                    </customer-selector>
                  </div>
                  <div class="column is-12" v-show="recipientSelected">
                    <div class="field">
                      <label class="label">Recipients Email<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <multiselect
                        :multiple="true"
                        :taggable="true"
                        v-validate="{'required': true}"
                        data-vv-scope="recipient-form"
                        data-vv-as="recipient email"
                        data-vv-validate-on="input|close"
                        :class="{'is-danger': errors.has('recipient-form.recipients') }"
                        @tag="onRecipientAdded"
                        name="recipients"
                        trackBy="email"
                        tagPlaceholder="Enter to add email address"
                        v-model="selectedRecipients"
                        :options="selectedRecipients"
                        :disabled="false"
                        :custom-label="fullNameWithEmail"
                        ref="recipientEmail">
                      </multiselect>
                      <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                    </div>
                  </div>
                  <div class="column is-12" v-if="recipientSelected">
                    <div class="field">
                      <label class="label">Recipients Mobile</label>
                      <multiselect
                        :multiple="true"
                        :taggable="true"
                        data-vv-scope="recipient-form"
                        data-vv-as="recipient mobile"
                        data-vv-validate-on="input|close"
                        @tag="onRecipientMobileAdded"
                        name="recipients-mobile"
                        trackBy="mobile"
                        tagPlaceholder="Enter to add mobile number"
                        v-model="selectedRecipientsMobile"
                        :options="selectedRecipientsMobile"
                        :custom-label="fullNameWithMobile">
                      </multiselect>
                      <span v-show="mobileInvalidFormat !== ''" class="help is-danger"><i v-show="mobileInvalidFormat !== ''" class="fas fa-exclamation-triangle"></i> {{ mobileInvalidFormat }}</span>
                    </div>
                  </div>
                <div class="column is-12" v-if="recipientSelected">
                  <div class="field">
                    <label class="label">Subject</label>
                    <div class="control">
                      <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="recipientSelected">
                  <div class="field">
                    <label class="label">Message (optional)</label>
                    <div class="control">
                      <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="recipientSelected">
                  <div class="notification is-info is-flex">
                    <div>
                      <i class="fas fa-info-circle is-info fa-2x"></i>
                    </div>
                    <div class="ml-10">
                      Please note the text (link) in the message will be replaced with a unique link for the customer/supplier when notification is processed.
                    </div>
                  </div>
                </div>
                <div class="column is-12 box" v-if="serviceMediaItem && recipientSelected">
                  <section-header title="Video & associated Media"></section-header>
                  <div class="columns">
                    <div class="column is-6" v-if="videoUrl">
                      <section-header title="Video" :allowCollapse="false"></section-header>
                      <div class="field">
                        <video  style="height: 500px;" class="video_player" controls crossorigin>
                            <source :src="videoUrl" />
                        </video>
                      </div>
                    </div>
                    <div class="column is-6" v-if="!videoUrl">
                      <section-header title="Video" :allowCollapse="false"></section-header>
                      <div class="notification is-info is-flex">
                        <div>
                          <i class="fas fa-info-circle is-info fa-2x"></i>
                        </div>
                        <div class="ml-10">
                          No video associated with the selected  media entry was found.
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <section-header title="Media" :allowCollapse="false"></section-header>
                      <div class="notification is-info is-flex" v-if="selectedMediaAttachments && selectedMediaAttachments.length > 0">
                        <div>
                          <i class="fas fa-info-circle is-info fa-2x"></i>
                        </div>
                        <div class="ml-10">
                          Please select any media to include.
                        </div>
                      </div>
                      <b-table
                        class="payment-events-table"
                        :data="serviceMediaAttachments"
                        :sortable="false"
                        :row-class="() => 'wire-payment-row'"
                        :checked-rows.sync="selectedMediaAttachments"
                        checkable
                        :narrowed="true"
                        style="height: 400px !important; overflow-y: scroll;">
                        <template slot-scope="props">
                          <b-table-column field="createdBy  " label="Created By">
                            {{ props.row.createdBy }}
                          </b-table-column>
                          <b-table-column field="createdAt" label="Created">
                            {{ props.row.createdAt | formatDateOnly }}
                          </b-table-column>
                          <b-table-column field="fileName" label="File Name">
                            {{ props.row.fileName }}
                          </b-table-column>
                          <b-table-column field="file" label="File">
                            <figure class="image is-64x64" v-viewer.static="{inline: false, title: false}" style="padding: 0, 0; margin: 0, 0; float: left;">
                              <img :src="props.row.fileLocation" style="max-width: 64px !important; max-height: 64px !important;"/>
                            </figure>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <section class="section">
                            <div class="has-text-centered">
                              <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                              <p>No media associated with the selected entry was found</p>
                            </div>
                          </section>
                        </template>
                      </b-table>
                      </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="modal-card-body view-container"  v-else>
              <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }" type="submit" :disabled="!formDataValid">Save</button>
              <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
            </footer>
        </form>
        </div>
      </b-modal>
    </portal>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import serviceVideoService from '@/services/serviceVideoService'
import CustomerSelector from '@/components/generic/CustomerSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import { createNamespacedHelpers } from 'vuex'
import SectionHeader from '@/components/generic/SectionHeader'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  mixins: [validateBeforeSubmitMixin, utilitiesMixin],
  inject: {
    $validator: '$validator'
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'switch-selector': SwitchSelector,
    'error-display-component': ErrorDisplayComponent,
    'customer-selector': CustomerSelector,
    'supplier-selector': SupplierSelector,
    'section-header': SectionHeader
  },
  name: 'ServiceMediaRequestAction',
  props: {
    isListMode: {
      type: Boolean,
      default: true
    },
    serviceMediaItem: {
      type: Object,
      default: null
    },
    vehicleName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isSaving: false,
      isLoading: false,
      instanceName: this.$uuid.v4(),
      name: null,
      serverErrors: [],
      serverErrorMessage: '',
      sendViewMediaControlActive: false,
      selectedRecipientType: null,
      selectedRecipientCustomer: null,
      selectedRecipientSupplier: null,
      selectedDealer: null,
      recipientTypes: [
        {
          id: 0,
          name: 'Customer',
          altName: 'Customer'
        },
        {
          id: 1,
          name: 'Supplier',
          altName: 'Supplier'
        }
      ],
      selectedRecipients: [],
      selectedRecipientsMobile: [],
      mobileInvalidFormat: '',
      optionalMessage: '',
      serviceMediaAttachments: [],
      selectedMediaAttachments: []
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    listIconTitle: function () {
      return 'Click to send a email to customer containing a link to view service vehicle media.'
    },
    isSupplierRecipient: function () {
      if (this.selectedRecipientType && this.selectedRecipientType.name === 'Supplier') {
        return true
      }
      return false
    },
    isCustomerRecipient: function () {
      if (this.selectedRecipientType && this.selectedRecipientType.name === 'Customer') {
        return true
      }
      return false
    },
    recipientSelected: function () {
      if (this.selectedRecipientType) {
        if (this.selectedRecipientType.altName === 'Customer' && this.selectedRecipientCustomer) {
          return true
        }
        if (this.selectedRecipientType.altName === 'Supplier' && this.selectedRecipientSupplier) {
          return true
        }
      }
      return false
    },
    formDataValid: function () {
      if (this.selectedRecipientType && (this.selectedRecipientCustomer || this.selectedRecipientSupplier) && this.selectedRecipients && this.selectedRecipients.length > 0) {
        return true
      }
      return false
    },
    videoUrl: function () {
      if (this.serviceMediaItem && this.serviceMediaItem.fileLocation) {
        return this.serviceMediaItem.fileLocation
      }
      return ''
    }
  },
  methods: {
    onCancel: function () {
      this.sendViewMediaControlActive = false
      this.selectedRecipientType = null
      this.selectedRecipientCustomer = null
      this.clearAllRecipients()
      this.$emit('on-cancel')
    },
    onSave: function () {
      var emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
      var mobileNumbers = this.selectedRecipientsMobile.map(e => e.mobile).join(',')
      var includedMediaIds = []
      this.selectedMediaAttachments.forEach(element => {
        includedMediaIds.push(element.id)
      })
      let that = this
      that.isSaving = true
      let serviceMediaRequest = {
        serviceVideoId: this.serviceMediaItem.id,
        customerId: this.selectedRecipientCustomer != null ? this.selectedRecipientCustomer.id : null,
        supplierId: this.selectedRecipientSupplier != null ? this.selectedRecipientSupplier.id : null,
        toEmailAddress: emailAddresses,
        subject: this.overridingSubject,
        content: this.optionalMessage,
        toMobileNumbers: mobileNumbers,
        userId: this.currentUser.id,
        dealerId: this.currentDealer.id,
        recipientType: this.selectedRecipientType.altName,
        inventorySalesVideoId: this.serviceMediaItem.id,
        includedAttachmentMediaIds: includedMediaIds,
        vehicleName: this.vehicleName
      }
      serviceVideoService.serviceMediaRequest(serviceMediaRequest).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.failedToast('Oops! Something went wrong')
          that.serverErrors = response.errors
        } else {
          that.$emit('on-save')
          that.successToast('Success! Request created.')
          that.sendViewMediaControlActive = false
          that.resetModal()
        }
      }).catch((error) => {
        that.failedToast('Oops! Something went wrong')
        that.isSaving = false
        that.serverErrorMessage = error.message
      })
    },
    openModal () {
      this.selectedRecipients = []
      if (this.serviceMediaItem && this.serviceMediaItem.attachments) {
        this.serviceMediaAttachments = this.serviceMediaItem.attachments.map((x) => {
          return {
            id: x.id,
            name: x.name,
            createdBy: x.createdBy,
            description: x.description,
            fileName: x.fileName,
            type: x.type,
            classType: x.classType,
            fileLocation: x.fileLocation,
            createdAt: x.createdAt
          }
        })
        this.selectedMediaAttachments = [...this.serviceMediaAttachments]
      }
      this.setSubjectMessage()
      this.sendViewMediaControlActive = true
    },
    onValidate: function () {
      this.validateBeforeSubmitScoped('reject-form')
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      this.mobileInvalidFormat = ''
    },
    setSubjectMessage: function () {
      if (this.serviceMediaItem && this.serviceMediaItem.vinNo) {
        this.overridingSubject = 'Vehicle information: ' + this.serviceMediaItem.vinNo
      }
      if (this.vehicleName) {
        this.overridingSubject = 'Vehicle information: ' + this.vehicleName
      }
      this.optionalMessage = 'Please navigate to our secure webpage to view information on the vehicle being serviced using the provided link: (link)'
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }
      this.selectedRecipients.push(recipient)
    },
    onRecipientMobileAdded: function (data) {
      this.mobileInvalidFormat = ''
      if (data && data.length === 16) {
        var recipient = {
          fullName: data,
          mobile: data
        }
        this.selectedRecipientsMobile.push(recipient)
      } else {
        this.mobileInvalidFormat = 'Invalid format for mobile number:  ' + data + '. Mobile format should be +1(xxx)-xxx-xxxx'
      }
    },
    getMessagePreface: function () {
      if (this.recipientSelected) {
        if (this.selectedRecipientType.altName === 'Customer') {
          let result = ''
          if (this.selectedRecipientCustomer && this.selectedRecipientCustomer.fullName) {
            result += 'Hi ' + this.selectedRecipientCustomer.fullName + ',\n\n'
          }
          result += 'Please navigate to our secure webpage to view information on the vehicle being serviced using the provided link:' + '\n'
          result += '(link)' + '\n\n'
          return result
        } else if (this.selectedRecipientType.altName === 'Supplier') {
          let result = ''
          if (this.selectedRecipientSupplier && this.selectedRecipientSupplier.name) {
            result += 'Hi ' + this.selectedRecipientSupplier.name + ',\n\n'
          }
          result += 'Please navigate to our secure webpage to view information on the vehicle being serviced using the provided link:' + '\n'
          result += '(link)' + '\n\n'
          return result
        }
      }
    },
    resetModal: function () {
      this.selectedRecipientType = null
      this.selectedRecipientCustomer = null
      this.selectedRecipientSupplier = null
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      this.optionalMessage = ''
    },
    fullNameWithMobile ({ fullName, mobile }) {
      return `${fullName} — [${mobile}]`
    },
    fullNameWithEmail ({ fullName, email }) {
      return `${fullName} — [${email}]`
    },
    getSuffixIdentifier: function (id) {
      return '|' + this.instanceId + id
    }
  },
  watch: {
    selectedRecipientSupplier: function (newValue, oldValue) {
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      if (newValue && newValue.name && newValue.email) {
        var recipient = {
          fullName: newValue.name,
          email: newValue.email
        }
        let existingContact = this.selectedRecipients.find(x => x.email === newValue.email)
        if (!existingContact) {
          this.selectedRecipients.push(recipient)
        }
        this.optionalMessage = this.getMessagePreface()
      } else {
        let that = this
        this.$nextTick(() => {
          that.$refs.recipientEmail.$el.focus()
        })
      }
    },
    selectedRecipientCustomer: function (newValue, oldValue) {
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      if (newValue) {
        if (newValue.email) {
          var recipient = {
            fullName: newValue.fullName,
            email: newValue.email
          }
          let existingContact = this.selectedRecipients.find(x => x.email === newValue.email)
          if (!existingContact) {
            this.selectedRecipients.push(recipient)
          }
        } else {
          let that = this
          this.$nextTick(() => {
            that.$refs.recipientEmail.$el.focus()
          })
        }
        if (newValue.mobile) {
          let recipientMobile = {
            mobile: newValue.mobile,
            fullName: newValue.fullName
          }
          let existingContact = this.selectedRecipients.find(x => x.mobile === newValue.mobile)
          if (!existingContact) {
            this.selectedRecipientsMobile.push(recipientMobile)
          }
        }
        this.optionalMessage = this.getMessagePreface()
      }
    }
  },
  mounted: function () {
    this.selectedDealer = this.$store.state.dealer.currentDealer
  }
}
</script>

<style scoped>
.view-container {
  min-height: calc(100vh - 135px);
  position:relative;
  overflow-y: scroll;
  overflow-x: clip;
}

@media screen and (min-width: 769px), print {
  .modal-content, .modal-card {
      position:relative;
  }
}

</style>
