<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :taggable="false"
      :disabled="isDisabled"
      :searchable="true"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedRoServiceOpCode"
      :options="allRoServiceOpCodes"
      label="listName"
      :placeholder="placeholder"
      trackBy="id"
      :loading="isRoServiceOpCodesLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :data-vv-scope="scope"
      :hideSelected="true">
        <template slot="option" slot-scope="props">
          <label>{{ props.option.opCode }} - {{props.option.description}}</label>
        </template>
      </multiselect>
      <a class="button is-primary new-op-code-btn" @click="isNewRoServiceOpCodeActive = true" v-if="allowNew && !isDisabled">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
      </a>
      <a class="button is-primary edit-op-code-btn" @click="editRoServiceOpCodeActive = true" v-if="allowEdit && showEditAction && selectedRoServiceOpCodeId !== 0 && !isDisabled">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewRoServiceOpCodeActive" scroll="keep" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <new-ro-service-opcode
            mode="modal"
            actionPortal="modal-footer"
            overviewPortal=""
            headerPortal="modal-header"
            v-on:on-save="onRoServiceOpCodeSaved"
            v-on:on-cancel="onRoServiceOpCodeCancelled">
          </new-ro-service-opcode>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="editRoServiceOpCodeActive" scroll="keep" :has-modal-card="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <edit-ro-service-opcode
            :opCodeId="selectedRoServiceOpCodeId"
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onExistingRoServiceOpCodeSaved"
            v-on:on-cancel="onCancelEditRoServiceOpCode"
            :fulfillForDepositCaptureMode="false">
          </edit-ro-service-opcode>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
      </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import eventBus from '@/eventBus'
import roServiceService from '@/services/roServiceService'

export default {
  name: 'RoServiceOpCodeSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'OpCode'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded service op code'
    },
    validateAs: {
      type: String,
      default: 'OpCode'
    },
    allowNew: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    showEditAction: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    },
    selectedDealer: {
      type: Object,
      default: null
    }
  },
  components: {
    'new-ro-service-opcode': () => import('@/components/roservice/NewRoServiceOpCode'),
    'edit-ro-service-opcode': () => import('@/components/roservice/EditRoServiceOpCode')
  },
  data () {
    return {
      isRoServiceOpCodesLoading: false,
      selectedRoServiceOpCode: this.value,
      isNewRoServiceOpCodeActive: false,
      data: [],
      instanceName: 'ro-service-opcode-selector-' + this.$uuid.v4(),
      editRoServiceOpCodeActive: false
    }
  },
  computed: {
    allRoServiceOpCodes () {
      return this.data
    },
    selectedRoServiceOpCodeId: function () {
      return this.selectedRoServiceOpCode ? this.selectedRoServiceOpCode.id : 0
    },
    hasEditRoServiceOpCodeAccess: function () {
      let allowedPermissions = ['ro.service.opcode.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    selectedRoServiceOpCode: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentRoServiceOpCode: function (newValue, oldValue) {
      this.selectedRoServiceOpCode = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedRoServiceOpCode = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    }
  },
  methods: {
    onRoServiceOpCodeSaved: function (newRoServiceOpCode) {
      this.data.push(newRoServiceOpCode)
      this.selectedRoServiceOpCode = newRoServiceOpCode
      this.isNewRoServiceOpCodeActive = false
    },
    onRoServiceOpCodeCancelled: function () {
      this.isNewRoServiceOpCodeActive = false
    },
    clearAllRoServiceOpCodes () {
      this.data = []
    },
    onPreviewRoServiceVehicle: function () {
      this.isNewRoServiceVehicleActive = true
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.selectedDealer.id,
        sortBy: 'priority.asc'
      }
      this.isRoServiceOpCodesLoading = true
      this.clearAllRoServiceOpCodes()
      roServiceService.allOpCodes(searchCommand).then(response => {
        this.data = response.results
        this.isRoServiceOpCodesLoading = false
      })
    },
    doRoServiceOpCodeSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (query) {
      this.doRoServiceOpCodeSearch(query)
    },
    onExistingRoServiceOpCodeSaved: function (roOpCode) {
      this.selectedRoServiceOpCode = roOpCode
      this.editRoServiceOpCodeActive = false
    },
    onCancelEditRoServiceOpCode: function () {
      this.editRoServiceOpCodeActive = false
    }
  },
  created () {
    eventBus.$on('preview-ro-service-opcode', this.onPreviewRoServiceOpCode)
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  },
  destroyed () {
    eventBus.$off('preview-ro-service-opcode', this.onPreviewRoServiceOpCode)
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .new-op-code-btn {
    margin-left:5px;
    height:40px;
  }

  .edit-op-code-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
  }

</style>
