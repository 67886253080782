<template>
  <section>
    <div class="modal-card" v-show="!bookingConfirmationActive">
      <header class="modal-card-head is-block">
        <div class="columns">
          <div class="column">
            <div class="field is-grouped is-grouped-multiline" style="justify-content: center">
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag is-dark">VIN</span>
                  <span class="tag is-success">{{firstSoldItemInventory.vinNo !== '' ? firstSoldItemInventory.vinNo : 'PENDING'}}</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag is-dark">STK</span>
                  <a @click.stop.prevent="onStockNoClick(firstSoldItemInventory)"><span class="tag is-success has-text-weight-semibold">{{firstSoldItemInventory.stockNo !== '' ? firstSoldItemInventory.stockNo : 'PENDING' }}</span></a>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag is-dark">ITEM</span>
                  <span class="tag is-success">{{firstSoldItemInventory.name !== '' ? firstSoldItemInventory.name : '-'}}</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag is-dark">TYPE</span>
                  <span class="tag is-success">{{firstSoldItemInventory.type !== '' ? firstSoldItemInventory.type : '-'}}</span>
                </div>
              </div>
              <div class="control" v-if="firstSoldItemInventory.type !== 'New'">
                <div class="tags has-addons">
                  <span class="tag is-dark">CPO</span>
                  <span class="tag is-success">{{firstSoldItemInventory.cpoStatus !== '' ? firstSoldItemInventory.cpoStatus : '-'}}</span>
                </div>
              </div>
              <div class="control" v-if="dealHasTrade">
                <div class="tags has-addons">
                  <span class="tag is-dark">TR ALLOWANCE</span>
                  <span class="tag is-success" v-if="firstTradeDealItem.salePrice">{{totalTradeAllowance | currency}}</span>
                  <span class="tag is-success" v-else>-</span>
                </div>
              </div>
              <div class="control" v-if="dealHasTrade">
                <div class="tags has-addons">
                  <span class="tag is-dark">ACV/PURCHASE</span>
                  <span class="tag is-success" v-if="totalAcvPurchase > 0">{{totalAcvPurchase | currency}}</span>
                  <span class="tag is-success" v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isReadOnly" class="column is-1">
            <div class="quick-action mr-50">
              <a class="button is-pulled-right has-text-success" target="_blank" @click="onQuickSave" v-tooltip="{content: 'quick save'}">
                <i class="fal fa-save fa-m"></i>
              </a>
            </div>
          </div>
        </div>

        <el-steps :active="active" :space="300" finish-status="wait" simple>
          <el-step class="el-step" title="Pricing" icon="fal fa-dollar-sign fa-lg" @click.native="active = 0"></el-step>
          <el-step class="el-step" title="Customer Info" icon="fal fa-users-class fa-lg" @click.native="active = 1"></el-step>
          <el-step class="el-step" title="Fees" icon="fal fa-money-bill fa-lg" @click.native="active = 2"></el-step>
          <el-step class="el-step" title="Trades & Leases" icon="fal fa-exchange fa-lg" @click.native="active = 3"></el-step>
        </el-steps>
      </header>
      <section class="modal-card-body" style="padding:12px;">
        <div v-if="isReadOnly && !isCancelledDeal && !isAllocatedDeal" class="notification is-info is-flex">
          <div>
            <i class="fas fa-info-circle is-info fa-2x"></i>
          </div>
          <div class="ml-10">
            <b>Please note: This deal is booked.</b> <br />You will need to unlock it by clicking the lock button below to change its status to Delivered.
          </div>
        </div>
        <div v-if="hasTradeInventorySwitch && isBookingStepBlocked && firstTradeDealItemsSaved" class="mb-5">
          <div class="is-flex" style="justify-content:flex-end; align-items: center;">
            <i v-if="showTradeWorkFlowItemAction"
              :data-type="getDataTypeByTradeStatus(tradeWorkFlowItem)"
              :attr="getTradeStatusBorderStatus(tradeWorkFlowItem)"
              class="grid-icon fa-fw fal fa-lg clickable"
              :class="getColorClassByTradeStatus(tradeWorkFlowItem)"
              v-tooltip="getTradeDescriptionByTradeStatus(tradeWorkFlowItem)"
              @click.exact.stop.prevent="onTradeItemClick(tradeWorkFlowItem)"
              style="margin-top: 10px; margin-right: 10px;"/>
            <i v-if="showEditPreEntryAction" class="grid-icon fa-fw fal fa-lg clickable fa-car-tilt" v-tooltip="{content: 'Click to stock-in trade pre-entry'}" @click="editTradePreEntryActive=true"></i>
            <convert-ppa-to-purchase-action v-if="showPPAConvertAction" :inventory="tradeItem" :dealId="deal.id" :usePortal="false" :defaultType="{id: 4, name: 'Trade In', altName: 'TradeIn'}" :openEditInventoryInModal="true"></convert-ppa-to-purchase-action>
            <approve-ppa-action v-if="showPPAApproveAction" :inventoryItem="tradeItem" :reloadList="false" :usePortal="false"></approve-ppa-action>
            <i style="margin-left: 10px; cursor: pointer" class="far fa-sync" @click.exact.stop.prevent="reloadDeal" title="refresh deal"></i>
          </div>
          <div class="notification is-warning is-flex">
            <span>
              <i class="fas fa-info-circle is-danger fa-2x"></i>
            </span>
            <span class="ml-10"><b>Please note, this deal cannot progress to booked until:</b><p v-html="bookingStepBlockingContent"></p></span>
          </div>
        </div>
        <div v-if="isBookingStepBlocked && !firstTradeDealItemsSaved">
          <div class="notification is-warning is-flex">
            <span>
              <i class="fas fa-info-circle is-info fa-2x"></i>
            </span>
            <div class="ml-10"><b>Please note: please save the newly added trade and then finalize the trade before you can book this deal.</b>
              <p><button type="button" class="button button-like-footer is-primary mt-10" :class="{'is-loading': isSaving }" @click="onQuickSave">Save Trade</button></p>
            </div>
          </div>
        </div>
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <form class="view-container" @change="onFormChange" @submit.prevent="">
          <!-- <div class="title is-7 has-text-centered">NB: You are required to capture all information</div> -->
          <div name="Pricing" v-show="active === 0">
            <div class="columns mb-0">
              <div class="column is-flex">
                <div>
                  <div class="field is-horizontal">
                    <div class="field-label is-small" style="margin-right: auto !important;">
                        <label class="label deal-code-label">Deal #<b-icon pack="fas" icon="star" class="fa-ss" v-if="progressStatus && !byPassDealCode" /></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input :disabled="isReadOnly" name="dealCode" data-vv-scope="page-1" class="is-small" data-vv-as="deal #" v-validate="{'required': progressStatus && !byPassDealCode, 'max': 10}" :class="{'input': true, 'is-danger': errors.has('page-1.dealCode') }" type="text" placeholder="0000000000" v-model="deal.dealCode">
                        </div>
                      </div>
                    </div>
                  </div>
                  <span v-show="errors.has('page-1.dealCode')" class="help is-danger">
                    <i v-show="errors.has('page-1.dealCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.dealCode') }}
                  </span>
                </div>
                <div v-if="progressStatus" class="field is-horizontal">
                  <div class="field-label is-small" style="margin-right: auto !important;">
                    <label class="label">Bypass Deal #</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control ml-8 mt-3">
                        <b-checkbox size="is-small" style="vertical-align: -webkit-baseline-middle;" type="is-info" v-model="byPassDealCode"></b-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isSaleTypeOfRetail" class="column">
                <div class="is-flex">
                  <div>
                    <div class="field is-horizontal">
                      <div class="field-label is-small" style="margin-right: auto !important;">
                          <label class="label supplier-no-label">Supplier #<b-icon pack="fas" icon="star" class="fa-ss" v-if="progressStatus && !byPassSupplierNo" /></label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <div class="control">
                            <input :disabled="isReadOnly" name="supplierNo" data-vv-scope="page-1" class="is-small" data-vv-as="supplier #" v-validate="{'required': progressStatus && !byPassSupplierNo, 'max': 10}" :class="{'input': true, 'is-danger': errors.has('page-1.supplierNo') }" type="text" placeholder="0000000000" v-model="deal.supplierNo">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-show="errors.has('page-1.supplierNo')" class="help is-danger">
                      <i v-show="errors.has('page-1.supplierNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.supplierNo') }}
                    </span>
                  </div>
                  <div v-if="progressStatus" class="field is-horizontal">
                    <div class="field-label is-small" style="margin-right: auto !important;">
                      <label class="label" style="width: 110px">Bypass Supplier #</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control ml-8 mt-3">
                          <b-checkbox size="is-small" style="vertical-align: -webkit-baseline-middle;" type="is-info" v-model="byPassSupplierNo"></b-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="is-pulled-right" style="width: 100%"><span class="is-pulled-right tag is-dark is-medium">{{ dealTypeDescription }}</span></div>
              </div>
            </div>

            <div class="box" v-for="(dealItem, index) in this.soldItems" :key="dealItem.id">
              <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)"><section-header :title="'Sold Item (' + (index + 1) +')'" :overview="dealItem.forInventory.stockNo" :allowCollapse="false"></section-header></a>
              <div class="columns">
                <div class="column is-5">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Sale Price<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="salePrice" data-vv-scope="page-1" data-vv-as="sale price" v-validate="'required|decimal:2'"
                            class="is-small" :class="{'input': true, 'is-danger': errors.has('page-1.salePrice') }" type="text"
                            v-model="dealItem.salePrice" v-bind="$globalMoneyFormat" :disabled="isReadOnly || showHoldbackCalc"></money>
                          <span v-show="errors.has('page-1.salePrice')" class="help is-danger"><i v-show="errors.has('page-1.salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.salePrice') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Purchase Cost<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="purchaseCost" data-vv-scope="page-1" data-vv-as="purchase cost" class="is-small" :class="{'input': true }" type="text" :value="purchaseCostDerived" v-bind="$globalMoneyFormat" :disabled="true" placeholder="0000.00"></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="control">
                      <a class="button" :disabled="isPurchaseCostDisabled" style="height: 2.5em" @click="togglePurchaseCostEditable()" title="Click to unlock field for editing">
                        <i class="fal fa-unlock fa-sm"></i>
                      </a>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Front GP<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                    </div>
                    <div v-if="!isBookedDeal" class="field-body">
                      <div class="field">
                        <div v-if="isGrossDisabled(dealItem)" class="field mt-10">
                          <label>{{calculatedTotalFrontGross(dealItem) | currency}}</label>
                        </div>
                        <div v-else class="control has-icons-left">
                          <money name="frontValue" data-vv-as="front GP" v-validate="'decimal:2'" class="is-small" :class="{'input': true}" type="text" v-model="dealItem.frontValue" v-bind="$globalMoneyFormat" :disabled="isReadOnly" placeholder="0000.00"></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div v-else class="field-body">
                      <div class="field mt-10">
                        <label :class="{'has-text-danger': dealItem.frontValue !== calculatedTotalFrontGross(dealItem), 'has-text-primary': dealItem.frontValue === calculatedTotalFrontGross(dealItem)}">
                          {{dealItem.frontValue | currency}}
                          <i v-if="dealItem.frontValue !== calculatedTotalFrontGross(dealItem)"
                            class="fas fa-exclamation-triangle"
                            v-tooltip="getBookedFgToolTip(dealItem)">
                          </i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Warranty GP</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="warValue" data-vv-as="warranty GP" v-validate="'decimal:2'" class="is-small" :class="{'input': true}" type="text" v-model="dealItem.warValue" v-bind="$globalMoneyFormat" :disabled="isReadOnly || showHoldbackCalc"></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal" v-if="dealItem.vehicleType === 'New'">
                    <div class="field-label is-normal">
                      <label class="label">Holdback</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="holdValue" data-vv-as="holdback" v-validate="'decimal:2'" class="is-small" :class="{'input': true, 'is-danger': errors.has('holdValue') }" type="text" v-model="dealItem.holdValue"
                            v-bind="$globalMoneyFormat" :disabled="!isHoldbackEditable || isReadOnly || showHoldbackCalc"></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                        <div class="field is-pulled-right">
                          <label class="label-frontgp" :class="{'has-text-danger': round(dealItem.holdValue) !== round(inventoryHoldValue(dealItem.forInventory)), 'has-text-success': round(dealItem.holdValue) === round(inventoryHoldValue(dealItem.forInventory))}"
                            @click="onSetHoldBack(dealItem)" title="This is the holdback value for this model. Click here to make this the holdback value for this deal." :disabled="isReadOnly">
                            <div>{{inventoryHoldValue(dealItem.forInventory) | currency}}</div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="control is-flex" v-if="hasFeatureAccess('deal.modify.holdback', false)">
                      <button type="button" :disabled="isReadOnly" class="button" style="height: 2.5em" @click="isHoldbackEditable = !isHoldbackEditable" v-tooltip="{content: isHoldbackEditable ? 'Click to re-lock opened field without saving' : 'Click to unlock field for editing'}">
                        <i class="fal fa-sm" :class="{'fa-unlock': !isHoldbackEditable, 'fa-lock': isHoldbackEditable}"></i>
                      </button>
                      <button type="button" :disabled="isReadOnly" class="button" :class="{'has-background-success': showHoldbackCalc}" style="height: 2.5em" v-tooltip="{content: !showHoldbackCalc ? 'Click to show holdback calculation view' : 'Click to hide holdback calculation view'}" @click="toggleShowHideHoldbackCalculator">
                        <i class="fal fa-square-root-alt fa-sm"></i>
                      </button>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label no-content-wrap">Bonus</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="mvpValue" data-vv-as="bonus" v-validate="'decimal:2'" class="is-small" :class="{'input': true, 'is-danger': errors.has('mvpValue') }" type="text" v-model="dealItem.mvpValue" v-bind="$globalMoneyFormat" :disabled="isReadOnly || showHoldbackCalc"></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Spiff<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="spiff" data-vv-scope="page-1" data-vv-as="spiff" v-validate="'required|decimal:2'" class="is-small" :class="{'input': true, 'is-danger': errors.has('page-1.spiff') }" type="text" v-model="dealItem.spiff" v-bind="$globalMoneyFormat" :disabled="isReadOnly || showHoldbackCalc"></money>
                          <span v-show="errors.has('page-1.spiff')" class="help is-danger"><i v-show="errors.has('page-1.spiff')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.spiff') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Finance Reserve<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="finResValue" data-vv-scope="page-1" data-vv-as="finance reserve" v-validate="'required|decimal:2'" class="is-small" :class="{'input': true, 'is-danger': errors.has('page-1.finResValue') }" type="text" v-model="dealItem.finResValue" v-bind="$globalMoneyFormat" :disabled="isReadOnly || showHoldbackCalc"></money>
                          <span v-show="errors.has('page-1.finResValue')" class="help is-danger"><i v-show="errors.has('page-1.finResValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.finResValue') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Recon Cost<b-icon pack="fas" icon="star" class="fa-ss" v-if="isSaleTypeOfRetail" /></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money name="reconCost" data-vv-scope="page-1" data-vv-as="recon cost" v-validate="{'required': isSaleTypeOfRetail, 'decimal':2}" class="is-small" :class="{'input': true, 'is-danger': errors.has('page-1.reconCost') }" type="text" v-model="dealItem.forInventory.reconCost" v-bind="$globalMoneyFormat" :disabled="!isReconEditable || showHoldbackCalc" placeholder="0000.00"></money>
                          <span v-show="errors.has('page-1.reconCost')" class="help is-danger"><i v-show="errors.has('page-1.reconCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.reconCost') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!--
                    <div class="control" v-if="hasFeatureAccess('deal.modify.unlockrecon', false)">
                      <a class="button" style="height: 2.5em" v-if="!isReconEditable" @click="!isReadOnly ? isReconEditable = true : isReconEditable = false" title="Click to unlock field for editing" :disabled="isReadOnly">
                        <i class="fal fa-unlock fa-sm"></i>
                      </a>
                      <a class="button" style="height: 2.5em" @click="isReconEditable = false" title="Click to re-lock opened field without saving" v-else :disabled="isReadOnly">
                        <i class="fal fa-lock fa-sm"></i>
                      </a>
                    </div>
                    -->
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Trade Allowance</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money class="is-small" :class="{'input': true}" type="text" :value="totalTradeAllowance" v-bind="$globalMoneyFormat" disabled></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">{{acvLabel}}<span class="icon has-text-danger acv-warning" v-if="showAcvWarning"><i class="fas fa-exclamation-triangle"></i></span></label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money class="is-small" :class="{'input': true}" type="text" :value="totalAcvDifference" v-bind="$globalMoneyFormat" disabled></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Product GP</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money class="is-small" :class="{'input': true}" type="text" :value="dealItemProductGP(dealItem)" v-bind="$globalMoneyFormat" disabled></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Total GP</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control has-icons-left">
                          <money class="is-small" :class="{'input': true}" type="text" :value="dealItemTotalGP(dealItem)" v-bind="$globalMoneyFormat" disabled></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-7">
                  <div id="product-section-widget" class="box" v-if="showFeePods">
                    <section-header title="Products" parentSelector="#product-section-widget" :collapsed="true"></section-header>
                    <product-cost-container v-model="soldItems[index]" :showLabelHeader="false" :showDescription="false" :isReadOnly="isReadOnly"></product-cost-container>
                  </div>

                  <inventory-supplemental-cost-component
                    :isCollapsed="true"
                    v-if="showFeePods && soldItems[index].forInventory.possessionStatus !== 'Locating'"
                    v-on:inventory-costs-updated="onInventorySupplementalCostsChanged"
                    v-on:inventory-costs-totals-updated="onInventorySupplementalCostTotalsChanged"
                    v-model="soldItems[index].forInventory.supplementalCosts"
                    :key="soldItems[index].forInventory.id"
                    :selectedInventory="soldItems[index].forInventory"
                    :selectedInventoryId="soldItems[index].forInventory.id"
                    :selectedMakeModel="firstSoldItemInventoryMakeModel"
                    :selectedTypeStatus="selectedTypeStatus"
                    :selectedDispositionIntentionStatus="selectedDispositionIntentionStatus"
                    :showVendor="false"
                    :showNettAction="false"
                    :showInGlBalance="false"
                    :autoSaveChanges="true"
                    dispositionSource="Deal"
                    :isReadOnly="isReadOnly">
                  </inventory-supplemental-cost-component>

                  <div class="box" id="serviceRequestPod" v-if="showFeePods && soldItems[index].forInventory.possessionStatus !== 'Locating'">
                    <section-header
                      :collapsed="true"
                      :title="buildWorkshopSectionTitle(soldItems[index].forInventory)"
                      :forInventoryView="true"
                      :overview="buildWorkshopSectionOverview(soldItems[index].forInventory, true)"
                      parentSelector="#serviceRequestPod">
                    </section-header>
                      <div class="columns is-multiline">
                        <div class="column is-12 no-h-padding">
                          <b-table
                            :data="soldItems[index].forInventory.serviceRequests"
                            :sortable="false"
                            :selecteable="false"
                            :mobile-cards="false"
                            :row-class="() => 'service-request-row'">
                            <template slot-scope="props">
                              <b-table-column field="requestStatusType" label="Status" class="service-request-column">
                                <i class="icon fal fa-layer-plus fa-lg" :class="getColorClassByGetReadyStatus(props.row.requestStatusTypeId)" title="Status" v-if="props.row.requestStatusTypeId === 0"/>
                                <i class="icon fal fa-wrench fa-lg" :class="getColorClassByGetReadyStatus(props.row.requestStatusTypeId)" title="Status" v-else/>
                                {{ requestStatusType(props.row.requestStatusType) }}
                              </b-table-column>

                              <b-table-column field="approvedCosts" label="Cost" class="service-request-column is-flex">
                                <div class="mt-5">{{ props.row.approvedCosts | currency }}</div>
                                <b-dropdown position="is-top-left" class="shop-drop-down" aria-role="list" :triggers="['hover', 'click']" :scrollable="true">
                                  <i class="icon fal fa-info-circle fa-lg has-text-info dropdown-btn clickable" slot="trigger" title="click to see worker items"/>
                                  <b-dropdown-item aria-role="listitem">
                                    <div v-html="buildServiceRequestLineItems(props.row.lines)"></div>
                                  </b-dropdown-item>
                                </b-dropdown>
                              </b-table-column>

                              <b-table-column field="serviceRequestType" label="Type" class="service-request-column">
                                {{ requestType(props.row.serviceRequestType) }}
                              </b-table-column>

                              <b-table-column field="requiredAt" label="Required" class="service-request-column">
                                {{ props.row.requiredAt | formatDateOnly }}
                              </b-table-column>

                              <b-table-column field="completedAt" label="Completed" class="service-request-column">
                                {{ props.row.completedAt | formatDateOnly }}
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <section class="section">
                                <div class="has-text-centered">
                                  <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                                  <p>No service requests recorded for this vehicle.</p>
                                </div>
                              </section>
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <quick-action-container v-if="false">
                        <service-request-capture :currentInventory="soldItems[index].forInventory"></service-request-capture>
                      </quick-action-container>
                  </div>

                  <div id="front-gross-calc" class="box"  v-if="showFrontGrossCal">
                    <section-header title="Front Gross Calculation" parentSelector="#front-gross-calc" :collapsed="false"></section-header>
                    <table class="table">
                      <tr>
                        <th>Item</th>
                        <th>CDK</th>
                        <th>LIVE</th>
                      </tr>
                      <tr>
                        <td>Purchase Cost</td>
                        <td>{{purchaseCostDerived | currency}}</td>
                        <td>{{purchaseCostDerived | currency}}</td>
                      </tr>
                      <tr>
                        <td>Recon</td>
                        <td>{{calculatedReconCDK(dealItem) | currency}}</td>
                        <td>{{calculatedReconLive(dealItem) | currency}}</td>
                      </tr>
                      <tr>
                        <td>Inventory Additional Costs</td>
                        <td>{{inventoryAdditionalCostsTotal(dealItem) | currency}}</td>
                        <td>{{inventoryAdditionalCostsTotal(dealItem) | currency}}</td>
                      </tr>
                      <tr v-if="showAccruePurchaseCostInstruction(dealItem)">
                        <td>&nbsp;</td>
                        <td colspan="2">
                          <span class="mt-5 tag is-warning">
                            Apply live purchase cost<b-checkbox class="ml-10" @click.native="toggleDefaultPurchaseCostCdk" v-model="defaultPurchaseCostCdk"></b-checkbox>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td colspan="2">
                          <section v-if="!isTotalFrontGrossWithInTolerance(dealItem)" class="tag" :class="getExpectedGrossClass(dealItem)">
                            {{getExpectedGrossText(dealItem)}}
                          </section>

                          <section v-else id="reconciled-container" class="level is-mobile" style="margin: 5px;">
                            <div class="level-item has-text-centered has-background-success has-text-white box is-flex" style="padding: 2px;">
                              <div class="heading mt-5 mr-5"><span><i class="fas fa-check-circle"></i></span> Reconciled GP</div>
                              <div v-if="hasAnyCdkAccrualSelections" class="heading mt-5 mr-5">
                                <span class="is-size-7 has-text-warning">CDK&nbsp;</span>{{cdkAdjustmentAmount | currency}}
                              </div>
                              <div v-if="hasAnyLiveAccrualSelections" class="heading mt-5">
                                <span class="is-size-7 has-text-warning">LIVE&nbsp;</span>{{liveAdjustmentAmount | currency}}
                              </div>
                            </div>
                          </section>
                        </td>
                      </tr>
                      <tr>
                        <td class="has-text-weight-bold">GL Balance (subtotal)</td>
                        <td class="has-text-weight-bold">{{frontGrossSubtotalCDK(dealItem) | currency}}</td>
                        <td class="has-text-weight-bold">{{frontGrossSubtotalLive(dealItem) | currency}}</td>
                      </tr>
                      <tr>
                        <td>Sale Price</td>
                        <td>{{dealItem.salePrice | currency}}</td>
                        <td>{{dealItem.salePrice | currency}}</td>
                      </tr>
                      <tr>
                        <td>Acv</td>
                        <td>{{totalAcvDifference | currency}}</td>
                        <td>{{totalAcvDifference | currency}}</td>
                      </tr>
                      <tr>
                        <td>Fees</td>
                        <td>{{additionalCostsGP | currency}}</td>
                        <td>{{additionalCostsGP | currency}}</td>
                      </tr>
                      <tr>
                        <td class="has-text-weight-bold">Sub Total Front GP</td>
                        <td class="has-text-weight-bold">{{calculatedSubFrontGrossCDK(dealItem) | currency}}</td>
                        <td class="has-text-weight-bold">{{calculatedSubFrontGross(dealItem) | currency}}</td>
                      </tr>
                      <tr v-if="showAutoUncheckMessage">
                        <td colspan="3">
                          <div class="is-flex notification is-warning">
                            <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
                            <span class="ml-10">
                              Auto detection has unchecked the default note as the amount has since been updated since the last time the adjustment was saved. Please review the amount and select it again if it should still apply.
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="is-pulled-right is-flex">
                            <span class="tag is-dark mr-10">Inventory Accrual</span>
                            <i class="fad fa-calculator-alt fa-2x dropdown-btn clickable" @click="toggleFgDropdown"></i>
                          </div>
                        </td>
                        <td class="has-background-grey-lighter">
                          <div class="is-flex">
                            <div class="mt-5">{{cdkAdjustmentAmount | currency}}</div>

                            <fg-accrual-line-items v-if="true"
                              :key="fgAccrualKey"
                              v-model="getFgLineItem"
                              :type="getFgAccrualType(dealItem)"
                              :forInventory="soldItems[index].forInventory"
                              :persistedAdjustments="soldItems[index].reconAdjustment"
                              :dealItem="soldItems[index]"
                              :isReadOnly="isReadOnly"
                              :saveAdjustmentsUuid="saveAdjustmentsUuid"
                              :toggleDefaultPurchaseCostCdkUuid="toggleDefaultPurchaseCostCdkUuid"
                              :showAutoUncheckMessage.sync="showAutoUncheckMessage"
                              :isDealProgressingToBooked="progressStatus"
                              :cdkAdjustmentAmount.sync="cdkAdjustmentAmount"
                              :liveAdjustmentAmount.sync="liveAdjustmentAmount"
                              :balanceFrontGrossCdk="calculatedFgAccrualCdk(dealItem)"
                              :balanceFrontGrossLive="calculatedFgAccrualLive(dealItem)"
                              :frontGrossDiff="getTotalFrontGrossDiff(dealItem)"
                              :cdkGlbalanceToPurchaseCostPercentage="cdkGlbalanceToPurchaseCostPercentage(dealItem)"
                              v-on:toggleDefaultPurchaseCostCdk="onToggleDefaultPurchaseCostCdk"
                              v-on:statusChanged="accrualLineStatusChange"
                              v-on:toggleCost="frontGrossCostToggle">
                            </fg-accrual-line-items>

                          </div>
                        </td>
                        <td class="has-background-grey-lighter">
                          <div class="is-flex">
                            <div class="mt-5">{{liveAdjustmentAmount | currency}}</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="has-text-weight-bold">Total Front GP</td>
                        <td class="has-text-weight-bold" :class="getTotalFrontGrossClass(dealItem)">{{calculatedTotalFrontGrossCDK(dealItem) | currency}}</td>
                        <td class="has-text-weight-bold" :class="getTotalFrontGrossClass(dealItem)">
                          {{calculatedTotalFrontGross(dealItem) | currency}}
                          <span v-if="showWithInToleranceNote(dealItem)"> [variance within tolerance {{getTotalFrontGrossDiff(dealItem) | currency}}]</span>
                          <span v-else-if="getTotalFrontGrossDiff(dealItem) !== 0"> [variance of {{Math.abs(getTotalFrontGrossDiff(dealItem)) | currency}}]</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div id="holdback-calc" v-show="showHoldbackCalc" class="box">
                    <div class="is-flex">
                      <div>
                        <section-header title="Holdback Calculator" parentSelector="#holdback-calc" :collapsed="false" :allowCollapse="false"></section-header>
                      </div>
                      <div class="quick-action holdback-calc-close-btn">
                        <a class="button is-pulled-right has-text-danger" target="_blank" @click="showHoldbackCalc = false" title="Click to close holdback calculator">
                          <i class="fal fa-times fa-lg"></i>
                        </a>
                      </div>
                    </div>
                    <div class="card" v-if="canShowCompleteMakeModel">
                      <div class="card-content">
                        <p class="subtitle" style="font-size: 0.75rem; margin-bottom: 0.75rem;">
                          Please complete vehicle make and model. Once completed, the holdback calculator default values will be auto filled.
                        </p>
                        <make-model-selector
                          data-vv-as="selected vehicle"
                          name="selectedMakeModelHbc"
                          :forceColorSelection="false"
                          v-model="selectedMakeModelHbc"
                          :inventoryType="selectedTypeStatus"
                          :useModal="true"
                          :purchasedFromType="firstSoldItemInventory.purchasedFromType"
                          dataVvScope="page-1">
                        </make-model-selector>
                        <span v-show="errors.has('page-1.selectedMakeModelHbc')" class="help is-danger"><i v-show="errors.has('page-1.selectedMakeModelHbc')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.selectedMakeModelHbc') }}</span>
                      </div>
                    </div>
                    <div class="columns is-gapless is-multiline is-mobile">
                      <div class="column is-12">
                        <table class="table">
                          <tr>
                            <th colspan="2">
                              <div class="is-flex">
                                <window-stick-status-capture :currentInventory="firstSoldItemInventory" :key="firstSoldItemInventory.id" :isDoubleStackedModal="true"></window-stick-status-capture>
                                <span v-if="isHoldbackCalculaterFormEditable" @click.stop.prevent="setInitialHoldbackValues()"><i class="icon fal fa-lg fa-undo" v-tooltip="{content:'reset to default values'}"></i></span>
                                <span v-if="!isHoldbackCalculaterFormEditable" @click.stop.prevent="isHoldbackCalculaterFormEditable = true"><i class="icon fal fa-unlock fa-lg" v-tooltip="{content:'click to open fields'}"></i></span>
                                <span><i class="icon fal fa-info-circle fa-lg" :class="{'has-text-info': showHoldbackBreakdown}" v-tooltip="{content:'click to toggle calculation breakdown view'}"  @click.stop.prevent="showHoldbackBreakdown = !showHoldbackBreakdown"></i></span>
                              </div>
                            </th>
                            <th>%</th>
                            <th>Amount</th>
                            <th>Holdback (including additions)</th>
                          </tr>
                          <tr>
                            <td class="has-text-weight-bold">Total MSRP</td>
                            <td>
                              <div class="control has-icons-left is-flex">
                                <money
                                  style="width:100px;" class="is-small" :class="{'input': true }" type="text" v-model="workingMsrp" v-bind="$globalMoneyFormat"
                                  name="workingMsrp" @focus.native="workingMsrpFocused=true" @blur.native="workingMsrpFocused=false" :disabled="!isHoldbackCalculaterFormEditable">
                                </money>
                                <span class="icon is-small is-left">
                                  <i class="fas fa-dollar-sign"></i>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div style="width: 45px;">
                                <input class="is-small input" type="text" placeholder="00.00" v-model="calculatedHoldbackPercentage" name="calculatedHoldbackPercentage"
                                  @focus="calculatedHoldbackPercentageFocused=true" @blur="calculatedHoldbackPercentageFocused=false" maxLength="5"
                                  :disabled="!isHoldbackCalculaterFormEditable" @input="calculatedHoldbackPercentageChange">
                              </div>
                            </td>
                            <td>
                              <div class="control has-icons-left is-flex">
                                <money style="width:100px;" class="is-small" :class="{'input': true }" type="text" v-model="calculatedHoldback" v-bind="$globalMoneyFormat"
                                  name="calculatedHoldback" @focus.native="calculatedHoldbackFocused=true" @blur.native="calculatedHoldbackFocused=false"
                                  :disabled="!isHoldbackCalculaterFormEditable" @input="calculatedHoldbackChange">
                                </money>
                                <span class="icon is-small is-left">
                                  <i class="fas fa-dollar-sign"></i>
                                </span>
                                <button id="calculatedHoldback_button" type="button" :disabled="!isHoldbackCalculaterFormEditable" class="button" style="height: 2.5em; margin-left: 3px;" @click="setHoldbackValue(calculatedHoldback, $event)">
                                  <i class="fal fa-check fa-sm"></i>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div class="holdback-option-container">{{calculatedHoldbackTotal | currency}}
                                <button id="calculatedHoldbackTotal_button" type="button" :disabled="!isHoldbackCalculaterFormEditable" class="button" style="height: 2.5em; margin-left: 3px;" @click="setHoldbackValue(calculatedHoldbackTotal, $event)">
                                  <i class="fal fa-check fa-sm"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr v-show="showHoldbackBreakdown">
                            <td colspan="5">
                              <div class="subtitle is-7 has-text-centered has-text-info is-marginless">{{holdbackCalculationBreakdown}}</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="has-text-weight-bold">Base MSRP</td>
                            <td>
                              <div class="control has-icons-left is-flex">
                                <money style="width:100px;" class="is-small" :class="{'input': true }" type="text" v-model="workingBaseMsrp" v-bind="$globalMoneyFormat"
                                  name="workingBaseMsrp" @focus.native="workingBaseMsrpFocused=true" @blur.native="workingBaseMsrpFocused=false" :disabled="!isHoldbackCalculaterFormEditable">
                                </money>
                                <span class="icon is-small is-left">
                                  <i class="fas fa-dollar-sign"></i>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div style="width: 45px;">
                                <input class="is-small input" type="text" placeholder="" v-model="calculatedBaseHoldbackPercentage" name="calculatedBaseHoldbackPercentage"
                                  @focus="calculatedBaseHoldbackPercentageFocused=true" @blur="calculatedBaseHoldbackPercentageFocused=false" maxlength="5"
                                  :disabled="!isHoldbackCalculaterFormEditable" @input="calculatedBaseHoldbackPercentageChange">
                              </div>
                            </td>
                            <td>
                              <div class="control has-icons-left is-flex">
                                <money style="width:100px;" class="is-small" :class="{'input': true }" type="text" v-model="calculatedBaseHoldback" v-bind="$globalMoneyFormat"
                                  name="calculatedBaseHoldback" @focus.native="calculatedBaseHoldbackFocused=true" @blur.native="calculatedBaseHoldbackFocused=false"
                                  :disabled="!isHoldbackCalculaterFormEditable" @input="calculatedBaseHoldbackChange">
                                </money>
                                <span class="icon is-small is-left">
                                  <i class="fas fa-dollar-sign"></i>
                                </span>
                                <button id="calculatedBaseHoldback_button" type="button" :disabled="!isHoldbackCalculaterFormEditable" class="button" style="height: 2.5em; margin-left: 3px;" @click="setHoldbackValue(calculatedBaseHoldback, $event)">
                                  <i class="fal fa-check fa-sm"></i>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div class="holdback-option-container">{{calculatedBaseHoldbackTotal | currency}}
                                <button id="calculatedBaseHoldbackTotal_button" type="button" :disabled="!isHoldbackCalculaterFormEditable" class="button" style="height: 2.5em; margin-left: 3px;" @click="setHoldbackValue(calculatedBaseHoldbackTotal, $event)">
                                  <i class="fal fa-check fa-sm"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr v-show="showHoldbackBreakdown">
                            <td colspan="5">
                              <div class="subtitle is-7 has-text-centered has-text-info is-marginless">{{holdbackBaseCalculationBreakdown}}</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="has-text-weight-bold">MSRP Deductions</td>
                            <td>
                              <div class="control has-icons-left">
                                <money style="width:100px;" class="is-small" :class="{'input': true }"  :maxlength="11" type="text" v-model="workingMsrpDeductions" v-bind="$globalMoneyFormat"
                                  :disabled="!isHoldbackCalculaterFormEditable">
                                </money>
                                <span class="icon is-small is-left">
                                  <i class="fas fa-dollar-sign"></i>
                                </span>
                              </div>
                            </td>
                            <td class="has-text-weight-bold" colspan="3">
                              <div class="is-pulled-right is-flex">
                                <div style="align-self: center; margin-right: 5px;">Additional Items</div>
                                <div class="control has-icons-left">
                                  <money style="width:100px;" class="is-small" :class="{'input': true }"  :maxlength="11" type="text" v-model="holdbackAdditional" v-bind="$globalMoneyFormat"
                                    :disabled="!isHoldbackCalculaterFormEditable" @focus.native="workingMsrpDeductionsFocused=true" @blur.native="workingMsrpDeductionsFocused=false">
                                  </money>
                                  <span class="icon is-small is-left">
                                    <i class="fas fa-dollar-sign"></i>
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="has-text-weight-bold">
                              <span class="is-pulled-left">Default Total MSRP <span class="tag" :class="getTotalMSRPColourClass">{{soldInventoryMsrp | currency}}</span></span>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" class="has-text-weight-bold">
                              <span class="is-pulled-left">Default Base MSRP <span class="tag" :class="getBaseMSRPColourClass">{{baseInventoryMsrp | currency}}</span></span>
                            </td>
                            <td colspan="2">&nbsp;</td>
                          </tr>
                          <tr>
                            <td colspan="3" class="has-text-weight-bold">
                              <span class="is-pulled-left">Default MSRP Deductions <span class="tag" :class="getDefaultMsrpDeductionsColourClass">{{defaultMsrpDeductions | currency}}</span></span>
                            </td>
                            <td colspan="2">&nbsp;</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div name="CustomerInfo" v-show="active === 1">
            <div id="customerinfo-widget" class="box">
              <section-header title="Persons" parentSelector="#customerinfo-widget"></section-header>
              <div class="columns">
                <div class="column is-6">
                  <supplier-selector v-if="isSaleTypeOfWholesale && !isReadOnly" label="Wholesaled to" validateAs="Wholesaled to" placeholder="Type to search for a loaded wholesaler" v-model="selectedSupplier" :required="true"  data-vv-scope="page-2" data-vv-as="wholesaled to" :isDisabled="isReadOnly" :allowNew="!isReadOnly"></supplier-selector>
                  <customer-selector v-if="isSaleTypeOfRetail && !isReadOnly" v-model="selectedCustomer" :selectedDealer="this.currentDealer" :required="true"  v-validate="'required'" data-vv-scope="page-2" data-vv-as="customer" :isDisabled="isReadOnly" :allowNew="!isReadOnly"></customer-selector>
                  <div v-if="isReadOnly" class="tags has-addons">
                    <span class="tag is-dark">PURCHASER</span>
                    <span class="tag is-success">{{ deal.forPurchaserModel.fullName }}</span>
                  </div>
                  <lead-source-selector :scope="'page-2'" name="leadSource" v-validate="isLeadSourceRequired ? 'required' : ''" v-model="selectedLeadSource" :required="isLeadSourceRequired" label="Lead Source" data-vv-as="lead source" :selectedDealer="this.currentDealer" :isDisabled="isReadOnly"></lead-source-selector>
                  <user-selector  :scope="'page-2'" name="salesAgentManager" v-model="selectedSalesAgentManager" v-validate="'required'" label="Sales Agent Manager" data-vv-as="sales agent manager" :selectedDealer="this.currentDealer" :required="true" :isDisabled="isReadOnly"></user-selector>
                  <label v-if="!isReadOnly" class="label-frontgp is-pulled-right" :class="{'has-text-danger': selectedSalesAgentManager && selectedSalesAgentManager.fullName !== currentUser.fullName, 'has-text-success': selectedSalesAgentManager && selectedSalesAgentManager.fullName === currentUser.fullName}" @click="setCurrentAsSalesManager" title="Click to make the current user the sales agent manager.">{{currentUser.fullName}}</label>
                </div>
                <div class="column is-6">
                  <user-selector  :scope="'page-2'" name="salesAgent" v-model="selectedSalesAgent" v-validate="isSalesAgentRequired ? 'required' : ''" filter="Sales" label="Sales Agent" data-vv-as="sales agent" :selectedDealer="this.currentDealer" :required="isSalesAgentRequired" :isDisabled="isReadOnly"></user-selector>
                  <user-selector  :scope="'page-2'" name="financeProcessedBy" v-model="selectedFinanceProcessedBy" v-validate="isFinanceProcessedByRequired ? 'required' : ''" filter="F&I Manager" label="Finance Processed By" data-vv-as="finance processed" :selectedDealer="this.currentDealer" :required="isFinanceProcessedByRequired" :isDisabled="isReadOnly"></user-selector>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <button-list-selector :enabled="!isReadOnly" validateAs="contract type" label="Contract Type" :required="true" v-model="selectedContractType" :availableValues="definedTypes.dealContractTypes.options" :allowDeselection="false" data-vv-scope="page-2" scope="page-2"></button-list-selector>
                </div>
                <div class="column is-6">
                  <div class="field" >
                    <div class="control">
                      <switch-selector type="is-info" label="Cash Down" v-model="isCashDown"></switch-selector>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="isCashDown">
                  <div class="field">
                    <label class="label">Deposit Amount</label>
                    <div class="control has-icons-left">
                      <money :disabled="isReadOnly" name="depositAmount" data-vv-scope="page-2" data-vv-as="deposit amount" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('page-2.depositAmount') }" type="text" placeholder="0000.00" v-model="deal.depositAmount" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('page-2.depositAmount')" class="help is-danger">
                      <i v-show="errors.has('page-2.depositAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.depositAmount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field" >
                    <label class="label">Lease Contract Term</label>
                    <div class="control">
                      <multiselect :disabled="isReadOnly" @select="onFormChange()" data-vv-scope="page-2" :hideSelected="true" data-vv-as="lease contract term" :multiple="false" name="leaseContractTerm" :class="{'is-danger': errors.has('page-2.leaseContractTerm') }" v-model="selectedLeaseContractTermType" label="name" :options="orderedDealLeaseContractTermTypes" placeholder="Lease Contract Term" track-by="name" :select-label="'Select'"></multiselect>
                    </div>
                  </div>
                </div>
                <div class="column is-5" v-if="isLeaseContractType">
                  <div class="field">
                    <label class="label">Lease Contract Amount</label>
                    <div class="control has-icons-left">
                      <money :disabled="isReadOnly" name="leaseContractAmount" data-vv-scope="page-2" data-vv-as="lease contract amount" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('page-2.leaseContractAmount') }" type="text" placeholder="0000.00" v-model="deal.leaseAmount" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('page-2.leaseContractAmount')" class="help is-danger">
                      <i v-show="errors.has('page-2.leaseContractAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.leaseContractAmount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field">
                    <label class="label">Residual Value</label>
                    <div class="control has-icons-left">
                      <money name="residualValue" data-vv-as="residual value" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('residualValue') }" type="text" placeholder="0000.00" v-model="deal.residualValue" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('residualValue')" class="help is-danger">
                      <i v-show="errors.has('residualValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('residualValue') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-for="(dealItem, index) in this.soldItems" :key="dealItem.id">
              <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)"><section-header :title="'Sold Item (' + (index + 1) +')'" :overview="dealItem.forInventory.stockNo"></section-header></a>
              <div class="columns" v-if="dealItem.forInventory">
                <div class="column is-2">
                  <div class="field">
                    <label class="label">Sale Type</label>
                    <div>{{ dealItem.vehicleType }}</div>
                  </div>
                </div>
                <div class="column is-4" v-if="!isReadOnly">
                  <button-list-selector validateAs="sale type" data-vv-scope="page-2" scope="page-2" label="Confirm Sale Type" :required="isSaleTypeRequired" v-model="selectedVehicleType" :availableValues="availableVehicleTypes"></button-list-selector>
                </div>
              </div>
              <div class="columns" v-if="dealItem.forInventory && isNewVehicleType || dealItem.forInventory && allowRdrOnCpo && isCpoVehicleType">
                <div class="column is-2">
                  <div class="field">
                    <label class="label">Rdr Complete</label>
                    <div class="control">
                        <b-switch type="is-info" v-model="dealItem.forInventory.rdrComplete" :disabled="isReadOnly">{{ dealItem.forInventory.rdrComplete ? 'Yes' : 'No' }}</b-switch>
                    </div>
                  </div>
                </div>
                <div class="column is-2" v-if="dealItem.forInventory.rdrComplete && isNewVehicleType || dealItem.forInventory.rdrComplete && isCpoVehicleType && allowRdrOnCpo">
                  <div class="field">
                    <label class="label">Rdr Completed Date<b-icon v-if="dealItem.forInventory.rdrComplete" pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <el-date-picker
                      name="rdrCompletedAt"
                      @change="onFormChange()"
                      v-model="dealItem.forInventory.rdrCompletedAt"
                      type="date"
                      placeholder="Click to select..."
                      v-validate="{'required': dealItem.forInventory.rdrComplete}"
                      data-vv-scope="page-2" data-vv-as="Rdr Completed Date"
                      popper-class="datepicker-container"
                      :disabled="isReadOnly">
                    </el-date-picker>
                    <span v-show="errors.has('page-2.rdrCompletedAt')" class="help is-danger">
                      <i v-show="errors.has('page-2.rdrCompletedAt')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.rdrCompletedAt') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div name="Commission" v-show="active === 2">
            <div id="supplement-cost-section-widget" class="box">
              <section-header title="Additional Costs" parentSelector="#supplement-cost-section-widget"></section-header>
              <div class="columns">
                <div class="column is-12">

                  <b-table :data="this.supplementalCosts" id="supplemental-costs" name="supplemental-costs">
                    <template slot-scope="props">
                      <b-table-column field="name" label="Name" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column field="vendorName" label="Vendor" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.vendorName }}
                      </b-table-column>

                      <b-table-column field="processingAction" label="Nett Action" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.processingAction }}
                      </b-table-column>

                      <b-table-column field="isInGlBalance" label="Is In GL Balance" :class="{'striked': props.row.delete === true}" sortable>
                        {{ humanizedSwitch(props.row.isInGlBalance) }}
                      </b-table-column>

                      <b-table-column field="description" label="Description" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.description }}
                      </b-table-column>

                      <b-table-column field="cost" label="Cost" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.cost | currency }}
                      </b-table-column>

                      <b-table-column field="sellingPrice" label="Selling Price" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.sellingPrice | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions">
                        <span class="icon" @click="editSupplementalCost(props.row, props.index)" v-if="!props.row.delete">
                          <i :class="editIcon"></i>
                        </span>
                        <span class="icon" @click="removeSupplementalCost(props.index)" v-if="!props.row.delete" v-show="!isReadOnly">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                        <span class="is-size-7 has-text-danger" v-if="props.row.delete">
                          Pending Delete
                        </span>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No additional costs have been added.</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer" v-if="availableAdditionalCosts.length > 0">
                      <th colspan="6"></th>
                      <th colspan="1"><label class="label">{{additionalCostsTotal | currency}}</label></th>
                      <th colspan="1"></th>
                    </template>
                  </b-table>

                </div>
              </div>
              <supplement-cost-capture :value="this.newSupplementalCost" :supplementalCosts="this.supplementalCosts" :isReadOnly="isReadOnly"></supplement-cost-capture>
              <div class="inner-add-remove-button" v-if="!isReadOnly">
                <a class="button is-primary" @click="addSupplementalCost()">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <button class="button is-warning is-pulled-right" type="button" @click="restoreDealAdditionalCosts()">Restore Defaults</button>
              </div>
            </div>

            <div id="deal-commission-widget" class="box">
              <section-header title="Commission" parentSelector="#deal-commission-widget"></section-header>
                <div class="columns">
                  <div class="column is-12">
                    <b-table :data="this.commissions">
                      <template slot-scope="props">
                        <b-table-column field="salesPersonName" label="Sales Person" :class="{'striked': props.row.delete === true}" sortable>
                          {{ props.row.salesPersonName }}
                        </b-table-column>

                        <b-table-column field="commission" label="Commission" :class="{'striked': props.row.delete === true}" sortable>
                          {{ props.row.commission | currency }}
                        </b-table-column>

                        <b-table-column field="" label="Actions">
                        <section style="padding-top: 0em; display: flex; justify-content: flex-start;flex-wrap: wrap;">
                          <span class="icon" @click="editCommission(props.row, props.index)" v-if="!props.row.delete">
                            <i :class="editIcon"></i>
                          </span>
                          <span class="icon" @click="removeCommission(props.index)" v-if="!props.row.delete" v-show="!isReadOnly">
                            <i class="fal fa-minus-circle"></i>
                          </span>
                          <span class="is-size-7 has-text-danger" v-if="props.row.delete">
                            Pending Delete
                          </span>
                          <span style="margin-top: 3px;"><deal-commission-processing-action :commissionRequest="props.row" :saveStatusChange="false" v-on:commission-request-status-change="commissionRequestStatusChange" :usePortal="false" :index="props.index"></deal-commission-processing-action></span>
                        </section>
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <section class="section">
                          <div class="has-text-centered">
                            <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                            <p>No commissions have been added.</p>
                          </div>
                        </section>
                      </template>
                      <template slot="footer" v-if="availableCommissions.length > 0">
                        <th colspan="1"></th>
                        <th colspan="1"><label class="label">{{commissionsTotal | currency}}</label></th>
                        <th colspan="1"></th>
                      </template>
                    </b-table>
                  </div>
                </div>
                <commission-capture name="commission" :value="this.newCommission" :commissions="this.commissions" :isReadOnly="isReadOnly"></commission-capture>
                <div class="inner-add-remove-button" v-show="!isReadOnly">
                  <a class="button is-primary" @click="addCommission()">
                    <span class="icon">
                      <i class="fas fa-plus"></i>
                    </span>
                  </a>
                </div>
              </div>
          </div>
          <div name="TradeLease" v-show="active === 3">
            <div id="trade-section-widget" class="box" v-if="showTradeSection">
                <section-header title="Trades" parentSelector="#trade-section-widget"></section-header>
                <make-selector  v-if="!hasTradeInventorySwitch && !hasLeaseInventorySwitch" label="Previous Vehicle Make" :required=false v-model="selectedMake" :selectedDealer="this.currentDealer" :isDisabled="isReadOnly"></make-selector>
                <div class="field">
                  <label class="label">Do you have a trade?</label>
                  <div class="control is-flex">
                    <b-switch type="is-info" v-model="hasTradeInventorySwitch" @click.native="toggleTrade" :disabled="hasLeaseInventorySwitch || isReadOnly"></b-switch>
                  </div>
                </div>

                <div v-if="hasTradeInventorySwitch">
                  <lease-renewal-capture
                    scope="page-4"
                    v-for="(tradeItem, index) in tradeDealItems"
                    :key="200 + (tradeItem.config) ? tradeItem.config.sectionId : index"
                    :showPrice="true"
                    :showStockNoLabel="true"
                    :selectedDealer="currentDealer"
                    v-model="tradeDealItems[index]"
                    :soldStockNo="firstSoldItemInventory.stockNo"
                    :defaultSalesPerson="selectedSalesAgent"
                    :defaultSellerType="selectedSellerType"
                    :defaultSeller="selectedSeller"
                    :isReadOnly="isReadOnly"
                    :isTitleStatusRequiredOveride="isTitleStatusRequired">
                  </lease-renewal-capture>
                </div>
                <div class="inner-add-remove-button" v-if="hasTradeInventorySwitch" v-show="!isReadOnly">
                  <a class="button is-primary" @click="AddDealItem({type: 'Trade'})">
                    <span class="icon">
                      <i class="fas fa-plus"></i>
                    </span>
                  </a>
                  <a class="button is-danger" @click="removeDealItem('Trade')" v-if="tradeDealItems.length > 1">
                    <span class="icon">
                      <i class="fas fa-minus"></i>
                    </span>
                  </a>
                </div>
              </div>

              <div v-else class="box">
                <div class="field">
                  <label class="label" v-if="!isSaleTypeOfRetail">Trades and Leases not required for wholesale deals.</label>
                  <label class="label" v-else>Trades and Leases not required for locate and order stock types.</label>
                </div>
              </div>

              <div id="lease-renewal-section-widget" class="box" v-if="showLeaseRenewalSection">
                <section-header title="Lease Renewal / Lease Return" parentSelector="#lease-renewal-section-widget"></section-header>
                <div class="field">
                  <label class="label">Is there a lease to return?</label>
                  <div class="control">
                    <b-switch type="is-info" v-model="hasLeaseInventorySwitch" @click.native="toggleLeaseRenewal" :disabled="hasTradeInventorySwitch || isReadOnly"></b-switch>
                  </div>
                </div>
                <div v-if="hasLeaseInventorySwitch">
                  <lease-renewal-capture
                    scope="page-4"
                    v-for="(leaseItem, index) in leaseRenewalDealItems"
                    :key="300 + (leaseItem.config) ? leaseItem.config.sectionId : index"
                    :selectedDealer="currentDealer"
                    v-model="leaseRenewalDealItems[index]"
                    mode="LeaseRenewal"
                    :defaultSalesPerson="selectedSalesAgent"
                    :defaultSellerType="selectedSellerType"
                    :defaultSeller="selectedSeller"
                    :isReadOnly="isReadOnly">
                  </lease-renewal-capture>
                </div>
                <div class="inner-add-remove-button" v-if="hasLeaseInventorySwitch" v-show="!isReadOnly">
                  <a class="button is-primary" @click="AddDealItem({type: 'LeaseRenewal'})">
                    <span class="icon">
                      <i class="fas fa-plus"></i>
                    </span>
                  </a>
                  <a class="button is-danger" @click="removeDealItem('LeaseRenewal')" v-if="leaseRenewalDealItems.length > 1">
                    <span class="icon">
                      <i class="fas fa-minus"></i>
                    </span>
                  </a>
                </div>
              </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="columns is-mobile" style="width:100%">
          <div class="column is-narrow-mobile">
            <div class="is-flex-tablet is-pulled-left">
              <a class="button is-danger" @click="onCancel">Cancel</a>
              <button type="button" class="button is-link" @click="previous" v-if="active > 0">Previous</button>
            </div>
          </div>
          <div class="column is-narrow-mobile">
            <div class="is-flex-tablet is-vcentered footer-center footer-margin">
              <view-deal-washout :currentDeal="deal" :isBookingWizard="true" v-show="isReadOnly && !isCancelledDeal && !keepLocked && !isAllocatedDeal"></view-deal-washout>
              <deal-booking-document-capture :currentDeal="deal" :viewOnly="isReadOnly" v-if="showCdkRecapDocumentViewer || showVehicleCostDocumentViewer" v-on:deal-document-uploaded="syncDealAttachment" :isBookingWizard="true"></deal-booking-document-capture>
              <deal-pdf-document-viewer :currentDeal="deal" allowedDocumentOption="VehicleCost" :isBookingWizard="true"></deal-pdf-document-viewer>
              <deal-pdf-document-viewer :currentDeal="deal" allowedDocumentOption="CdkRecap" :isBookingWizard="true" captureIcon="exchange-alt"></deal-pdf-document-viewer>
              <deal-pdf-document-viewer :currentDeal="deal" allowedDocumentOption="CdkWashout" :isBookingWizard="true" captureIcon="dollar-sign"></deal-pdf-document-viewer>
              <a class="button is-link is-small" @click="unlockBookedDeal()" v-show="isReadOnly && !isCancelledDeal && !keepLocked && !isAllocatedDeal">
                <span class="icon is-small"><i class="fas fa-unlock"></i></span>
              </a>
            </div>
          </div>
          <div class="column">
            <div class="is-flex-tablet is-pulled-right footer-margin">
              <button type="button" class="button is-link" @click="next" v-if="active < 3">Next</button>
              <button type="button" :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }" @click="onQuickSave" v-if="isBookingStepBlocked && !firstTradeDealItemsSaved">Save Trade</button>
              <button v-if="hasTradeInventorySwitch && isBookingStepBlocked && firstTradeDealItemsSaved && showTradeWorkFlowItemAction" class="button is-warning" @click="onTradeItemClick(tradeWorkFlowItem)" type="button">Finalize Trade</button>
              <button type="button" class="button is-primary" :class="{'is-loading': isSaving }" @click="bookDeal" :disabled="isReadOnly || isBookingStepBlockedByTradeWorkFlow || isUploadingFile">{{saveBtnTitle}}</button>
              <deal-code-capture
                v-if="this.hasFeatureAccess(this.finalisePermission) && isBookedDeal && !deal.isBookingFinalised"
                :dealListModel="deal"
                :key="'dcc2' + deal.id"
                :usePortal="false"
                :isBookingWizard="true"
                :useLabel="true"
                :isListMode="false">
              </deal-code-capture>
            </div>
          </div>
        </div>
      </footer>
      <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
    </div>

    <div class="modal-card" v-show="bookingConfirmationActive">
      <header class="modal-card-head">
          <p class="modal-card-title">Booking Confirmation</p>
      </header>
      <section class="modal-card-body">
        <div id="deal-overview-widget" class="box">
          <section-header title="Overview" parentSelector="#deal-overview-widget"></section-header>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Total GP</label>
                <label class="label">{{totalSalesGP | currency}}</label>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Spiff</label>
                <label class="label">{{totalSpiff | currency}}</label>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Sale Price</label>
                <label class="label">{{totalSalesValue | currency}}</label>
              </div>
            </div>
            <!--
            <div class="column is-6">
              <div class="field">
                <label class="label">Total</label>
                <label class="label">{{deal.totalValue | currency}}</label>
              </div>
            </div>
            -->
            <div class="column is-6">
              <div class="field">
                <label class="label">Product GP</label>
                <div class="field">
                  <label class="label">{{totalProductGP | currency}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-multiline" v-if="!isSaleTypeOfRetail && selectedSeller">
            <div class="column is-12">
                <label class="label">Vendor Name</label>
                <label class="label">{{ supplierName }}</label>
            </div>
            <div class="column is-6">
              <label class="label">Vendor Email</label>
              <span class="is-size-7">
                <a @click="openEmail()">{{ supplierEmail }}</a>
              </span>
            </div>
            <div class="column is-6">
              <label class="label">Vendor Phone</label>
              <span class="is-size-7">
                <click-to-call
                  :key="'ctc' + selectedSeller.id"
                  :destinationLineProp="supplierPhone"
                  :receiverTypeProp="'Wholesaler'"
                  :receiverIdProp="selectedSeller.id">
                </click-to-call>
              </span>
            </div>
          </div>
          <div class="columns is-multiline" v-if="hasSoldItems">
            <div class="column is-6">
              <div class="field">
                <label class="label">Front GP</label>
                <label class="label">{{calculatedTotalFrontGross(firstSoldLineItem) | currency}}</label>
              </div>
            </div>
            <div class="column is-6">
              <!--
              <div class="field">
                <label class="label">Expected Front GP</label>
                <label class="label-frontgp-confirmation"
                  :class="{'has-text-danger': round(firstSoldLineItem.frontValue) !== round(calculatedTotalFrontGross(firstSoldLineItem)),
                    'has-text-success': round(firstSoldLineItem.frontValue === round(calculatedTotalFrontGross(firstSoldLineItem)))}"
                  @click="firstSoldLineItem.frontValue = calculatedTotalFrontGross(firstSoldLineItem)"
                  title="This is the expected value for front GP.">
                  <div>{{calculatedTotalFrontGross(firstSoldLineItem) | currency}}
                    <span v-if="round(firstSoldLineItem.frontValue) !== round(calculatedTotalFrontGross(firstSoldLineItem))">
                      WARNING! FRONT GP DOES NOT MATCH EXPECTED FRONT GP VALUE.
                    </span>
                  </div>
                </label>
              </div>
              -->
            </div>
            <div id="front-gross-calc-confirmation" class="column is-12">
              <label class="label">Front GP Calculation</label>
              <br/>
              <table class="table">
                <tr>
                    <th>Item</th>
                    <th>CDK</th>
                    <th>LIVE</th>
                  </tr>
                  <tr>
                    <td>Purchase Cost</td>
                    <td>{{ purchaseCostDerived | currency}}</td>
                    <td>{{ purchaseCostDerived | currency}}</td>
                  </tr>
                  <tr>
                    <td>Recon</td>
                    <td>{{calculatedReconCDK(firstSoldLineItem) | currency}}</td>
                    <td>{{calculatedReconLive(firstSoldLineItem) | currency}}</td>
                  </tr>
                  <tr>
                    <td>Inventory Additional Costs</td>
                    <td>{{inventoryAdditionalCostsTotal(firstSoldLineItem) | currency}}</td>
                    <td>{{inventoryAdditionalCostsTotal(firstSoldLineItem) | currency}}</td>
                  </tr>
                  <tr>
                    <td class="has-text-weight-bold">GL Balance (subtotal)</td>
                    <td class="has-text-weight-bold">{{frontGrossSubtotalCDK(firstSoldLineItem) | currency}}</td>
                    <td class="has-text-weight-bold">{{frontGrossSubtotalLive(firstSoldLineItem) | currency}}</td>
                  </tr>
                  <tr>
                    <td>Sale Price</td>
                    <td>{{firstSoldLineItem.salePrice | currency}}</td>
                    <td>{{firstSoldLineItem.salePrice | currency}}</td>
                  </tr>
                  <tr>
                    <td>Acv</td>
                    <td>{{totalAcvDifference | currency}}</td>
                    <td>{{totalAcvDifference | currency}}</td>
                  </tr>
                  <tr>
                    <td>Fees</td>
                    <td>{{additionalCostsGP | currency}}</td>
                    <td>{{additionalCostsGP | currency}}</td>
                  </tr>
                  <!--
                  <tr>
                    <td class="has-text-weight-bold">Total Front GP</td>
                    <td class="has-text-weight-bold">{{calculatedSubFrontGrossCDK(firstSoldLineItem) | currency}}</td>
                    <td class="has-text-weight-bold">{{calculatedSubFrontGross(firstSoldLineItem) | currency}}</td>
                  </tr>
                  --->
                  <tr>
                    <td class="has-text-weight-bold">Sub Total Front GP</td>
                    <td class="has-text-weight-bold">{{calculatedSubFrontGrossCDK(firstSoldLineItem) | currency}}</td>
                    <td class="has-text-weight-bold">{{calculatedSubFrontGross(firstSoldLineItem) | currency}}</td>
                  </tr>
                  <tr>
                    <td class="is-pulled-right"><span class="tag is-dark">Inventory Accrual</span></td>
                    <td class="has-background-grey-lighter">
                      <div class="is-flex">
                        <div class="mt-5">{{cdkAdjustmentAmount | currency}}</div>
                      </div>
                    </td>
                    <td class="has-background-grey-lighter">
                      <div class="is-flex">
                        <div class="mt-5">{{liveAdjustmentAmount | currency}}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="has-text-weight-bold">Total Front GP</td>
                    <td class="has-text-weight-bold" :class="getTotalFrontGrossClass(firstSoldLineItem)">{{calculatedTotalFrontGrossCDK(firstSoldLineItem) | currency}}</td>
                    <td class="has-text-weight-bold" :class="getTotalFrontGrossClass(firstSoldLineItem)">
                      {{calculatedTotalFrontGross(firstSoldLineItem) | currency}}
                      <span v-if="showWithInToleranceNote(firstSoldLineItem)"> [variance within tolerance {{getTotalFrontGrossDiff(firstSoldLineItem) | currency}}]</span>
                      <span v-else-if="getTotalFrontGrossDiff(firstSoldLineItem) !== 0"> [variance of {{Math.abs(getTotalFrontGrossDiff(firstSoldLineItem)) | currency}}]</span>
                    </td>
                  </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="onConfirmSaveBookedDeal(true)" type="button" :class="{'is-loading': isSaving }">Confirm</button>
        <button class="button is-danger" @click="onConfirmSaveBookedDeal(false)" type="button">Cancel</button>
      </footer>
      <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
    </div>

    <b-modal :active.sync="saveChangedDealItemsConfirmation" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']" id="save-changed-deal-items-modal">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          <div class="notification is-warning is-flex">
            <i class="fas fa-exclamation-triangle is-warning fa-2x"></i>
            <div class="ml-10">
              Unsaved items has been detected with trades.
              <br/><br/>Do you want to save before closing or close without saving?
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmTradeAdded(true)" type="button">Save And Close</button>
          <button class="button is-danger" @click="onConfirmTradeAdded(false)" type="button">Close Without Saving</button>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="confirmHoldbackSelectionActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']" id="confirm-holdback-selection-modal" :key="'chbs' + selectedHoldbackValue">
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Select Holdback Confirmation</p>
        </header>
        <section class="modal-card-body">
          <div class="column notification is-warning">
            <span>
              <i class="fas fa-exclamation-triangle is-warning"></i>
            </span>
            Please confirm that you want to update holdback value to {{selectedHoldbackValue | currency}}
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmHoldbackSelection(true)" type="button">Yes And Close</button>
          <button class="button is-danger" @click="onConfirmHoldbackSelection(false)" type="button">No</button>
        </footer>
      </div>
    </b-modal>

    <purchase-cost-total-capture :value="firstSoldItemInventory" :isPurchaseCostEditable.sync="isPurchaseCostEditable"></purchase-cost-total-capture>

    <trade-fullfillment-workflow
      v-on:trade-fullfillment-workflow-complete="onTradeWorkFlowComplete"
      v-on:trade-fullfillment-title-capture-skipped="onTradeWorkFlowTitleCaptureSkipped"
      v-on:trade-fullfillment-arrival-capture-skipped="onTradeWorkFlowArrivalCaptureSkipped"
      v-if="inventoryForTradeWorkFlow !== null"
      :forInventory="inventoryForTradeWorkFlow"
      :titleSkipped="tradeWorkflowTitleCaptureSkipped"
      :arrivalCaptureSkipped="tradeWorkflowArrivalCaptureSkipped"
      :key="'TFW' + inventoryForTradeWorkFlow.id"
      :usePortal="false">
    </trade-fullfillment-workflow>

    <b-modal :active.sync="editTradePreEntryActive" scroll="keep" v-if="dealHasTrade">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body edit-inventory">
          <edit-inventory
            :inventoryId="tradeItem.id"
            mode="modal"
            actionPortal="modal-footer"
            overviewPortal=""
            headerPortal="modal-header"
            :defaultPreEntry="false"
            v-on:on-save="onInventorySaved"
            v-on:on-cancel="onInventoryCancelled"></edit-inventory>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="closeConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']" id="save-changed-deal-items-modal">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          <div class="notification is-warning is-flex">
            <i class="fas fa-exclamation-triangle is-warning fa-2x"></i>
            <div class="ml-10">
              Do you want to save any entries made or close and lose any entries made.
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onSaveAndClose" type="button">Save And Close</button>
          <button class="button is-danger" @click="onConfirmCancel" type="button">Close Without Saving</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import eventBus from '@/eventBus'
import dealService from '@/services/dealService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import SectionHeader from '@/components/generic/SectionHeader'
import CustomerSelector from '@/components/generic/CustomerSelector'
import UserSelector from '@/components/generic/UserSelector'
import LeadSourceSelector from '@/components/generic/LeadSourceSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import supplierService from '@/services/supplierService'
import CommissionCapture from '@/components/generic/sales/CommissionCapture'
import SupplementalCostCapture from '@/components/generic/sales/SupplementalCostCapture'
import LeaseRenewalCapture from '@/components/generic/sales/LeaseRenewalCapture'
import MakeSelector from '@/components/generic/MakeSelector'
import ProductCostContainer from '@/components/generic/sales/ProductCostContainer'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import utilitiesMixin from '@/mixins/generic/utilities'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import PurchaseCostTotalCapture from '@/components/generic/PurchaseCostTotalCapture'
import InventoryWindowStickerCapture from '@/components/generic/inventory/InventoryWindowStickerCapture'
import MakeModelSelector from '@/components/generic/MakeModelSelector'
import TradeFullFillmentWorkFlow from '@/components/inventory/TradeFullFillmentWorkFlow'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import ConvertPpaToPurchaseAction from '@/components/inventory/ConvertPpaToPurchaseAction'
import ApprovePpaAction from '@/components/inventory/ApprovePpaAction'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import formattingMixin from '@/mixins/inventory/formatting'
import FrontGrossAccrualLineItems from '@/components/generic/sales/FrontGrossAccrualLineItems'
import ClickToCallComponent from '@/components/generic/ClickToCallComponent'
import ViewDealWashoutAction from '@/components/sales/deals/ViewDealWashoutAction'
import DealCodeCapture from '@/components/generic/DealCodeCapture'
import CalculatorControl from '@/components/generic/CalculatorControl'
import DealBookingDocumentCapture from '@/components/sales/deals/DealBookingDocumentCapture'
import SwitchSelector from '@/components/generic/SwitchSelector'
import DealPdfDocumentViewer from '@/components/sales/deals/DealPdfDocumentViewer'
import InventorySupplementalCostComponent from '@/components/generic/inventory/InventorySupplementalCostComponent'
import DealCommissionProcessingAction from '@/components/sales/deals/DealCommissionProcessingAction'
import { createNamespacedHelpers } from 'vuex'
import { Steps, Step } from 'element-ui'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'BookDealCapture',
  mixins: [validateBeforeSubmitMixin, humanizedSwitchMixin, utilitiesMixin, dealInventoryStyleClasses, formattingMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'el-steps': Steps,
    'el-step': Step,
    'section-header': SectionHeader,
    'customer-selector': CustomerSelector,
    'user-selector': UserSelector,
    'lead-source-selector': LeadSourceSelector,
    'supplier-selector': SupplierSelector,
    'commission-capture': CommissionCapture,
    'supplement-cost-capture': SupplementalCostCapture,
    'lease-renewal-capture': LeaseRenewalCapture,
    'make-selector': MakeSelector,
    'product-cost-container': ProductCostContainer,
    'error-display-component': ErrorDisplayComponent,
    'purchase-cost-total-capture': PurchaseCostTotalCapture,
    'window-stick-status-capture': InventoryWindowStickerCapture,
    'make-model-selector': MakeModelSelector,
    'trade-fullfillment-workflow': TradeFullFillmentWorkFlow,
    'edit-inventory': () => import('@/components/inventory/EditInventory'),
    'convert-ppa-to-purchase-action': ConvertPpaToPurchaseAction,
    'approve-ppa-action': ApprovePpaAction,
    'quick-action-container': QuickActionContainer,
    'fg-accrual-line-items': FrontGrossAccrualLineItems,
    'click-to-call': ClickToCallComponent,
    'view-deal-washout': ViewDealWashoutAction,
    'deal-code-capture': DealCodeCapture,
    'calculator-control': CalculatorControl,
    'switch-selector': SwitchSelector,
    'deal-booking-document-capture': DealBookingDocumentCapture,
    'inventory-supplemental-cost-component': InventorySupplementalCostComponent,
    'deal-pdf-document-viewer': DealPdfDocumentViewer,
    'deal-commission-processing-action': DealCommissionProcessingAction
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    bookDealCaptureActive: {
      type: Boolean,
      default: true
    },
    progressStatus: {
      type: Boolean,
      default: true
    },
    statusProgressionWizardActive: {
      type: Boolean,
      default: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    vehicleTypes: {
      type: Array,
      default: () => [
        {
          id: 0,
          name: 'New'
        },
        {
          id: 1,
          name: 'Used'
        },
        {
          id: 2,
          name: 'Cpo'
        }
      ]
    },
    keepLocked: {
      type: Boolean,
      default: false
    },
    pricingMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      redirectUrl: '',
      redirectMessage: '',
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      selectedCustomer: null,
      inventorySoldItem: null,
      active: 0,
      currentInventory: {
      },
      selectedSalesAgent: null,
      selectedFinanceProcessedBy: null,
      selectedLeadSource: null,
      selectedSupplier: null,
      commissions: [],
      newCommission: this.createGenericCommission(),
      isLoading: false,
      supplementalCosts: [],
      newSupplementalCost: this.createGenericSupplementalCost(),
      hasTradeInventorySwitch: false,
      dealItems: [],
      selectedMake: null,
      deal: this.value,
      hasLeaseInventorySwitch: false,
      bookingConfirmationActive: false,
      formValid: true,
      isReconEditable: false,
      isHoldbackEditable: false,
      isPurchaseCostEditable: false,
      purchaseCostBeforeModify: 0,
      selectedSalesAgentManager: null,
      selectedVehicleType: null,
      allowRdrOnCpo: false,
      initialTradeIdList: [],
      saveChangedDealItemsConfirmation: false,
      showHoldbackCalc: false,
      calculatedHoldback: 0.00,
      calculatedHoldbackPercentage: 0,
      calculatedBaseHoldback: 0.00,
      calculatedBaseHoldbackPercentage: 0,
      holdbackAdditional: 0.00,
      workingMsrp: 0.00,
      workingBaseMsrp: 0.00,
      calculatedHoldbackFocused: false,
      calculatedHoldbackPercentageFocused: false,
      calculatedBaseHoldbackFocused: false,
      calculatedBaseHoldbackPercentageFocused: false,
      workingMsrpDeductionsFocused: false,
      workingMsrpFocused: false,
      workingBaseMsrpFocused: false,
      isHoldbackCalculaterFormEditable: true,
      workingMsrpDeductions: 0.00,
      showHoldbackBreakdown: true,
      selectedMakeModelHbc: null,
      showCompleteMakeModel: false,
      selectedHoldbackValue: null,
      confirmHoldbackSelectionActive: false,
      inventoryForTradeWorkFlow: null,
      tradeWorkflowTitleCaptureSkipped: false,
      tradeWorkflowArrivalCaptureSkipped: false,
      autoContinueTradeFlow: false,
      editTradePreEntryActive: false,
      closeConfirmationActive: false,
      byPassDealCode: false,
      byPassSupplierNo: false,
      selectedContractType: { id: 0, name: 'Retail', altName: 'Retail' },
      selectedLeaseContractTermType: null,
      isUploadingFile: false,
      deleteSupplementalCostAccessPermission: 'inventory.view.delete.supplemental.cost',
      currentDeleteSupplementalcost: null,
      accrualLineStatus: true,
      frontGrossLineItemsAmount: 0,
      frontGrossLineItemsAmountLive: 0,
      saveAdjustmentsUuid: this.$uuid.v4(),
      finalisePermission: 'deal.modify.heat-sheet',
      isFinalisationConfirmationActive: false,
      toggleDefaultPurchaseCostCdkUuid: this.$uuid.v4(),
      defaultPurchaseCostCdk: false,
      isCashDown: false,
      selectedSaleType: null,
      fgAccrualKey: this.$uuid.v4(),
      showAutoUncheckMessage: false,
      cdkAdjustmentAmount: 0,
      liveAdjustmentAmount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'allowedRoles']),
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    isLeaseContractType: function () {
      return this.selectedContractType ? this.selectedContractType.id === this.definedTypes.dealContractTypes.lease : false
    },
    firstSoldItemInventoryMakeModel: function () {
      var exteriorColor = this.firstSoldItemInventory.attributes.find((x) => x.name === 'ExteriorColor')
      var interiorColor = this.firstSoldItemInventory.attributes.find((x) => x.name === 'InteriorColor')
      var vehicleTrim = this.firstSoldItemInventory.attributes.find((x) => x.name === 'Trim')
      var vehicleStyle = this.firstSoldItemInventory.attributes.find((x) => x.name === 'Style')
      var searchType = this.firstSoldItemInventory.attributes.find((x) => x.name === 'SearchType')
      var altExteriorColors = this.firstSoldItemInventory.attributes.find((x) => x.name === 'AlternateExteriorColors')
      var altInteriorColors = this.firstSoldItemInventory.attributes.find((x) => x.name === 'AlternateInteriorColors')
      var searchTags = this.firstSoldItemInventory.attributes.find((x) => x.name === 'SearchTags')
      var searchNotes = this.firstSoldItemInventory.attributes.find((x) => x.name === 'SearchNotes')

      var vehicleAge = moment().year() - (+this.currentInventory.vehicleProductionYear)
      var cpoAllowed = true
      var autoCpoStatus = 'Ineligible'

      if (vehicleAge >= 0 && vehicleAge <= this.currentDealer.autoCpoAgeLimit) {
        cpoAllowed = true
        autoCpoStatus = 'Cpo'
      } else if (vehicleAge > this.currentDealer.autoCpoAgeLimit && vehicleAge <= this.currentDealer.cpoQualifyingAge) {
        cpoAllowed = true
        autoCpoStatus = 'Eligible'
      }

      return {
        exteriorColor: exteriorColor ? exteriorColor.value : '',
        interiorColor: interiorColor ? interiorColor.value : '',
        trim: vehicleTrim ? vehicleTrim.value : '',
        style: vehicleStyle ? vehicleStyle.value : '',
        makeId: this.firstSoldItemVehicleMake ? this.firstSoldItemVehicleMake.id : null,
        selectedMake: this.firstSoldItemVehicleMake,
        modelId: this.firstSoldItemVehicleMakeModel ? this.firstSoldItemVehicleMakeModel.id : null,
        selectedModel: this.firstSoldItemVehicleMakeModel,
        productionYear: this.firstSoldItemInventory.vehicleProductionYear,
        customMakeName: this.firstSoldItemInventory.customMake === '' ? null : this.firstSoldItemInventory.customMake,
        customModelName: this.firstSoldItemInventory.customModel === '' ? null : this.firstSoldItemInventory.customModel,
        customMakeModel: this.firstSoldItemInventory.customVehicleMakeVersion,
        vehicleAge: vehicleAge,
        cpoAllowed: cpoAllowed,
        autoCpoStatus: autoCpoStatus,
        locateType: (searchType && searchType.value && searchType.value !== '') ? this.definedTypes.allowedLocateTypes.options.find((x) => x.name === searchType.value) : null,
        altExteriorColors: (altExteriorColors && altExteriorColors.value && altExteriorColors.value.length > 0) ? altExteriorColors.value.split(',') : [],
        altInteriorColors: (altInteriorColors && altInteriorColors.value && altInteriorColors.value.length > 0) ? altInteriorColors.value.split(',') : [],
        tags: (searchTags && searchTags.value && searchTags.value.length > 0) ? searchTags.value.split(',') : [],
        notes: searchNotes ? searchNotes.value : '',
        transmissionType: this.firstSoldItemInventory.transmissionType
      }
    },
    selectedDispositionIntentionStatus: function () {
      return this.selectedSaleType
    },
    totalTradeAllowance: function () {
      return this.tradeDealItems.map(x => x.salePrice).reduce((prev, next) => prev + next, 0)
    },
    acvLabel: function () {
      if (this.totalAcvDifference > 0) {
        return 'Over Allowance'
      } else {
        return 'ACV\'ed Up'
      }
    },
    showAcvWarning: function () {
      return this.totalAcvDifference < 0
    },
    totalAcvDifference: function () {
      return this.tradeDealItems.map(x => x.acvDifference).reduce((prev, next) => prev + next, 0)
    },
    totalAcvPurchase: function () {
      let ppaTotal = this.tradeDealItems.filter(x => x.forInventory && x.forInventory.type === 'ForPpaAppraisal').map(x => x.forInventory.proposedCost).reduce((prev, next) => prev + next, 0)
      let inventoryTotal = this.tradeDealItems.filter(x => x.forInventory && x.forInventory.type !== 'ForPpaAppraisal').map(x => x.forInventory.purchaseCost).reduce((prev, next) => prev + next, 0)
      return ppaTotal + inventoryTotal
    },
    soldItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    firstSoldItemInventory: function () {
      return this.hasSoldItems ? this.soldItems[0].forInventory : null
    },
    totalSpiff: function () {
      return this.soldItems.map(x => x.spiff).reduce((prev, next) => prev + next, 0)
    },
    overview: function () {
      if (!this.firstSoldItemInventory) {
        return ''
      }

      var result = ''
      if (this.firstSoldItemInventory.vinNo !== '') {
        result += 'VIN: ' + this.firstSoldItemInventory.vinNo
      } else {
        result += 'VIN: Pending'
      }
      if (this.firstSoldItemInventory.stockNo !== '') {
        result += ', STK: ' + this.firstSoldItemInventory.stockNo
      } else {
        result += ', STK - Pending'
      }
      if (this.firstSoldItemInventory.name !== '') {
        result += ', ITEM: ' + this.firstSoldItemInventory.name
      } else {
        result += ', ITEM: -'
      }
      if (this.firstSoldItemInventory.type !== '') {
        result += ', TYPE: ' + this.firstSoldItemInventory.type
      } else {
        result += ', TYPE: -'
      }
      if (this.firstSoldItemInventory.cpoStatus !== '' && this.firstSoldItemInventory.type !== 'New') {
        result += ', CPO: ' + this.firstSoldItemInventory.cpoStatus
      } else if (this.firstSoldItemInventory.type !== 'New') {
        result += ', CPO: -'
      }

      return result
    },
    isSaleTypeOfRetail: function () {
      if (this.deal !== null && this.deal.saleType === 'Retail') {
        return true
      } else {
        return false
      }
    },
    isLeadSourceRequired: function () {
      if (this.deal && this.isSaleTypeOfRetail && (this.isBookedDeal || this.isDeliveredDeal)) {
        return true
      } else {
        return false
      }
    },
    isSalesAgentRequired: function () {
      if (this.deal && this.deal.type === 'Cancelled') {
        return false
      } else {
        return true
      }
    },
    isFinanceProcessedByRequired: function () {
      if (this.deal && this.deal.saleType === 'Retail' && (this.isBookedDeal || this.isDeliveredDeal)) {
        return true
      } else {
        return false
      }
    },
    totalFrontValue: function () {
      return this.soldItems.map(x => x.frontValue).reduce((prev, next) => prev + next, 0)
    },
    totalSalesValue: function () {
      return this.soldItems.map(x => x.salePrice).reduce((prev, next) => prev + next, 0)
    },
    isSaleTypeOfWholesale: function () {
      return (this.deal && this.deal.saleType === 'Wholesale')
    },
    canShowRdr: function () {
      return this.hasSoldItems && this.firstSoldLineItem.vehicleType === 'New'
    },
    commissionsTotal: function () {
      if (!this.commissions) {
        return 0
      }
      return this.commissions.reduce(function (prev, cur) {
        return prev + ((cur.delete === true || cur.requestStatus !== 'Approved') ? +0 : +cur.commission)
      }, 0)
    },
    additionalCostsTotal: function () {
      if (!this.supplementalCosts) {
        return 0
      }
      return this.supplementalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    showTradeSection: function () {
      return this.isSaleTypeOfRetail && this.hasSoldItems && this.firstSoldItemInventory !== null &&
      (this.deal.stockType !== 'Locate' || (this.isPendingCreditDeal || this.isPreDeal))
    },
    hasTradeInventory: function () {
      return this.tradeDealItems.length > 0
    },
    hasLeaseInventory: function () {
      return this.leaseRenewalDealItems.length > 0
    },
    hasTradeSections: function () {
      return this.hasTradeInventory
    },
    humanizedHasTradeInventory: function () {
      return this.hasTradeInventorySwitch ? 'Yes' : 'No'
    },
    tradeDealItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter((x) => x.dealItemType === 'Trade') : []
    },
    hasLeaseRenewalSections: function () {
      return this.leaseRenewalDealItems.length > 0
    },
    leaseRenewalDealItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter(x => x.dealItemType === 'LeaseRenewal') : []
    },
    humanizedHasLeaseRenewal: function () {
      return this.hasLeaseInventorySwitch ? 'Yes' : 'No'
    },
    showLeaseRenewalSection: function () {
      return this.isSaleTypeOfRetail && this.deal.stockType !== 'Locate' && this.deal.stockType !== 'Order'
    },
    availableAdditionalCosts: function () {
      return this.supplementalCosts.filter(x => !x.delete)
    },
    availableCommissions: function () {
      let filtered = this.commissions.filter(x => !x.delete)
      filtered = filtered.filter(x => x.requestStatus === 'Approved')
      return filtered
    },
    totalGP: function () {
      var totalGP = 0
      this.soldItems.forEach(function (dealItem) {
        totalGP += dealItem.salePrice + dealItem.itemProducts.map(x => x.sellingPrice).reduce((prev, next) => prev + next, 0)
      }, this)
      return totalGP
    },
    totalProductGP: function () {
      var totalProductGP = 0
      this.soldItems.forEach(function (dealItem) {
        totalProductGP += dealItem.itemProducts.filter(y => y.type === 'Product' && !y.deleted).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0)
      }, this)
      return totalProductGP
    },
    totalSalesGP: function () {
      let total = 0
      this.soldItems.forEach((item) => {
        total += this.dealItemTotalGP(item)
      }, total)
      return total
    },
    saveBtnTitle: function () {
      return this.progressStatus ? 'Book Deal' : 'Save Deal'
    },
    additionalCostsGP: function () {
      if (this.availableAdditionalCosts.length === 0) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true || cur.isInGlBalance ? +0 : +(cur.sellingPrice - cur.cost))
      }, 0)
    },
    additionalCostsRecon: function () {
      if (this.availableAdditionalCosts.length === 0) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true || !cur.isInGlBalance ? +0 : +(cur.sellingPrice - cur.cost))
      }, 0)
    },
    salePriceWatcher: function () {
      return this.firstSoldLineItem.salePrice
    },
    selectedSeller: function () {
      if (this.isSaleTypeOfRetail) {
        return this.selectedCustomer
      }
      if (this.isSaleTypeOfWholesale) {
        return this.selectedSupplier
      }
      return null
    },
    selectedSellerType: function () {
      if (this.isSaleTypeOfRetail) {
        return this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Individual)
      }
      if (this.isSaleTypeOfWholesale) {
        return this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
      }
      return null
    },
    editIcon: function () {
      return this.isReadOnly ? 'fal fa-eye' : 'fal fa-pencil'
    },
    isCancelledDeal: function () {
      return this.deal && this.deal.type === 'Cancelled'
    },
    isAllocatedDeal: function () {
      return this.deal && this.deal.type === 'Allocated'
    },
    availableVehicleTypes () {
      if (this.isSaleTypeOfWholesale) {
        return this.vehicleTypes.filter(x => x.name === 'New' || x.name === 'Used')
      }
      return this.vehicleTypes
    },
    isSaleTypeRequired: function () {
      if (this.deal && this.progressStatus) {
        return true
      } else {
        return false
      }
    },
    isNewVehicleType: function () {
      if (this.selectedVehicleType) {
        return this.selectedVehicleType.name === 'New'
      }
      return this.canShowRdr
    },
    isCpoVehicleType: function () {
      if (this.selectedVehicleType) {
        return this.selectedVehicleType.name === 'Cpo'
      }
      return this.canShowRdr
    },
    isTitleStatusRequired: function () {
      if (this.deal && this.progressStatus) {
        return true
      } else {
        return false
      }
    },
    purchaseCostDerived: function () {
      return this.firstSoldItemInventory.purchaseCost + this.firstSoldItemInventory.commission
    },
    isPurchaseCostDisabled: function () {
      if (this.isReadOnly) {
        return true
      } else if (this.firstSoldItemInventory && this.purchaseCostBeforeModify < 3) {
        return false
      } else if (this.hasFeatureAccess('deal.modify.unlockpurchasecost', false)) {
        return false
      }
      return true
    },
    hasSoldItems: function () {
      return this.soldItems.length > 0
    },
    firstSoldLineItem: function () {
      return this.hasSoldItems ? this.soldItems[0] : {}
    },
    hasDealItemsChanged: function () {
      let result = false

      if (this.initialTradeIdList.length !== this.tradeDealItems.length) {
        result = true
      } else {
        this.tradeDealItems.forEach((x) => {
          if (x.forInventory && this.initialTradeIdList.length > 0) {
            var index = this.initialTradeIdList.findIndex((y) => (y.id === x.forInventory.id))
            if (index === -1) {
              result = true
            }
          }
        })
      }

      return result
    },
    showFeePods: function () {
      return !this.showHoldbackCalc
    },
    showFrontGrossCal: function () {
      return !this.showHoldbackCalc
    },
    firstSoldItemVehicleMakeModel: function () {
      return this.firstSoldItemVehicleMake ? this.firstSoldItemVehicleMake.versions.find((x) => x.id === (this.firstSoldItemInventory.modelId || this.firstSoldItemInventory.vehicleMakeVersionId)) : null
    },
    firstSoldItemVehicleMake: function () {
      return this.allVehicleMakes.find((x) => x.id === (this.firstSoldItemInventory.makeId || this.firstSoldItemInventory.vehicleMakeId))
    },
    calculatedBaseHoldbackTotal: function () {
      return this.formatNumberToTwoDecimalPlaces(this.holdbackAdditional + this.calculatedBaseHoldback)
    },
    calculatedHoldbackTotal: function () {
      return this.formatNumberToTwoDecimalPlaces(this.holdbackAdditional + this.calculatedHoldback)
    },
    soldInventoryMsrp: function () {
      return this.firstSoldItemInventory && this.firstSoldItemInventory.msrp ? this.firstSoldItemInventory.msrp : 0.00
    },
    baseInventoryMsrp: function () {
      return this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMakeModel.msrp ? this.firstSoldItemVehicleMakeModel.msrp : 0.00
    },
    getTotalMSRPColourClass: function () {
      return this.soldInventoryMsrp === this.workingMsrp ? 'is-success' : 'is-danger'
    },
    getBaseMSRPColourClass: function () {
      return this.baseInventoryMsrp === this.workingBaseMsrp ? 'is-success' : 'is-danger'
    },
    defaultMsrpDeductions: function () {
      let defaultMsrpDeductions = this.firstSoldItemVehicleMake && this.firstSoldItemVehicleMake.msrpDeductions ? this.firstSoldItemVehicleMake.msrpDeductions : 0.00
      return this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMakeModel.msrpDeductionsToUse ? this.firstSoldItemVehicleMakeModel.msrpDeductionsToUse : defaultMsrpDeductions
    },
    getDefaultMsrpDeductionsColourClass: function () {
      return this.defaultMsrpDeductions === this.workingMsrpDeductions ? 'is-success' : 'is-danger'
    },
    holdBackCalculatorButtonIds: function () {
      return ['calculatedHoldback_button', 'calculatedHoldbackTotal_button', 'calculatedBaseHoldback_button', 'calculatedBaseHoldbackTotal_button']
    },
    holdbackCalculationBreakdown: function () {
      return '(' + this.workingMsrp + ' - ' + this.workingMsrpDeductions + ')' + ' * ' + this.calculatedHoldbackPercentage + '%' + ' = ' + this.calculatedHoldback
    },
    holdbackBaseCalculationBreakdown: function () {
      return '(' + this.workingBaseMsrp + ' - ' + this.workingMsrpDeductions + ')' + ' * ' + this.calculatedBaseHoldbackPercentage + '%' + ' = ' + this.calculatedBaseHoldback
    },
    isPorter: function () {
      return this.allowedRoles.indexOf('Porter Role') >= 0
    },
    selectedTypeStatus: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.firstSoldItemInventory.type || x.altName === this.firstSoldItemInventory.type))
    },
    currentDealer: function () {
      return this.$store.state.dealer.currentDealer
    },
    canShowCompleteMakeModel: function () {
      return this.showHoldbackCalc && this.showCompleteMakeModel
    },
    isTradeWorkFlowComplete: function () {
      return this.dealHasTrade && this.tradeWorkFlowItem.tradeHasCheckRequest
    },
    isBookingStepBlockedByTradeWorkFlow: function () {
      if (this.dealHasTrade && this.progressStatus) {
        if (!this.isTradeWorkFlowComplete && (this.isTradeTypeTradeIn || this.isTradeTypePpaAppraisal)) {
          return true
        }
      }
      return false
    },
    dealHasTrade: function () {
      return this.tradeItem !== null && this.tradeItem !== undefined
    },
    showTradeWorkFlowItemAction: function () {
      return this.dealHasTrade && !this.isTradeTypePpaAppraisal && !this.isTradeStatusPreEntry
    },
    tradeItem: function () {
      return this.tradeItems.length > 0 ? this.tradeItems[0].forInventory : null
    },
    firstTradeDealItem () {
      return this.tradeItems.length > 0 ? this.tradeItems[0] : null
    },
    firstTradeDealItemsSaved: function () {
      return this.firstTradeDealItem && this.firstTradeDealItem.id > 0
    },
    tradeWorkFlowItem: function () {
      if (this.dealHasTrade) {
        let incoming = this.tradeItem.possessionStatus === 'Inbound'
        let tradeHasTitle = this.tradeItem.titleStatus === 'Yes' || this.tradeItem.titleStatus === 'PayOff' || this.tradeItem.titleStatus === 'Lost'
        let tradeHasAgreement = this.tradeItem.sellersAgreementStatus === 'Signed' || this.tradeItem.sellersAgreementStatus === 'Complete'
        let tradeHasAgreementPending = this.tradeItem.hasPendingRetailAgreements
        let tradeHasCheckRequest = this.tradeItem.paymentRequestStatus === 'Sent'
        let carFaxStatus = -1
        if (this.tradeItem.carFaxRating && (this.tradeItem.carFaxRating.brandedTitle === 1 || this.tradeItem.carFaxRating.totalAccidents > 0 ||
          this.tradeItem.carFaxRating.accidentIndicator === 1 || this.tradeItem.carFaxRating.frameDamageOrRepair === 1 || this.tradeItem.carFaxRating.airBagDeployed === 1 ||
          this.tradeItem.carFaxRating.damaged === 1 || this.tradeItem.carFaxRating.majorProblems === 1 || this.tradeItem.carFaxRating.recall === 1)) {
          carFaxStatus = 0
        }
        let isPpa = this.tradeItem.type === 'ForPpaAppraisal'
        let tradeAllowanceApproved = (this.firstTradeDealItem) ? this.firstTradeDealItem.tradeAllowanceApproved : false
        let item = {
          tradeId: this.tradeItem.id,
          hasTrade: true,
          tradeIncoming: incoming,
          tradeHasTitle: tradeHasTitle,
          tradeHasAgreement: tradeHasAgreement,
          tradeHasCheckRequest: tradeHasCheckRequest,
          tradeDamaged: carFaxStatus === 0 || this.tradeItem.isDamaged,
          carFaxStatus: carFaxStatus,
          tradeHasAgreementPending: tradeHasAgreementPending,
          tradeArrivalSkipped: this.tradeItem.arrivalSkipped,
          tradeArrivalSkippedBy: this.tradeItem.arrivalSkippedBy,
          tradeIsPPA: isPpa,
          dealId: this.dealId,
          tradeAllowanceApproved: tradeAllowanceApproved,
          tradeStockNo: this.tradeItem.stockNo
        }
        return item
      }
      return { tradeId: null, hasTrade: false, tradeIncoming: false, tradeHasTitle: false, tradeHasAgreement: false, tradeHasCheckRequest: false, tradeDamaged: false, carFaxStatus: -1, tradeHasAgreementPending: false, tradeArrivalSkipped: false, tradeArrivalSkippedBy: '', tradeIsPPA: false, dealId: 0, tradeAllowanceApproved: false, tradeStockNo: '' }
    },
    tradeItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter((x) => x.dealItemType === 'Trade') : []
    },
    dealId: function () {
      return this.deal ? this.deal.id : 0
    },
    bookingStepBlockingContent: function () {
      let content = ''

      if (this.isBookingStepBlockedPPATrade) {
        content += ' <p>The PPA must be approved and converted. Click the approve or conversion icon to proceed</p>'
      }

      if (this.showEditPreEntryAction) {
        content += ' <p>The trade-in vehicle is still in pre-entry status, please click the pre-entry icon and complete the stock-in.</p>'
      }

      if (this.isBookingStepBlockedByTradeWorkFlow) {
        content += ' <p>The trade needs to be been finalized. Click the trade icon to proceed.</p>'
      }
      return content
    },
    isTradeTypeTradeIn: function () {
      return this.dealHasTrade && this.tradeItem.type === 'TradeIn'
    },
    isTradeTypePpaAppraisal: function () {
      return this.dealHasTrade && this.tradeItem.type === 'ForPpaAppraisal'
    },
    isBookingStepBlockedPPATrade: function () {
      return this.isTradeTypePpaAppraisal && this.isDeliveredDeal
    },
    isTradeStatusPreEntry: function () {
      return this.dealHasTrade && this.tradeItem.status === 'PreEntry'
    },
    isBookingStepBlocked () {
      return this.isBookingStepBlockedByTradeWorkFlow || this.isBookingStepBlockedPPATrade || this.isBookingStepBlockedPreEntryTrade
    },
    isDeliveredDeal: function () {
      return this.deal && this.deal.type === 'Delivered'
    },
    isBookingStepBlockedPreEntryTrade: function () {
      return this.showEditPreEntryAction && this.isDeliveredDeal
    },
    showEditPreEntryAction () {
      if (this.dealHasTrade) {
        if (this.isTradeStatusPreEntry) {
          if (this.isTradeTypePpaAppraisal && this.tradeItem.ppaApproved) {
            return true
          } else if (this.isTradeTypePpaAppraisal) {
            return false
          }
          return true
        }
      }
      return false
      // return this.dealHasTrade && this.isTradeStatusPreEntry && ((this.tradeItem.ppaApproved && this.isTradeTypePpaAppraisal) || this.isTradeTypeTradeIn)
    },
    isPreDeal: function () {
      return this.deal && this.deal.status === 'PreDeal'
    },
    isPendingCreditDeal: function () {
      return this.deal && this.deal.type === 'PendingCredit'
    },
    dealTypeDescription: function () {
      let description = 'Retail'
      if (this.deal && this.deal.isLeaseReturnDeal) {
        description = 'Lease'
      }
      return description
    },
    orderedDealLeaseContractTermTypes: function () {
      let dealLeaseContractTermTypes = this.definedTypes.dealLeaseContractTermTypes.options
      return _.sortBy(dealLeaseContractTermTypes, ['name'])
    },
    supplierName: function () {
      return !this.isSaleTypeOfRetail && this.selectedSellerType.altName === 'Wholesaler' && this.selectedSeller ? this.selectedSeller.name : ''
    },
    supplierEmail: function () {
      return !this.isSaleTypeOfRetail && this.selectedSellerType.altName === 'Wholesaler' && this.selectedSeller ? this.selectedSeller.primaryContactEmail : ''
    },
    supplierPhone: function () {
      return !this.isSaleTypeOfRetail && this.selectedSellerType.altName === 'Wholesaler' && this.selectedSeller ? this.selectedSeller.primaryContactMobile : ''
    },
    showVehicleCostDocumentViewer: function () {
      let matchedDocuments = this.deal.attachments.filter((x) => x.classType === 'VehicleCost')
      if (this.isReadOnly && matchedDocuments.length === 0) {
        return false
      }
      return true
    },
    getCdkAttachmentUrl: function (type) {
      let resultAttachments = []
      if (this.deal && this.deal.attachments && this.deal.attachments.length > 0) {
        let orderedAttachments = _.orderBy(this.deal.attachments, ['createdAt'], ['desc'])
        orderedAttachments.filter(y => y.status !== 'Deleted' && y.classType === 'CdkRecap').forEach(x => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)
          resultAttachments.push(x)
        })
      }
      if (resultAttachments.length > 0) {
        return resultAttachments[0].fileLocation
      }
      return ''
    },
    showCdkRecapDocumentViewer: function () {
      let matchedDocuments = this.deal.attachments.filter((x) => x.classType === 'CdkRecap')
      if (this.isReadOnly && matchedDocuments.length === 0) {
        return false
      }
      return true
    },
    getFgLineItem: function () {
      let amount = this.canShowgAccrualInfoCdk(this.firstSoldLineItem) ? this.calculatedFgAccrualCdk(this.firstSoldLineItem) : this.calculatedFgAccrualLive(this.firstSoldLineItem)
      return {
        dealItemId: this.firstSoldLineItem.id,
        amount: amount,
        cdkGlbalanceToPurchaseCostPercentage: this.cdkGlbalanceToPurchaseCostPercentage(this.firstSoldLineItem),
        purchaseCostDerived: this.purchaseCostDerived
      }
    },
    isBookedDeal: function () {
      return this.deal && this.deal.type === 'Booked'
    },
    hasAnyCdkAccrualSelections: function () {
      return this.cdkAdjustmentAmount !== 0
    },
    hasAnyLiveAccrualSelections: function () {
      return this.liveAdjustmentAmount !== 0
    }
  },
  methods: {
    next () {
      var scope = 'page-' + (this.active + 1) + '.*'
      switch (this.active) {
        case 0: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.active++
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
        case 1: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.active++
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
        case 2: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.active++
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
        case 3: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.validateBeforeSubmit()
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
      }
    },
    previous () {
      if (this.active-- < 1) this.active = 0
    },
    onSave: function () {
      if (this.progressStatus && !this.quickSaveActive && this.saveChangedDealItemsConfirmation === false) {
        this.bookingConfirmationActive = true
      } else {
        this.onSaveConfirmed()
      }
    },
    onCancel: function () {
      if (this.hasDealItemsChanged) {
        this.saveChangedDealItemsConfirmation = true
        return
      }

      if (this.isReadOnly) {
        this.onConfirmTradeAdded(false)
      } else {
        this.closeConfirmationActive = true
      }
    },
    onConfirmTradeAdded: function (save) {
      if (save) {
        this.bookDeal()
      } else {
        this.reloadDeal()
        if (this.pricingMode) {
          this.$emit('update:statusProgressionWizardActive', false)
          this.$emit('update:bookDealCaptureActive', false)
          this.$emit('update:pricingMode', false)
        } else {
          this.$emit('update:statusProgressionWizardActive', true)
          this.$emit('update:bookDealCaptureActive', false)
        }
      }
    },
    onComplete: function () {
      if (this.progressStatus && !this.quickSaveActive && this.saveChangedDealItemsConfirmation === false) {
        eventBus.$emit('update-deal-status-to-booked', {})
        return
      }
      this.reloadDeal()
      if (this.quickSaveActive && !this.closeConfirmationActive) {
        this.setInitialDealValues()
        this.quickSaveActive = false
        return
      }
      this.$emit('update:statusProgressionWizardActive', true)
      this.$emit('update:bookDealCaptureActive', false)
    },
    onValidationError: function (errors) {
      this.serverErrors = errors
      let rdrError = this.serverErrors.find(x => x.errorMessage === 'Cannot book without RDR on a new car')
      if (rdrError) {
        this.active = 1
        this.$nextTick(() => {
          this.$goToErrorBlock(this.$refs.generalErrorBlock)
        })
      }
    },
    onError: function (error) {
      this.serverErrorMessage = error.message
      this.isLoading = false
      this.isSaving = false
      this.$nextTick(() => {
        this.$goToErrorBlock(this.$refs.generalErrorBlock)
      })
    },
    setInitialDealValues () {
      if (this.deal) {
        if (this.deal.customer.id !== 0) {
          this.selectedCustomer = this.deal.customer
        }
        this.selectedSaleType = this.definedTypes.dealSaleTypes.find((x) => x.name === this.deal.saleType)
        this.selectedSalesAgent = (this.deal.salesPersonId) ? { id: this.deal.salesPersonId, fullName: this.deal.salesPersonName, dealerId: this.deal.dealerId } : null
        this.selectedFinanceProcessedBy = (this.deal.financeProcessedById) ? { id: this.deal.financeProcessedById, fullName: this.deal.financeProcessedByName, dealerId: this.deal.dealerId } : null
        this.selectedLeadSource = (this.deal.sourceId) ? { id: this.deal.sourceId, name: this.deal.sourceName, dealerId: this.deal.dealerId } : null
        this.selectedSalesAgentManager = (this.deal.salesPersonManagerId) ? { id: this.deal.salesPersonManagerId, fullName: this.deal.salesPersonManagerName, dealerId: this.deal.dealerId } : null
        this.isCashDown = this.deal.cashDown

        if (this.deal.supplierId) {
          this.isLoading = true
          supplierService.single(this.deal.supplierId).then(response => {
            this.isLoading = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              var supplier = response.supplier
              this.selectedSupplier = supplier
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isLoading = false
          })
        }

        this.supplementalCosts = []
        this.deal.supplementalCosts.forEach(function (supplementalCost) {
          this.supplementalCosts.push(supplementalCost)
        }, this)

        this.commissions = []
        this.deal.commissions.forEach(function (commission) {
          this.commissions.push(commission)
        }, this)

        // removed all items with id 0 as user may have added client side but not saved it
        var dealItemsFiltered = this.deal.items.filter(function (el) {
          return el.id !== 0
        })
        this.deal.items = dealItemsFiltered

        let indexer = 0
        this.deal.items.filter(x => x.dealItemType === 'Sold').forEach((x) => {
          var index = this.deal.items.findIndex((y) => (y.id === x.id))
          if (index !== -1) {
            var config = {
              sectionId: indexer + this.getIndexOffset('Sold')
            }
            this.deal.items[index].config = config
            indexer++
          }
        })

        indexer = 0
        this.initialTradeIdList = []
        this.deal.items.filter(x => x.dealItemType === 'Trade').forEach((x) => {
          this.initialTradeIdList.push({id: x.forInventory.id})
          var index = this.deal.items.findIndex((y) => (y.id === x.id))
          if (index !== -1) {
            var config = {
              sectionId: indexer + this.getIndexOffset('Trade')
            }
            this.deal.items[index].config = config
            indexer++
            this.hasTradeInventorySwitch = true
          }
        })

        indexer = 0
        this.deal.items.filter(x => x.dealItemType === 'LeaseRenewal').forEach((x) => {
          var index = this.deal.items.findIndex((y) => (y.id === x.id))
          if (index !== -1) {
            var config = {
              sectionId: indexer + this.getIndexOffset('LeaseRenewal')
            }
            this.deal.items[index].config = config
            indexer++
            this.hasLeaseInventorySwitch = true
          }
        })

        if (this.deal.previousVehicleMakeId && this.deal.previousVehicleMakeId !== 0) {
          this.selectedMake = { id: this.deal.previousVehicleMakeId, name: this.deal.previousVehicleMakeName }
        }

        this.firstSoldItemInventory.reconCost = this.firstSoldItemInventory.reconCost === null ? 0.00 : this.firstSoldItemInventory.reconCost
        this.purchaseCostBeforeModify = this.firstSoldItemInventory.purchaseCostTotal === null ? 0.00 : this.firstSoldItemInventory.purchaseCostTotal

        if (this.hasSoldItems && this.firstSoldLineItem.vehicleType === 'New' && this.isSaleTypeOfRetail) {
          this.selectedVehicleType = { id: 0, name: 'New' }
        }

        if (this.isSaleTypeOfWholesale && this.hasSoldItems) {
          this.selectedVehicleType = this.availableVehicleTypes.find(x => x.name === this.firstSoldLineItem.vehicleType)
        }

        this.allowRdrOnCpo = this.$store.state.subscriber.currentSubscriber.allowRdrOnCpo
        this.selectedContractType = this.definedTypes.dealContractTypes.options.find((x) => x.name === this.deal.contractType)
        this.selectedLeaseContractTermType = this.deal.leaseContractTermType !== '' ? this.definedTypes.dealLeaseContractTermTypes.options.find((x) => x.altName === this.deal.leaseContractTermType) : null
        this.setInitialHoldbackValues()
      }
    },
    updateFrontGross: function () {
      if (this.firstSoldLineItem) {
        let reconCost = this.calculatedReconLive(this.firstSoldLineItem)
        let tradeAcvDifference = this.totalAcvDifference || 0.00
        let actualInventoryCost = this.purchaseCostDerived + reconCost + tradeAcvDifference
        var fg = this.firstSoldLineItem.salePrice + this.additionalCostsGP - (actualInventoryCost)
        this.firstSoldLineItem.frontValue = fg
      }
    },
    sumSalePriceProductTotal: function (salePrice, productTotal) {
      return salePrice + productTotal
    },
    addCommission: function () {
      this.newCommission = this.createGenericCommission()
      this.newCommission.isAddingCommission = true
    },
    editCommission (commission, index) {
      this.newCommission = this.createGenericCommission()
      this.newCommission = {
        index: index,
        id: commission.id,
        salesPersonId: commission.salesPersonId,
        salesPersonName: commission.salesPersonName,
        commission: commission.commission,
        dealId: commission.dealId,
        isAddingCommission: true,
        requestStatus: commission.requestStatus
      }
    },
    createGenericCommission () {
      return {
        id: 0,
        salesPersonId: null,
        salesPersonName: null,
        commission: null,
        isAddingCommission: false,
        requestStatus: 'Requested'
      }
    },
    saveCommission: function (dealStatusWizardModel) {
      this.deal.commissions = []
      this.commissions.forEach(function (commission) {
        var dealCommission = {
          index: commission.index,
          id: commission.id,
          salesPersonId: commission.salesPersonId,
          salesPersonName: commission.salesPersonName,
          commission: commission.commission,
          dealId: commission.dealId,
          isAddingCommission: true,
          delete: commission.delete === undefined ? false : commission.delete,
          requestStatus: commission.requestStatus
        }
        dealStatusWizardModel.commissions.push(dealCommission)
        this.deal.commissions.push(dealCommission)
      }, this)
    },
    removeCommission (index) {
      var item = this.commissions[index]
      if (item.id === 0) {
        this.$delete(this.commissions, index)
      } else {
        item.delete = true
      }
    },
    createGenericSupplementalCost () {
      return {
        id: 0,
        name: null,
        payeeType: 'Supplier',
        vendorId: null,
        vendorName: '',
        attachments: [],
        processingAction: null,
        description: '',
        cost: null,
        sellingPrice: null,
        isInGlBalance: false,
        appliesToDispositionIntention: 'All',
        appliesOnSaleOnly: false,
        requiresMatchedSaleIntention: false,
        dealerDefaultCostId: null,
        isAddingCost: false
      }
    },
    editSupplementalCost (supplement, index) {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost = {
        index: index,
        id: supplement.id,
        name: supplement.name,
        payeeType: supplement.payeeType,
        vendorId: supplement.vendorId,
        vendorName: supplement.vendorName,
        attachments: supplement.attachments,
        processingAction: supplement.processingAction,
        description: supplement.description,
        cost: supplement.cost,
        sellingPrice: supplement.sellingPrice,
        dealId: supplement.dealId,
        isInGlBalance: supplement.isInGlBalance,
        dealerDefaultCostId: supplement.dealerDefaultCostId,
        isAddingCost: true
      }
    },
    saveSupplementalCost: function (dealStatusWizardModel) {
      this.supplementalCosts.forEach(function (cost) {
        var supplementalCost = {
          id: cost.id,
          dealerDefaultCostId: cost.dealerDefaultCostId,
          name: cost.name,
          payeeType: cost.payeeType,
          vendorId: cost.vendorId,
          vendorName: cost.vendorName,
          attachments: cost.attachments,
          processingAction: cost.processingAction,
          description: cost.description,
          cost: cost.cost,
          sellingPrice: cost.sellingPrice,
          isInGlBalance: cost.isInGlBalance,
          delete: cost.delete === undefined ? false : cost.delete
        }

        dealStatusWizardModel.supplementalCosts.push(supplementalCost)
      }, this)
    },
    addSupplementalCost: function () {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost.isAddingCost = true
    },
    removeSupplementalCost (index) {
      var item = this.supplementalCosts[index]
      if (item.id === 0) {
        this.$delete(this.supplementalCosts, index)
      } else {
        item.delete = true
      }
    },
    toggleTrade: function () {
      if (!this.hasTradeInventory && this.tradeDealItems.length === 0) {
        this.AddDealItem({type: 'Trade'})
      }
    },
    AddDealItem: function (configuration) {
      var config = {
        sectionId: this.deal.items.filter(x => x.dealItemType === configuration.type).length + this.getIndexOffset(configuration.type)
      }

      var dealItem = this.createGenericDealItem(configuration)
      dealItem.dealItemType = configuration.type
      dealItem.config = config
      this.deal.items.push(dealItem)
    },
    getIndexOffset (type) {
      var offset
      if (type === 'Sold') {
        offset = 100
      } else if (type === 'Trade') {
        offset = 200
      } else if (type === 'LeaseRenewal') {
        offset = 300
      }
      return offset
    },
    createGenericDealItem (configuration) {
      return {
        id: 0,
        vehicleType: null,
        salePrice: null,
        frontValue: null,
        holdValue: null,
        mvpValue: null,
        warValue: null,
        finResValue: null,
        tradeNo: '',
        itemProducts: [],
        forInventory: (configuration && configuration.forInventory !== null) ? configuration.forInventory : null,
        lineComment: '',
        status: 'Active',
        dealItemType: configuration.type,
        lineAttachments: [],
        isPrimary: true
      }
    },
    removeDealItem (type) {
      var sectionId
      var offset = this.getIndexOffset(type)

      if (type === 'Sold') {
        sectionId = this.soldItems.length + offset - 1
      } else if (type === 'Trade') {
        sectionId = this.tradeDealItems.length + offset - 1
      } else if (type === 'LeaseRenewal') {
        sectionId = this.leaseRenewalDealItems.length + offset - 1
      }

      var dealItemsFiltered = this.deal.items.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.deal.items = dealItemsFiltered
    },
    toggleLeaseRenewal: function () {
      if (!this.hasLeaseInventory && this.leaseRenewalDealItems.length === 0) {
        this.AddDealItem({type: 'LeaseRenewal'})
      }
    },
    onConfirmSaveBookedDeal (confirmed) {
      if (confirmed) {
        this.onSaveConfirmed()
      } else {
        this.bookingConfirmationActive = false
      }
    },
    onSaveConfirmed: function () {
      if (this.hasSoldItems) {
        if (this.selectedVehicleType) {
          this.firstSoldLineItem.vehicleType = this.selectedVehicleType.name
        }

        if (this.workingMsrp !== this.baseInventoryMsrp) {
          this.firstSoldItemInventory.msrp = this.workingMsrp
        }

        if ((this.firstSoldItemInventory.modelId !== null || this.firstSoldItemInventory.vehicleMakeVersionId !== null) && this.selectedMakeModelHbc) {
          this.saveSoldItemAttributes()
        }
        this.ensureIdDataTypeSafety(this.firstSoldLineItem.itemProducts)
        var dealStatusWizardModel = {
          inventoryId: this.firstSoldLineItem.forInventory.id,
          dealItemId: this.firstSoldLineItem.id,
          frontValue: (this.isGrossDisabled(this.firstSoldLineItem)) ? this.calculatedTotalFrontGross(this.firstSoldLineItem) : this.firstSoldLineItem.frontValue,
          holdValue: this.firstSoldLineItem.holdValue ? this.firstSoldLineItem.holdValue : null,
          warValue: this.firstSoldLineItem.warValue ? this.firstSoldLineItem.warValue : null,
          finResValue: this.firstSoldLineItem.finResValue ? this.firstSoldLineItem.finResValue : null,
          productValue: this.firstSoldLineItem.itemProducts.filter(y => y.type === 'Product' && !y.delete).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0),
          mvpValue: this.firstSoldLineItem.mvpValue ? this.firstSoldLineItem.mvpValue : null,
          salePrice: this.firstSoldLineItem.salePrice ? this.firstSoldLineItem.salePrice : null,
          products: this.firstSoldLineItem.itemProducts,
          rdrComplete: (this.isNewVehicleType || (this.isCpoVehicleType && this.allowRdrOnCpo)) ? this.firstSoldLineItem.forInventory.rdrComplete : false,
          rdrCompleteAt: (this.isNewVehicleType || (this.isCpoVehicleType && this.allowRdrOnCpo)) ? this.firstSoldLineItem.forInventory.rdrCompletedAt : null,
          spiff: this.firstSoldLineItem.spiff ? this.firstSoldLineItem.spiff : null,
          salesPersonId: (this.selectedSalesAgent) ? this.selectedSalesAgent.id : null,
          customerId: (this.selectedCustomer) ? this.selectedCustomer.id : null,
          leadSourceId: (this.selectedLeadSource) ? this.selectedLeadSource.id : null,
          financeProcessedById: (this.selectedFinanceProcessedBy) ? this.selectedFinanceProcessedBy.id : null,
          supplierId: (this.selectedSupplier) ? this.selectedSupplier.id : null,
          vehicleType: this.firstSoldLineItem.vehicleType,
          commissions: [],
          supplementalCosts: [],
          previousVehicleMakeId: (this.selectedMake && !this.hasTradeInventorySwitch && !this.hasLeaseInventorySwitch) ? this.selectedMake.id : null,
          items: this.deal.items,
          bookingDeal: this.progressStatus && !this.quickSaveActive,
          dealSaleType: this.deal.saleType,
          salesPersonManagerId: (this.selectedSalesAgentManager != null) ? this.selectedSalesAgentManager.id : null,
          dealCode: this.deal.dealCode,
          supplierNo: this.deal.supplierNo,
          contractType: this.selectedContractType.name,
          leaseContractTermType: this.selectedContractType.name === 'Retail' || !this.selectedLeaseContractTermType ? null : this.selectedLeaseContractTermType.altName,
          leaseAmount: this.selectedContractType.name === 'Retail' ? null : this.deal.leaseAmount,
          residualValue: this.selectedContractType.name === 'Retail' ? null : this.deal.residualValue,
          cashDown: this.isCashDown,
          depositAmount: !this.isCashDown ? null : this.deal.depositAmount
        }

        // To prevent posting too much data im deleting these sub objects here to not exceed the json post limit. Better more correct option is to only sent back to the server what we need
        dealStatusWizardModel.items.forEach((x) => {
          delete x.forInventory.serviceRequests
          delete x.forInventory.accessoryDetailWarnings
          delete x.forInventory.accessoryEvents
          delete x.forInventory.allReports
          delete x.forInventory.appraisalRating
          delete x.forInventory.appraisals
          delete x.forInventory.carFaxRating
          delete x.forInventory.deals
          delete x.forInventory.serviceRequests
          delete x.forInventory.vinUsageHistory
          delete x.forInventory.notes
        })

        this.saveCommission(dealStatusWizardModel)
        this.saveSupplementalCost(dealStatusWizardModel)

        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true
        this.isLoading = true

        dealService.dealStatusWizardSave(dealStatusWizardModel).then(response => {
          if (response.errors) {
            this.onValidationError(response.errors)
            this.bookingConfirmationActive = false
            this.isSaving = false
            this.isLoading = false
          } else {
            if (this.selectedMake) {
              this.deal.previousVehicleMakeId = this.selectedMake.id
              this.deal.previousVehicleMakeName = this.selectedMake.name
            }

            this.deal.salesPersonId = (this.selectedSalesAgent) ? this.selectedSalesAgent.id : null
            this.deal.salesPersonName = (this.selectedSalesAgent) ? this.selectedSalesAgent.fullName : null
            this.deal.customer.Id = (this.selectedCustomer) ? this.selectedCustomer.id : null
            this.deal.sourceId = (this.selectedLeadSource) ? this.selectedLeadSource.id : null
            this.deal.sourceName = (this.selectedLeadSource) ? this.selectedLeadSource.name : null
            this.deal.financeProcessedById = (this.selectedFinanceProcessedBy) ? this.selectedFinanceProcessedBy.id : null
            this.deal.financeProcessedByName = (this.selectedFinanceProcessedBy) ? this.selectedFinanceProcessedBy.fullName : null
            this.deal.supplierId = (this.selectedSupplier) ? this.selectedSupplier.id : null
            this.deal.items = response.deal.items
            this.deal.backupDeals = response.backupDeals
            this.deal.salesPersonManagerId = (this.selectedSalesAgentManager) ? this.selectedSalesAgentManager.id : null
            this.deal.salesPersonManagerName = (this.selectedSalesAgentManager) ? this.selectedSalesAgentManager.fullName : ' '

            this.deal.supplementalCosts = []
            response.deal.supplementalCosts.forEach(function (supplementalCost) {
              this.deal.supplementalCosts.push(supplementalCost)
            }, this)

            this.deal.commissions = []
            response.deal.commissions.forEach(function (commission) {
              this.deal.commissions.push(commission)
            }, this)

            // we need to save adjustments by updating the uuid as there is internal logic watching this variable in the control
            this.saveAdjustmentsUuid = this.$uuid.v4()
            let that = this
            this.$nextTick(() => {
              setTimeout(function () {
                that.isSaving = false
                that.isLoading = false
                that.onComplete()
              }, 500)
            })
          }
        }).catch((error) => {
          this.onError(error)
          this.bookingConfirmationActive = false
          this.isSaving = false
          this.isLoading = false
        })
      }
    },
    bookDeal () {
      this.$validator.validate().then(isValid => {
        if (!isValid) {
          // for some reason the multiselects components returns two error items for one input, first doesnt have scope but the second one has. Inputs on the form return one error item
          let errorScope = this.$validator.errors.items[0].scope ? this.$validator.errors.items[0].scope : null
          if (errorScope === null) {
            errorScope = this.$validator.errors.items[1] && this.$validator.errors.items[1].scope ? this.$validator.errors.items[1].scope : null
          }
          if (errorScope !== null) {
            let pageIndex = errorScope.substr(-1) - 1
            this.active = pageIndex
          }
          this.$nextTick(() => { this.$goToFirstValidationError(this.$validator.errors.items) })
          // unsaved trades confirmation modal may be open, close it
          this.saveChangedDealItemsConfirmation = false
          // close confirmation modal maybe open close it
          this.closeConfirmationActive = false
        } else {
          if (this.isSaleTypeOfRetail && this.progressStatus && !(this.hasTradeInventory || this.hasLeaseInventory || this.selectedMake) && !this.quickSaveActive) {
            this.serverErrorMessage = 'One of previous make or trade or lease lenewal / lease return is required for a retail deal.'
            this.active = 3
            // unsaved trades confirmation modal may be open, close it
            this.saveChangedDealItemsConfirmation = false
            // close confirmation modal maybe open close it
            this.closeConfirmationActive = false
          } else {
            this.validateBeforeSubmit()
          }
        }
      })
    },
    calculatedSubFrontGross: function (dealItem) {
      let reconCost = this.calculatedReconLive(dealItem)
      let tradeAcvDifference = this.totalAcvDifference || 0.00
      let inventoryAdditionalCosts = this.inventoryAdditionalCostsTotal(dealItem)
      let actualInventoryCost = this.purchaseCostDerived + reconCost + tradeAcvDifference + inventoryAdditionalCosts
      let result = dealItem.salePrice + this.additionalCostsGP - (actualInventoryCost)
      return this.round2Digits(result)
    },
    dealItemTotalGP: function (dealItem) {
      let holdValue = dealItem.vehicleType === 'New' ? dealItem.holdValue : 0.00
      let result = dealItem.frontValue + dealItem.warValue + dealItem.finResValue + holdValue + dealItem.mvpValue + this.dealItemProductGP(dealItem)
      return this.round2Digits(result)
    },
    dealItemProductGP: function (dealItem) {
      return dealItem.itemProducts.filter(y => y.type === 'Product' && !y.delete).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0)
    },
    onUnlockRecon: function () {
      if (!this.isReadOnly) {
        this.isReconLocked = false
        this.isReconEditable = true
      }
    },
    onLockRecon: function () {
      if (!this.isReadOnly) {
        this.isReconLocked = true
        this.isReconEditable = false
      }
    },
    inventoryHoldValue: function (inventoryItem) {
      if (inventoryItem) {
        let msrpToUse = inventoryItem.msrp
        let msrpDeductions = this.defaultMsrpDeductions ? this.defaultMsrpDeductions : 0
        if (this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMake && this.firstSoldItemVehicleMakeModel.msrp) {
          msrpToUse = (this.firstSoldItemVehicleMake.holdbackValueCalculation && this.firstSoldItemVehicleMake.holdbackValueCalculation === 'BaseMsrp') ? this.firstSoldItemVehicleMakeModel.msrp : msrpToUse
        }
        if (msrpToUse && msrpToUse !== 0 && this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMakeModel.holdBackPercentage !== null) {
          return (msrpToUse - msrpDeductions) * this.firstSoldItemVehicleMakeModel.holdBackPercentage / 100
        } else {
          return this.firstSoldItemVehicleMakeModel ? this.firstSoldItemVehicleMakeModel.holdValue - msrpDeductions : 0.00
        }
      }
      return 0.00
    },
    onSetHoldBack (dealItem) {
      if (dealItem.holdValue !== this.inventoryHoldValue(dealItem.forInventory)) {
        if (!this.isHoldbackEditable) {
          this.failedToast('You need to unlock holdback for editing in order to reset the holdback value!')
        } else {
          dealItem.holdValue = this.inventoryHoldValue(dealItem.forInventory)
        }
      }
    },
    calculatedReconCDK: function (dealItem) {
      let generalLedgerBalance = 0.00
      if (dealItem.forInventory) {
        if (dealItem.forInventory.generalLedgerBalance) {
          generalLedgerBalance = dealItem.forInventory.generalLedgerBalance
        }
      }
      let result = generalLedgerBalance - (this.purchaseCostDerived + this.inventoryAdditionalCostsTotal(dealItem))
      return this.round2Digits(result)
    },
    calculatedReconLive: function (dealItem) {
      let result = 0.00
      /*
      if (dealItem.forInventory && dealItem.forInventory.reconCost) {
        result = dealItem.forInventory.reconCost + -(this.inventoryAdditionalCostsTotal(dealItem))
      } else {
        result = -(this.inventoryAdditionalCostsTotal(dealItem))
      }
      */
      if (dealItem.forInventory && dealItem.forInventory.reconCost) {
        result = dealItem.forInventory.reconCost
      }
      return this.round2Digits(result)
    },
    inventoryAdditionalCostsTotal: function (dealItem) {
      return dealItem.forInventory && dealItem.forInventory.additionalCostsTotal ? this.round2Digits(dealItem.forInventory.additionalCostsTotal) : 0.00
    },
    frontGrossSubtotalLive: function (dealItem) {
      let result = this.purchaseCostDerived + this.calculatedReconLive(dealItem) + this.inventoryAdditionalCostsTotal(dealItem)
      return this.round2Digits(result)
    },
    frontGrossSubtotalCDK: function (dealItem) {
      let result = (dealItem.forInventory) ? (dealItem.forInventory.generalLedgerBalance) ? dealItem.forInventory.generalLedgerBalance : 0.00 : 0.00
      return this.round2Digits(result)
    },
    calculatedSubFrontGrossCDK: function (dealItem) {
      let tradeAcvDifference = this.totalAcvDifference || 0.00
      let actualInventoryCost = this.frontGrossSubtotalCDK(dealItem) + tradeAcvDifference
      let result = dealItem.salePrice + this.additionalCostsGP - (actualInventoryCost)
      return this.round2Digits(result)
    },
    unlockBookedDeal: function () {
      let isBeingCalledFromBookDealCapture = true
      eventBus.$emit('unlock-booked-deal', { isBeingCalledFromBookDealCapture })
      this.$emit('update:bookDealCaptureActive', false)
    },
    restoreDealAdditionalCosts: function () {
      let savedSupplementalCosts = this.supplementalCosts.filter((x) => x.id !== 0)
      savedSupplementalCosts.forEach((x) => {
        x.delete = true
      })
      this.supplementalCosts = savedSupplementalCosts
      let dealSupplementalCosts = this.currentDealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
      if (this.firstSoldItemInventory && this.firstSoldItemInventory.vehicleMakeId) {
        let vehicleMakeSpecificCosts = this.currentDealer.defaultSupplementalCosts.filter(f => f.vehicleMakes.some(o => o.vehicleMakeId === this.firstSoldItemInventory.vehicleMakeId))
        let vehicleMakeSupplementalCosts = vehicleMakeSpecificCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
        dealSupplementalCosts = this.currentDealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
        dealSupplementalCosts.forEach(function (item, index) {
          let foundIndex = vehicleMakeSupplementalCosts.findIndex(x => x.name === item.name)
          if (foundIndex === -1) {
            vehicleMakeSupplementalCosts.push(item)
          }
        })
        dealSupplementalCosts = []
        dealSupplementalCosts = [...vehicleMakeSupplementalCosts]
      }
      dealSupplementalCosts = this.uniqueDefaultCosts(dealSupplementalCosts)
      let validSupplementalCosts = dealSupplementalCosts.filter((y) => {
        var isValid = true
        if (this.isSaleTypeOfWholesale) {
          isValid = isValid && y.appliesTo.includes('Wholesale')
        }

        if (this.isSaleTypeOfRetail) {
          isValid = isValid && y.appliesTo.includes('Retail')
        }

        if (this.firstSoldLineItem && this.firstSoldLineItem.vehicleType !== '') {
          isValid = isValid && y.appliesTo.includes(this.firstSoldLineItem.vehicleType)
        }

        return isValid === true
      })

      validSupplementalCosts.forEach((x) => {
        let cost = {
          ...x,
          id: 0,
          dealerDefaultCostId: x.id,
          delete: false
        }
        this.supplementalCosts.push(cost)
      })
    },
    setCurrentAsSalesManager: function () {
      this.selectedSalesAgentManager = { dealerId: this.currentUser.dealerId,
        fullName: this.currentUser.fullName,
        id: this.currentUser.id}
    },
    togglePurchaseCostEditable: function () {
      if (!this.isPurchaseCostDisabled) {
        this.isPurchaseCostEditable = !this.isPurchaseCostEditable
      }
    },
    setHoldbackValue: function (value, ev) {
      this.removeSuccessClassToHoldbackButtons()
      ev.target.classList.add('is-success')
      let id = ev.target.parentElement.id.toString()
      let ids = this.holdBackCalculatorButtonIds
      if (ids.findIndex(y => y === id) !== -1) {
        ev.target.parentElement.classList.add('is-success')
      }
      this.isHoldbackCalculaterFormEditable = false
      this.selectedHoldbackValue = Number(value)
      this.confirmHoldbackSelectionActive = true
    },
    removeSuccessClassToHoldbackButtons: function () {
      let ids = this.holdBackCalculatorButtonIds
      ids.forEach((id) => {
        let el = document.getElementById(id)
        el.classList.remove('is-success')
      })
    },
    setInitialHoldbackValues: function () {
      let initialholdBackPercentage = this.firstSoldItemVehicleMakeModel ? this.formatNumberToTwoDecimalPlaces(this.firstSoldItemVehicleMakeModel.holdBackPercentage) : 0
      this.workingMsrp = this.soldInventoryMsrp
      this.workingBaseMsrp = this.baseInventoryMsrp
      this.workingMsrpDeductions = this.defaultMsrpDeductions
      this.calculatedHoldback = (this.workingMsrp - this.workingMsrpDeductions) * initialholdBackPercentage / 100
      this.calculatedBaseHoldback = (this.workingBaseMsrp - this.workingMsrpDeductions) * initialholdBackPercentage / 100
      this.calculatedHoldbackPercentage = initialholdBackPercentage
      this.calculatedBaseHoldbackPercentage = initialholdBackPercentage
      this.removeSuccessClassToHoldbackButtons()
    },
    calculatedHoldbackPercentageChange: function () {
      this.calculatedHoldbackPercentage = this.calculatedHoldbackPercentage.replace(/[^\d.]/g, '')
    },
    calculatedHoldbackChange: function () {
      let workingMsrpTotal = this.workingMsrp - this.workingMsrpDeductions
      if (workingMsrpTotal > 0 && workingMsrpTotal < this.calculatedHoldback) {
        this.calculatedHoldback = workingMsrpTotal
        this.calculatedHoldbackPercentage = 100
      }
    },
    calculatedBaseHoldbackPercentageChange: function () {
      this.calculatedBaseHoldbackPercentage = this.calculatedBaseHoldbackPercentage.replace(/[^\d.]/g, '')
    },
    calculatedBaseHoldbackChange: function () {
      let workingBaseMsrpTotal = this.workingBaseMsrp - this.workingMsrpDeductions
      if (workingBaseMsrpTotal > 0 && workingBaseMsrpTotal < this.calculatedBaseHoldback) {
        this.calculatedBaseHoldback = workingBaseMsrpTotal
        this.calculatedBaseHoldbackPercentage = 100
      }
    },
    toggleShowHideHoldbackCalculator: function () {
      this.showHoldbackCalc = !this.showHoldbackCalc
      this.isHoldbackEditable = this.showHoldbackCalc
      if (this.showHoldbackCalc && (this.firstSoldItemVehicleMakeModel === null || this.firstSoldItemVehicleMakeModel === undefined)) {
        this.showCompleteMakeModel = true
        this.$nextTick(() => {
          this.initializeSelectedMakeModelHbc()
        })
      }
    },
    initializeSelectedMakeModelHbc: function () {
      var exteriorColor = this.firstSoldItemInventory.attributes.find((x) => x.name === 'ExteriorColor')
      var interiorColor = this.firstSoldItemInventory.attributes.find((x) => x.name === 'InteriorColor')
      var vehicleTrim = this.firstSoldItemInventory.attributes.find((x) => x.name === 'Trim')
      var vehicleStyle = this.firstSoldItemInventory.attributes.find((x) => x.name === 'Style')
      var searchType = this.firstSoldItemInventory.attributes.find((x) => x.name === 'SearchType')
      var altExteriorColors = this.firstSoldItemInventory.attributes.find((x) => x.name === 'AlternateExteriorColors')
      var altInteriorColors = this.firstSoldItemInventory.attributes.find((x) => x.name === 'AlternateInteriorColors')
      var searchTags = this.firstSoldItemInventory.attributes.find((x) => x.name === 'SearchTags')
      var searchNotes = this.firstSoldItemInventory.attributes.find((x) => x.name === 'SearchNotes')

      var vehicleAge = moment().year() - (+this.currentInventory.vehicleProductionYear)
      var cpoAllowed = true
      var autoCpoStatus = 'Ineligible'

      if (vehicleAge >= 0 && vehicleAge <= this.currentDealer.autoCpoAgeLimit) {
        cpoAllowed = true
        autoCpoStatus = 'Cpo'
      } else if (vehicleAge > this.currentDealer.autoCpoAgeLimit && vehicleAge <= this.currentDealer.cpoQualifyingAge) {
        cpoAllowed = true
        autoCpoStatus = 'Eligible'
      }

      this.selectedMakeModelHbc = {
        exteriorColor: exteriorColor ? exteriorColor.value : '',
        interiorColor: interiorColor ? interiorColor.value : '',
        trim: vehicleTrim ? vehicleTrim.value : '',
        style: vehicleStyle ? vehicleStyle.value : '',
        makeId: this.firstSoldItemVehicleMake ? this.firstSoldItemVehicleMake.id : null,
        selectedMake: this.firstSoldItemVehicleMake,
        modelId: this.firstSoldItemVehicleMakeModel ? this.firstSoldItemVehicleMakeModel.id : null,
        selectedModel: this.firstSoldItemVehicleMakeModel,
        productionYear: this.firstSoldItemInventory.vehicleProductionYear,
        customMakeName: this.firstSoldItemInventory.customMake === '' ? null : this.firstSoldItemInventory.customMake,
        customModelName: this.firstSoldItemInventory.customModel === '' ? null : this.firstSoldItemInventory.customModel,
        customMakeModel: this.firstSoldItemInventory.customVehicleMakeVersion,
        vehicleAge: vehicleAge,
        cpoAllowed: cpoAllowed,
        autoCpoStatus: autoCpoStatus,
        locateType: (searchType && searchType.value && searchType.value !== '') ? this.definedTypes.allowedLocateTypes.options.find((x) => x.name === searchType.value) : null,
        altExteriorColors: (altExteriorColors && altExteriorColors.value && altExteriorColors.value.length > 0) ? altExteriorColors.value.split(',') : [],
        altInteriorColors: (altInteriorColors && altInteriorColors.value && altInteriorColors.value.length > 0) ? altInteriorColors.value.split(',') : [],
        tags: (searchTags && searchTags.value && searchTags.value.length > 0) ? searchTags.value.split(',') : [],
        notes: searchNotes ? searchNotes.value : '',
        transmissionType: this.firstSoldItemInventory.transmissionType
      }
    },
    saveSoldItemAttributes: function () {
      if (this.firstSoldItemInventory.modelId !== null || this.firstSoldItemInventory.vehicleMakeVersionId !== null) {
        let setAttribute = function (model, name, value) {
          var existingAttribute = model.attributes.find((x) => x.name === name)

          if ((value === '') && existingAttribute) {
            existingAttribute.status = 'Deleted'
            return
          }

          if (existingAttribute) {
            existingAttribute.value = value || ''
          } else {
            model.attributes.push({name: name, value: value})
          }
        }

        setAttribute(this.firstSoldItemInventory, 'ExteriorColor', this.selectedMakeModelHbc.exteriorColor)
        setAttribute(this.firstSoldItemInventory, 'InteriorColor', this.selectedMakeModelHbc.interiorColor)
        setAttribute(this.firstSoldItemInventory, 'Style', this.selectedMakeModelHbc.style)
        setAttribute(this.firstSoldItemInventory, 'Trim', this.selectedMakeModelHbc.trim)
        setAttribute(this.firstSoldItemInventory, 'ProductionYear', this.selectedMakeModelHbc.productionYear)
        setAttribute(this.firstSoldItemInventory, 'Make', this.selectedMakeModelHbc.customMakeName)
        setAttribute(this.firstSoldItemInventory, 'Model', this.selectedMakeModelHbc.customModelName)
        setAttribute(this.firstSoldItemInventory, 'SearchType', (this.selectedMakeModelHbc.locateType && this.selectedMakeModelHbc.locateType.name) ? this.selectedMakeModelHbc.locateType.name : '')
        setAttribute(this.firstSoldItemInventory, 'AlternateExteriorColors', (this.selectedMakeModelHbc.altExteriorColors && this.selectedMakeModelHbc.altExteriorColors.length > 0) ? this.selectedMakeModelHbc.altExteriorColors.join(',') : '')
        setAttribute(this.firstSoldItemInventory, 'AlternateInteriorColors', (this.selectedMakeModelHbc.altInteriorColors && this.selectedMakeModelHbc.altInteriorColors.length > 0) ? this.selectedMakeModelHbc.altInteriorColors.join(',') : '')
        setAttribute(this.firstSoldItemInventory, 'SearchTags', (this.selectedMakeModelHbc.tags && this.selectedMakeModelHbc.tags.length > 0) ? this.selectedMakeModelHbc.tags.join(',') : '')
        setAttribute(this.firstSoldItemInventory, 'SearchNotes', (this.selectedMakeModelHbc.notes && this.selectedMakeModelHbc.notes.length > 0) ? this.selectedMakeModelHbc.notes : '')
      }
    },
    onSaveMakeModelSelectorCallback: function () {
      if (this.selectedMakeModelHbc && this.selectedMakeModelHbc.modelId > 0) {
        // only close make model selector if model was selected
        this.showCompleteMakeModel = false
      }
    },
    onConfirmHoldbackSelection: function (selection) {
      if (selection) {
        this.firstSoldLineItem.holdValue = this.selectedHoldbackValue
        this.confirmHoldbackSelectionActive = false
        this.showHoldbackCalc = false
      } else {
        this.selectedHoldbackValue = null
        this.removeSuccessClassToHoldbackButtons()
        this.confirmHoldbackSelectionActive = false
        this.isHoldbackCalculaterFormEditable = true
      }
    },
    onQuickSave: function () {
      this.quickSaveActive = true
      this.bookDeal()
    },
    onTradeItemClick: function (tradeWorkFlowItem) {
      if (this.isTradeWorkFlowComplete) return false
      let inventoryId = +tradeWorkFlowItem.tradeId
      if (inventoryId !== -1) {
        this.inventoryForTradeWorkFlow = {
          ...tradeWorkFlowItem,
          id: inventoryId
        }
      } else {
        this.inventoryForTradeWorkFlow = null
      }
    },
    onTradeWorkFlowTitleCaptureSkipped: function () {
      this.tradeWorkflowTitleCaptureSkipped = true
    },
    onTradeWorkFlowArrivalCaptureSkipped: function () {
      this.tradeWorkflowArrivalCaptureSkipped = true
    },
    onTradeWorkFlowComplete: function (inventoryItem) {
      this.inventoryForTradeWorkFlow = null
      this.autoContinueTradeFlow = false

      // action is cancelled we dont need to do anything further
      if (inventoryItem.actionCancelled !== null && inventoryItem.actionCancelled) return false

      // background processor sets the checkrequest so we dont want to sit in a loop here
      if (inventoryItem.checkRequestSent === null || inventoryItem.checkRequestSent === undefined) {
        this.autoContinueTradeFlow = true
      }

      this.reloadDeal()
    },
    reloadDeal: function () {
      eventBus.$emit('reload-deal-list')
      eventBus.$emit('reload-deal')
    },
    onInventorySaved: function (modifiedInventoryItem) {
      let tradeItem = this.tradeItems.length > 0 ? this.tradeItems[0].forInventory : null
      if (tradeItem && tradeItem.id === modifiedInventoryItem.id && modifiedInventoryItem.status === 'Active') {
        tradeItem.status = 'Active'
        this.reloadDeal()
      }
      this.editTradePreEntryActive = false
    },
    onInventoryCancelled: function () {
      this.editTradePreEntryActive = false
    },
    showPPAConvertAction () {
      return this.dealHasTrade && this.isTradeTypePpaAppraisal && this.tradeItem.ppaApproved
    },
    showPPAApproveAction () {
      return this.dealHasTrade && this.isTradeTypePpaAppraisal && !this.tradeItem.ppaApproved
    },
    onConfirmCancel: function () {
      this.closeConfirmationActive = false
      this.$emit('update:statusProgressionWizardActive', true)
      this.$emit('update:bookDealCaptureActive', false)
    },
    onSaveAndClose: function () {
      this.onQuickSave()
    },
    onStockNoClick: function (inventory) {
      let that = this
      if (inventory) {
        this.$copyText(inventory.stockNo).then(
          function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
              position: 'is-top',
              type: 'is-info'
            })
          }, function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Could not copy stock no. to clipboard',
              position: 'is-top',
              type: 'is-warning'
            })
          }
        )
      }
    },
    fileUploadsComplete: function () {
      this.isUploadingFile = false
    },
    fileUploadsStarted: function () {
      this.isUploadingFile = true
    },
    getTotalAdditionalCostsHeader: function (inventory) {
      if (!inventory) return ''
      return 'Total: ' + (inventory.additionalCostsTotal ? this.$formatCurrency(inventory.additionalCostsTotal) : '0.00')
    },
    onInventorySupplementalCostsChanged: function (updateSupplementalCosts) {
      // check if cost got deleted, if deleted then emit message to fg-control to sync deletion
      let updatedActiveCosts = updateSupplementalCosts.filter(x => x.delete === false)
      if (this.firstSoldItemInventory.supplementalCosts.length > updatedActiveCosts.length) {
        let deletedCosts = this.$lodash.differenceWith(this.firstSoldItemInventory.supplementalCosts, updatedActiveCosts, this.$lodash.isEqual)
        if (deletedCosts && deletedCosts.length > 0) {
          eventBus.$emit('fg-sync-deleted-cost', deletedCosts[0])
        }
      }

      this.firstSoldItemInventory.supplementalCosts = []
      updateSupplementalCosts.filter((x) => x.delete === false).forEach(function (supplementalCost) {
        this.firstSoldItemInventory.supplementalCosts.push(supplementalCost)
      }, this)

      this.$forceUpdate()
    },
    onInventorySupplementalCostTotalsChanged: function (newCostTotal) {
      this.firstSoldItemInventory.additionalCostsTotal = newCostTotal
      this.$forceUpdate()
    },
    getServiceRequestCostTitle: function (amount) {
      let amountCurrency = this.$options.filters.currency(amount)
      return 'Approved Costs: ' + amountCurrency
    },
    requestStatusType: function (type) {
      let found = this.definedTypes.serviceRequestStatusType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.name
    },
    requestType: function (type) {
      let found = this.definedTypes.serviceRequestType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.name
    },
    buildServiceRequestLineItems: function (serviceRequestLines) {
      let result = '<table class="table is-striped is-narrow"><tr><th>Name</th><th>Status</th><th>Cost</th></tr>'
      serviceRequestLines.forEach(serviceRequestLine => {
        let name = serviceRequestLine.workRequired
        let status = serviceRequestLine.serviceRequestLineStatus
        let totalCost = this.$options.filters.currency(serviceRequestLine.totalCost)
        result += '<tr><td>' + name + '</td><td>' + status + '</td><td>' + totalCost + '</td></tr>'
      })
      result += '</table>'
      return result
    },
    convertIdToStatusType: function (id) {
      return this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === id)
    },
    calculatedFgAccrual: function (dealItem) {
      let result = this.calculatedSubFrontGross(dealItem) - this.calculatedSubFrontGrossCDK(dealItem)
      return result
    },
    calculatedFgAccrualCdk: function (dealItem) {
      // let result = this.calculatedSubFrontGross(dealItem) - this.calculatedSubFrontGrossCDK(dealItem)
      // return result < 0 ? -(result) : 0
      let result = this.calculatedSubFrontGrossCDK(dealItem) - this.calculatedSubFrontGross(dealItem) - this.cdkAdjustmentAmount
      return result
    },
    calculatedFgAccrualLive: function (dealItem) {
      // let result = this.calculatedSubFrontGross(dealItem) - this.calculatedSubFrontGrossCDK(dealItem)
      // return result < 0 ? 0 : result
      let result = this.calculatedSubFrontGross(dealItem) - this.calculatedSubFrontGrossCDK(dealItem) - this.liveAdjustmentAmount
      return result
    },
    calculatedTotalFrontGrossCDK: function (dealItem) {
      // return this.calculatedSubFrontGrossCDK(dealItem) - this.frontGrossLineItemsAmount
      return this.calculatedSubFrontGrossCDK(dealItem) - this.cdkAdjustmentAmount
    },
    calculatedTotalFrontGross: function (dealItem) {
      // let result = this.calculatedSubFrontGross(dealItem) - this.frontGrossLineItemsAmountLive
      // return result
      return this.calculatedSubFrontGross(dealItem) + this.liveAdjustmentAmount
    },
    getTotalFrontGrossClass: function (dealItem) {
      return this.isTotalFrontGrossWithInTolerance(dealItem) ? 'has-text-primary' : 'has-text-danger'
    },
    getTotalFrontGrossDiff: function (dealItem) {
      let diff = this.calculatedTotalFrontGrossCDK(dealItem) - this.calculatedTotalFrontGross(dealItem)
      return this.round2Digits(diff)
    },
    isTotalFrontGrossWithInTolerance (dealItem) {
      let diff = this.getTotalFrontGrossDiff(dealItem)
      return diff <= 1 && diff >= -1
    },
    showWithInToleranceNote (dealItem) {
      return this.getTotalFrontGrossDiff(dealItem) !== 0 && this.isTotalFrontGrossWithInTolerance(dealItem)
    },
    accrualLineStatusChange: function (isActive) {
      this.accrualLineStatus = isActive
    },
    canShowgAccrualInfoCdk: function (dealItem) {
      return this.calculatedFgAccrualCdk(dealItem) > -1
    },
    canShowgAccrualInfoLive: function (dealItem) {
      return this.calculatedFgAccrualLive(dealItem) > -1
    },
    frontGrossCostToggle: function (data) {
      if (data.applyTo === 'cdk') {
        if (data.useAmountAsIs) {
          this.frontGrossLineItemsAmount = data.amount
        } else {
          this.frontGrossLineItemsAmount += data.amount
          // if id=4 then the accrue purchase cost was checked in the control, we need to keep this in sync here
          if (data.id === 4) {
            // if amount is postive then the user checked, else if negative then the user unchecked
            this.defaultPurchaseCostCdk = data.amount > 0
          }
        }
        return
      }

      if (data.useAmountAsIs) {
        this.frontGrossLineItemsAmountLive = data.amount
      } else {
        this.frontGrossLineItemsAmountLive += data.amount
      }
    },
    getFgAccrualType: function (dealItem) {
      if (this.canShowgAccrualInfoCdk(dealItem)) return 'cdk'
      return 'live'
    },
    openEmail: function () {
      let subject = ''
      let message = ''
      window.location.href = 'mailto:' + this.supplierEmail + '?subject=' + subject + '&body=' + message
    },
    cdkGlbalanceToPurchaseCostPercentage: function (dealItem) {
      let glBalance = this.frontGrossSubtotalCDK(dealItem)

      if (glBalance > this.purchaseCostDerived) return 100

      let percentage = (glBalance / this.purchaseCostDerived) * 100
      return percentage
    },
    showAccruePurchaseCostInstruction: function (dealItem) {
      return this.cdkGlbalanceToPurchaseCostPercentage(dealItem) < 40
    },
    isGrossDisabled: function (dealItem) {
      if (this.progressStatus) return true

      if (this.totalCdkGlBalance(dealItem) < 15000) return false

      if (dealItem && dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) {
        return dealItem.forInventory.purchaseCostTotal > 15000
      }
      return false
    },
    toggleDefaultPurchaseCostCdk: function () {
      // we need to apply live purchase price by updating the uuid as there is internal logic watching this variable in the control
      this.toggleDefaultPurchaseCostCdkUuid = this.$uuid.v4()
    },
    updateFgAccrualKey: function () {
      this.fgAccrualKey = this.$uuid.v4()
    },
    syncDealAttachment: function (attachments) {
      this.deal.attachments = attachments
    },
    onToggleDefaultPurchaseCostCdk: function (value) {
      this.defaultPurchaseCostCdk = value
    },
    getBookedFgToolTip: function (dealItem) {
      return 'The booked Front GP does not match the current auto calculated Front GP of ' + this.$options.filters.currency(this.calculatedTotalFrontGross(dealItem))
    },
    totalCdkGlBalance: function (dealItem) {
      return this.cdkAdjustmentAmount + this.frontGrossSubtotalCDK(dealItem)
    },
    getExpectedGrossClass: function (dealItem) {
      return this.isTotalFrontGrossWithInTolerance(dealItem) ? 'is-primary' : 'is-danger'
    },
    getExpectedGrossText: function (dealItem) {
      if (this.getTotalFrontGrossDiff(dealItem) !== 0) {
        return 'Expected Accrual ' + this.$formatCurrency(this.getTotalFrontGrossDiff(dealItem))
      }

      return 'Reconciled GP ' + this.$formatCurrency(this.cdkAdjustmentAmount)
    },
    toggleFgDropdown: function () {
      eventBus.$emit('fg-open-with-calculator')
    },
    commissionRequestStatusChange: function (selectedCommissionRequestStatus, index) {
      if (selectedCommissionRequestStatus) {
        var commission = this.commissions[index]
        if (commission) {
          commission.requestStatus = selectedCommissionRequestStatus.altName
        }
      }
    }
  },
  watch: {
    selectedSupplier: function (newValue, oldValue) {
      if (newValue) {
        this.deal.buyerName = newValue.name
        this.deal.supplierId = newValue.id
        this.deal.supplierName = newValue.fullName
      }
    },
    selectedCustomer: function (newValue, oldValue) {
      if (newValue) {
        this.deal.buyerName = newValue.fullName
        this.deal.customer.Id = newValue.id
        this.deal.customer.fullName = newValue.fullName
      }
    },
    value: function (newValue, oldValue) {
      this.deal = newValue
    },
    salePriceWatcher: function (newValue, oldValue) {
      this.updateFrontGross()
    },
    hasTradeInventorySwitch: function (newValue, oldValue) {
      if (newValue === false) {
        let dealItemsFiltered = this.deal.items.filter(function (dealItem) {
          return dealItem.dealItemType !== 'Trade'
        })
        this.deal.items = dealItemsFiltered
      } else {
        this.selectedMake = null
      }
    },
    hasLeaseInventorySwitch: function (newValue, oldValue) {
      if (newValue === false) {
        let dealItemsFiltered = this.deal.items.filter(function (dealItem) {
          return dealItem.dealItemType !== 'LeaseRenewal'
        })
        this.deal.items = dealItemsFiltered
      } else {
        this.selectedMake = null
      }
    },
    calculatedHoldbackPercentage: function (newValue, oldValue) {
      if (this.calculatedHoldbackFocused) {
        // dont auto change the holdback as this is what the user changed
        return
      }

      this.calculatedHoldback = this.formatNumberToTwoDecimalPlaces((this.workingMsrp - this.workingMsrpDeductions) * newValue / 100)
    },
    calculatedHoldback: function (newValue, oldValue) {
      if (this.calculatedHoldbackPercentageFocused || this.workingMsrpFocused || this.workingMsrpDeductionsFocused) {
        // dont auto change the calculatedHoldbackPercentage
        return
      }

      this.calculatedHoldbackPercentage = this.workingMsrp && this.workingMsrp > 0 ? this.formatNumberToTwoDecimalPlaces(newValue * 100 / (this.workingMsrp - this.workingMsrpDeductions)) : this.calculatedHoldbackPercentage
    },
    workingMsrp: function (newValue, oldValue) {
      this.calculatedHoldback = this.formatNumberToTwoDecimalPlaces((newValue - this.workingMsrpDeductions) * this.calculatedHoldbackPercentage / 100)
    },
    calculatedBaseHoldbackPercentage: function (newValue, oldValue) {
      if (this.calculatedBaseHoldbackFocused) {
        // dont auto change the calculatedBaseHoldback
        return
      }

      this.calculatedBaseHoldback = this.formatNumberToTwoDecimalPlaces((this.workingBaseMsrp - this.workingMsrpDeductions) * newValue / 100)
    },
    calculatedBaseHoldback: function (newValue, oldValue) {
      if (this.workingBaseMsrpFocused || this.calculatedBaseHoldbackPercentageFocused || this.workingMsrpDeductionsFocused) {
        // dont auto change the calculatedBaseHoldbackPercentage
        return
      }

      this.calculatedBaseHoldbackPercentage = this.workingBaseMsrp && this.workingBaseMsrp > 0 ? this.formatNumberToTwoDecimalPlaces(newValue * 100 / (this.workingBaseMsrp - this.workingMsrpDeductions)) : this.calculatedBaseHoldbackPercentage
    },
    workingBaseMsrp: function (newValue, oldValue) {
      this.calculatedBaseHoldback = this.formatNumberToTwoDecimalPlaces((newValue - this.workingMsrpDeductions) * this.calculatedBaseHoldbackPercentage / 100)
    },
    workingMsrpDeductions: function (newValue, oldValue) {
      this.calculatedHoldback = (this.workingMsrp - newValue) * this.calculatedHoldbackPercentage / 100
      this.calculatedBaseHoldback = (this.workingBaseMsrp - newValue) * this.calculatedBaseHoldbackPercentage / 100
    },
    isHoldbackEditable: function (newValue, oldValue) {
      if (newValue === false) {
        if (this.showHoldbackCalc === true) {
          this.showHoldbackCalc = false
        }
      }
    },
    'selectedMakeModelHbc': {
      handler: function (newValue, oldValue) {
        if (newValue) {
          if (newValue.makeId && newValue.makeId > 0) {
            this.firstSoldItemInventory.vehicleMakeId = newValue.makeId
            this.firstSoldItemInventory.modelId = newValue.modelId
            this.firstSoldItemInventory.vehicleMakeVersionId = newValue.modelId
            this.firstSoldItemInventory.transmissionType = newValue.transmissionType
            this.firstSoldItemInventory.customVehicleMakeVersion = newValue.customMakeModel
            this.setInitialHoldbackValues()
          }
        }
      },
      deep: true,
      immediate: true
    },
    active: function (newValue, oldValue) {
      if (newValue === 3 && this.showHoldbackCalc === true) {
        // when on trades tab close close holdback calculator and hide selectedMakeModelHbc
        this.showHoldbackCalc = false
      }
    }
  },
  mounted: function () {
    this.setInitialDealValues()
  },
  created () {
    eventBus.$on('parent-make-model-onSave-book-deal-capture', this.onSaveMakeModelSelectorCallback)
    eventBus.$on('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$on('file-uploads-started', this.fileUploadsStarted)
    eventBus.$on('deal-finalized', this.onCancel)
  },
  destroyed () {
    eventBus.$off('parent-make-model-onSave-book-deal-capture', this.onSaveMakeModelSelectorCallback)
    eventBus.$off('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$off('file-uploads-started', this.fileUploadsStarted)
    eventBus.$off('deal-finalized', this.onCancel)
  }
}

</script>

<style scoped>

  .content ul {
    list-style-type: none;
    margin-top: 0
  }

  .el-steps--simple {
    border-radius: 0 !important;
  }

  .box {
    border:0;
  }

  .label-frontgp {
    margin-top: 3px !important;
  }

  div.field-label.is-normal > label
  {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width:120px;
    text-align:left;
  }

  .field.is-horizontal {
    margin: 3px !important;
  }

  input.is-small {
    height:2.5em;
  }

  .acv-warning {
    margin-left:5px;
  }

  .holdback-option-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .holdback-calc-close-btn {
    margin-top: -20px;
    width: 100%;
    padding: 5px 0px 5px 0px;
  }

  .edit-inventory {
    overflow:auto;
    height: calc(100vh - 300px);
  }

  .is-dealcode-label {
    padding-top: 0.375em;
    align-self: flex-end;
  }

  div.field-label.is-small > label
  {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width:85px;
    text-align:left;
    margin-right: auto !important;
  }

  .service-request-row {
    text-align: left;
    vertical-align:middle;
  }

  .service-request-column {
    text-align: left;
    vertical-align:middle;
  }

  .deal-code-label {
    width: 60px !important;
  }

  .supplier-no-label {
    width: 90px !important;
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-fullscreen .modal-card {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    max-height: 100% !important;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(110vh - 383px);
  }

  @media screen and (max-width: 850px) {
    .modal-fullscreen .modal-card-body {
      max-height: calc(89vh - 135px) !important;
    }
  }

  @media screen and (max-width: 797px) {
    .modal-fullscreen .modal-card-body {
      max-height: calc(75vh - 135px) !important;
    }
  }

  @media screen and (max-width: 536px) {
    .modal-fullscreen .modal-card-body {
      max-height: calc(60vh - 135px) !important;
    }
  }

  @media screen and (max-width: 390px) {
    .modal-fullscreen .modal-card-body {
      max-height: calc(50vh - 135px) !important;
    }
  }

  .footer-margin > * {
    padding-right: 10px;
  }

</style>
