<template>
  <div class="inline-block" :name="instanceId">
    <section v-if="isListMode" class="is-flex">
      <span
        class="list-icon clickable"
        :title="listIconTitle"
         @click.stop.prevent="onLaunchStatusChangeModal()"
         style="margin-top: 2px; margin-left: 5px;"
         >
         <i class="fal fa-thumbs-up fa" :id="instanceId"
         :disabled="!canShowCommissionModifyButton" :class="getDealListRequestIconColour()"></i>
      </span>
    </section>
    <div v-else class="quick-action">
      <button
        class="button"
        :id="instanceId"
        @click.stop.prevent="onLaunchStatusChangeModal()"
        :title="listIconTitle"
         :class="getDealRequestIconColour()"
         :disabled="!canShowCommissionModifyButton">
        <b-icon pack="fal" icon="thumbs-up" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <portal to="global-modal-portal" v-if="confirmStatusChangeActive && usePortal">
      <b-modal :active.sync="confirmStatusChangeActive" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Change commission request status</p>
          </header>
          <section class="modal-card-body" style="padding:12px">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form class="view-container">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">{{ purchaseCommissionTitle }}</label>
                    <div class="control has-icons-left">
                      <money name="commission" :disabled="true" data-vv-as="purchase commission" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('commission') }" type="text" placeholder="0.00" v-model="commissionRequest.commission" v-bind="$globalMoneyFormat"></money>
                      <span v-show="errors.has('commission')" class="help is-danger"><i v-show="errors.has('commission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commission') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Commission Source</label>
                    <input name="commissionSource" data-vv-as="commission source"  :class="{'input': true, 'is-danger': errors.has('commissionSource') }" type="text" placeholder="" v-model="commissionRequest.salesPersonName" :readonly="true">
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Commission Requested By</label>
                    <input name="commissionRequestedBy" data-vv-as="commission source" :class="{'input': true, 'is-danger': errors.has('commissionRequestedBy') }" type="text" placeholder="" v-model="commissionRequest.requestedBy" :readonly="true">
                  </div>
                </div>
                <div class="column is-12">
                  <button-list-selector
                  validateAs="commission request status"
                  label="Commission request status"
                  :required="true"
                  v-model="selectedCommissionRequestStatus"
                  :allowDeselection="false"
                  :availableValues="filteredDealCommissionRequestStatusTypes"
                  :enabled="true">
                  </button-list-selector>
                </div>
                <div class="column is-12" v-if="isProcessed">
                  <div class="field">
                    <label class="label">{{ processedByTitle }}</label>
                    <input name="commissionProcessedBy" data-vv-as="commission processed by"  :class="{'input': true, 'is-danger': errors.has('commissionProcessedBy') }" type="text" placeholder="" v-model="commissionRequest.requestProcessedBy" :readonly="true">
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmStatusChange(true)" type="button" :disabled="!canSave">Save</button>
            <button class="button is-danger" @click="onConfirmStatusChange(false)" type="button">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
    <b-modal :active.sync="confirmStatusChangeActive" :width="640" scroll="keep" :has-modal-card="true" v-if="confirmStatusChangeActive && !usePortal">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Change commission request status</p>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="field">
                  <label class="label">{{ purchaseCommissionTitle }}</label>
                  <div class="control has-icons-left">
                    <money name="commission" :disabled="true" data-vv-as="purchase commission" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('commission') }" type="text" placeholder="0.00" v-model="commissionRequest.commission" v-bind="$globalMoneyFormat"></money>
                    <span v-show="errors.has('commission')" class="help is-danger"><i v-show="errors.has('commission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commission') }}</span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Commission Source</label>
                  <input name="commissionSource" data-vv-as="commission source"  :class="{'input': true, 'is-danger': errors.has('commissionSource') }" type="text" placeholder="" v-model="commissionRequest.salesPersonName" :readonly="true">
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Commission Requested By</label>
                  <input name="commissionRequestedBy" data-vv-as="commission source" :class="{'input': true, 'is-danger': errors.has('commissionRequestedBy') }" type="text" placeholder="" v-model="commissionRequest.requestedBy" :readonly="true">
                </div>
              </div>
              <div class="column is-12">
                <button-list-selector
                validateAs="commission request status"
                label="Commission Request Status"
                :required="true"
                v-model="selectedCommissionRequestStatus"
                :allowDeselection="false"
                :availableValues="filteredDealCommissionRequestStatusTypes"
                :enabled="true">
                </button-list-selector>
              </div>
              <div class="column is-12" v-if="isProcessed">
                <div class="field">
                  <label class="label">{{ processedByTitle }}</label>
                  <input name="commissionProcessedBy" data-vv-as="commission processed by"  :class="{'input': true, 'is-danger': errors.has('commissionProcessedBy') }" type="text" placeholder="" v-model="commissionRequest.requestProcessedBy" :readonly="true">
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmStatusChange(true)" type="button" :disabled="!canSave">Save</button>
          <button class="button is-danger" @click="onConfirmStatusChange(false)" type="button">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import formattingMixin from '@/mixins/accounting/formatting'
import dealService from '@/services/dealService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import ButtonListSelector from '@/components/generic/ButtonListSelector'

export default {
  name: 'DealCommissionProcessingAction',
  mixins: [formattingMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'button-list-selector': ButtonListSelector
  },
  props: {
    commissionRequest: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    saveStatusChange: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isLoading: false,
      serverErrors: [],
      serverErrorMessage: '',
      confirmStatusChangeActive: false,
      selectedCommissionRequestStatus: null,
      initialRequestStatus: ''
    }
  },
  computed: {
    hasCommissionRequestModifyAccess: function () {
      return this.hasFeatureAccess('deal.commission.approve.reject')
    },
    listIconTitle: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.requestStatus === 'Requested') {
          return 'Commission approval requested, click to update commission request status.'
        } else if (this.commissionRequest.requestStatus === 'Approved') {
          if (this.commissionRequest.requestProcessedBy) {
            return 'Commission request approved by ' + this.commissionRequest.requestProcessedBy
          }
          return 'Commission request approved'
        } else if (this.commissionRequest.requestStatus === 'Rejected') {
          if (this.commissionRequest.requestProcessedBy) {
            return 'Commission request rejected by ' + this.commissionRequest.requestProcessedBy
          }
          return 'Commission request rejected'
        }
      }
      return 'Click to update commission request status.'
    },
    canShowCommissionModifyButton: function () {
      if (!this.hasCommissionRequestModifyAccess) return false
      return true
    },
    filteredDealCommissionRequestStatusTypes: function () {
      let excludedTypes = [3, 4]
      let filteredTypes = this.definedTypes.dealCommissionRequestStatusTypes.filter(function (item) {
        return excludedTypes.indexOf(item.id) === -1
      })
      return filteredTypes
    },
    purchaseCommissionTitle: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.requestStatus === 'Requested') {
          return 'Requested Purchase Commission'
        }
      }
      return 'Purchase Commission'
    },
    canSave: function () {
      if (this.selectedCommissionRequestStatus && this.commissionRequest && this.selectedCommissionRequestStatus.altName === this.commissionRequest.requestStatus) {
        return false
      }
      return true
    },
    isProcessed: function () {
      if (this.commissionRequest && this.commissionRequest.requestStatus) {
        if (this.commissionRequest.requestStatus === 'Approved' || this.commissionRequest.requestStatus === 'Rejected') {
          return true
        }
      }
      return false
    },
    processedByTitle: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.requestStatus === 'Approved') {
          return 'Commission Approved By'
        } else if (this.commissionRequest.requestStatus === 'Rejected') {
          return 'Commission Rejected By'
        }
      }
      return 'Commission Processed By'
    },
    commissionAmountIsZero: function () {
      if (this.commissionRequest && this.commissionRequest.commission !== undefined && this.commissionRequest.commission === 0) {
        return true
      }
      return false
    }
  },
  methods: {
    onConfirmStatusChange: function (save) {
      if (save) {
        if (this.saveStatusChange) {
          this.initialRequestStatus = this.selectedCommissionRequestStatus.altName
          this.isSaving = true
          this.serverErrorMessage = ''
          this.serverErrors = []
          let that = this
          dealService.updateCommissionRequestStatus(that.commissionRequest.dealId, that.commissionRequest.id, that.selectedCommissionRequestStatus.altName).then(response => {
            that.isSaving = false
            if (response.errors) {
              that.serverErrors = response.errors
            } else {
              eventBus.$emit('quickview-refresh')
              eventBus.$emit('reload-payment-tracking-list')
            }
          }).catch((error) => {
            that.serverErrorMessage = error.message
            that.isSaving = false
          })
        } else {
          this.$emit('commission-request-status-change', this.selectedCommissionRequestStatus, this.index)
        }
      }
      this.confirmStatusChangeActive = false
    },
    onLaunchStatusChangeModal: function () {
      if (!this.commissionAmountIsZero) {
        this.selectedCommissionRequestStatus = this.commissionRequest && this.commissionRequest.requestStatus ? this.definedTypes.dealCommissionRequestStatusTypes.find(x => x.altName === this.commissionRequest.requestStatus) : null
        this.confirmStatusChangeActive = true
      }
    },
    getDealRequestIconColour: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.requestStatus === 'Requested') {
          return 'is-warning'
        } else if (this.commissionRequest.requestStatus === 'Approved') {
          return 'is-success'
        } else if (this.commissionRequest.requestStatus === 'Rejected') {
          return 'is-danger'
        }
      }
      return ''
    },
    getDealListRequestIconColour: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.requestStatus === 'Requested') {
          return 'has-text-warning'
        } else if (this.commissionRequest.requestStatus === 'Approved') {
          return 'has-text-success'
        } else if (this.commissionRequest.requestStatus === 'Rejected') {
          return 'has-text-danger'
        }
      }
      return ''
    }
  },
  watch: {
    commissionRequest: function (newValue, oldValue) {
      if (newValue !== null) {
        if (newValue.commission !== undefined && newValue.commission === 0) {
          this.commissionRequest.requestStatus = 'Approved'
        } else if (newValue.commission && newValue.commission > 0 && this.commissionRequest.requestStatus === 'Approved') {
          this.commissionRequest.requestStatus = this.initialRequestStatus
        }
      }
    }
  },
  mounted: function () {
    if (this.commissionRequest) {
      if (this.commissionRequest && this.commissionRequest.requestStatus === 'Requested' && (this.commissionRequest.commission === undefined || this.commissionRequest.commission === 0)) {
        this.commissionRequest.requestStatus = 'Approved'
        this.initialRequestStatus = 'Approved'
      } else {
        this.initialRequestStatus = this.commissionRequest.requestStatus
      }
    }
  },
  beforeDestroy () {
  },
  updated: function () {
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 95% !important;
    max-width: 95% !important;
    width: 95% !important;
  }

</style>
