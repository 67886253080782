import mutationTypes from '../mutation-types.js'
import subscriberService from '../../services/subscriberService'
import moment from 'moment-timezone'

const state = {
  currentSubscriber: {}
}

const getters = {
  getCurrentSubscriber: state => {
    return state.currentSubscriber
  }
}

const mutations = {
  [mutationTypes.SET_CURRENT_SUBSCRIBER] (state, subscriber) {
    state.currentSubscriber = subscriber
  }
}

const actions = {
  setCurrentSubscriber ({dispatch, commit, state}, subscriber) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_CURRENT_SUBSCRIBER, subscriber)
      subscriberService.single(subscriber.id).then((data) => {
        commit(mutationTypes.SET_CURRENT_SUBSCRIBER, data)
        dispatch('user/setActiveTimeZone', null, { root: true }).then(timezone => {
          console.log('Current timezone set post set subscriber ' + moment.defaultZone.name)
        })
        resolve(data)
      }).catch((error) => {
        console.log(error)
        reject(error)
      }
      )
    })
  },
  updateCurrentSubscriber ({dispatch, commit, state}, subscriber) {
    let currentTimeZone = state.currentSubscriber.timezone
    commit(mutationTypes.SET_CURRENT_SUBSCRIBER, subscriber)
    if (subscriber.timezone !== currentTimeZone) {
      dispatch('user/setActiveTimeZone', null, { root: true }).then(timezone => {
        console.log('Current timezone set post set subscriber ' + moment.defaultZone.name)
      })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
