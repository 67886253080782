<template>
  <div class="is-inline-block">
    <span class="icon"><i class="fal fa-lg fa-play" @click.stop.prevent="onPlayVideo(true)" @click.ctrl.stop.prevent="onPlayVideo(false)" title="Play"></i></span>
    <portal to="global-modal-portal" v-if="isPlayingVideo" :disabled="!usePortal" :order="portalOrder">
        <b-modal :active.sync="isPlayingVideo" scroll="keep" :has-modal-card="true" :canCancel="true">
          <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
            </header>
            <section class="modal-card-body">
              <video class="video_player" controls crossorigin>
                  <source :src="videoUrl" type="video/mp4" />
              </video>
            </section>
            <footer class="modal-card-foot">
                <button key="video-player-close" class="button is-danger" @click.stop.prevent="onClose()">Close</button>
            </footer>
          </div>
        </b-modal>
    </portal>
  </div>
</template>

<script>

export default {
  name: 'VideoPlayerControl',
  components: {
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    portalOrder: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isPlayingVideo: false,
      useDirectStream: false
    }
  },
  computed: {
    options: function () {
      return {
        settings: ['speed', 'loop'],
        autoplay: false
      }
    },
    videoUrl: function () {
      let videoUrl = process.env.VUE_APP_ROOT_API + 'video/stream?url=' + encodeURI(this.url)
      return this.useDirectStream ? this.url : videoUrl
    }
  },
  methods: {
    onClose () {
      this.isPlayingVideo = false
      this.$emit('on-video-closed')
    },
    onPlayVideo (useDirectStream) {
      this.useDirectStream = useDirectStream
      this.isPlayingVideo = true
    }
  }
}
</script>

<style scoped>
    .video_player {
        width:auto !important;
        height:70vh !important;
        object-fit: cover !important;
    }
</style>
